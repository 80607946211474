import React, { useContext, useState } from "react";
import ThemeContext from "../../context/ThemeContext";
import "./CampaignAnalyticHeader.scss";
import InstagramIcon from "../../assets/icons/instagram.png";
import TikTokIcon from "../../assets/icons/tiktok.png";
import YoutubeIcon from "../../assets/icons/youtube.svg";
import SpotifyIcon from "../../assets/icons/spotify.png";

export default function CampaignAnalyticHeader({ setData, campaign }) {
  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === "light" ? "light-theme" : "dark-theme";

  // State to track the active tab
  const [activeTab, setActiveTab] = useState(0);

  // Handle tab click and update the active tab in state
  const handleTabClick = (index) => {
    setActiveTab(index);
    setData(index);
  };

  // Determine if the tab is active based on the clicked tab
  const isActive = (index) => {
    return activeTab === index
      ? `campaign-analytic-header__link--active campaign-analytic-header__link--${themeClass}--active`
      : "";
  };

  return (
    <div className="campaign-analytic-header">
      <div
        onClick={() => handleTabClick(0)}
        className={`campaign-analytic-header__link campaign-analytic-header__link--${themeClass} ${isActive(0)}`}
      >
        <img src={InstagramIcon} alt="Instagram Icon" className="campaign-analytic-header__icon" />
        Instagram
      </div>
      <div
        onClick={() => handleTabClick(1)}
        className={`campaign-analytic-header__link campaign-analytic-header__link--${themeClass} ${isActive(1)}`}
      >
        <img src={SpotifyIcon} alt="Podcast Icon" className="campaign-analytic-header__icon" />
        Podcast
      </div>
      <div
        onClick={() => handleTabClick(2)}
        className={`campaign-analytic-header__link campaign-analytic-header__link--${themeClass} ${isActive(2)}`}
      >
        <img src={YoutubeIcon} alt="Youtube Icon" className="campaign-analytic-header__icon" />
        Youtube
      </div>

      <div
        onClick={() => handleTabClick(3)}
        className={`campaign-analytic-header__link campaign-analytic-header__link--${themeClass} ${isActive(3)}`}
      >
        <img src={TikTokIcon} alt="TikTok Icon" className="campaign-analytic-header__icon" />
        TikTok
      </div>
    </div>
  );
}
