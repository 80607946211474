// src/components/Link/Link.jsx
import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import './Link.scss';
import ThemeContext from '../../context/ThemeContext';
import LinkIcon from './LinkIcon';
import Toggle from '../Toggle/Toggle';

const Link = ({ title, url, modify, isActive, isUser, changeLinkStatus }) => { // Corrected 'modifiy' to 'modify'

  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

  if (!isUser) {
    return (
      <RouterLink
        to={url}
        className={`link-cmp link-cmp--${themeClass} link-cmp${modify} ${isUser ? 'link-cmp--user' : ''}`}
      >
        <LinkIcon />
        <p className={`link-text link-text--${themeClass} link-text${modify} ${isUser ? 'link-text--user' : ''}`}>
          {title}
        </p>
      </RouterLink>
    );
  }

  return (
    <div
      className={`link-cmp link-cmp--${themeClass} link-cmp${modify} ${isUser ? 'link-cmp--user' : ''}`}
    >
      <LinkIcon />
      <RouterLink to={url} className={`link-text link-text--${themeClass} link-text${modify} ${isUser ? 'link-text--user' : ''}`}>
        {title}
      </RouterLink>
      {
        isUser && (
          <div className="link-cmp__toggle">
            <Toggle value={isActive} noC={true} modifier={'link-disable'} onChange={changeLinkStatus} />
          </div>
        )
      }
    </div>
  );


};

export default Link;
