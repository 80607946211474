import React from 'react';

const CheckIcon = ({ themeMode }) => {
  // const strokeColor = themeMode === 'light' ? 'black' : 'lightgrey';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      stroke={"green"}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
      className="check-icon"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <circle cx={12} cy={12} r={9} />
      <path d="M9 12l2 2l4 -4" />
    </svg>
  );
};

export default CheckIcon;
