import React, { useEffect, useRef } from 'react';
import './TikTokEmbed.scss';

const TikTokEmbed = ({ username }) => {
  const tiktokEmbedRef = useRef(null);

  // Extract the username if the input contains a full URL
  const getUsernameFromUrl = (input) => {
    const match = input.match(/@([a-zA-Z0-9_]+)/);
    return match ? match[1] : input;
  };

  const extractedUsername = getUsernameFromUrl(username);

  useEffect(() => {
    const loadTikTokScript = () => {
      const script = document.createElement('script');
      script.src = 'https://www.tiktok.com/embed.js';
      script.async = true;

      const appendScript = () => {
        if (tiktokEmbedRef.current) {
          tiktokEmbedRef.current.appendChild(script);
        }
      };

      // Ensure the TikTok script is loaded after the component mounts
      if (document.readyState === 'complete') {
        appendScript();
      } else {
        window.addEventListener('load', appendScript);
        return () => window.removeEventListener('load', appendScript);
      }
    };

    loadTikTokScript();
  }, []);

  return (
    <div id="tiktok-embed" ref={tiktokEmbedRef}>
      <blockquote
        className="tiktok-embed"
        cite={`https://www.tiktok.com/@${extractedUsername}`}
        data-unique-id={extractedUsername}
        data-embed-type="creator"
        style={{ maxWidth: '780px', minWidth: '288px' }}
      >
        <section>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://www.tiktok.com/@${extractedUsername}?refer=creator_embed`}
          >
            @{extractedUsername}
          </a>
        </section>
      </blockquote>
    </div>
  );
};

export default TikTokEmbed;
