
export const PointIcon = (themeMode) => {
  // const color = themeMode.themeMode === "light" ? "black" : "white";
  return (
    <div style={{ marginTop: '10px' }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="#0095f6" className="icon icon-tabler icons-tabler-filled icon-tabler-point">
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M12 7a5 5 0 1 1 -4.995 5.217l-.005 -.217l.005 -.217a5 5 0 0 1 4.995 -4.783z" />
      </svg>
    </div>

  );
};