import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import './VideoPlayer.scss';
import ThemeContext from '../../context/ThemeContext';

const VideoPlayer = ({ videoArray, modifier = ["video-player", "video-player__video"], closeOptions, postsLength, setPostIndex, handlePostLike, postLiked }) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState("muted");
  const { themeMode } = useContext(ThemeContext);

  // const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

  const playNextVideo = useCallback(() => {
    setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videoArray.length);
  }, [videoArray.length]);

  useEffect(() => {
    const video = videoRef.current;
    video.addEventListener('ended', playNextVideo);
    return () => {
      video.removeEventListener('ended', playNextVideo);
    };
  }, [playNextVideo]);

  useEffect(() => {
    const video = videoRef.current;
    if (isPlaying) {
      video.play().catch((error) => {
        console.error('Video playback failed: ', error);
      });
    } else {
      video.pause();
    }
  }, [isPlaying]);

  const handleTogglePlay = () => {
    setIsPlaying((prevIsPlaying) => !prevIsPlaying);
  };

  const handleNextVideo = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videoArray.length);
    setPostIndex((prevIndex) => Math.min(prevIndex + 1, postsLength - 1))

  };

  const handlePreviousVideo = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex - 1 + videoArray.length) % videoArray.length);
    setPostIndex((prevIndex) => Math.max(prevIndex - 1, 0))
  };

  const VolumeIcon = () => {
    const color = themeMode === 'light' ? '#b3b2b2' : 'white';

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="24" fill={color} className="video-player__vol-icon" viewBox="0 0 16 16">
        <path d="M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z" />
        <path d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z" />
        <path d="M8.707 11.182A4.486 4.486 0 0 0 10.025 8a4.486 4.486 0 0 0-1.318-3.182L8 5.525A3.489 3.489 0 0 1 9.025 8 3.49 3.49 0 0 1 8 10.475l.707.707zM6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06z" />
        <path d={isMuted ? "M4.5 11.5l2-2" : "M3 10l3.5-3.5"} /> {/* Mute/unmute icon */}
      </svg>
    )
  };

  const MuteIcon = () => {
    const color = themeMode === 'light' ? '#e1e1e1' : 'white';

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="24" fill={color} className="video-player__vol-icon" viewBox="0 0 16 16">
        <path d="M6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06zm7.137 2.096a.5.5 0 0 1 0 .708L12.207 8l1.647 1.646a.5.5 0 0 1-.708.708L11.5 8.707l-1.646 1.647a.5.5 0 0 1-.708-.708L10.793 8 9.146 6.354a.5.5 0 1 1 .708-.708L11.5 7.293l1.646-1.647a.5.5 0 0 1 .708 0z" />
      </svg>
    )
  }

  const CommentIcon = () => {
    const color = themeMode === 'light' ? '#e1e1e1' : 'white';

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill={color} className="video-player__vol-icon" viewBox="0 0 16 16" >
        <path d="M8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6-.097 1.016-.417 2.13-.771 2.966-.079.186.074.394.273.362 2.256-.37 3.597-.938 4.18-1.234A9.06 9.06 0 0 0 8 15z" />
      </svg >
    )
  }

  const LikeIcon = () => {
    let color = themeMode === 'light' ? '#e1e1e1' : 'white';
    color = postLiked ? 'rgb(255, 0, 102)' : color;

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill={color} className="video-player__vol-icon" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z" />
      </svg>
    )
  }

  const ShareIcon = () => {
    const color = themeMode === 'light' ? '#e1e1e1' : 'white';

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill={color} className="video-player__vol-icon" viewBox="0 0 16 16">
        <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
      </svg>
    )
  }

  return (
    <div className={`${modifier[0]} ${modifier[3]}`} onClick={closeOptions}>
      <video className={`${modifier[1]} ${modifier[2]}`} ref={videoRef} src={videoArray[currentVideoIndex]} type="video/quicktime" autoPlay muted={isMuted} loop playsInline poster={videoArray[currentVideoIndex] + '?#t=0.1'} ></video>
      {videoArray[currentVideoIndex] &&
        (<div className="video-player__overlay">
          <div className="video-player__overlay__left" onClick={handlePreviousVideo}></div>
          <div className="video-player__overlay__center" onClick={handleTogglePlay}>
            {isPlaying ? (
              <i className="fa fa-pause"></i>
            ) : (
              <i className="fa fa-play"></i>
            )}
          </div>
          <div className="video-player__overlay__right" >
            <div className="video-player__icon" onClick={() => { setIsMuted((prevIsMuted) => !prevIsMuted); }}>
              {isMuted || isMuted === "muted" ? <VolumeIcon /> : <MuteIcon />}
            </div>
            <div className='video-player__icon--share'>
              <ShareIcon />
            </div>
            <div className='video-player__icon--like'>
              <CommentIcon />
            </div>
            <div className='video-player__icon--like' onClick={() => { handlePostLike() }}>
              <LikeIcon />
            </div>
            <div className='video-player__next' onClick={handleNextVideo}>

            </div>
          </div>
        </div>)}
    </div>
  );
};

export default VideoPlayer;
