import ChatRoom from '../../Components/ChatRoom/ChatRoom';
import UserRoute from '../../Routes/Auth';
import './NewMessage.scss';
import { Context } from "../../context/index";
import React, { useContext, useEffect, useState, useCallback } from 'react';
import Navigation from '../../Components/Navigation/Navigation';
import axios from 'axios';

export default function NewMessage({ currentRoom, setCurrentRoom, serverUrl }) {
    const [username, setUsername] = useState("");
    const [connections, setConnections] = useState([]);
    const [messages, setMessages] = useState([]);

    const { state: { user } } = useContext(Context);

    const fetchUserConnections = useCallback(async (username) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/user/${username}/connections`);
            // console.log(response);
            setConnections(response.data.connections);
        } catch (error) {
            console.error(error);
        }
    }, [setConnections]);

    useEffect(() => {
        if (user !== null) {
            setUsername(user.username);
            fetchUserConnections(user.username);
            setCurrentRoom(user._id)

        }
    }, [user, setCurrentRoom, fetchUserConnections]);





    return (
        <UserRoute username={username}>
            <div className='new-message-pg'>
                <Navigation heading={"New Message"} back={true} />
                <ChatRoom 
                    serverUrl={serverUrl} 
                    currentRoom={currentRoom} 
                    setCurrentRoom={setCurrentRoom} 
                    username={username} 
                    isNewMessage={true} 
                    members={connections} 
                    messages={messages}
                    connections={connections}
                    setMessages={setMessages}
                />
            </div>
        </UserRoute>
    );
}
