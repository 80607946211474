import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import UserRoute from '../../Routes/Auth';
import UserSettings from '../../Components/UserSettings/UserSettings';
import Toggle from '../../Components/Toggle/Toggle';
import './SettingsAdvancedPage.scss';
import { selectLightMode, selectDarkMode } from '../../utils/stylesUtils';
import { languageOptions, ethnicityOptions, genderOptions } from '../../utils/constants';
import ThemeContext from '../../context/ThemeContext';
import Navigation from '../../Components/Navigation/Navigation';
import Button from '../../Components/Button/Button';
import { apiGet, apiPut } from '../../utils/apiUtils';
import MessageModal from '../../Components/MessageModal/MessageModal';

function SettingsAdvancedPage() {
  const [isChildrenFriendly, setIsChildrenFriendly] = useState(false);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [isAccountHidden, setIsAccountHidden] = useState(false);
  const [isEmailVisible, setIsEmailVisible] = useState(false); // New state for email visibility
  const [allowPreApprovalCheckout, setAllowPreApprovalCheckout] = useState(false); // New state for pre-approval checkout
  const [selectedEthnicity, setSelectedEthnicity] = useState(null);
  const [selectedGender, setSelectedGender] = useState(null);
  const { themeMode } = useContext(ThemeContext);

  // State for MessageModal
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [serverResponse, setServerResponse] = useState("");

  // Fetch user settings on component mount
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const data = await apiGet('/settings/advanced');

        const initialLanguages = (data.languages || []).map(language =>
          languageOptions.find(option => option.value === language)
        ).filter(Boolean);

        setIsChildrenFriendly(data.children_friendly);
        setSelectedLanguages(initialLanguages);
        setIsAccountHidden(data.accountHidden);
        setIsEmailVisible(data.emailVisible); // Set email visibility from fetched data
        setAllowPreApprovalCheckout(data.allowPreApprovalCheckout); // Set pre-approval checkout from fetched data
        setSelectedEthnicity(data.ethnicity);
        setSelectedGender(data.gender);
      } catch (error) {
        console.error('Error fetching user settings:', error);
      }
    };
    fetchSettings();
  }, []);

  // Update user settings on button click
  const handleUpdate = async () => {
    const updatedSettings = {
      children_friendly: isChildrenFriendly,
      languages: selectedLanguages.map(lang => lang.value),
      accountHidden: isAccountHidden,
      emailVisible: isEmailVisible,
      allowPreApprovalCheckout: allowPreApprovalCheckout, // Include pre-approval checkout in updated settings
      ethnicity: selectedEthnicity,
      gender: selectedGender,
    };

    try {
      await apiPut('/settings/advanced', updatedSettings);
      setServerResponse("Profile Updated");
    } catch (error) {
      setServerResponse("Something went wrong. Please try again.");
      console.error('Error updating settings:', error);
    } finally {
      setIsMessageModalOpen(true); // Open the message modal
    }
  };

  // Automatically close the message modal after 2 seconds
  useEffect(() => {
    if (isMessageModalOpen) {
      const timer = setTimeout(() => setIsMessageModalOpen(false), 750);
      return () => clearTimeout(timer); // Cleanup timer on unmount or before re-running
    }
  }, [isMessageModalOpen]);

  return (
    <UserRoute>
      <div className="settings_advanced-pg">
        <Navigation heading={"Advanced Settings"} back={true} />
        <UserSettings>
          <div className="settings_advanced-pg__container">
            {/* Ethnicity Selection */}
            <div className="settings_advanced-pg__option">
              <label className="settings_advanced__label">Ethnicity</label>
              <Select
                options={ethnicityOptions}
                value={ethnicityOptions.find(option => option.value === selectedEthnicity)}
                onChange={(option) => setSelectedEthnicity(option ? option.value : null)}
                placeholder="Select Ethnicity..."
                styles={themeMode === "light" ? selectLightMode : selectDarkMode}
              />
            </div>

            {/* Language Selection */}
            <div className="settings_advanced-pg__option">
              <label className="settings_advanced__label">Preferred Languages</label>
              <Select
                options={languageOptions}
                isMulti
                value={selectedLanguages}
                onChange={setSelectedLanguages}
                placeholder="Select Languages..."
                styles={themeMode === "light" ? selectLightMode : selectDarkMode}
              />
            </div>

            {/* Gender Selection */}
            <div className="settings_advanced-pg__option">
              <label className="settings_advanced__label">Gender</label>
              <Select
                options={genderOptions}
                value={genderOptions.find(option => option.value === selectedGender)}
                onChange={(option) => setSelectedGender(option ? option.value : null)}
                placeholder="Select Gender..."
                styles={themeMode === "light" ? selectLightMode : selectDarkMode}
              />
            </div>
            <div className="settings_advanced__option">
              <Toggle
                label="Allow Brands to Checkout Campaign Pre-Approval"
                value={allowPreApprovalCheckout}
                onChange={setAllowPreApprovalCheckout}
                modifier="childrenFriendly"
              />
            </div>

            {/* Children-Friendly Content Toggle */}
            <div className="settings_advanced__option">
              <Toggle
                label="Children-Friendly Content"
                value={isChildrenFriendly}
                onChange={setIsChildrenFriendly}
                modifier="childrenFriendly"
              />
            </div>

            {/* Email Visibility Toggle */}
            <div className="settings_advanced__option">
              <Toggle
                label="Show Email in Profile"
                value={isEmailVisible}
                onChange={setIsEmailVisible}
                modifier="childrenFriendly"
              />
            </div>

            {/* Account Hidden from Discover and Search Toggle */}
            <div className="settings_advanced__option">
              <Toggle
                label="Hide Account from Discover and Search"
                value={isAccountHidden}
                onChange={setIsAccountHidden}
                modifier="accountHidden"
              />
            </div>

            {/* Allow Brands to Checkout Campaign Pre-Approval Toggle */}
    
          </div>
          <Button onClick={handleUpdate}>Update</Button>
        </UserSettings>

        {/* Message Modal */}
        <MessageModal
          isOpen={isMessageModalOpen}
          setIsOpen={setIsMessageModalOpen}
          message={serverResponse}
          modifier={"center"}
        />
      </div>
    </UserRoute>
  );
}

export default SettingsAdvancedPage;
