import React, { useState } from "react";
import "./GetStartedModal.scss";
import profileImage from "../../assets/GetStarted/ProfilePage.png";
import featuredImage from "../../assets/GetStarted/FeaturedPage.png";
import OrdersPage from "../../assets/GetStarted/OrdersPage.png";
import ClipBoardIcon from "../../Icons/ClipBoardIcon";
import chequeaiLogo from "../../assets/icons/chequai-logo.png";

const GetStartedModal = ({ isOpen, onClose, themeMode, profileUrl }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const themeClass = themeMode === "light" ? "light-theme" : "dark-theme";
  const handleCopyLink = () => {
    navigator.clipboard.writeText(`https://www.chequai.com/${profileUrl}`);
  };

  const steps = [
    {
      title: "Welcome",
      content: (
        <div className={`welcome-section welcome-section--${themeClass}`}>
          <div className="welcome-section__logo">
            <img src={chequeaiLogo} alt="Chequeai Logo" />
            <h1 className="welcome-section__logo__title">Chequeai</h1>
          </div>
          <p>The Home for Creators and Brands</p>
        </div>
      ),
    },
    {
      title: "Setup Profile",
      content: (

        <p>
          Add your social platforms, such as Instagram, TikTok, YouTube, and more. You can also include your affiliate, website, and shop links. {" "}
          <a href="/settings/socials" className="get-started-link">Get Started</a>
        </p>

      ),
      image: profileImage,
    },
    {
      title: "Discover Brands",
      content: (

        <p>
          Explore brands that align with your style. {" "}
          <a href="/featured/" className="get-started-link">Discover now</a>
        </p>

      ),
      image: featuredImage,
    },
    {
      title: "Manage Deals",
      content: (

        <p>
          Manage your brand partnerships and track earnings effortlessly. Set up your account to receive seamless payments from collaborators.
          <a href="/settings/monetization/" className="get-started-link">
            Connect Stripe
          </a>
        </p>

      ),
      image: OrdersPage,
    },
    {
      title: "",
      content: (
        <div className={`grow-together-section grow-together-section--${themeClass}`}>
          <h2>
            Share your <strong>Chequeai</strong> Profile
          </h2>
          <div className={`profile-url-container profile-url-container--${themeClass}`} onClick={handleCopyLink}>
            https://www.chequai.com/{profileUrl}
            <ClipBoardIcon  themeMode={themeMode} />
          </div>
        </div>
      ),
    },
  ];

  if (!isOpen) return null;

  const handleNext = () =>
    currentStep < steps.length - 1
      ? setCurrentStep(currentStep + 1)
      : onClose();
  const handlePrev = () => currentStep > 0 && setCurrentStep(currentStep - 1);

  return (
    <div className="get-started-modal-overlay" onClick={onClose}>
      <div
        className={`get-started-modal-content get-started-modal-content--${themeClass}`}
        onClick={(e) => e.stopPropagation()}
      >
        {steps[currentStep].title !== "" && (
          <div className="modal-header">
            <h2>{steps[currentStep].title}</h2>
          </div>
        )}
        <div className={`modal-body modal-body--${themeClass}`}>
          {steps[currentStep].image && (
            <img
              src={steps[currentStep].image}
              alt={steps[currentStep].title}
              className="modal-image"
            />
          )}
          {steps[currentStep].content}
        </div>
        <div className="modal-footer">
          <button
            className={`prev-button prev-button--${themeClass}`}
            onClick={handlePrev}
            disabled={currentStep === 0}
          >
            Previous
          </button>
          <button className={`next-button next-button--${themeClass}`} onClick={handleNext}>
            {currentStep === steps.length - 1 ? "Finish" : "Next"}
          </button>
        </div>
        <div className="modal-progress">
          {steps.map((_, index) => (
            <div
              key={index}
              className={`progress-dot ${index === currentStep ? "active" : ""
                }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default GetStartedModal;
