import React, { useContext } from 'react';
import ThemeContext from '../../../context/ThemeContext';

const ReuseIcon = () => {
    const { themeMode } = useContext(ThemeContext);
    const color = themeMode === 'light' ? 'black' : 'darkgrey';


    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth={1.75} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-refresh">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4" />
            <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4" />
        </svg>
    );
}

export default ReuseIcon;
