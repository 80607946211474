import React, { useEffect, useState, useContext } from 'react';
import UserRoute from '../../Routes/Auth';
import Card from '../../Components/Card/Card';
import Navigation from '../../Components/Navigation/Navigation';
import './CampaignProductsPage.scss';
import { useParams, Link } from 'react-router-dom';
import EditProductModal from '../../Components/EditProductModal/EditProductModal';
import axios from 'axios';
import OptionsIcon from '../../assets/icons/three-dots.svg';
import OptionsModalBox from '../../Components/OptionsModalBox/OptionsModalBox';
import ClipBoardIcon from '../../assets/icons/clipboard-check.svg';
import ShareIcon from '../../assets/icons/share-dg.svg';
import ConfirmModal from '../../Components/ConfirmModal/ConfirmModal';
import productIcon from '../../assets/icons/bag-check.svg';
import productImage from '../../assets/defaults/product.png';
import ViewProductModel from '../../Components/ViewProductModal/ViewProductModal';
import CampaignHeader from '../../Components/CampaignHeader/CampaignHeader';
import ThemeContext from '../../context/ThemeContext';
import CampaignSupportModal from '../../Components/CampaignSupportModal/CampaignSupportModal';
import CampaignInfoModal from '../../Components/CampaignInfoModal/CampaignInfoModal';
import { formatLabel } from '../../utils/helperFunctions';

export default function CampaignProductsPage() {
    const { id } = useParams();
    const [title, setTitle] = useState('');
    const [campaignStatus, setCampaignStatus] = useState(null);
    const [products, setProducts] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isOptionsOpen, setIsOptionsOpen] = useState(false);
    const [isViewProductModalOpen, setIsViewProductModalOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [isConfirmAddShop, setIsConfirmAddShop] = useState(false);
    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';
    const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [isCampaignInfoModalOpen, setIsCampaignInfoModalOpen] = useState(false);



    useEffect(() => {
        const fetchCampaign = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API}/campaigns/${id}/products`);
                // console.log(response.data);
                setTitle(response.data.campaignName);
                setProducts(response.data.products);
                setCampaignStatus(response.data.status);
            } catch (error) {
                console.error(error);
            }
        };

        if (!products) {
            fetchCampaign();
        }
    }, [products, id]);

    const handleUpdateProduct = async (productData) => {
        try {
            console.log(productData);
            // const { data } = 
            await axios.put(`${process.env.REACT_APP_API}/campaigns/${id}/products/`, { productData });
            // console.log('IMAGE REMOVED', data);
            // Handle the response data and state updates
            console.log('Updated');
            window.location.reload();
        } catch (err) {
            console.log('Failed to remove. Try again later.', err);
        }
    };

    const handleDeleteProduct = async (productData) => {
        try {
            // const { data } =
            await axios.delete(`${process.env.REACT_APP_API}/campaigns/${id}/products/${productData._id}`);
            // console.log('IMAGE REMOVED', data);
            // Handle the response data and state updates
            console.log('Updated');
            window.location.reload();
        } catch (err) {
            console.log('Failed to remove. Try again later.', err);
        }
    };

    const copyToClipboard = async (textToCopy) => {
        try {
            await navigator.clipboard.writeText(textToCopy);
        } catch (error) {
            console.error('Failed to copy text:', error);
        }
    };

    const handleAddLinkToProfile = async (productData) => {
        try {
            console.log(productData);
            await axios.post(`${process.env.REACT_APP_API}/user/edit-profile/link`, {
                title: productData.name,
                url: productData.url,
            });
            window.location.reload();

            console.log('Updated');
        } catch (err) {
            console.log('Failed to add. Try again later.', err);
        }
    };

    const handleAddProductToShop = async (productData) => {
        try {
            console.log(productData);
            await axios.post(`${process.env.REACT_APP_API}/user/edit-profile/shop`, {
                productData,
            });
            window.location.reload();
            // console.log('Updated');
        } catch (err) {
            console.log('Failed to add. Try again later.', err);
        }
    };

    return (
        <UserRoute>
            <div className="cp-prod-pg">
                <Navigation heading={title} back={true} edit={`/campaigns/edit/${id}`} />
                <Card className="card--margin">
                    <div className="campaign-card__header">
                        <div className="campaign-card__header__left">
                            <CampaignHeader setIsCampaignInfoModalOpen={setIsCampaignInfoModalOpen} setShowOptions={setShowOptions}
                                setIsSupportModalOpen={setIsSupportModalOpen} showOptions={showOptions} isSupportModalOpen={isSupportModalOpen}

                            />

                        </div>
                        <div className="campaign-card__header__right"></div>
                    </div>
                    {products && products.length > 0 ? (
                        products.map((product, index) => {
                            return (
                                <div key={index} className={`cp-prod-pg__product cp-prod-pg__product--${themeClass}`}>
                                    <div className="cp-prod-pg__product__left">
                                        <img
                                            src={product.images.length > 0 ? product.images[0].url : productImage}
                                            alt={product.productName}
                                            className="cp-prod-pg__product__img"
                                            onClick={() => {
                                                setIsViewProductModalOpen(product);
                                            }}
                                        />
                                    </div>
                                    <div className="cp-prod-pg__product__right">
                                        <div className="cp-prod-pg__title-section">
                                            <p className="cp-prod-pg__product__name">{product.name}</p>
                                            <img
                                                className="cp-prod-pg__post__actions__icons"
                                                src={OptionsIcon}
                                                alt="delete icon"
                                                onClick={() => setIsOptionsOpen(product)}
                                            />
                                        </div>
                                        <div className="cp-prod-pg__link-section">
                                            <span className="cp-prod-pg__product__price">
                                                ${product.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                            </span>
                                            <div>
                                                <img
                                                    src={ClipBoardIcon}
                                                    className="cp-prod-pg__clipboard"
                                                    onClick={() => {
                                                        copyToClipboard(product.url);
                                                    }}
                                                    alt="clipboard icon"
                                                />
                                                <img
                                                    src={ShareIcon}
                                                    className="cp-prod-pg__clipboard"
                                                    onClick={() => {
                                                        setIsConfirmModalOpen(product);
                                                    }}
                                                    alt="clipboard icon"
                                                />
                                                <img
                                                    src={productIcon}
                                                    className="cp-prod-pg__clipboard"
                                                    onClick={() => {
                                                        setIsConfirmAddShop(product);
                                                    }}
                                                    alt="clipboard icon"
                                                />
                                            </div>
                                        </div>
                                        <div className='cp-prod-pg__product-site'>
                                            <p className="cp-prod-pg__product__sku">{product.sku || "SKU"}  </p>
                                            <p className="cp-prod-pg__product__sku"> - {formatLabel(product.category)} - </p>
                                            <Link className="cp-prod-pg__product__url" to={product.url}>
                                                Product Page 
                                            </Link>
                                           
                                            
                                        </div>
                                        <p className="cp-prod-pg__product__sku">{product.description}</p>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <p className="cp-prod-pg__no-products">No campaign products available</p>
                    )}

                    {(campaignStatus !== 'Completed' && campaignStatus !== 'Post Campaign') &&
                        <Link className={`cp-prod-pg__add-product cp-prod-pg__add-product--${themeClass}`} to={`/campaigns/${id}/products/add`}>
                            <p className="cp-prod-pg__add">+</p>
                            <p className="cp-prod-pg__text">Add Campaign Product</p>
                        </Link>
                    }
                </Card>
                {isOptionsOpen !== false && (
                    <OptionsModalBox
                        isOpen={isOptionsOpen}
                        setIsOpen={setIsOptionsOpen}
                        handleRemoveProduct={() => handleDeleteProduct(isOptionsOpen)}
                        handleViewProduct={() => setIsViewProductModalOpen(isOptionsOpen)}
                        handleEditProduct={() => setIsEditModalOpen(isOptionsOpen)}
                    />
                )}
                {isConfirmAddShop && (
                    <ConfirmModal
                        isOpen={isConfirmAddShop}
                        onClose={() => setIsConfirmAddShop(false)}
                        setIsOpen={setIsConfirmAddShop}
                        message={"Add this product to your Shop section in your profile"}
                        subMessage={"Showcase your product in your shop section to boost visibility. Make it easier for others to find and buy from you, and track your data."}
                        onConfirm={handleAddProductToShop}
                    />
            
                )}
                {isConfirmModalOpen && (
                    <ConfirmModal
                        isOpen={isConfirmModalOpen}
                        onClose={() => setIsConfirmModalOpen(false)}
                        setIsOpen={setIsConfirmModalOpen}
                        message={"Would you like to add this link to your profile"}
                        onConfirm={handleAddLinkToProfile}
                    />
                )}
                {isEditModalOpen && (
                    <EditProductModal
                        isOpen={isEditModalOpen}
                        onClose={() => setIsEditModalOpen(false)}
                        onSave={handleUpdateProduct}
                        setIsOpen={setIsEditModalOpen}
                    />
                )}
                {isViewProductModalOpen && (
                    <ViewProductModel
                        isOpen={isViewProductModalOpen}
                        onClose={() => setIsViewProductModalOpen(false)}
                    />
                )}
                {isSupportModalOpen && (
                    <CampaignSupportModal
                        isOpen={isSupportModalOpen}
                        onClose={() => setIsSupportModalOpen(false)}
                        campaignId={id}
                    />
                )
                }
                <CampaignInfoModal isOpen={isCampaignInfoModalOpen} onClose={() => setIsCampaignInfoModalOpen(false)} themeMode={themeMode} />

            </div>
        </UserRoute>
    );
}
