// FacebookTikTokConnect.jsx

import React, { useContext, useState } from 'react';
import axios from 'axios';
import instagramIcon from '../../assets/icons/instagram.png';
import tiktokIcon from '../../assets/icons/tiktok.png';
import './FacebookTikTokConnect.scss';
import ThemeContext from '../../context/ThemeContext';
import { apiGet } from '../../utils/apiUtils';

const FacebookTikTokConnect = ({ setProvider }) => {
  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Function to handle Instagram Login via Facebook
  const handleInstagramFacebookConnect = async () => {
    try {
      setProvider('facebook');
      setLoading(true);
      const response = await apiGet(`/auth/facebook`, { withCredentials: true });
      if (response && response.url) {
        window.location.href = response.url; // Redirect to the Facebook auth URL
      } else {
        console.error('Unexpected response format:', response);
        setError('Failed to initiate Facebook authentication.');
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  // Function to handle Instagram Login via Instagram
  const handleInstagramConnect = async () => {
    try {
      setProvider('instagram');
      setLoading(true);
      const response = await axios.get(`/auth/instagram`, { withCredentials: true });
      if (response && response.data && response.data.url) {
        window.location.href = response.data.url; // Redirect to the Instagram auth URL
      } else {
        console.error('Unexpected response format:', response);
        setError('Failed to initiate Instagram authentication.');
      }

    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  // Function to handle TikTok Connect
  const handleTiktokConnect = async () => {
    try {
      setProvider('tiktok');
      setLoading(true);
      const response = await axios.get(`/auth/tiktok`, { withCredentials: true });
      if (response && response.data && response.data.url) {
        window.location.href = response.data.url; // Redirect to the TikTok auth URL
      } else {
        console.error('Unexpected response format:', response);
        setError('Failed to initiate TikTok authentication.');
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  // Error handling function
  const handleError = (error) => {
    if (error.response) {
      console.error('Error response from server:', error.response);
      setError(error.response.data.message || 'Authentication failed.');
    } else if (error.request) {
      console.error('No response received:', error.request);
      setError('No response from server. Please try again later.');
    } else {
      console.error('Error setting up request:', error.message);
      setError('An unexpected error occurred.');
    }
  };

  return (
    <div className="connect-buttons-container">
      {/* {loading && <p>Processing...</p>} */}
      {error && <p className="error-message">{error}</p>}
      
      <button
        className={`connect-button connect-button--${themeClass}`}
        onClick={handleInstagramConnect}
        disabled={loading}
      >
        <img src={instagramIcon} alt="Instagram" className="connect-icon" />
        Connect Instagram
      </button>
      <button
        className={`connect-button connect-button--${themeClass}`}
        onClick={handleInstagramFacebookConnect}
        disabled={loading}
      >
        <img src={instagramIcon} alt="Instagram via Facebook" className="connect-icon" />
        Connect Instagram (Facebook)
      </button>
      <button
        className={`connect-button connect-button--${themeClass}`}
        onClick={handleTiktokConnect}
        disabled={loading}
      >
        <img src={tiktokIcon} alt="TikTok" className="connect-icon" />
        Connect TikTok
      </button>
    </div>
  );
};

export default FacebookTikTokConnect;
