import React, { useState } from 'react';
import './BlogComponent.scss';
import { Link } from 'react-router-dom';

const BlogComponent = ({ themeClass, blogs = [] }) => {
    // console.log(blogs);
    const [filter, setFilter] = useState('all');

    const handleFilterClick = (selectedFilter) => {
        setFilter(selectedFilter);
    };

    const filteredBlogs = filter === 'all' 
        ? blogs 
        : blogs.filter(blog => blog.categories.includes(filter));

    return (
        <div className="blog">
            <div className="blog__filter">
                <button
                    className={`blog__filter-button blog__filter-button--${themeClass} ${filter === 'all' ? 'blog__filter-button--active' : ''}`}
                    onClick={() => handleFilterClick('all')}
                >
                    All
                </button>
                <button
                    className={`blog__filter-button blog__filter-button--${themeClass} ${filter === 'brands' ? 'blog__filter-button--active' : ''}`}
                    onClick={() => handleFilterClick('brands')}
                >
                    Brands
                </button>
                <button
                    className={`blog__filter-button blog__filter-button--${themeClass} ${filter === 'creators' ? 'blog__filter-button--active' : ''}`}
                    onClick={() => handleFilterClick('creators')}
                >
                    Creators
                </button>
            </div>
            <div className="blog__grid">
                {filteredBlogs && filteredBlogs.length > 0 ? (
                    filteredBlogs.map(blog => (
                        <Link to={`/blog/${blog.slug}`} key={blog._id} className={`blog__card blog__card--${themeClass}`}>
                            <img src={blog.headerImage} alt={blog.title} className="blog__card-image" />
                            <div className="blog__card-content">
                                <h3 className={`blog__card-title blog__card-title--${themeClass}`}>{blog.title}</h3>
                            </div>
                        </Link>
                    ))
                ) : (
                    <p className="blog__no-blogs">No blog posts found</p>
                )}
            </div>
        </div>
    );
};

export default BlogComponent;
