// src/pages/AddProductPage/AddProductPage.jsx

import React, { useState, useEffect, useContext } from 'react';
import UserRoute from '../../Routes/Auth';
import Navigation from '../../Components/Navigation/Navigation';
import './AddProductPage.scss';
import Card from '../../Components/Card/Card';
import InputField from '../../Components/InputField/InputField';
import Button from '../../Components/Button/Button';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import TextArea from '../../Components/TextArea/TextArea';
import Resizer from 'react-image-file-resizer';
import productImg from '../../assets/defaults/product2.png';
import ThemeContext from '../../context/ThemeContext';
import productImgLight from '../../assets/defaults/productLight.png';
import { groupedCategoryOptions as initialCategoryOptions } from '../../utils/categoryOptions';

export default function AddProductPage() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [campaignStatus, setCampaignStatus] = useState(null);
    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';
    const [product, setProduct] = useState({
        name: '',
        price: '',
        sku: '',
        category: '',
        url: '',
        description: '',
        imageUrl: '',
    });
    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [error, setError] = useState('');

    // Manage category options as state to allow dynamic updates
    const [categoryOptions, setCategoryOptions] = useState(initialCategoryOptions);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProduct((prevProduct) => ({
            ...prevProduct,
            [name]: value,
        }));
    };

    const handleCategoryCreate = (inputValue) => {
        const newOption = {
            label: inputValue,
            value: inputValue.toLowerCase().replace(/\s+/g, '_'),
        };

        // Find the "Other Categories" group
        const otherGroupIndex = categoryOptions.findIndex(
            (group) => group.label === 'Other Categories'
        );

        if (otherGroupIndex !== -1) {
            // Add the new option to the "Other Categories" group
            const updatedCategoryOptions = [...categoryOptions];
            updatedCategoryOptions[otherGroupIndex].options.push(newOption);
            setCategoryOptions(updatedCategoryOptions);
        } else {
            // If "Other Categories" group doesn't exist, create it
            setCategoryOptions([
                ...categoryOptions,
                {
                    label: 'Other Categories',
                    options: [newOption],
                },
            ]);
        }

        setProduct((prevProduct) => ({
            ...prevProduct,
            category: newOption.value,
        }));
    };

    let productImage = themeMode === 'light' ? productImgLight : productImg;

    const handlePost = async (e) => {
        e.preventDefault();

        // Validate price to be numeric digits only
        const priceRegex = /^[0-9]+$/;
        if (!priceRegex.test(product.price)) {
            setError('Price must be numeric digits only');
            return;
        }

        // Ensure either image file or image URL is provided
        if (!image && !product.imageUrl) {
            setError('Please provide either an image file or an image URL.');
            return;
        }

        if (image) {
            Resizer.imageFileResizer(
                image,
                500,
                500,
                'PNG',
                100,
                0,
                async (uri) => {
                    try {
                        await axios.post(
                            `${process.env.REACT_APP_API}/campaigns/${id}/product`,
                            {
                                image: uri,
                                product,
                            }
                        );
                        navigate('/orders');
                    } catch (err) {
                        console.log('Failed to upload image. Try later.', err);
                        setError('Failed to upload image. Please try again later.');
                    }
                }
            );
        } else {
            try {
                await axios.post(`${process.env.REACT_APP_API}/campaigns/${id}/product`, {
                    product,
                });
                navigate('/orders');
            } catch (err) {
                console.log('Failed to add product. Try later again.');
                setError(
                    `${err.response.data}. Error adding product. Please try again later.`
                );
            }
        }
    };

    const validateImg = (e) => {
        const file = e.target.files[0];
        if (file.size > 2048576) {
            // 2MB in bytes
            console.error('Max file size 2MB');
            setError('Image size should be less than 2MB');
        } else {
            setImage(file);
            setImagePreview(URL.createObjectURL(file));
            setError(''); // Clear any previous errors
        }
    };

    useEffect(() => {
        const getCampaignStatus = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API}/campaigns/${id}/campaign-status`
                );
                setCampaignStatus(response.data);
            } catch (error) {
                console.error(error);
            }
        };

        if (id && !campaignStatus) {
            getCampaignStatus();
        }
    }, [id, campaignStatus]);

    return (
        <UserRoute>
            <div className="add-product-page">
                <Navigation title={'Add Product'} back={true} />
                {campaignStatus !== 'Completed' && campaignStatus !== 'Post Campaign' ? (
                    <Card className="card--input">
                        <form>
                            {/* Use the new ImageUploader component */}
                            <ImageUploader
                                product={product}
                                setProduct={setProduct}
                                image={image}
                                setImage={setImage}
                                imagePreview={imagePreview}
                                setImagePreview={setImagePreview}
                                validateImg={validateImg}
                                handleInputChange={handleInputChange}
                                productImage={productImage}
                                error={error}
                                setError={setError}
                            />

                            {/* Other input fields */}
                            <InputField
                                label="Name"
                                name="name"
                                type="text"
                                value={product.name}
                                onChange={handleInputChange}
                                placeholder="Product Name"
                                required
                            />
                            <InputField
                                label="Category"
                                name="category"
                                isSelect={true}
                                isCreatable={true}
                                options={categoryOptions}
                                value={product.category}
                                onChange={handleInputChange}
                                onCreateOption={handleCategoryCreate}
                                placeholder="Product Category"
                                required
                            />
                            <InputField
                                label="Price"
                                name="price"
                                type="number"
                                value={product.price}
                                onChange={handleInputChange}
                                placeholder="Product Price"
                                required
                            />
                            <InputField
                                label="SKU"
                                name="sku"
                                type="text"
                                value={product.sku}
                                onChange={handleInputChange}
                                placeholder="Product SKU"
                                required
                            />
                            <InputField
                                label="Link"
                                name="url"
                                type="url"
                                value={product.url}
                                onChange={handleInputChange}
                                placeholder="Product Page URL"
                            />
                            <TextArea
                                label="Description"
                                name="description"
                                value={product.description}
                                onChange={handleInputChange}
                                placeholder="Enter product description"
                            />
                            {error && <div className="error-message">{error}</div>}

                        </form>
                        <Button onClick={handlePost}>Add Product</Button>

                    </Card>
                ) : (
                    <Card
                        className={`card--link campaign-complete campaign-complete--${themeClass}`}
                    >
                        <p>
                            <span>Campaign</span> has been
                        </p>
                        <p>{campaignStatus}</p>
                    </Card>
                )}
            </div>
        </UserRoute>
    );
}

// New ImageUploader component
function ImageUploader({
    product,
    setProduct,
    image,
    setImage,
    imagePreview,
    setImagePreview,
    validateImg,
    handleInputChange,
    productImage,
    error,
    setError,
}) {
    return (
        <div className="add-product-page__profile-img">
            <div className="add-product-page__img-wrapper">
                <img
                    className="add-product-page__img"
                    src={product.imageUrl || imagePreview || productImage}
                    alt="product"
                />
                <div className="add-product-page__img-overlay">
                    {/* Combine both file upload and URL input */}
                    <div className="add-product-page__img-options">

                        <InputField
                            name="imageUrl"
                            type="url"
                            value={product.imageUrl}
                            onChange={handleInputChange}
                            placeholder="Image Link"
                        />
                        <label htmlFor="file" className="add-product-page__file-label">
                            <input
                                type="file"
                                id="file"
                                accept="image/png, image/jpeg, image/webp"
                                onChange={validateImg}
                                className="add-product-page__file-input"
                            />
                            <span>Upload</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
}
