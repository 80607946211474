import React, { useRef, useContext } from 'react';
import './OptionsModalBox.scss';
import ThemeContext from '../../context/ThemeContext';

const OptionsModalBox = ({ isOpen, setIsOpen, handleRemoveImage, handleUpdateImage, image, setImagePreview, handleRemoveProduct, 
    handleViewProduct, handleEditProduct, handleEditPost, handleViewPost, handleRemovePost, handleRemoveChat, handleEditPromotion, 
    handleDeletePromotion, handleEditTeamMember, handleRemoveTeamMember, handleViewTeamMember }) => {
    const fileInputRef = useRef(null);
    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';
    const closeModal = () => {
        setIsOpen(false);
    };


    const handleOptionClick = (option) => {
        switch (option) {
            case 'remove':
                handleRemoveImage(isOpen);
                closeModal();
                break;
            case 'view product':
                handleViewProduct(isOpen);
                closeModal();
                break;

            case 'edit product':
                handleEditProduct(isOpen);
                closeModal();
                break;
            case 'remove product':
                handleRemoveProduct(isOpen);
                closeModal();
                break;
            case 'view post':
                handleViewPost(isOpen);
                closeModal();
                break;
            case 'edit post':
                handleEditPost(isOpen);
                closeModal();
                break;
            case 'remove post':
                handleRemovePost(isOpen);
                closeModal();
                break;
            case 'remove room':
                handleRemoveChat(isOpen);
                closeModal();
                break;
            case 'edit promotion':
                handleEditPromotion(isOpen);
                closeModal();
                break;
            case 'delete promotion':
                handleDeletePromotion(isOpen);
                closeModal();
                break;
            case 'delete team member':
                handleRemoveTeamMember(isOpen);
                closeModal();
                break;
            case 'edit team member':
                handleEditTeamMember(isOpen);
                closeModal();
                break;
            case 'view team member':
                handleViewTeamMember(isOpen);
                closeModal();
                break;
            case 'update':
                if (fileInputRef.current) {
                    fileInputRef.current.click();
                }
                break;
            case 'cancel':
                closeModal();
                break;
            default:
                break;
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        console.log(file);
        if (file.size > 10048576) {
            console.error('Max file size 1mb', { position: 'top-center' });
        } else {
            handleUpdateImage(file);
            setImagePreview(URL.createObjectURL(file));
        }
    };


    return (
        <div className={`modal ${isOpen ? 'modal--open' : ''}`} onClick={closeModal}>
            <div className={`modal__content modal__content--${themeClass}`} onClick={(e) => e.stopPropagation()}>
                <ul className="modal__options">

                    {handleUpdateImage && (
                        <>
                            <li className={`modal__option modal__option--${themeClass}`} onClick={() => handleOptionClick('update')}>
                                {image ? "Updated" : 'Update Photo'}
                            </li>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                            />
                        </>
                    )}
                    {handleRemoveImage && (
                        <li className={`modal__option modal__option--${themeClass}`} onClick={() => handleOptionClick('remove')}>
                            Remove Photo
                        </li>
                    )}
                    {handleViewProduct && (
                        <li className={`modal__option modal__option--${themeClass}`} onClick={() => handleOptionClick('view product')}>
                            View Product
                        </li>
                    )}
                    {handleEditProduct && (
                        <li className={`modal__option modal__option--${themeClass}`} onClick={() => handleOptionClick('edit product')}>
                            Edit Product
                        </li>
                    )}
                    {handleRemoveProduct && (
                        <li className={`modal__option modal__option--${themeClass}`} onClick={() => handleOptionClick('remove product')}>
                            Delete Product
                        </li>
                    )}
                    {handleViewPost && (
                        <li className={`modal__option modal__option--${themeClass}`} onClick={() => handleOptionClick('view post')}>
                            View Post
                        </li>
                    )}
                    {handleEditPost && (
                        <li className={`modal__option modal__option--${themeClass}`} onClick={() => handleOptionClick('edit post')}>
                            Edit Post
                        </li>
                    )}
                    {handleRemovePost && (
                        <li className={`modal__option modal__option--${themeClass}`} onClick={() => handleOptionClick('remove post')}>
                            Delete Post
                        </li>
                    )}
                    {handleRemoveChat && (
                        <li className={`modal__option modal__option--${themeClass}`} onClick={() => handleOptionClick('remove room')}>
                            Delete
                        </li>
                    )}
                    {handleEditPromotion && (
                        <li className={`modal__option modal__option--${themeClass}`} onClick={() => handleOptionClick('edit promotion')}>
                            Edit Promotion
                        </li>
                    )}
                    {handleDeletePromotion && (
                        <li className={`modal__option modal__option--${themeClass}`} onClick={() => handleOptionClick('delete promotion')}>
                            Delete Promotion
                        </li>
                    )}
                    {handleViewTeamMember && (
                        <li className={`modal__option modal__option--${themeClass}`} onClick={() => handleOptionClick('view team member')}>
                            View Memeber
                        </li>
                    )}
                    {handleEditTeamMember && (
                        <li className={`modal__option modal__option--${themeClass}`} onClick={() => handleOptionClick('edit team member')}>
                            Edit Team Member
                        </li>
                    )}
                    {handleRemoveTeamMember && (
                        <li className={`modal__option modal__option--${themeClass}`} onClick={() => handleOptionClick('delete team member')}>
                            Delete Team Member
                        </li>
                    )}
                    <li className={`modal__option  modal__option--${themeClass} modal__option--cancel`} onClick={() => handleOptionClick('cancel')}>
                        Done
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default OptionsModalBox;
