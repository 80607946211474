import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './BlogPostPage.scss';
import UserRoute from '../../Routes/Auth';
import ThemeContext from "../../context/ThemeContext";
import { Context } from "../../context/index";
import Header from '../../Components/Header/Header';

const BlogPostPage = () => {
    const { id } = useParams(); // Capture ID from URL params
    const [blogPost, setBlogPost] = useState(null); // Initialize as null
    const [loading, setLoading] = useState(true); // State for loading status
    const [error, setError] = useState(null); // State for error handling\
    const { state: { user }, } = useContext(Context);
    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';
    console.log(blogPost)

    useEffect(() => {
        const fetchBlogPost = async () => {
            try {
                const response = await axios.get(`/blog/posts/${id}`);
                // console.log(response.data);
                setBlogPost(response.data.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching blog post:', error);
                setError('Failed to load the blog post.');
                setLoading(false);
            }
        };

        fetchBlogPost();
    }, [id]);


    if (loading) {
        return user ? (
            <UserRoute>
                <div className={`blog-post ${themeClass}`}>
                    <p>Loading...</p>
                </div>
            </UserRoute>
        ) : (
            <div className={`blog-post ${themeClass}`}>
                <p>Loading...</p>
            </div>
        );
    }

    if (error) {
        return user ? (
            <UserRoute>
                <div className={`blog-post blog-post__error ${themeClass}`}>
                    <h1>404 - Page Not Found</h1>
                    <p>The page is currently unavailable. Please check back later.</p>
                </div>
            </UserRoute>) : (
            <div className={`blog-post blog-post__error ${themeClass}`}>
                <h1>404 - Page Not Found</h1>
                <p>The page is currently unavailable. Please check back later.</p>
            </div>
        );
    }

    if (!blogPost) {
        return user ? (
            <UserRoute>
                <div className={`blog-post blog-post__error ${themeClass}`}>
                    <h1>404 - Page Not Found</h1>
                    <p>The page is currently unavailable. Please check back later.</p>
                </div>
            </UserRoute>
        ) : (
            <div className={`blog-post blog-post__error ${themeClass}`}>
                <h1>404 - Page Not Found</h1>
                <p>The page is currently unavailable. Please check back later.</p>
            </div>
        );
    }

    if (!user) {
        return (
            <div className={`blog-post ${themeClass}`}>
                <Header user={null} themeClass={themeClass} />
                <div className='blog-post__container'>
                    <img
                        src={blogPost.headerImage || "https://via.placeholder.com/800x340.png"}
                        alt="Blog Header"
                        className="blog-post__image"
                    />
                    <div className={`blog-post__content blog-post__content--${themeClass}`}>
                        <h1 className="blog-post__title">{blogPost.title}</h1>
                        <p className="blog-post__meta">
                            Published {new Date(blogPost.publishDate).toLocaleDateString()} | Last Updated {new Date(blogPost.lastUpdated).toLocaleDateString()}
                        </p>
                        {/* Render HTML content safely */}
                        <div
                            className="blog-post__paragraph"
                            dangerouslySetInnerHTML={{ __html: blogPost.content }}
                        />
                        {/* Conditionally render subheadings if they exist */}
                        {blogPost.subheadings && blogPost.subheadings.length > 0 && (
                            blogPost.subheadings.map((subheading, index) => (
                                <React.Fragment key={index}>
                                    <h2 className="blog-post__subheading">{subheading.title}</h2>
                                    <div
                                        className="blog-post__paragraph"
                                        dangerouslySetInnerHTML={{ __html: subheading.content }}
                                    />
                                </React.Fragment>
                            ))
                        )}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <UserRoute>
            <div className={`blog-post ${themeClass}`}>
                <div className='blog-post__container'>
                    <img
                        src={blogPost.headerImage || "https://via.placeholder.com/800x340.png"}
                        alt="Blog Header"
                        className="blog-post__image"
                    />
                    <div className={`blog-post__content blog-post__content--${themeClass}`}>
                        <h1 className="blog-post__title">{blogPost.title}</h1>
                        <p className="blog-post__meta">
                            Published {new Date(blogPost.publishDate).toLocaleDateString()} | Last Updated {new Date(blogPost.lastUpdated).toLocaleDateString()}
                        </p>
                        {/* Render HTML content safely */}
                        <div
                            className="blog-post__paragraph"
                            dangerouslySetInnerHTML={{ __html: blogPost.content }}
                        />
                        {/* Conditionally render subheadings if they exist */}
                        {blogPost.subheadings && blogPost.subheadings.length > 0 && (
                            blogPost.subheadings.map((subheading, index) => (
                                <React.Fragment key={index}>
                                    <h2 className="blog-post__subheading">{subheading.title}</h2>
                                    <div
                                        className="blog-post__paragraph"
                                        dangerouslySetInnerHTML={{ __html: subheading.content }}
                                    />
                                </React.Fragment>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </UserRoute>
    );
};

export default BlogPostPage;
