// EditPromotionPage.jsx

import React, { useEffect, useState, useContext } from 'react';
import './EditPromotionPage.scss';
import UserRoute from '../../Routes/Auth';
import Card from '../../Components/Card/Card';
import InputField from '../../Components/InputField/InputField';
import SocialFieldCard from '../../Components/SocialField/SocialField';
import Button from '../../Components/Button/Button';
import { useNavigate, useParams } from "react-router-dom";
import Navigation from '../../Components/Navigation/Navigation';
import axios from 'axios';
import TextArea from '../../Components/TextArea/TextArea';
import UploadImageComponent from '../../Components/UploadImageComponent/UploadImageComponent';
import Resizer from 'react-image-file-resizer';
import ThemeContext from '../../context/ThemeContext';
import CustomSelect from '../../Components/CustomSelect/CustomSelect';
import { selectLightMode, selectDarkMode } from '../../utils/stylesUtils';
import Select from 'react-select';



const contentRightsOptions = [
    { value: 'exclusive', label: 'Exclusive Rights' },
    { value: 'non_exclusive', label: 'Non-Exclusive Rights' },
    { value: 'limited', label: 'Limited Rights' },
];

const durationOptions = [
    { value: '1_month', label: '1 Month' },
    { value: '3_months', label: '3 Months' },
    { value: '6_months', label: '6 Months' },
    { value: '12_months', label: '12 Months' },
    // Add more options as needed
];

const reuseOptions = [
    { value: 'allowed', label: 'Allowed' },
    { value: 'not_allowed', label: 'Not Allowed' },
    // Add more options as needed
];

export default function EditPromotions() {
    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

    const { id } = useParams();
    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState("");
    const [currency, setCurrency] = useState("USD");
    const [instagramPosts, setInstagramPosts] = useState(0);
    const [tikTokPosts, setTikTokPosts] = useState(0);
    const [youtubePosts, setYoutubePosts] = useState(0);
    const [podcastPosts, setPodcastPosts] = useState(0);
    const [portfolio, setPortfolio] = useState([]);

    // Select state variables with default values
    const [contentRights, setContentRights] = useState(contentRightsOptions[1]); // Defaults to Non-Exclusive Rights
    const [duration, setDuration] = useState(durationOptions[1]); // Defaults to 3 Months (adjust index if needed)
    const [reuseAfter, setReuseAfter] = useState(reuseOptions[0]); // Adjust based on intended default

    const navigate = useNavigate();

    useEffect(() => {
        const fetchPromotion = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_API}/user/edit-profile/promotion/${id}`);
                // console.log(data.promotion);
                setName(data.promotion.name);
                setDescription(data.promotion.description);
                setPrice(data.promotion.price);
                setInstagramPosts(data.promotion.instagram.quantity);
                setTikTokPosts(data.promotion.tiktok.quantity);
                setYoutubePosts(data.promotion.youtube.quantity);
                setPodcastPosts(data.promotion.podcast.quantity);
                setPortfolio(data.promotion.portfolio);
                setCurrency(data.promotion.currency);

                // Map fetched string values to their corresponding option objects
                const selectedContentRights = contentRightsOptions.find(
                    (option) => option.value === data.promotion.contentRights
                );
                setContentRights(selectedContentRights || contentRightsOptions[1]); // Default to Non-Exclusive if not found

                const selectedDuration = durationOptions.find(
                    (option) => option.value === data.promotion.duration
                );
                setDuration(selectedDuration || durationOptions[1]); // Default to 3 Months if not found

                const selectedReuseAfter = reuseOptions.find(
                    (option) => option.value === data.promotion.reuseAfter
                );
                setReuseAfter(selectedReuseAfter || reuseOptions[0]); // Default to Allowed if not found
            } catch (error) {
                console.log(error);
            }
        };
        fetchPromotion();
    }, [id]);

    const handleAdd = async () => {
        try {
            if (image) {
                Resizer.imageFileResizer(image, 750, 750, 'PNG', 100, 0, async (uri) => {
                    try {
                        const { data } = await axios.put(
                            `${process.env.REACT_APP_API}/user/edit-profile/promotion/${id}/upload-image`,
                            {
                                image: uri,
                            }
                        );
                        console.log('IMAGE UPLOADED', data);
                        // Optionally update the portfolio or imagePreview state here
                    } catch (err) {
                        console.log('Failed to upload. Try later.', err);
                    }
                });
            }
        } catch (err) {
            console.error(err);
        }
    };

    const editPromotion = async (event) => {
        event.preventDefault();
        try {
            const instagram = {
                quantity: instagramPosts
            };
            const tiktok = {
                quantity: tikTokPosts
            };
            const youtube = {
                quantity: youtubePosts
            };
            const podcast = {
                quantity: podcastPosts
            };

            await axios.put(`${process.env.REACT_APP_API}/user/edit-profile/promotion/${id}`, {
                name,
                description,
                price,
                instagram,
                tiktok,
                youtube,
                podcast,
                currency,
                contentRights: contentRights.value, // Send the selected value
                duration: duration.value, // Send the selected value
                reuseAfter: reuseAfter.value, // Send the selected value
            });
            // console.log(data);
            navigate(`/settings/promotions/`);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <UserRoute>
            <div className='edit-promotion-pg'>
                <Navigation heading={"Edit Promotion"} back={true} />
                <Card className="card--input" >
                    <InputField
                        label={"Title"}
                        name={"name"}
                        type={"text"}
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                    <TextArea
                        label={"Description"}
                        name={"description"}
                        type={"text"}
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                    />

                    {/* Currency Select */}
                    <div className='edit-promotion-pg__row edit-promotion-pg__row--modifier'>
                        <label className="edit-promotion-pg__label">Currency</label>
                        <CustomSelect
                            value={currency}
                            options={["USD", "CAD", "EUR", "GBP"]}
                            disabledOptions={["JPY", "AUD", "NZD", "INR", "SGD", "AED", "CHF", "ZAR"]}
                            onOptionSelect={e => setCurrency(e)}
                        />
                    </div>

                    <InputField
                        label={"Price"}
                        name={"price"}
                        type={"number"}
                        value={price}
                        onChange={e => setPrice(e.target.value)}
                        className="add-promo-pg__price add-promo-pg__price--modifier"
                    />

                    {/* Content Rights Select */}
                    <div className='edit-promotion-pg__row edit-promotion-pg__row--modifier'>
                        <label className="edit-promotion-pg__label">Content Rights</label>
                        <Select
                            value={contentRights}
                            onChange={setContentRights}
                            options={contentRightsOptions}
                            placeholder="Select Content Rights"
                            classNamePrefix="react-select"
                            isClearable={false} // Disable clearing since there's a default
                            styles={themeMode === 'light' ? selectLightMode : selectDarkMode}
                        />
                    </div>

                    {/* Duration Select */}
                    <div className='edit-promotion-pg__row edit-promotion-pg__row--modifier'>
                        <label className="edit-promotion-pg__label">Duration</label>
                        <Select
                            value={duration}
                            onChange={setDuration}
                            options={durationOptions}
                            placeholder="Select Duration"
                            classNamePrefix="react-select"
                            isClearable={false} // Disable clearing since there's a default
                            styles={themeMode === 'light' ? selectLightMode : selectDarkMode}
                        />
                    </div>

                    {/* Reuse After Campaign Select */}
                    <div className='edit-promotion-pg__row edit-promotion-pg__row--modifier'>
                        <label className="edit-promotion-pg__label">Modify & Reuse</label>
                        <Select
                            value={reuseAfter}
                            onChange={setReuseAfter}
                            options={reuseOptions}
                            placeholder="Select Reuse Period"
                            classNamePrefix="react-select"
                            isClearable={false} // Disable clearing since there's a default
                            styles={themeMode === 'light' ? selectLightMode : selectDarkMode}
                        />
                    </div>

                    <div className='edit-promotion-pg__row'>
                        <div className='edit-promotion-pg__row-left'>
                            <div className={`edit-promotion-pg__portoflio-container edit-promotion-pg__portoflio-container--${themeClass}`}>
                                {portfolio.map((item, index) => {
                                    return (
                                        <div key={index} className="edit-promotion-pg__portfolio edit-promotion-pg__portfolio--modifier">
                                            <img className="edit-promotion-pg__portfolio-image" src={item} alt={`Portfolio ${index}`} />
                                        </div>
                                    );
                                })}
                                <UploadImageComponent
                                    tag={"edit-promotion-pg__upload"}
                                    handleAdd={handleAdd}
                                    image={image}
                                    setImage={setImage}
                                    imagePreview={imagePreview}
                                    setImagePreview={setImagePreview}
                                />
                            </div>
                        </div>

                        <div className='edit-promotion-pg__row-right'>
                            <SocialFieldCard name={"Instagram"} setPosts={setInstagramPosts} posts={instagramPosts} />
                            <SocialFieldCard name={"TikTok"} setPosts={setTikTokPosts} posts={tikTokPosts} />
                            <SocialFieldCard name={"Youtube"} setPosts={setYoutubePosts} posts={youtubePosts} />
                            <SocialFieldCard name={"Podcasts"} setPosts={setPodcastPosts} posts={podcastPosts} />
                        </div>
                    </div>

                    <Button
                        modifier={"button"}
                        onClick={editPromotion}
                    >
                        Update
                    </Button>
                </Card>
            </div>
        </UserRoute>
    );

}
