import React, { useContext } from 'react';
import Select from 'react-select';
import './Dropdown.scss';
import ThemeContext from '../../context/ThemeContext';
import { selectLightMode, selectDarkMode } from '../../utils/stylesUtils';

const Dropdown = ({ label, links, selected, setSelected, singleSelection }) => {
    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

    const options = links.map(link => ({ value: link.path, label: link.label }));

    const handleChange = (selectedOption) => {
        if (singleSelection) {
            setSelected(selectedOption ? selectedOption.value : "");
        } else {
            setSelected(selectedOption ? selectedOption.map(option => option.value) : []);
        }
    };

    return (
        <div className={`dropdown dropdown--${themeClass}`}>
            {/* <label className={`dropdown-label dropdown-label--${themeClass}`}>{label}</label> */}
            <Select
                className={`react-select-container react-select-container--${themeClass}`}
                classNamePrefix="react-select"
                value={
                    singleSelection
                        ? options.find(option => option.value === selected)
                        : options.filter(option => selected.includes(option.value))
                }
                onChange={handleChange}
                options={options}
                isMulti={!singleSelection}
                placeholder={`${label}`}
                isClearable
                
                styles={ themeMode === 'light' ? selectLightMode : selectDarkMode }
            />
        </div>
    );
};

export default Dropdown;
