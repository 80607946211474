// utils/validators.js

export const isValidEmail = (email) => {
    // Regular expression for validating email addresses
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    // Test the email against the regex
    return emailRegex.test(email);
  };

  export const countryToPhoneNumberCode = (countryCode) => {
    switch (countryCode) {
      case 'US':
      case 'CA':
        return '+1';
      case 'GB':
        return '+44';
      case 'DE':
        return '+49';
      case 'FR':
        return '+33';
      case 'IT':
        return '+39';
      case 'ES':
        return '+34';
      case 'AU':
        return '+61';
      case 'NZ':
        return '+64';
      case 'JP':
        return '+81';
      case 'BR':
        return '+55';
      case 'IN':
        return '+91';
      case 'SG':
        return '+65';
      case 'HK':
        return '+852';
      case 'MY':
        return '+60';
      case 'TH':
        return '+66';
      case 'ID':
        return '+62';
      case 'PH':
        return '+63';
      case 'VN':
        return '+84';
      case 'ZA':
        return '+27';
      case 'NG':
        return '+234';
      case 'AE':
        return '+971';
      case 'SA':
        return '+966';
      case 'AR':
        return '+54';
      case 'CL':
        return '+56';
      case 'MX':
        return '+52';
      case 'IE':
        return '+353';
      case 'NL':
        return '+31';
      case 'BE':
        return '+32';
      case 'AT':
        return '+43';
      case 'PT':
        return '+351';
      case 'FI':
        return '+358';
      case 'GR':
        return '+30';
      case 'SE':
        return '+46';
      case 'DK':
        return '+45';
      case 'NO':
        return '+47';
      case 'PL':
        return '+48';
      case 'CZ':
        return '+420';
      case 'SK':
        return '+421';
      default:
        return '+1'; // Default to +1 (US/Canada) for unspecified countries
    }
  };
  
  

  export const countryNameToCode = (countryName) => {
    const countryMapping = {
        'United States': 'US',
        'Canada': 'CA',
        'Mexico': 'MX',
        'United Kingdom': 'GB',
        'Ireland': 'IE',
        'Germany': 'DE',
        'France': 'FR',
        'Spain': 'ES',
        'Italy': 'IT',
        'Netherlands': 'NL',
        'Belgium': 'BE',
        'Luxembourg': 'LU',
        'Switzerland': 'CH',
        'Austria': 'AT',
        'Denmark': 'DK',
        'Sweden': 'SE',
        'Norway': 'NO',
        'Finland': 'FI',
        'Portugal': 'PT',
        'Poland': 'PL',
        'Greece': 'GR',
        'Czech Republic': 'CZ',
        'Slovakia': 'SK',
        'Hungary': 'HU',
        'Romania': 'RO',
        'Bulgaria': 'BG',
        'Croatia': 'HR',
        'Serbia': 'RS',
        'Slovenia': 'SI',
        'Estonia': 'EE',
        'Latvia': 'LV',
        'Lithuania': 'LT',
        'Australia': 'AU',
        'New Zealand': 'NZ',
        'Japan': 'JP',
        'Brazil': 'BR',
        'India': 'IN',
        'Singapore': 'SG',
        'Hong Kong': 'HK',
        'Malaysia': 'MY',
        'Thailand': 'TH',
        'Indonesia': 'ID',
        'Philippines': 'PH',
        'Vietnam': 'VN',
        'South Africa': 'ZA',
        'Nigeria': 'NG',
        'Kenya': 'KE',
        'Egypt': 'EG',
        'Morocco': 'MA',
        'Tunisia': 'TN',
        'United Arab Emirates': 'AE',
        'Saudi Arabia': 'SA',
        'Qatar': 'QA',
        'Kuwait': 'KW',
        'Oman': 'OM',
        'Bahrain': 'BH',
        'Argentina': 'AR',
        'Chile': 'CL',
        'Colombia': 'CO',
        'Peru': 'PE',
        'Ecuador': 'EC'
        // Add more countries as needed
    };

    const countryCode = countryMapping[countryName];
    return countryCode || null; // Return null if country name is not found in mapping
};


export const countryToCurrency = (countryCode) => {

  switch (countryCode) {
      case 'CA':
          return 'CAD';
      case 'US':
      case 'MX':
          return 'USD';
      case 'GB':
      case 'IE':
      case 'IM':
      case 'JE':
      case 'GG':
          return 'GBP';
      case 'DE':
      case 'FR':
      case 'ES':
      case 'IT':
      case 'NL':
      case 'BE':
      case 'AT':
      case 'LU':
      case 'PT':
      case 'FI':
      case 'GR':
      case 'SI':
      case 'CY':
      case 'MT':
      case 'SK':
      case 'EE':
      case 'LV':
      case 'LT':
          return 'EUR';
      case 'AU':
      case 'NZ':
          return 'AUD';
      case 'JP':
          return 'JPY';
      case 'BR':
          return 'BRL';
      case 'IN':
          return 'INR';
      case 'SG':
          return 'SGD';
      case 'HK':
          return 'HKD';
      case 'MY':
          return 'MYR';
      case 'TH':
          return 'THB';
      case 'ID':
          return 'IDR';
      case 'PH':
          return 'PHP';
      case 'VN':
          return 'VND';
      case 'ZA':
          return 'ZAR';
      case 'NG':
          return 'NGN';
      case 'AE':
          return 'AED';
      case 'SA':
          return 'SAR';
      case 'AR':
          return 'ARS';
      case 'CL':
          return 'CLP';
      default:
          return 'USD'; // Default to USD for unspecified countries
  }
};