// src/pages/FacebookErrorPage.js
import React, { useEffect } from 'react';
import UserRoute from '../../Routes/Auth';
import './FacebookErrorPage.scss';

const FacebookErrorPage = () => {

  useEffect(() => {
    const logErrorToBackend = async () => {
      try {
        const response = await fetch('/api/facebook/error-log', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ message: 'Facebook linking error occurred' }),
        });

        if (!response.ok) {
          console.error("Failed to log error to backend");
        }
      } catch (error) {
        console.error("Error logging to backend:", error);
      }
    };

    logErrorToBackend();
  }, []);

  return (
    <UserRoute>
      <div className="facebook-error">
        <h1 className="facebook-error__title">Error</h1>
        <p className="facebook-error__message">
          There was an error linking your Facebook account. Please try again.
        </p>
      </div>
    </UserRoute>
  );
};

export default FacebookErrorPage;
