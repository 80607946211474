import React from 'react';

const OutBoxIcon = ({ themeMode }) => {
  const color = themeMode === 'light' ? 'black' : 'white';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28px" height="28px" viewBox="0 0 24 24" fill={color}>
      <g>
        <path d="M20.488,19.944H3.512c-0.799,0-1.45-0.65-1.45-1.449v-5.639c0-0.799,0.65-1.45,1.45-1.45h4.104
        c0.55,0,1.046,0.305,1.294,0.797l1.373,2.725c0.077,0.152,0.231,0.247,0.402,0.247h2.629c0.171,0,0.325-0.095,0.402-0.248
        l1.373-2.724c0.248-0.492,0.744-0.797,1.294-0.797h4.104c0.799,0,1.45,0.65,1.45,1.45v5.639
        C21.938,19.293,21.287,19.944,20.488,19.944z M3.512,12.406c-0.248,0-0.45,0.202-0.45,0.45v5.639c0,0.248,0.202,0.449,0.45,0.449
        h16.976c0.248,0,0.45-0.201,0.45-0.449v-5.639c0-0.248-0.202-0.45-0.45-0.45h-4.104c-0.171,0-0.325,0.095-0.402,0.247l-1.373,2.725
        c-0.248,0.491-0.744,0.797-1.294,0.797h-2.629c-0.55,0-1.046-0.306-1.294-0.797l-1.373-2.725c-0.077-0.152-0.231-0.247-0.402-0.247
        H3.512z"/>
        <path d="M12.503,5.753v4.83c0,0.64-1,0.64-1,0v-4.82c-0.42,0.42-0.85,0.85-1.27,1.27
        c-0.2,0.19-0.51,0.18-0.71,0c-0.2-0.19-0.18-0.53,0-0.71l2.13-2.12c0.18-0.2,0.52-0.19,0.7,0l2.12,2.12c0.2,0.2,0.19,0.51,0,0.71
        c-0.18,0.19-0.52,0.18-0.7,0L12.503,5.753z"/>
      </g>
    </svg>
  );
};

export default OutBoxIcon;
