// /src/utils/serachFilters.js

// Options for the new filters
export const languageFilter = [
      { path: 'english', label: 'English' },
      { path: 'spanish', label: 'Spanish' },
      { path: 'french', label: 'French' },
      { path: 'german', label: 'German' },
      { path: 'chinese', label: 'Chinese' },
      { path: 'hindi', label: 'Hindi' },
      { path: 'punjabi', label: 'Punjabi' },
      { path: 'arabic', label: 'Arabic' },
      { path: 'japanese', label: 'Japanese' },
      { path: 'korean', label: 'Korean' },
      { path: 'portuguese', label: 'Portuguese' },
      { path: 'bengali', label: 'Bengali' },
      { path: 'russian', label: 'Russian' },
      { path: 'urdu', label: 'Urdu' },
      { path: 'indonesian', label: 'Indonesian' },
      { path: 'swahili', label: 'Swahili' },
];

export const ageFilter = [
      { path: '18-24', label: '18-24' },
      { path: '25-34', label: '25-34' },
      { path: '35-44', label: '35-44' },
      { path: '45-54', label: '45-54' },
      { path: '55+', label: '55+' },
];

export const childrenFriendlyFilter = [
      { path: true, label: 'Yes' },
      { path: false, label: 'No' },

];

// Platform filter options
export const platforms = [
      { path: 'instagram', label: 'Instagram' },
      { path: 'tiktok', label: 'TikTok' },
      { path: 'spotifyPodcast', label: 'Podcasts' },
      { path: 'youtube', label: 'Youtube' },
      { path: 'spotifyMusic', label: 'Music' },
];

// Following filter options
export const followingFilter = [
      { path: '1korless', label: '1,000 or less' },
      { path: '1k-10k', label: '1K-10K' },
      { path: '10k-50k', label: '10K-50K' },
      { path: '50k-100k', label: '50K-100K' },
      { path: '100k-300k', label: '100K-300K' },
      { path: '300k+', label: '300K & Above' },

];

// Pricing filter options
export const pricingFilter = [
      { path: 'under100', label: '$100 or less' },
      { path: 'under1000', label: '$100-$1,000' },
      { path: 'under10k', label: '$1,000-$10K' },
      { path: 'under50k', label: '$10K-$50K' },
];

// Rating filter options
export const ratingFilter = [
      { path: '5stars', label: '⭐️⭐️⭐️⭐️⭐️' },
      { path: '4stars', label: '⭐️⭐️⭐️⭐️' },
      { path: '3stars', label: '⭐️⭐️⭐️' },
];

// Ethnicity filter options
export const ethnicityFilter = [
      { path: 'asian', label: 'Asian' },
      { path: 'east_asian', label: 'East Asian' },
      { path: 'south_asian', label: 'South Asian' },
      { path: 'hispanic_latino', label: 'Hispanic/Latino' },
      { path: 'middle_eastern', label: 'Middle Eastern/North African' },
      { path: 'african', label: 'African' },
      { path: 'african_american', label: 'African American' },
      { path: 'caribbean', label: 'Caribbean' },
      { path: 'pacific_islander', label: 'Pacific Islander' },
      { path: 'caucasian_white', label: 'Caucasian/White' },
      { path: 'native_american', label: 'Native American/Indigenous' },
      { path: 'other', label: 'Other' },
];

// Country filter options (sample list of countries)
export const countryFilter = [
      { path: 'US', label: 'United States' },
      { path: 'UK', label: 'United Kingdom' },
      { path: 'CA', label: 'Canada' },
      { path: 'AU', label: 'Australia' },
      { path: 'NZ', label: 'New Zealand' },
      { path: 'MX', label: 'Mexico' },
      { path: 'IE', label: 'Ireland' },
      { path: 'DE', label: 'Germany' },
      { path: 'FA', label: 'France' },
      { path: 'NL ', label: 'Netherlands' },
      { path: 'CH', label: 'Switzerland' },
      { path: 'IN', label: 'India' },
      { path: 'PK', label: 'Pakistan' },
      { path: 'AE', label: 'United Arab Emirates' },
      { path: 'ZA', label: 'South Africa' },
];
