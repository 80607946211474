// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logout-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #1a1a1a;
  color: #fff;
}
.logout-container h2 {
  background-color: #333;
  padding: 20px 40px;
  border-radius: 50px;
  font-size: 1.5rem;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
}`, "",{"version":3,"sources":["webpack://./src/Pages/LogoutPage/LogoutPage.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,yBAAA;EACA,WAAA;AACJ;AACI;EACE,sBAAA;EACA,kBAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;EACA,yCAAA;AACN","sourcesContent":[".logout-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh; // Full height of the viewport\n    background-color: #1a1a1a; // Dark mode background color\n    color: #fff; // White text color\n  \n    h2 {\n      background-color: #333; // Darker background for the text container\n      padding: 20px 40px;\n      border-radius: 50px; // Pill shape\n      font-size: 1.5rem;\n      text-align: center;\n      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5); // Soft shadow for depth\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
