import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import './CountryFlag.scss';

const CountryFlag = ({ countryCode }) => {
  return (
    <div className="country-flag">
      {/* <span className="country-name">{countryCode}</span> */}

      <div className="flag-container">
        <ReactCountryFlag
          countryCode={countryCode}
          svg
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover', // Ensures the flag covers the circle fully
            borderRadius: '50%',
          }}
          aria-label={countryCode}
        />
      </div>
    </div>
  );
};

export default CountryFlag;
