import React from 'react';
import { Link } from 'react-router-dom';
import './FooterComponent.scss';
import InstagramIcon from '../../assets/icons/instagram.png';
import TikTokIcon from '../../assets/icons/tiktok.png';
import CountryFlag from '../CountryFlag/CountryFlag';
// import TwitterIcon from '../../assets/icons/twitter.png';

const FooterComponent = ({ country }) => {
    return (
        <footer className="footer">
            <div className="footer-left">
                <CountryFlag countryCode={country} />
                <p>&copy; 2024 Chequeai</p>
            </div>
            <div className="footer-middle">
                <Link to="/countries">Countries</Link>

                <Link to="/faq">FAQ</Link>

                <Link to="/blog">Blog</Link>
                <a href="mailto:hello@chequeai.com">Contact Us</a>

                {/* <a href="mailto:feedback@chequeai.com">Feedback</a> */}
                <Link to="/terms-of-service">Terms</Link>
                <Link to="/privacy-policy">Privacy</Link>
            </div>
            <div className="footer-right">
                <a href="https://x.com/cheque_ai" target="_blank" rel="noopener noreferrer">
                    X
                </a>
                <a href="https://tiktok.com/@chequeai" target="_blank" rel="noopener noreferrer">
                    <img src={TikTokIcon} alt="TikTok" className="social-icon" />
                </a>
                <a href="https://instagram.com/chequeai" target="_blank" rel="noopener noreferrer">
                    <img src={InstagramIcon} alt="Instagram" className="social-icon" />
                </a>

            </div>
        </footer>
    );
};

export default FooterComponent;
