// src/components/AnalyticsCard/AnalyticsCard.jsx

import React from 'react';
import './AnalyticsCard.scss';
import ViewIcon from './ViewIcon';
import LikeIcon from './LikeIcon';
import CommentIcon from './CommentIcon';

const formatNumber = (num) => {
  if (typeof num === 'number') {
    return num.toLocaleString();
  }
  // If num is a string, convert it to a number first
  const parsedNum = parseInt(num, 10);
  if (!isNaN(parsedNum)) {
    return parsedNum.toLocaleString();
  }
  return num;
};

const AnalyticsCard = ({ views, likes, comments }) => {
  return (
    <div className={'analytics-card'}>
      <div className={'analytics-card__metric'}>
      <ViewIcon />


        <div className={'analytics-card__value'}>
        {formatNumber(views)}
        </div>
        {/* <div className={'analytics-card__label'}>Views</div> */}
      </div>

      <div className={'analytics-card__metric'}>
        <CommentIcon />
        <div className={'analytics-card__value'}>{formatNumber(comments)}</div>
        {/* <div className={'analytics-card__label'}>Comments</div> */}
      </div>
      <div className={'analytics-card__metric'}>
        <LikeIcon />
        <div className={'analytics-card__value'}>{formatNumber(likes)}</div>
        {/* <div className={'analytics-card__label'}>Likes</div> */}
      </div>

    </div>
  );
};


export default AnalyticsCard;
