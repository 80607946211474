import React from 'react';
import './EditProductModal.scss';
import InputField from '../InputField/InputField';
import Button from '../Button/Button';
import TextArea from '../TextArea/TextArea';

const EditProductModelForm = ({ isOpen, setIsOpen, onClose, onSave }) => {

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setIsOpen((prevPost) => ({
      ...prevPost,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(isOpen);
    onClose();
  };

  return (
    <div className={`edit-post-modal`}>
      <div className="edit-post-modal__overlay" onClick={onClose} />
      <div className="edit-post-modal__content">
        <div className='edit-post-modal__container'>
          <div className='edit-post-modal__body'>
            <InputField
              label="SKU"
              name="sku"
              type="text"
              value={isOpen?.sku}
              onChange={handleInputChange}
            />
            <InputField
              label="Product Name"
              name="name"
              type="text"
              value={isOpen?.name}
              onChange={handleInputChange}
            />
            <InputField
              label="Price"
              name="price"
              type="text"
              value={isOpen?.price}
              onChange={handleInputChange}
            />
            <InputField
              label="Product Page"
              name="url"
              type="text"
              value={isOpen?.url}
              onChange={handleInputChange}
            />
            <TextArea
              label="Description"
              name="description"
              value={isOpen?.description}
              onChange={handleInputChange}
            />
          </div>
          <div className='edit-post-modal__footer'>
            <Button modifier="button" type="submit" onClick={handleSubmit}>
              Update Product
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProductModelForm;
