import React, { useContext, useEffect, useState } from 'react';
import './LandingPage.scss';
import FooterComponent from '../../Components/FooterComponent/FooterComponent';
import HomeImg from '../../assets/screenshoots/HomePage3.png';
import EarningsImg from '../../assets/screenshoots/EarningsFeature.png';
import SearchImg from '../../assets/screenshoots/SearchFeature.png';
import CampaignImg from '../../assets/screenshoots/CampaignFeature.png';
import SubscriptionPlanCard from '../../Components/SubscriptionPlanCard/SubscriptionPlanCard';
import { Link, useNavigate } from 'react-router-dom';
import { Context } from "../../context/index";
import ThemeContext from '../../context/ThemeContext';
// import EmailListComponent from '../../Components/EmailListComponent/EmailListComponent';
import LandingPgCarousel from '../../Components/LandingPgCarousel/LandingPgCarousel';
import axios from 'axios'; // Ensure axios is imported
import Header from '../../Components/Header/Header';
import MobileMenuModal from '../../Components/MobileMenuModal/MobileMenuModal';

export default function LandingPage() {
    const { state } = useContext(Context);
    const navigate = useNavigate();
    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';
    const { user } = state;
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    // State variables for filtering
    const [activeTab, setActiveTab] = useState(0); // 0 for Basic, 1 for Pro
    const [currency, setCurrency] = useState("USD");
    const [billingPeriod, setBillingPeriod] = useState("monthly");
    const [plans, setPlans] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Available currency options
    const currencies = [
        { value: "USD", label: "USD" },
        { value: "EUR", label: "EUR" },
        { value: "GBP", label: "GBP" },
        { value: "CAD", label: "CAD" },
    ];

    const handleCheckout = async (id) => {
        try {
            // Implement your checkout logic here
            // For example, navigate to signup or initiate Stripe checkout
            navigate('/signup'); // Placeholder
        } catch (error) {
            console.log(error);
        }
    };

    const fetchPlans = async () => {
        try {
            const response = await axios.get("/subscriptions/"); // Adjust the endpoint if necessary
            // console.log(response.data);
            setPlans(response.data.data.reverse());
            setLoading(false);
        } catch (err) {
            console.error("Error fetching plans:", err);
            setError("Failed to load subscription plans. Please try again later.");
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPlans();
    }, []);

    // Filter plans based on activeTab and billingPeriod
    const getFilteredPlans = () => {
        if (activeTab === 0) { // Basic
            return plans.filter(plan => (plan.name === "Basic" || plan.name === "Free") && (plan.duration === billingPeriod || plan.duration === "forever"));
        } else { // Pro
            return plans.filter(plan => (plan.name === "Standard" || plan.name === "Premium") && plan.duration === billingPeriod);
        }
    };

    // Format money based on selected currency
    const formatMoney = (amount) => {
        return new Intl.NumberFormat("en-US", { style: "currency", currency }).format(amount);
    };

    // Handle tab change
    const handleTabChange = (tabIndex) => {
        setActiveTab(tabIndex);
    };

    // Handle billing period change
    const handleBillingPeriodChange = (period) => {
        setBillingPeriod(period);
    };

    return (
        <div className={`landing-pg landing-pg--${themeClass}`}>
            <Header user={user} themeClass={themeClass} isMobileMenuOpen={isMobileMenuOpen} setIsMobileMenuOpen={setIsMobileMenuOpen} />

            <section className='landing-pg__hero'>
                <h4 className='landing-pg__hero__made-for'>Made for Creators and Brands</h4>
                <h1 className='landing-pg__hero__title'>Manage Brand Deals</h1>
                <p className='landing-pg__hero__text'>Find the perfect creator for your brand, or become a creator and start earning money today!</p>
                <img src={HomeImg} alt='Search for Influencers' className='landing-pg__hero__img' />
                <div className='landing-pg__hero__cta'>
                    <Link to="/signup">
                        <button className='landing-pg__btn landing-pg__btn--primary'>
                            <span className={`landing-pg__start-now landing-pg__start-now--${themeClass}`}>Start now</span>
                        </button>
                    </Link>
                    <Link to="/login">
                        <button className={`landing-pg__btn landing-pg__btn--translucent--${themeClass}`}>
                            {user ? <span className="landing-pg__learn-more">Home</span> : <span className="landing-pg__learn-more">Sign In</span>}
                        </button>
                    </Link>
                </div>
            </section>

            <LandingPgCarousel fullImage={HomeImg} themeClass={themeClass} />

            <section className='landing-pg__features' id="features">
                <h1 className='landing-pg__features__title'>Features</h1>
                <div className='landing-pg__features__list'>
                    <div className='landing-pg__features__item'>
                        <div className='landing-pg__features__img'>
                            <img src={SearchImg} alt='Search for Influencers' className='landing-pg__features__img' />
                        </div>
                        <div className='landing-pg__features__text'>
                            <i className="fas fa-search"></i>
                            <h3>Search for Creators</h3>
                            <p>Brands can search for creators based on their target audience, engagement rate, and more.</p>
                        </div>
                    </div>
                    <div className='landing-pg__features__item'>
                        <div className='landing-pg__features__img'>
                            <img src={EarningsImg} alt='Search for Influencers' className='landing-pg__features__img' />
                        </div>
                        <div className='landing-pg__features__text'>
                            <i className="fas fa-user-plus"></i>
                            <h3>Start as a Creator today</h3>
                            <p>Creators can create a profile, set their rates, and start earning money by promoting brands on their social media channels.</p>
                        </div>
                    </div>
                    <div className='landing-pg__features__item'>
                        <div className='landing-pg__features__img'>
                            <img src={CampaignImg} alt='Search for Influencers' className='landing-pg__features__img' />
                        </div>
                        <div className='landing-pg__features__text'>
                            <i className="fas fa-chart-line"></i>
                            <h3>Track Performance</h3>
                            <p>Brands can track the performance of their creators' campaigns in real-time, including reach, engagement, and conversions.</p>
                        </div>
                    </div>
                </div>
            </section>
            {/* <WaitListComponent /> */}

            <section className='landing-pg__subscription' id="subscriptions">
                <h1 className='landing-pg__subscription__title'>Subscriptions</h1>
                <div className='landing-pg__controls'>

                    <div className="subscription-plan__billing-period-selector">
                        <div className="tabs">
                            <button
                                className={`tabs__btn tabs__btn--${themeClass} tabs__btn${activeTab === 0 ? "--active" : ""}`}
                                onClick={() => handleTabChange(0)}
                            >
                                Basic
                            </button>
                            <button
                                className={`tabs__btn tabs__btn--${themeClass} tabs__btn${activeTab === 1 ? "--active" : ""}`}
                                onClick={() => handleTabChange(1)}
                            >
                                Pro
                            </button>
                        </div>


                    </div>
                </div>
                <div className='landing-pg__subscription-div'>
                    <div className="currency-selector">
                        <select
                            id="currency"
                            value={currency}
                            onChange={(e) => setCurrency(e.target.value)}
                            className={`currency-selector__selector currency-selector__selector--${themeClass}`}
                        >
                            {currencies.map((cur) => (
                                <option key={cur.value} value={cur.value}>
                                    {cur.label}
                                </option>
                            ))}
                        </select>
                        <div className="billing-period-selector">
                            <button
                                className={`billing-period-selector__btn billing-period-selector__btn--${themeClass} billing-period-selector__btn${billingPeriod === "monthly" ? "--active" : ""}`}
                                onClick={() => handleBillingPeriodChange("monthly")}
                            >
                                Monthly
                            </button>
                            <button
                                className={`billing-period-selector__btn 
                                            billing-period-selector__btn--${themeClass} billing-period-selector__btn${billingPeriod === "annually" ? "--active" : ""}`}
                                onClick={() => handleBillingPeriodChange("annually")}
                            >
                                Annual
                            </button>
                        </div>
                    </div>
                    <div className='landing-pg__subscription__plans-list'>
                        {loading && <p>Loading plans...</p>} {/* Optional: Loading indicator */}
                        {error && <p className="error">{error}</p>} {/* Optional: Error message */}
                        {!loading && !error && getFilteredPlans().length > 0 ? (
                            getFilteredPlans().map((plan) => (
                                <SubscriptionPlanCard
                                    key={plan.priceId}
                                    priceId={plan.priceId}
                                    name={plan.name}
                                    price={formatMoney(plan.prices?.[billingPeriod]?.[currency] || 0)} // Adjust based on API response
                                    duration={plan.duration}
                                    buttonLabel="Subscribe" // Adjust if needed
                                    handleCheckout={handleCheckout}
                                    features={plan.features}
                                />
                            ))
                        ) : (
                            !loading && !error && <p className='landing-pg__subscription__plans-list__text'>No subscription plans available</p>
                        )}
                    </div>
                </div>
            </section>

            <section className='landing-pg__cta'>
                <div className='landing-pg__cta-content'>
                    <h1 className='landing-pg__cta__title'>Ready to get started?</h1>
                    <p className='landing-pg__cta__text'>Join us today and take your brand to the next level with the perfect creator partnerships.</p>
                    <div className='landing-pg__cta__btn-div'>
                        <Link to="/signup">
                            <button className={`landing-pg__btn landing-pg__btn--cta landing-pg__btn--cta--${themeClass}`}>Start now</button>
                        </Link>
                        <Link to="/login">
                            <button className={`landing-pg__btn landing-pg__btn--cta landing-pg__btn--cta--${themeClass}`}>Login</button>
                        </Link>
                    </div>
                </div>
            </section>

            {/* <EmailListComponent /> */}

            <FooterComponent />
            <MobileMenuModal onClose={() => setIsMobileMenuOpen(false)} isMobileMenuOpen={isMobileMenuOpen} setIsMobileMenuOpen={setIsMobileMenuOpen} user={user} themeClass={themeClass} /> 
        </div>
    );
}
