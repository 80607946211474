import React from 'react';

const ListViewFilledIcon = ({ themeMode }) => {
    const color = themeMode === 'light' ? 'black' : 'white';

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill={color} className="icon icon-tabler icons-tabler-filled icon-tabler-layout-list">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M18 3a3 3 0 0 1 3 3v2a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3v-2a3 3 0 0 1 3 -3z" />
            <path d="M18 13a3 3 0 0 1 3 3v2a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3v-2a3 3 0 0 1 3 -3z" />
        </svg>
    );
};

export default ListViewFilledIcon;
