// src/utils/helperFunctions.js

/**
 * Formats a label by:
 * 1. Replacing underscores, hashes, ampersands, hyphens, and other specified characters with spaces.
 * 2. Removing any remaining non-alphanumeric characters except spaces.
 * 3. Capitalizing the first letter of each word.
 *
 * @param {string} value - The label value to format (e.g., "mens_shoes", "health#care").
 * @returns {string} - The formatted label (e.g., "Mens Shoes", "Health Care").
 */
export function formatLabel(value) {
      if (!value || typeof value !== 'string') return '';
    
      // Step 1: Replace specific non-alphanumeric characters with spaces
      // Hyphen is moved to the end to avoid needing to escape it
      let formatted = value.replace(/[_#&-]+/g, ' ');
    
      // Step 2: Remove any remaining non-alphanumeric characters except spaces
      formatted = formatted.replace(/[^\w\s]/gi, '');
    
      // Step 3: Split the string into words, filtering out any empty strings
      const words = formatted.split(' ').filter(word => word.length > 0);
    
      // Step 4: Capitalize the first letter of each word
      const capitalizedWords = words.map(word => capitalize(word));
    
      // Step 5: Join the words back into a single string separated by spaces
      return capitalizedWords.join(' ');
    }
    
    /**
     * Capitalizes the first letter of a word and converts the rest to lowercase.
     *
     * @param {string} word - The word to capitalize.
     * @returns {string} - The capitalized word.
     */
    
export function capitalize(word) {
      if (!word) return '';
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
    