import React, { useState, useEffect, useContext } from 'react'
import UserRoute from '../../Routes/Auth'
import Navigation from '../../Components/Navigation/Navigation'
// import CampaignCard from '../../Components/CampaignCard/CampaignCard'
import "./PastCampaignsPage.scss"
import CampaignRequest from '../../Components/CampaignRequest/CampaignRequest'
import axios from 'axios'
import ThemeContext from '../../context/ThemeContext';
import { Context } from '../../context';
import Card from '../../Components/Card/Card';
import { Link } from 'react-router-dom';

export default function PastCampaignsPage() {
    
    const [campaigns, setCampaigns] = useState([]);
    const { state: { user }, } = useContext(Context);
    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

    useEffect(() => {  
        const fetchCampaigns = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API}/campaigns/completed`);
                const fetchedCampaigns = response.data?.campaigns || [];
                setCampaigns(fetchedCampaigns);
                // console.log(response.data);

            } catch (error) {
                console.error(error);
            }
        };
        fetchCampaigns();
    }, [])



    if (user?.stripeAccountId === "" || Object.keys(user?.stripeSeller).length === 0 || user?.stripeSeller?.charges_enabled === false) {
        return (
            <UserRoute>
                <div className={`donations-settings donations-settings--${themeClass}`}>
                    <Navigation heading={"Past Campaigns"} back={true} />
                    <Card className={`card card--donations card--donations--${themeClass}`}>
                        <h2 className={`donations-settings__title ${themeClass}`}>
                            <span className="donations-settings__branding">Connect Stripe</span>
                        </h2>
                        <p className="donations-settings__message">
                            {user?.stripeSeller && Object.keys(user?.stripeSeller).length === 0 && user?.stripeAccountId !== ""
                                ? "Your Stripe account is connected to this account. Please complete the Stripe connection." : "Stripe is not connected to this account. Please connect your Stripe account to enable Campaigns."
                            }
                        </p>
                        <div className="donations-settings__connect-button__div">
                            <Link to="/settings/payments/"
                                className={`donations-settings__connect-button donations-settings__connect-button--${themeClass}`}
                            >
                                Connect Stripe
                            </Link>
                        </div>
                    </Card>
                </div>
            </UserRoute>
        );
    }

    return (
        <UserRoute>
          <div className='past-campaigns-pg'>
            <Navigation heading={"Past Campaigns"} subh back={true} edit={"/settings/promotions"} />
      
            {campaigns && campaigns.length > 0 ? (
              campaigns.map((campaign) => (
                <CampaignRequest
                  key={campaign._id}
                  campaign={campaign}
                />
              ))
            ) : (

                <div className="past-campaigns-pg__message__container">
                <p className="past-campaigns-pg__message">No past campaigns available at this time.</p>
                </div>
             
            )}
          </div>
        </UserRoute>
      );
}
