import React from 'react'

export default function CloseIcon({ themeClass }) {
      return (
            <div style={{ cursor: 'pointer', marginTop: '0.75rem' }}>
                  <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke={ themeClass === 'light-theme' ? 'darkgrey' : '#fff'}
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="icon icon-tabler icon-tabler-x"
                  >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1={18} y1={6} x2={6} y2={18} />
                        <line x1={6} y1={6} x2={18} y2={18} />
                  </svg>
            </div>
      )
}
