import React, { useEffect, useContext, useCallback, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import './StripePaymentSuccessPage.scss';
import UserRoute from '../../Routes/Auth';
import ThemeContext from '../../context/ThemeContext';
import { Context } from '../../context';
import { apiGet } from '../../utils/apiUtils';

function StripePaymentSuccessPage() {
    const { id, promotionId } = useParams();
    const navigate = useNavigate();
    const { themeMode } = useContext(ThemeContext);
    const { state: { user } } = useContext(Context);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';
    const [isRequested, setIsRequested] = useState(false); // Track if the request was made

    const successRequest = useCallback(async () => {
        try {
            const data = await apiGet(`/campaigns/${id}/${promotionId}/`);
            // console.log(data);
            if (data.success) {
                navigate(`/campaigns/outgoing/`);
            }
        } catch (err) {
            console.error(err);
        }
    }, [id, promotionId, navigate]);

    useEffect(() => {
        if (user && !isRequested) {
            successRequest();
            setIsRequested(true); // Mark request as made
        }
    }, [successRequest, user, isRequested]);

    return (
        <UserRoute>
            <div className='stripe-payment-success-pg'>
                <h1 className='stripe-payment-success-pg__title'>Request Sent</h1>
                <p className='stripe-payment-success-pg__text'>Get started now and edit the details of your campaign!</p>
                <p className='stripe-payment-success-pg__link'>
                    <Link to="/campaigns/outgoing/" className={`stripe-payment-success-pg__link--text stripe-payment-success-pg__link--text--${themeClass}`}>Campaigns</Link>
                </p>
            </div>
        </UserRoute>
    );
}

export default StripePaymentSuccessPage;
