import React from 'react';

const PlusIcon = ({ onClick, themeMode }) => {
  const themeClass = themeMode === "light" ? "light-theme" : "dark-theme";

  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width={24} 
      height={24} 
      viewBox="0 0 24 24" 
      fill="none" 
      stroke={themeClass === 'light-theme' ? 'black' : '#0a0a0a'}  
      strokeWidth={2} 
      strokeLinecap="round" 
      strokeLinejoin="round" 
      className="icon icon-tabler icons-tabler-outline icon-tabler-plus" 
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M12 5l0 14" />
      <path d="M5 12l14 0" />
    </svg>
  );
};

export default PlusIcon;
