import React, { useState, useEffect } from 'react';
import './OnboardingModal.scss';
import PersonalInfoStep from './Steps/PersonalInfoStep.jsx';
import ContactInfoStep from './Steps/ContactInfoStep.jsx';
import ProfessionalDetailsStep from './Steps/ProfessionalDetailsStep.jsx';
import SocialMediaLinksStep from './Steps/SocialMediaLinksStep.jsx';
import PreferencesStep from './Steps/PreferencesStep.jsx';
import axios from 'axios';
import PropTypes from 'prop-types';

function OnboardingModal({ isOpen, onClose, themeMode, username, user }) {
  const [step, setStep] = useState(1);
  const [userData, setUserData] = useState({
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    dateOfBirth: user?.dateOfBirth || '',
    email: user?.email || '',
    phoneNumber: user?.phoneNumber || '',
    location: user?.location || '',
    userType: user?.userType || '',
    companyName: user?.companyName || '',
    bio: user?.bio || '',
    country: user?.country || '',
    categories: user?.categories || [],
    languages: user?.languages || ["English"], // New languages array field
    website: user?.website || '', // New website single string field
    socialMedia: {
      tiktok: user?.socialMedia?.tiktok || '',
      instagram: user?.socialMedia?.instagram || '',
      youtube: user?.socialMedia?.youtube || '',
      twitter: user?.socialMedia?.twitter || '',
    },
    isPrivate: user?.isPrivate || false,
    subscription: user?.subscription || 'free',
  });
  
  const [isSubmitting, setIsSubmitting] = useState(false);

  const totalSteps = 5;

  // Handle 'Esc' key to close the modal
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleEsc);
    }

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [isOpen, onClose]);

  const handleNext = () => {
    setStep((prev) => Math.min(prev + 1, totalSteps));
  };

  const handleBack = () => {
    setStep((prev) => Math.max(prev - 1, 1));
  };

  const handleChange = (data) => {
    setUserData((prevData) => ({
      ...prevData,
      ...data,
    }));
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/settings/expressOnboarding`,
        userData
      );
      console.log(response.data);
      onClose(); // Close the modal after successful submission
    } catch (error) {
      console.error('Error submitting onboarding data', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className={`onboarding-modal-overlay ${themeMode}`}>
      <div className={`onboarding-modal ${themeMode}`} role="dialog" aria-modal="true">
        {/* Progress Bar */}
        <div className="progress-bar">
          <div
            className={`progress ${themeMode}`}
            style={{ width: `${(step / totalSteps) * 100}%` }}
            aria-valuenow={(step / totalSteps) * 100}
            aria-valuemin="0"
            aria-valuemax="100"
            role="progressbar"
          ></div>
        </div>
        {/* Step Components */}
        {step === 1 && (
          <PersonalInfoStep
            data={userData}
            onChange={handleChange}
            onNext={handleNext}
            themeMode={themeMode}
          />
        )}
        {step === 4 && (
          <ContactInfoStep
            data={userData}
            onChange={handleChange}
            onNext={handleNext}
            onBack={handleBack}
            themeMode={themeMode}
          />
        )}
        {step === 3 && (
          <ProfessionalDetailsStep
            data={userData}
            onChange={handleChange}
            onNext={handleNext}
            onBack={handleBack}
            themeMode={themeMode}
          />
        )}
        {step === 2 && (
          <SocialMediaLinksStep
            data={userData}
            onChange={handleChange}
            onNext={handleNext}
            onBack={handleBack}
            themeMode={themeMode}
          />
        )}
        {step === 5 && (
          <PreferencesStep
            data={userData}
            onChange={handleChange}
            onSubmit={handleSubmit}
            onBack={handleBack}
            themeMode={themeMode}
            isSubmitting={isSubmitting}
            username={username}
          />
        )}
      </div>
    </div>
  );
}

OnboardingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
  username: PropTypes.string,
  user: PropTypes.object,
};

export default OnboardingModal;
