import React, { useContext, useEffect } from 'react';
import './WidgetGrid.scss';
import { Link } from 'react-router-dom';
import ColorDropdown from '../ColorDropDown/ColorDropDown';
// import DayEventsWidget from '../DayEventsWidget/DayEventsWidget';
import ThemeContext from "../../context/ThemeContext";
// import MoodSelector from '../MoodSelector/MoodSelector';

const WidgetGrid = ({ data, username, mode, onBackHandle, onNextHandle, currency, metrics, handleMoodToday, donationsCount, notificationsCount }) => {
  const formatNumber = (num) => {
    const absNum = Math.abs(num);
    if (absNum >= 1000000) {
      return (absNum / 1000000).toFixed(1) + 'M';
    } else if (absNum >= 1000) {
      return (absNum / 1000).toFixed(1) + 'K';
    } else if (absNum > 100) {
      return absNum.toFixed(0);
    } else if (absNum === 0) {
      return absNum.toFixed(2);
    } else {
      return absNum.toFixed(1);
    }
  };

  const { themeMode, widgetColor, changeWidgetColor } = useContext(ThemeContext);
  // const themeClass = themeMode === "light" ? "lightgrey" : "black";

  // console.log(themeClass);
  // const [selectedColor, setSelectedColor] = useState(themeClass); 

  const LeftArrow = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="black" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" opacity={0.25} className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-left">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M5 12l14 0" />
      <path d="M5 12l6 6" />
      <path d="M5 12l6 -6" />
    </svg>
  );

  const RightArrow = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="black" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" opacity={0.25} className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-right">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M5 12l14 0" />
      <path d="M13 18l6 -6" />
      <path d="M13 6l6 6" />
    </svg>
  );

  useEffect(() => {
    changeWidgetColor(themeMode === "light" ? "lightgrey" : "black");
  }, [themeMode, changeWidgetColor]);
  const formattedAllTimeAmount = formatNumber(metrics?.metrics?.grossVolume / 100 || 0);
  const displayCurrency = formattedAllTimeAmount === "0.0" ? currency : metrics?.metrics?.netVolume?.currency.toUpperCase();
  const amountWithSign = (metrics?.metrics?.grossVolume?.netAmount || 0) < 0 ? `-$${formattedAllTimeAmount}` : `$${formattedAllTimeAmount}`;

  return (
    <div className="widget-grid">
      <div className='widget-color-dropdown'>
        <ColorDropdown label={"Choose a color"} selectedColor={widgetColor} setSelectedColor={changeWidgetColor} />
      </div>

      <div className='row-one'>
        <div className={`col--two ${widgetColor}`} >
          <div className="hello-hi">
            <h4 className={`hello-hi__title hello-hi__title--${widgetColor}`}>Hello,</h4>
          </div>
          <Link to={`/${username}`} className={`hello-user hello-user--${widgetColor}`}>{username}</Link>
        </div>
        <div className={`col--two ${widgetColor}`} >
          <div className='earnings-today__btns'>
            <div onClick={onBackHandle}><LeftArrow /></div>
            <h3 className={`earnings-today widget--title widget--title--${widgetColor}`}>{mode}</h3>
            <div onClick={onNextHandle}><RightArrow /></div>
          </div>
          <Link to={"/earnings?tf=today"}>
            <h1 className={`widget--value earnings-today--amount earnings-today--amount--${widgetColor}`}>{amountWithSign}<span className={` earnings-currency earnings-currency--${widgetColor}`}>{displayCurrency}</span></h1>
          </Link>
        </div>
      </div>
      <div className='home-page--body'>
        {/* <DayEventsWidget selectedColor={selectedColor} /> */}
        <div className='widget-div'>
          <div className='row-two'>
          <Link className={`col--100 ${widgetColor}`} to={"/campaigns/requests/"}>
              <h3 className={`widget--title widget--title--${widgetColor}`}>Requests</h3>
              <h1 className={`widget--value widget--value--${widgetColor}`}>{data.incomingRequests || 0}</h1>
            </Link>
            <Link className={`col--100 ${widgetColor}`} to={"/campaigns/"}>
              <h3 className={`widget--title widget--title--${widgetColor}`}>Active</h3>
              <h1 className={`widget--value widget--value--${widgetColor}`}>{data.activeCampaigns || 0}</h1>
            </Link>
            <Link className={`col--100 ${widgetColor}`} to={"/campaigns/past/"}>
              <h3 className={`widget--title widget--title--${widgetColor}`}>Complete</h3>
              <h1 className={`widget--value widget--value--${widgetColor}`}>{data.completedCampaigns || 0}</h1>
            </Link>
            
        
          </div>
          <div className='row-four'>

            <Link className={`col--100 ${widgetColor}`} to={"/campaigns/outgoing/"}>
              <h3 className={`widget--title widget--title--${widgetColor}`}>Outgoing</h3>
              <h1 className={`widget--value widget--value--${widgetColor}`}>{data.outgoingCampaigns || 0}</h1>
            </Link>

            <Link className={`col--100 ${widgetColor}`} to={"/donations/"}>
              <h3 className={`widget--title widget--title--${widgetColor}`}>Donations</h3>
              <h1 className={`widget--value widget--value--${widgetColor}`}>{donationsCount || 0}</h1>
            </Link>
            <Link className={`col--100 ${widgetColor}`} to={"/notifications"}>
              <h3 className={`widget--title widget--title--${widgetColor}`}>Notifications</h3>
              <h1 className={`widget--value widget--value--${widgetColor}`}>{notificationsCount || 0}</h1>
            </Link>
            {/* <div className={`col--100 widget--time--container ${widgetColor}`}>
                <h3 className={`widget--title widget--date widget--title--${widgetColor}`}>{` ${new Date().toLocaleString('default', { month: 'long' })} ${new Date().getDate()}`}</h3>
                <h1 className={`widget--time widget--time--${widgetColor}`}>{new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })}</h1>
              </div> */}

          </div>
          {/* <MoodSelector selectedColor={selectedColor} handleMoodToday={handleMoodToday} /> */}
        </div>
      </div>
    </div>
  );
};

export default WidgetGrid;
