import React, { useContext, useEffect, useState } from 'react';
import './CampaignAnalyticsModal.scss';
import InputField from '../InputField/InputField';
import ThemeContext from '../../context/ThemeContext';
import InstagramIcon from "../../assets/icons/instagram.png";
import TikTokIcon from "../../assets/icons/tiktok.png";
import YoutubeIcon from "../../assets/icons/youtube.svg";
import SpotifyIcon from "../../assets/icons/spotify.png";
import CircleCheckIcon from "./CircleCheckIcon";

// Define platform details with icons and display text
const platformDetails = {
  instagram: { name: 'Instagram', icon: InstagramIcon, text: 'Post ID' },
  tiktok: { name: 'TikTok', icon: TikTokIcon, text: 'Post ID' },
  youtube: { name: 'YouTube', icon: YoutubeIcon, text: 'Video ID' },
  spotifyPodcast: { name: 'Spotify Podcast', icon: SpotifyIcon, text: 'Podcast Episode ID' },
};

const CampaignAnalyticsModal = ({ isOpen, onClose, message, onConfirm, existingPostIds, instagramPosts, tikTokPosts }) => {
  // console.log(instagramPosts, tikTokPosts)

  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';
  const [inputFields, setInputFields] = useState([]);

  const [manual, setManual] = useState(false);

  // New state for error messages
  const [error, setError] = useState('');

  // Close modal on "Escape" key press
  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleEscKey);
    }

    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, [isOpen, onClose]);

  // Populate inputFields with existing data when modal opens
  useEffect(() => {
    if (isOpen && existingPostIds && !manual) { // Ensure it's not manual mode
      const fields = [];
      Object.keys(existingPostIds).forEach((platformKey) => {
        const platform = platformDetails[platformKey];
        if (platform && existingPostIds[platformKey]) {
          const postIds = Array.isArray(existingPostIds[platformKey]) ? existingPostIds[platformKey] : [existingPostIds[platformKey]];
          postIds.forEach((postId) => {
            fields.push({
              platformKey,
              platformName: platform.name,
              platformText: platform.text,
              platformIcon: platform.icon,
              value: postId,
            });
          });
        }
      });
      setInputFields(fields);
    } else if (!isOpen) {
      // Clear inputFields and error when modal closes
      setInputFields([]);
      setError('');
    }
  }, [isOpen, existingPostIds, manual]);

  // Function to add a new input field for a platform
  const addInputField = (platformKey, value = '') => {
    // Check for duplicates
    const isDuplicate = inputFields.some(
      (field) => field.platformKey === platformKey && field.value === value
    );

    if (isDuplicate && value !== '') {
      // Set the error message instead of using alert
      setError(`This ${platformDetails[platformKey].name} post ID is already added.`);
      return;
    }

    const platform = platformDetails[platformKey];
    setInputFields([
      ...inputFields,
      {
        platformKey,
        platformName: platform.name,
        platformText: platform.text,
        platformIcon: platform.icon,
        value
      }
    ]);

    // // Automatically switch to manual mode if not already
    // if (!manual) {
    //   setManual(true);
    // }

    // Clear any existing errors when a new field is added successfully
    setError('');
  };

  // Function to remove an input field
  const removeInputField = (index) => {
    const fields = [...inputFields];
    fields.splice(index, 1);
    setInputFields(fields);

    // Clear any existing errors when a field is removed
    setError('');
  };

  // Function to handle input change
  const handleInputChange = (index, event) => {
    const fields = [...inputFields];
    fields[index].value = event.target.value;
    setInputFields(fields);

    // Clear any existing errors when user modifies input
    setError('');
  };

  // Function to handle confirm action
  const handleConfirm = () => {
    // Prepare data to send back to parent component
    const data = inputFields.reduce((acc, field) => {
      if (!acc[field.platformKey]) {
        acc[field.platformKey] = [];
      }
      acc[field.platformKey].push(field.value);
      return acc;
    }, {});
    onConfirm(data); // Pass data to onConfirm callback
    onClose();
  };

  const extractPostCode = (permalink) => {
    const baseUrl = 'https://www.instagram.com/p/';
    if (permalink.startsWith(baseUrl)) {
      // Remove the base URL
      let code = permalink.slice(baseUrl.length);
      // Remove any trailing slash
      if (code.endsWith('/')) {
        code = code.slice(0, -1);
      }
      return code;
    }
    // If permalink doesn't match the expected format, return as is or handle accordingly
    return permalink;
  };


  return (
    <div className={`campaign-analytics-modal ${isOpen ? 'open' : ''}`}>
      <div className="campaign-analytics-modal__overlay" onClick={onClose} />
      <div className={`campaign-analytics-modal__content campaign-analytics-modal__content--${themeClass}`}>
        <div className="campaign-analytics-modal__container">
          <div className={`campaign-analytics-modal__header campaign-analytics-modal__header--${themeClass}`}>
            <p className="campaign-analytics-modal__message">{manual ? 'Add Platform Post IDs' : <>Add Post <span>from Socials</span></>}</p>
          </div>

          {/* Display Error Message if exists */}
          {error && (
            <div className="campaign-analytics-modal__error">
              <p>{error}</p>
              <button
                className="campaign-analytics-modal__error-close"
                onClick={() => setError('')}
                aria-label="Close error message"
              >
                <p>&times;</p>
              </button>
            </div>
          )}

          {/* Non-Manual Mode: Display Existing Posts */}
          {!manual && (
            <div className={`campaign-analytics-modal__body campaign-analytics-modal__body--${themeClass}`}>
              {/* Instagram Posts */}
              {instagramPosts && instagramPosts.length > 0 && (
                <div className="campaign-analytics-modal__platform-section">
                  <div className="campaign-analytics-modal__platform-section__header">
                    <img src={InstagramIcon} alt="Instagram" className="platform-icon" />
                    <h4>Instagram</h4>
                  </div>
                  <div className="campaign-analytics-modal__posts-grid">
                    {instagramPosts.map(post => {
                      const postId = extractPostCode(post.permalink);
                      const isSelected = inputFields.some(
                        field => field.platformKey === 'instagram' && field.value === postId
                      );

                      return (
                        <div key={post.id} className="campaign-analytics-modal__post">
                          {post.media_type === 'IMAGE' && (
                            <img
                              src={post.media_url}
                              alt={`Instagram Post`}
                              className="campaign-analytics-modal__post-image"
                              onClick={() => addInputField('instagram', postId)}
                              style={{ cursor: 'pointer' }}
                            />
                          )}
                          {/* Show CircleCheckIcon if post is selected */}
                          {isSelected && (
                            <div className="campaign-analytics-modal__selected-icon">
                              <CircleCheckIcon />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              {tikTokPosts && tikTokPosts.length > 0 && (
                <div className="campaign-analytics-modal__platform-section">
                  <div className="campaign-analytics-modal__platform-section__header">
                    <img src={TikTokIcon} alt="TikTok" className="platform-icon" />
                    <h4>TikTok</h4>
                  </div>
                  <div className="campaign-analytics-modal__posts-grid">
                    {tikTokPosts.map(post => {
                      const isSelected = inputFields.some(
                        field => field.platformKey === 'tiktok' && field.value === post.id
                      );

                      return (
                        <div key={post.id} className="campaign-analytics-modal__post">
                          <img
                            src={post.coverImageUrl}
                            alt={`TikTok Post`}
                            className="campaign-analytics-modal__post-image"
                            onClick={() => addInputField('tiktok', post.id)}
                            style={{ cursor: 'pointer' }}
                          />
                          {/* Show CircleCheckIcon if post is selected */}
                          {isSelected && (
                            <div className="campaign-analytics-modal__selected-icon">
                              <CircleCheckIcon />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

            </div>
          )}

          {/* Manual Mode: Add/Modify Post IDs */}
          {manual && (
            <div className={`campaign-analytics-modal__body campaign-analytics-modal__body--${themeClass}`}>
              <div className="campaign-analytics-modal__platform-buttons">
                {Object.keys(platformDetails).map((platformKey) => {
                  const platform = platformDetails[platformKey];
                  return (
                    <button
                      key={platformKey}
                      className="campaign-analytics-modal__add-button"
                      onClick={() => addInputField(platformKey)}
                    >
                      <img
                        src={platform.icon}
                        alt={`${platform.name} icon`}
                        className="campaign-analytics-modal__icon"
                      />
                      <span>{platform.text}</span>
                    </button>
                  );
                })}
              </div>

              {inputFields.map((field, index) => (
                <div className={`campaign-analytics-modal__body campaign-analytics-modal__body--${themeClass}`}>
                  <div key={index} className="campaign-analytics-modal__input-group">
                    <InputField
                      label={`${field.platformText}`}
                      placeholder={`Enter ${field.platformText}`}
                      name={`input-${index}`}
                      type="text"
                      value={field.value}
                      icon={field.platformIcon}
                      onChange={(event) => handleInputChange(index, event)}
                    />
                    <button
                      className="campaign-analytics-modal__remove-button"
                      onClick={() => removeInputField(index)}
                      aria-label={`Remove ${field.platformName} post ID`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="red"
                        strokeWidth={1.5}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="icon icon-tabler icon-tabler-x"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M18 6l-12 12" />
                        <path d="M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                </div>
              ))}


            </div>
          )}

          {/* Toggle Manual/Non-Manual Mode */}
          <button
            onClick={() => {
              setManual(!manual);
              setError(''); // Clear error when toggling modes
            }}
            className={`campaign-analytics-modal__manual-button campaign-analytics-modal__manual-button--${themeClass}`}
          >
            {manual ? 'via Socials' : 'Add Manually'}
          </button>

          {/* Footer with Cancel and Confirm Buttons */}
          <div className={`campaign-analytics-modal__footer campaign-analytics-modal__footer--${themeClass}`}>
            <button
              className={`campaign-analytics-modal__button campaign-analytics-modal__button--cancel campaign-analytics-modal__button--${themeClass}`}
              onClick={() => {
                onClose();
                setError(''); // Clear error when cancelling
              }}
            >
              Cancel
            </button>
            <button
              className={`campaign-analytics-modal__button campaign-analytics-modal__button--confirm campaign-analytics-modal__button--${themeClass}`}
              onClick={handleConfirm}
              disabled={inputFields.length === 0} // Optionally disable if no inputs
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignAnalyticsModal;
