import React, { useContext } from 'react';
import ThemeContext from '../../../context/ThemeContext';

const DurationIcon = () => {
    const { themeMode } = useContext(ThemeContext);
    const color = themeMode === 'light' ? 'black' : 'darkgrey';

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-clock-hour-5">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
            <path d="M12 12l2 3" />
            <path d="M12 7v5" />
        </svg>
    );
}

export default DurationIcon;
