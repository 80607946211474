import React, { useEffect, useState, useContext } from "react";
import UserRoute from "../../Routes/Auth";
import Card from "../../Components/Card/Card";
import Navigation from "../../Components/Navigation/Navigation";
import "./CampaignAnalyticsPage.scss";
import { useParams } from "react-router-dom";
import CampaignHeader from "../../Components/CampaignHeader/CampaignHeader";
import Carousel from "../../Components/Carousel/Carousel";
import MenuIcon from "../../Pages/UserPage/icons/MenuIcon";
import ThemeContext from "../../context/ThemeContext";
// import LineChartComponent from "../../Components/LineChartComponent/LineChartComponent";
import CampaignAnalyticHeader from "../../Components/CampaignAnalyticHeader/CampaignAnalyticHeader";
import CampaignAnalyticsModal from "../../Components/CampaignAnalyticsModal/CampaignAnalyticsModal";
import axios from "axios";
import { Context } from '../../context';
import CampaignSupportModal from '../../Components/CampaignSupportModal/CampaignSupportModal';
import CampaignInfoModal from '../../Components/CampaignInfoModal/CampaignInfoModal';

export default function CampaignAnalyticsPage() {
  const [campaign, setCampaign] = useState(null);
  const { state: { user }, } = useContext(Context);
  const [analyticsData, setAnalyticsData] = useState(null); // New state for analytics data
  const { id } = useParams();
  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === "light" ? "light-theme" : "dark-theme";
  const [currentIndex, setCurrentIndex] = useState(0);
  const [analyticsModal, setAnalyticsModal] = useState(false);
  const [instagramPosts, setInstagramPosts] = useState([]); // Initialize as empty array
  const [tikTokPosts, setTikTokPosts] = useState([]);       // Initialize as empty array
  const [isLoading, setIsLoading] = useState(true);         // Loading state
  // const [activeChart, setActiveChart] = useState("views");
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [isCampaignInfoModalOpen, setIsCampaignInfoModalOpen] = useState(false);

  // Fetch campaign details and analytics data
  useEffect(() => {
    const fetchCampaign = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/campaigns/${id}`);
        setCampaign(response.data);
      } catch (error) {
        console.error("Error fetching campaign:", error);
      }
    };

    const fetchInstagramProfile = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/auth/instagram/profile`);
        console.log("Instagram Profile Data:", response.data);
        setInstagramPosts(response.data.media);
      } catch (error) {
        console.error("Error fetching Instagram posts:", error);
      }
    };

    const fetchTikTokProfile = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/auth/tiktok/profile`);
        console.log("TikTok Profile Data:", response.data.posts);
        setTikTokPosts(response.data.posts); 
      } catch (error) {
        console.error("Error fetching TikTok posts:", error);
      }
    };

    const fetchAnalyticsData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/campaigns/${id}/analytics`);
        // console.log("Analytics Data:", response.data);
        setAnalyticsData(response.data.analytics);
      } catch (error) {
        console.error("Error fetching analytics:", error);
      }
    };

    // Fetch all data concurrently and manage loading state
    const fetchAllData = async () => {
      try {
        const fetchTasks = [
          fetchCampaign(),
          fetchAnalyticsData(),
        ];

        // Conditionally add Instagram fetch if the user is a creator and authenticated with Instagram
        if (user.userType === "creator" && user.socialMediaAuth?.instagram && user.socialMediaAuth?.instagram?.accessToken !== "") {
          fetchTasks.push(fetchInstagramProfile());
        }

        // Conditionally add TikTok fetch if the user is a creator and authenticated with TikTok
        if (user.userType === "creator" && user.socialMediaAuth?.tiktok && user.socialMediaAuth?.tiktok?.accessToken !== "") {
          fetchTasks.push(fetchTikTokProfile());
        }

        await Promise.all(fetchTasks);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllData();
  }, [id, user]); // Only dependent on `id` and `user`, assuming they don't change

  const handleUpdatePostId = async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/campaigns/${id}/update-post-id`,
        data
      );

      console.log("Update Post ID Response:", response.data);

      // Update state with the new postIds from the response
      setCampaign((prevCampaign) => ({
        ...prevCampaign,
        postIds: response.data.campaign.postIds,
      }));

      // Close the modal
      setAnalyticsModal(false);
    } catch (error) {
      console.error("Error updating post IDs:", error);
    }
  };

  return (
    <UserRoute>
      <div className={`campaign-analytics-pg ${themeClass}`}>
        <Navigation
          heading={campaign?.campaignName || "Campaign Analytics"}
          back={true}
          edit={`/campaigns/edit/${id}`}
        />
        <Card className="card--margin">
          <div className="campaign-analytics-pg__header">
            <div className="campaign-analytics-pg__header__left">
              <CampaignHeader campaign={campaign}

                setIsCampaignInfoModalOpen={setIsCampaignInfoModalOpen} setShowOptions={setShowOptions}
                setIsSupportModalOpen={setIsSupportModalOpen} showOptions={showOptions} isSupportModalOpen={isSupportModalOpen}

              />
            </div>
            <div
              className="campaign-analytics-pg__menu-icon-container"
              onClick={() => setAnalyticsModal(true)}
            >
              <MenuIcon themeMode={themeMode} />
            </div>
          </div>
          <div className="campaign-analytics-pg__header-container">
            <div className="campaign-analytics-pg__header-right">
              <CampaignAnalyticHeader
                campaign={campaign}
                setData={setCurrentIndex}
              />
            </div>
          </div>
          <div className="campaign-analytics-pg__container">
            {isLoading ? (
              <div>Loading...</div> // Replace with a spinner or loader component
            ) : (
              <>
                {campaign && (
                  <Carousel
                    themeClass={themeClass}
                    campaign={campaign}
                    currentIndex={currentIndex}
                    setAnalyticsModal={setAnalyticsModal}
                    analyticsData={analyticsData}
                  />
                )}
                {/* 
                <div className="campaign-analytics-pg__charts">
                  <div className="campaign-analytics-pg__chart-selection">
                    <button
                      className={`campaign-analytics-pg__chart-btn ${
                        activeChart === "views"
                          ? "campaign-analytics-pg__chart-btn--active"
                          : ""
                      }`}
                      onClick={() => setActiveChart("views")}
                    >
                      Views
                    </button>
                    <button
                      className={`campaign-analytics-pg__chart-btn ${
                        activeChart === "engagement"
                          ? "campaign-analytics-pg__chart-btn--active"
                          : ""
                      }`}
                      onClick={() => setActiveChart("engagement")}
                    >
                      Engagement
                    </button>
                    <button
                      className={`campaign-analytics-pg__chart-btn ${
                        activeChart === "clicks"
                          ? "campaign-analytics-pg__chart-btn--active"
                          : ""
                      }`}
                      onClick={() => setActiveChart("clicks")}
                    >
                      Clicks
                    </button>
                    <button
                      className={`campaign-analytics-pg__chart-btn ${
                        activeChart === "conversions"
                          ? "campaign-analytics-pg__chart-btn--active"
                          : ""
                      }`}
                      onClick={() => setActiveChart("conversions")}
                    >
                      Conversions
                    </button>
                  </div>
                  <div className="campaign-analytics-pg__chart-div">
                    {activeChart === "views" && (
                      <LineChartComponent
                        data={analyticsData?.views || []} // Use the fetched analytics data
                        dataKey="value"
                        title="Views Over Time"
                      />
                    )}
                    {activeChart === "clicks" && (
                      <LineChartComponent
                        data={analyticsData?.clicks || []}
                        dataKey="value"
                        title="Clicks Over Time"
                      />
                    )}
                    {activeChart === "engagement" && (
                      <LineChartComponent
                        data={analyticsData?.engagement || []}
                        dataKey="value"
                        title="Engagement Over Time"
                      />
                    )}
                    {activeChart === "conversions" && (
                      <LineChartComponent
                        data={analyticsData?.conversions || []}
                        dataKey="value"
                        title="Conversions Over Time"
                      />
                    )}
                  </div>
                </div> 
                */}
              </>
            )}
          </div>
        </Card>
        {analyticsModal && (
          <CampaignAnalyticsModal
            isOpen={true}
            onClose={() => setAnalyticsModal(false)}
            onConfirm={handleUpdatePostId}
            existingPostIds={campaign?.postIds}
            instagramPosts={instagramPosts} // Pass Instagram posts
            tikTokPosts={tikTokPosts}       // Pass TikTok posts
          />
        )}
        {isSupportModalOpen && (
          <CampaignSupportModal
            isOpen={isSupportModalOpen}
            onClose={() => setIsSupportModalOpen(false)}
            campaignId={id}
          />
        )
        }
        <CampaignInfoModal isOpen={isCampaignInfoModalOpen} onClose={() => setIsCampaignInfoModalOpen(false)} themeMode={themeMode} />

      </div>
    </UserRoute>
  );
}
