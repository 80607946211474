import React, { useContext } from 'react'
import "./Card.scss"
import ThemeContext from '../../context/ThemeContext';



export default function Card(props) {

    const { themeMode } = useContext(ThemeContext);

    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

    return (
        <div className={`${props.className} ${props.className}--${themeClass} card--${themeClass} card`} >
            {props.children}
        </div>
    )
}
