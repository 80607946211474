import React from 'react'

export default function FailedIcon({ themeClass, purchaseConfirmed }) {
      return (
            <div
                  style={{
                        display: purchaseConfirmed === "failed" ? 'inline-flex' : 'none',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingTop: '0.2rem',
                  }}
            >
                  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="red" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-circle-x">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                        <path d="M10 10l4 4m0 -4l-4 4" />
                  </svg>
            </div>
      )
}
