import React from 'react';
import './SpinnerIcon.scss'; // Ensure you import the CSS file

export default function SpinnerIcon({ themeClass, purchaseConfirmed }) {
  return (
    <div
      style={{
        display: purchaseConfirmed === "processing" ? 'inline-flex' : 'none',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '0.1rem',
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        stroke={themeClass === "light-theme" ? "darkgrey" : "darkgrey"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        className="icon icon-tabler icons-tabler-outline icon-tabler-refresh spinner"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4" />
        <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4" />
      </svg>
    </div>
  );
}
