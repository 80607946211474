import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import ThemeContext from '../../context/ThemeContext';
import './CampaignHeader.scss';
import { EditIcon } from '../CampaignCard/EditIcon';
import MenuIcon from '../../Pages/UserPage/icons/MenuIcon';
import HamburgerIcon from './HamburgerIcon';

export default function CampaignHeader({
    status,
    showOptions,
    setShowOptions,
    setIsSupportModalOpen,
    setIsCampaignInfoModalOpen,
}) {
    const { id } = useParams();
    const url = useLocation();
    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

    const headerRef = useRef(null); // Reference for detecting outside clicks

    const isActive = (pathname) => {
        return url.pathname === pathname
            ? `campaign-card__link--active campaign-card__link--${themeClass}--active`
            : '';
    };

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const getCurrentPageName = () => {
        const path = url.pathname;
        if (path.includes('/campaigns/' + id + '/campaign')) return 'Posts';
        if (path.includes('/campaigns/' + id + '/products')) return 'Products';
        if (path.includes('/campaigns/' + id + '/teams')) return 'Team';
        if (path.includes('/campaigns/' + id + '/analytics')) return 'Analytics';
        if (path.includes('/campaigns/' + id + '/review')) return 'Review';
        if (path.includes('/campaigns/' + id + '/edit')) return 'Edit';
        return 'Home';
    };

    const navLinks = (
        <>
            <Link
                to={`/campaigns/${id}/`}
                className={`campaign-header__link campaign-header__link--${themeClass} ${isActive(
                    `/campaigns/${id}/`
                )}`}
            >
                Home
            </Link>
            <Link
                to={`/campaigns/${id}/campaign`}
                className={`campaign-header__link campaign-header__link--${themeClass} ${isActive(
                    `/campaigns/${id}/campaign`
                )}`}
            >
                Posts
            </Link>
            <Link
                to={`/campaigns/${id}/products`}
                className={`campaign-header__link campaign-header__link--${themeClass} ${isActive(
                    `/campaigns/${id}/products`
                )}`}
            >
                Products
            </Link>
            <Link
                to={`/campaigns/${id}/teams`}
                className={`campaign-header__link campaign-header__link--${themeClass} ${isActive(
                    `/campaigns/${id}/teams`
                )}`}
            >
                Team
            </Link>
            <Link
                to={`/campaigns/${id}/analytics`}
                className={`campaign-header__link campaign-header__link--${themeClass} ${isActive(
                    `/campaigns/${id}/analytics`
                )}`}
            >
                Analytics
            </Link>
            <Link
                to={`/campaigns/${id}/review`}
                className={`campaign-header__link campaign-header__link--${themeClass} ${isActive(
                    `/campaigns/${id}/review`
                )}`}
            >
                Review
            </Link>

            {status !== 'Completed' && status !== 'Post Campaign' && (
                <Link
                    to={`/campaigns/${id}/edit`}
                    className={`campaign-header__link campaign-header__link--${themeClass}`}
                >
                    {isMobileMenuOpen ? "Edit Campaign" : <EditIcon themeMode={themeMode} />}
                </Link>
            )}

            <div
                className={`campaign-header__link campaign-header__link--${themeClass}  campaign-header__link--menu `}
                onClick={() => setShowOptions(!showOptions)}
            >
                <MenuIcon themeMode={themeMode} />
                {showOptions && (
                    <div className={`campaign-header__options campaign-header__options--${themeClass}`}>
                        <div
                            className={`campaign-header__options-item campaign-header__options-item--cancel campaign-header__options-item--${themeClass}`}
                            onClick={() => setShowOptions(false)}
                        >
                            Cancel
                        </div>
                        <div
                            className={`campaign-header__options-item  campaign-header__options-item--${themeClass}`}
                            onClick={() => {
                                setIsCampaignInfoModalOpen(true);
                                setShowOptions(false);
                            }}
                        >
                            Info
                        </div>
                        <div
                            className={`campaign-header__options-item campaign-header__options-item--${themeClass}`}
                            onClick={() => {
                                setIsSupportModalOpen(true);
                                setShowOptions(false);
                            }}
                        >
                            Support
                        </div>
                    </div>
                )}
            </div>
        </>
    );

    // Close options when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (headerRef.current && !headerRef.current.contains(event.target)) {
                setIsMobileMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setShowOptions, setIsMobileMenuOpen]);

    return (
        <div className="campaign-header" ref={headerRef}>
            {/* Mobile Header */}
            <div className={`campaign-header__mobile campaign-header__mobile--${themeClass}`}>
                <div className="campaign-header__mobile-left" onClick={() => {
                        setIsMobileMenuOpen(!isMobileMenuOpen)
                        if (showOptions) {
                            setShowOptions(!showOptions);
                        }
                    }
                }>
                    <div className="campaign-header__mobile-menu-icon" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
                        <HamburgerIcon themeMode={themeMode} />
                    </div>
                    <div className="campaign-header__mobile-title">{getCurrentPageName()}</div>
                </div>
                <button
                    className="campaign-header__mobile-options-icon"
                    onClick={() => setShowOptions(!showOptions)}
                    disabled={isMobileMenuOpen}
                >
                    <MenuIcon themeMode={themeMode} />
                    {showOptions && (
                        <div className={`campaign-header__options campaign-header__options--${themeClass}`}>
                            <div
                                className={`campaign-header__options-item campaign-header__options-item--cancel campaign-header__options-item--${themeClass}`}
                                onClick={() => setShowOptions(false)}
                            >
                                Cancel
                            </div>
                            <div
                                className={`campaign-header__options-item  campaign-header__options-item--${themeClass}`}
                                onClick={() => {
                                    setIsCampaignInfoModalOpen(true);
                                    setShowOptions(false);
                                }}
                            >
                                Info
                            </div>
                            <div
                                className={`campaign-header__options-item campaign-header__options-item--${themeClass}`}
                                onClick={() => {
                                    setIsSupportModalOpen(true);
                                    setShowOptions(false);
                                }}
                            >
                                Support
                            </div>
                        </div>
                    )}
                </button>
                {isMobileMenuOpen && (
                    <div className={`campaign-header__mobile-menu campaign-header__mobile-menu--${themeClass}`}>
                        {navLinks}
                    </div>
                )}
            </div>

            {/* Tablet and Desktop Header */}
            <div className={`campaign-header__desktop campaign-header__desktop--${themeClass}`}>
                {navLinks}
            </div>
        </div>
    );
}
