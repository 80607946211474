import React, { useContext } from 'react';
import "./PayoutCard.scss";
import ThemeContext from '../../context/ThemeContext';

// Helper function to format dollar amount with commas
const formatAmount = (amount) => {
  return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

const PayoutCard = ({ pending, available, payoutSchedule, stripeExpressDashboard}) => {
  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

  return (
    <div className={`payout-card payout-card--${themeClass}`}>
      <div className={`payout-card__header`}>
        <p className={`payout-card__title`}>Balance</p>
        {/* <p className="payout-card__amount">
          ${formatAmount((available[0]?.amount || 0) / 100)} {available[0]?.currency?.toUpperCase()}
          <span style={{ fontSize: '1rem', opacity: '0.5' }}> available</span>
        </p> */}
        <p className="payout-card__amount">
          ${formatAmount((pending[0]?.amount || 0) / 100)} {pending[0]?.currency?.toUpperCase()}
          <span style={{ fontSize: '1rem', opacity: '0.5' }}> pending</span>
        </p>
        <p className={`payout-card__schedule`}>Next payout: {payoutSchedule}</p> 
      </div>
      <div className="payout-card__body" onClick={stripeExpressDashboard}>
        <div className={`payout-card__btn payout-card__btn--${themeClass}`}>Payout</div>
      </div>
    </div>
  );
};

export default PayoutCard;
