import React, { useState, useContext } from 'react';
import Select from 'react-select';
import './FeedbackModal.scss';
import StarIcon from '../../Pages/CampaignReviewPage/StarIcon';
import { apiPost } from '../../utils/apiUtils';  
import ThemeContext from '../../context/ThemeContext';  
import { selectDarkMode, selectLightMode } from '../../utils/stylesUtils';

const FeedbackModal = ({ isOpen, onClose }) => {
  const [title, setTitle] = useState('');
  const [comments, setComments] = useState('');
  const [rating, setRating] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionType, setSubmissionType] = useState({ value: 'feedback', label: 'Feedback' });
  const [supportCategory, setSupportCategory] = useState(null);

  const { themeMode } = useContext(ThemeContext); 
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';  

  const submissionOptions = [
    { value: 'feedback', label: 'Feedback' },
    { value: 'support-request', label: 'Support Request' }
  ];

  const supportOptions = [
    { value: 'account-setup', label: 'Account Setup' },
    { value: 'payments-setup', label: 'Payments Setup (Stripe)' },
    { value: 'brand-deals-management', label: 'Brand Deals Management' },
    { value: 'payment-issues', label: 'Payment Issues' },
    { value: 'reporting-analytics', label: 'Reporting & Analytics' },
    { value: 'other', label: 'Other' }
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Determine API endpoint based on submission type
      const endpoint = submissionType.value === 'support-request' ? '/user/support/' : '/feedback';
      
      // Send title, feedback, rating, submissionType, and supportCategory if applicable
      const response = await apiPost(endpoint, { 
        title, 
        comments, 
        rating: submissionType.value === 'feedback' ? rating : undefined,
        submissionType: submissionType.value,
        supportCategory: submissionType.value === 'support-request' ? supportCategory?.value : undefined
      });

      console.log('Submission successful:', response);

      // Reset form and close modal after successful submission
      setTitle('');
      setComments('');
      setRating(0);
      setSubmissionType({ value: 'feedback', label: 'Feedback' });
      setSupportCategory(null);
      onClose();
    } catch (error) {
      console.error('Error submitting:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleStarClick = (index) => {
    setRating(index + 1);
  };

  if (!isOpen) return null;

  return (
    <div className={`modal-overlay modal-overlay--${themeClass}`} onClick={onClose}>
      <div className={`modal-content modal-content--${themeClass}`} onClick={(e) => e.stopPropagation()}>
        <h2 className={`modal-title modal-title--${themeClass}`}>{submissionType.label}</h2>
        <form onSubmit={handleSubmit}>
          <div className="submission-type">
            <Select
              id="submissionType"
              value={submissionType}
              onChange={setSubmissionType}
              options={submissionOptions}
              styles={themeMode === 'light' ? selectLightMode : selectDarkMode}
            />
          </div>
          {submissionType.value === 'feedback' && (
            <div className="feedback-rating">
              {[...Array(5)].map((_, index) => (
                <StarIcon
                  key={index}
                  filled={index < rating}
                  onClick={() => handleStarClick(index)}
                />
              ))}
            </div>
          )}
          
          {submissionType.value === 'support-request' && (
            <div className="support-category">
              <Select
                id="supportCategory"
                value={supportCategory}
                onChange={setSupportCategory}
                options={supportOptions}
                placeholder="Select a support category..."
                styles={themeMode === 'light' ? selectLightMode : selectDarkMode}
              />
            </div>
          )}

          <input
            type="text"
            className={`feedback-title feedback-title--${themeClass}`}
            placeholder={ submissionType.value === 'support-request' ? `What's your ${submissionType.label.toLowerCase()}?` : `What's your ${submissionType.label.toLowerCase()} about?`}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
  
          <textarea
            className={`feedback-input feedback-input--${themeClass}`}
            placeholder={`Any ${submissionType.label.toLowerCase()} details...`}
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            required
          />
          <div className="modal-actions">
            <button type="button" className={`close-button close-button--${themeClass}`} onClick={onClose}>
              Close
            </button>
            <button type="submit" className={`submit-button submit-button--${themeClass}`} disabled={isSubmitting}>
              {isSubmitting ? 'Sending...' : 'Send'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FeedbackModal;
