// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* SpinnerIcon.scss */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.spinner {
  animation: spin 1s linear infinite;
  max-width: 26px;
  max-height: 26px;
}`, "",{"version":3,"sources":["webpack://./src/Components/PromotionCheckoutModal/SpinnerIcon.scss"],"names":[],"mappings":"AAAA,qBAAA;AAEA;EACM;IACE,uBAAA;EAAN;EAEI;IACE,yBAAA;EAAN;AACF;AAGI;EACE,kCAAA;EACA,eAAA;EACA,gBAAA;AADN","sourcesContent":["/* SpinnerIcon.scss */\n\n@keyframes spin {\n      from {\n        transform: rotate(0deg);\n      }\n      to {\n        transform: rotate(360deg);\n      }\n    }\n    \n    .spinner {\n      animation: spin 1s linear infinite;\n      max-width: 26px;\n      max-height: 26px;\n    }\n    "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
