import { Context } from "../../context/index";
import React, { useContext, useEffect, useState } from 'react';
import UserRoute from '../../Routes/Auth';
import Card from '../../Components/Card/Card';
import './PrivacyPolicy.scss';
import Navigation from "../../Components/Navigation/Navigation";
// import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  const [username, setUsername] = useState("");
  const { state: { user } } = useContext(Context);

  useEffect(() => {
    if (user !== null) {
      setUsername(user.username);
    }
  }, [user]);

  const privacyPolicyContent = (
    <div className='privacy-pg'>
      <Navigation heading={"Privacy Policy"} back={true} />
      <Card className="card--padding">

        <h1>Privacy Policy</h1>
        <p><strong>Last updated:</strong> October 20, 2024</p>

        <h2>1. Introduction</h2>
        <p>Welcome to Chequeai Marketplace for Creators and Brands. This Privacy Policy ("Policy") describes how Chequeai ("we", "us", or "our") collects, uses, discloses, and protects your personal information when you use our Marketplace and services (collectively, the "Services"). By accessing or using our Services, you agree to this Policy, so please read it carefully. If you do not agree with this Policy, please do not use our Services.</p>

        <h2>2. Information We Collect</h2>
        <p>We collect information about you when you use our Services, including:</p>
        <h3>2.1 Information You Provide Directly</h3>
        <ul>
          <li><strong>Account Information:</strong> When you create an account, we collect personal information such as your name, email address, username, password, profile picture, and any other information you choose to provide.</li>
          <li><strong>Payment Information:</strong> If you make or receive payments through our Services, we collect financial information such as bank account details, credit card numbers, and transaction details. All payment transactions are processed through secure third-party payment processors (e.g., Stripe). We do not store your credit card information on our servers.</li>
          <li><strong>Content and Communications:</strong> Any content you upload, share, or transmit through our Services, including messages, posts, images, videos, and other materials.</li>
          <li><strong>Customer Support:</strong> Information you provide when you contact us for support, such as your name, email address, and the content of your messages.</li>
        </ul>

        <h3>2.2 Information We Collect Automatically</h3>
        <ul>
          <li><strong>Usage Information:</strong> We collect information about your interactions with our Services, such as the pages or content you view, your searches, and other actions.</li>
          <li><strong>Device Information:</strong> We collect information about the devices you use to access our Services, including IP address, browser type, operating system, device identifiers, and mobile network information.</li>
          <li><strong>Location Information:</strong> We may collect precise or approximate location information as determined through data such as GPS, IP address, and Wi-Fi.</li>
          <li><strong>Cookies and Similar Technologies:</strong> We use cookies, web beacons, and other tracking technologies to collect information about your use of our Services and to provide certain features to you.</li>
        </ul>

        <h3>2.3 Information We Collect from Third Parties</h3>
        <ul>
          <li><strong>Third-Party Services:</strong> If you choose to link or sign up using third-party services (e.g., social media accounts), we may collect information from those services as permitted by your settings with the third party.</li>
          <li><strong>Public Sources:</strong> We may collect information from publicly available sources to supplement your profile.</li>
        </ul>

        <h2>3. How We Use Your Information</h2>
        <p>We use your information for various purposes, including:</p>
        <ul>
          <li><strong>Providing Services:</strong> To operate, maintain, and provide you with the features and functionality of our Services.</li>
          <li><strong>Personalization:</strong> To personalize your experience, such as by providing tailored content and recommendations.</li>
          <li><strong>Communication:</strong> To send you administrative messages, updates, security alerts, and other notifications related to your account and our Services.</li>
          <li><strong>Marketing:</strong> To send you promotional messages and other information that may be of interest to you, in accordance with your preferences.</li>
          <li><strong>Payments:</strong> To process transactions and send you related information, including confirmations and invoices.</li>
          <li><strong>Analytics:</strong> To monitor and analyze trends, usage, and activities in connection with our Services.</li>
          <li><strong>Security:</strong> To detect, investigate, and prevent fraudulent transactions, abuse, and other illegal activities.</li>
          <li><strong>Compliance:</strong> To comply with legal obligations, resolve disputes, and enforce our agreements.</li>
        </ul>

        <h2>4. How We Share Your Information</h2>
        <p>We may share your information with third parties under the following circumstances:</p>
        <h3>4.1 Service Providers</h3>
        <p>We share your information with third-party vendors and service providers who perform services on our behalf, such as payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.</p>

        <h3>4.2 Business Partners</h3>
        <p>We may share your information with business partners with whom we jointly offer products or services.</p>

        <h3>4.3 Other Users</h3>
        <p>When you interact with other users on our Services, certain information may be shared, such as your username, profile information, and any content you post or share.</p>

        <h3>4.4 Legal Obligations</h3>
        <p>We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or government agency).</p>

        <h3>4.5 Protecting Rights and Interests</h3>
        <p>We may disclose your information to protect and defend our rights, property, or safety, or that of our users or others, including enforcing agreements, policies, and terms of use.</p>

        <h3>4.6 Business Transfers</h3>
        <p>In the event of a merger, acquisition, reorganization, bankruptcy, or sale of assets, your information may be sold or transferred as part of that transaction.</p>

        <h2>5. Your Choices and Rights</h2>
        <p>You have certain rights and choices regarding your personal information:</p>
        <h3>5.1 Access and Update</h3>
        <p>You can access and update your account information by logging into your account and editing your profile.</p>

        <h3>5.2 Communication Preferences</h3>
        <p>You can opt out of receiving promotional communications from us by following the unsubscribe instructions provided in those communications or by adjusting your account settings.</p>

        <h3>5.3 Data Subject Rights</h3>
        <p>Depending on your jurisdiction, you may have rights such as:</p>
        <ul>
          <li><strong>Right to Access:</strong> Request access to the personal information we hold about you.</li>
          <li><strong>Right to Rectification:</strong> Request correction of inaccurate or incomplete personal information.</li>
          <li><strong>Right to Erasure:</strong> Request deletion of your personal information under certain circumstances.</li>
          <li><strong>Right to Restrict Processing:</strong> Request that we restrict processing of your personal information under certain conditions.</li>
          <li><strong>Right to Data Portability:</strong> Receive your personal information in a structured, commonly used, and machine-readable format.</li>
          <li><strong>Right to Object:</strong> Object to processing of your personal information under certain conditions.</li>
        </ul>
        <p>To exercise these rights, please contact us at <a href="mailto:support@chequeai.com">support@chequeai.com</a>.</p>

        <h3>5.4 Cookies and Tracking Technologies</h3>
        <p>You can manage your cookie preferences through your browser settings. However, disabling cookies may affect the functionality of our Services.</p>

        <h2>6. Data Security</h2>
        <p>We implement reasonable administrative, technical, and physical security measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>

        <h2>7. Data Retention</h2>
        <p>We retain your personal information for as long as necessary to provide our Services, comply with legal obligations, resolve disputes, and enforce our agreements. The retention period may vary depending on the type of data and applicable laws.</p>

        <h2>8. International Data Transfers</h2>
        <p>Your information may be transferred to and processed in countries other than your country of residence. These countries may have data protection laws that are different from the laws of your country. We take appropriate measures to ensure that your personal information remains protected in accordance with this Policy.</p>

        <h2>9. Children's Privacy</h2>
        <p>Our Services are not intended for children under the age of 13, and we do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete such information promptly. If you believe that we might have information from or about a child under 13, please contact us at <a href="mailto:support@chequeai.com">support@chequeai.com</a>.</p>

        <h2>10. Third-Party Links and Services</h2>
        <p>Our Services may contain links to third-party websites or services that are not owned or controlled by Chequeai. This Policy does not apply to those third-party websites or services. We recommend that you review the privacy policies of any third-party websites or services before providing any personal information.</p>

        <h2>11. Changes to this Policy</h2>
        <p>We may modify this Policy from time to time. If we make material changes, we will notify you by email or by posting a notice on our Services prior to the change becoming effective. Your continued use of our Services after the effective date of the revised Policy constitutes your acceptance of the updated Policy.</p>

        <h2>12. California Privacy Rights</h2>
        <p>If you are a California resident, you have specific privacy rights under the California Consumer Privacy Act ("CCPA"). This section describes your CCPA rights and how to exercise them.</p>
        <h3>12.1 Categories of Personal Information Collected</h3>
        <p>We collect the following categories of personal information: Identifiers, commercial information, internet or other electronic network activity information, geolocation data, professional or employment-related information, and inferences drawn from other personal information.</p>

        <h3>12.2 Rights Under CCPA</h3>
        <ul>
          <li><strong>Right to Know:</strong> You have the right to request that we disclose certain information about our collection and use of your personal information over the past 12 months.</li>
          <li><strong>Right to Delete:</strong> You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions.</li>
          <li><strong>Right to Opt-Out:</strong> You have the right to opt-out of the sale of your personal information. We do not sell personal information.</li>
          <li><strong>Right to Non-Discrimination:</strong> We will not discriminate against you for exercising any of your CCPA rights.</li>
        </ul>
        <p>To exercise your CCPA rights, please contact us at <a href="mailto:support@chequeai.com">support@chequeai.com</a>.</p>

        <h2>13. GDPR Compliance</h2>
        <p>If you are located in the European Economic Area ("EEA"), United Kingdom, or Switzerland, you have certain rights under the General Data Protection Regulation ("GDPR"):</p>
        <ul>
          <li><strong>Legal Basis for Processing:</strong> We process your personal information based on your consent, to perform a contract with you, to comply with legal obligations, or for our legitimate interests.</li>
          <li><strong>Data Protection Rights:</strong> You have the right to access, correct, update, or request deletion of your personal information. You also have the right to object to processing, restrict processing, and request data portability.</li>
          <li><strong>Right to Withdraw Consent:</strong> If we have collected and processed your personal information with your consent, you have the right to withdraw your consent at any time.</li>
          <li><strong>Right to Complain:</strong> You have the right to complain to a data protection authority about our collection and use of your personal information.</li>
        </ul>
        <p>To exercise your GDPR rights, please contact us at <a href="mailto:support@chequeai.com">support@chequeai.com</a>.</p>

        <h2>14. Data Protection Officer</h2>
        <p>We have appointed a Data Protection Officer ("DPO") who is responsible for overseeing questions in relation to this Policy. If you have any questions about this Policy or our privacy practices, please contact our DPO at <a href="mailto:support@chequeai.com">support@chequeai.com</a>.</p>

        <h2>15. Contact Us</h2>
        <p>If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at:</p>
        <p>Email: <a href="mailto:support@chequeai.com">support@chequeai.com</a></p>
        <p>Address: Chequeai, 1234 Main Street, Suite 100, Wilmington, DE 19801, USA</p>

        <h2>16. Consent to Processing and Transfer of Information</h2>
        <p>By accessing or using our Services or otherwise providing information to us, you consent to the processing and transfer of information in and to the United States and other countries.</p>

        <h2>17. Do Not Track Signals</h2>
        <p>Our Services do not respond to Do Not Track ("DNT") signals. Third parties may collect personal information about your online activities over time and across different websites when you use our Services.</p>

        <h2>18. Social Media Features</h2>
        <p>Our Services may include social media features, such as the Facebook Like button and widgets, or interactive mini-programs that run on our Services. These features may collect your IP address, which page you are visiting, and may set a cookie to enable the feature to function properly. Your interactions with these features are governed by the privacy policy of the company providing it.</p>

        <h2>19. Security Breach Notification</h2>
        <p>In the event of a security breach that affects your personal information, we will notify you and the appropriate authorities as required by applicable law.</p>

        <h2>20. Language</h2>
        <p>This Privacy Policy is written in English. If this Policy is translated into another language, the English version will prevail in the event of any conflict between the two versions.</p>

        <h2>21. Acknowledgment</h2>
        <p>By using our Services, you acknowledge that you have read and understood this Privacy Policy and agree to its terms.</p>

      </Card>
    </div>
  );

  if (!user) {
    return (
      <div>
        {privacyPolicyContent}
      </div>
    );
  }
  return (
    <UserRoute username={username}>
      {privacyPolicyContent}
    </UserRoute>
  );
};

export default PrivacyPolicy;
