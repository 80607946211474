import React, { useContext }  from 'react'
import './TextArea.scss';
import ThemeContext from '../../context/ThemeContext';


const TextArea = ({ label, name, value, onChange }) => {
  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

  return (
    <div className="text-area">
      <label className={`text-area__label text-area__label--${themeClass}`} htmlFor={name}>
        {label}
      </label>
      <textarea
        className={`text-area__input text-area__input--${themeClass}`}
        name={name}
        id={name}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default TextArea;
