// src/components/EarningsPage/Earnings.js

import React, { useContext, useEffect, useState, useCallback } from 'react';
import Card from '../../Components/Card/Card';
import CardHeader from '../../Components/CardHeader/CardHeader';
import UserRoute from '../../Routes/Auth';
import { Context } from "../../context/index";
import "./EarningsPage.scss";
import Navigation from '../../Components/Navigation/Navigation';
import InsightsCard from '../../Components/InsightsCard/InsightsCard';
import TimelineOverview from '../../Components/TimelineOverview/TimelineOverview';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import EarningsActivity from '../../Components/EarningsActivity/EarningsActivity';

export default function Earnings() {
  const [username, setUsername] = useState("");
  const { state: { user }, } = useContext(Context);
  const [tf, setTf] = useState("today");
  const [metrics, setMetrics] = useState({});
  const [priorMetrics, setPriorMetrics] = useState({});
  const [transactions, setTransactions] = useState([]);

  const navigate = useNavigate();

  const fetchEarnings = useCallback(async (tf) => {
    try {
      setTf(tf);
      navigate(`?tf=${tf}`);
      // console.log(tf);

      const response = await axios.get(`${process.env.REACT_APP_API}/earnings/`, {
        params: { tf }
      });
      // console.log(response.data);
      setMetrics(response.data.metrics.metrics);
      setPriorMetrics(response.data.metrics.priorMetrics);

    } catch (error) {
      console.error(error);
    }
  }, [navigate]);

  const fetchTransactions = useCallback(async (tf) => { // Wrapped with useCallback for memoization
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/creator/transactions/`, {
        params: { tf }
      });
      // console.log(response.data);
      const fetchedTransactions = response.data
      //   .reverse();
      setTransactions(fetchedTransactions);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleAccept = async (campaignId, brand) => {
    try {
      console.log(campaignId, brand);
      //const response =
      await axios.put(`${process.env.REACT_APP_API}/campaigns/requests/`, {
        campaignId,
        brand
      });
      // console.log(response);
      fetchTransactions(tf); // Re-fetch transactions after accepting
    } catch (error) {
      console.log(error);
    }
  };

  const handleDecline = async (campaignId, brand) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API}/campaigns/requests/`, {
        data: { campaignId, brand }
      });
      // console.log(response);
      fetchTransactions(tf); // Re-fetch transactions after declining
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user !== null) {
      setUsername(user.username);
    }

    if (user?.stripeAccountId !== "" && Object.keys(user?.stripeSeller).length !== 0 && user?.stripeSeller?.charges_enabled === true) {

      fetchEarnings(tf);
      fetchTransactions(tf); // Fetch transactions based on tf
    }


  }, [user, tf, fetchEarnings, fetchTransactions]);

  const type = user.userType;

  const tfLabels = {
    "today": "Today",
    "1W": "Last 1 Week",
    "1M": "Last 4 Weeks",
    "1Y": "Last 1 Year",
    "MTD": "Month to Date",
    "QTD": "Quarter to Date",
    "YTD": "Year to Date",
    "ALL": "All Time"
  };

  return (
    <UserRoute username={username}>
      <div className='earnings-pg'>
        <Navigation heading={user.userType === "creator" ? "Earnings" : "Campaign - Invoices"} back={true} />
        <Card className="card--pb">
          <div className="earnings-pg__content">
            <CardHeader title={tfLabels[tf] || tf} />
            <TimelineOverview tf={tf} setTf={fetchEarnings} />
            {(type === "creator"  || type === "brand") && (
              <div className="earnings-pg__body">
                <InsightsCard title={"Gross Volume"} currentMetric={metrics.grossVolume} priorMetric={priorMetrics.grossVolume} timeframe={tf} dollar={true} currency={user?.currency} />
                <InsightsCard title={"Net volume from sales"} currentMetric={metrics?.netVolume?.netAmount} priorMetric={priorMetrics?.netVolume?.netAmount} timeframe={tf} dollar={true} currency={user?.currency} />
                <InsightsCard title={"Campaigns"} currentMetric={metrics.campaignCount} priorMetric={priorMetrics.campaignCount} timeframe={tf} count={true} />
                <InsightsCard title={"Successful Payments"} currentMetric={metrics.successfulChargesCount} priorMetric={priorMetrics.successfulChargesCount} timeframe={tf} count={true} />
                <InsightsCard title={"Cancellation Volume"} currentMetric={metrics.cancellationVolume} priorMetric={priorMetrics.cancellationVolume} timeframe={tf} dollar={true} currency={user.currency} />
                <InsightsCard title={"High Risk Payments"} currentMetric={metrics.highRiskPaymentsCount} priorMetric={priorMetrics.highRiskPaymentsCount} timeframe={tf} count={true} />
                <InsightsCard title={"Dispute Count"} currentMetric={metrics.disputeCount} priorMetric={priorMetrics.disputeCount} timeframe={tf} count={true} />
                <InsightsCard title={"Dispute Activity"} currentMetric={metrics.disputeActivityCount} priorMetric={priorMetrics.disputeActivityCount} timeframe={tf} percent={true} />
              </div>
            )}
            <div className="earnings-pg__activity">
              {transactions.length > 0 &&
                <EarningsActivity
                  transactions={transactions}
                  username={username}
                  handleAccept={handleAccept}
                  handleDecline={handleDecline}
                />
              }
            </div>

          </div>
        </Card>
      </div>
    </UserRoute>
  );
}
