import React, { useState } from 'react';
import axios from 'axios';
import { useLocation, Link } from 'react-router-dom';
import './ResetPasswordPage.scss';

const ResetPassword = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const email = query.get('email');
  const code = query.get('code');
  
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${process.env.REACT_APP_API}/reset-password`, { email, resetCode: code, newPassword });
      if (res.data.ok) {
        setMessage('Password reset successful');
        setError('');
      } else {
        setMessage('');
        setError('Error resetting password');
      }
    } catch (err) {
      setMessage('');
      setError('Error resetting password');
    }
  };

  return (
    <div className='reset-password-form__container'>
      <Link to={"/login"} className='reset-password-form__header'>Chequeai</Link>
      <h3 className='reset-password-form__sub-text'>Reset Password</h3>
      <form className='reset-password-form' onSubmit={handleSubmit}>
        <div className='reset-password-form__password-container'>
          <input
            className='reset-password-form__password-input'
            name='newPassword'
            placeholder='New Password'
            type='password'
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div className='reset-password-form__btn-container'>
          <button className='reset-password-form__btn-submit' type='submit'>Reset Password</button>
        </div>
        {message && <div className='reset-password-form__message'>{message}</div>}
        {error && <div className='reset-password-form__error'>{error}</div>}
      </form>
    </div>
  );
};

export default ResetPassword;
