import { Context } from "../../context/index";
import React, { useContext, useEffect, useState } from 'react';
import UserRoute from '../../Routes/Auth';
import Card from '../../Components/Card/Card';
import './TermsOfService.scss';
import Navigation from "../../Components/Navigation/Navigation";
import { Link } from 'react-router-dom';

const TermsOfService = () => {
  const [username, setUsername] = useState("");
  const { state: { user } } = useContext(Context);

  useEffect(() => {
    if (user !== null) {
      setUsername(user.username);
    }
  }, [user]);

  function TermsPage() {
    return (
      <div className='terms-pg'>
        <Navigation heading={"Terms of Service"} back={true} />
        <Card className="card--padding">
          <h1>Terms of Service</h1>
          <p><strong>Last updated:</strong> October 20, 2024</p>

          <h2>1. Introduction</h2>
          <p>Welcome to Chequeai! These Terms of Service (the "Terms") govern your use of our app and services (the "Services"). By accessing or using our Services, you agree to be bound by these Terms and our <Link to="/privacy-policy">Privacy Policy</Link>. If you do not agree to these Terms, please do not use our Services.</p>

          <h2>2. Definitions</h2>
          <p><strong>"User"</strong> refers to any individual or entity that accesses or uses the Services.</p>
          <p><strong>"Content"</strong> refers to any text, images, videos, or other materials uploaded or shared by Users through the Services.</p>
          <p><strong>"Brand"</strong> refers to any company or individual using Chequeai's Services to manage brand deals and payments.</p>
          <p><strong>"Creator"</strong> refers to any individual or entity creating and sharing content through the Services.</p>

          <h2>3. Eligibility and Age Requirement</h2>
          <p>You must be at least 18 years old to use our Services. By using our Services, you represent and warrant that you are at least 18 years old. If you are using the Services on behalf of an entity, you represent and warrant that you have the authority to bind that entity to these Terms.</p>

          <h2>4. Account Registration and Security</h2>
          <p>To access certain features of our Services, you must register for an account. When registering, you agree to provide accurate, current, and complete information and to update this information as necessary. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account or any other breach of security. Chequeai will not be liable for any loss or damage arising from your failure to comply with this obligation.</p>

          <h2>5. User Conduct and Obligations</h2>
          <p>By using our Services, you agree to abide by the following conduct standards. You shall not:</p>
          <ul>
            <li>Violate any applicable local, state, national, or international laws or regulations.</li>
            <li>Engage in any fraudulent, deceptive, or misleading practices.</li>
            <li>Post, share, or transmit any content that is unlawful, harmful, threatening, defamatory, obscene, harassing, or otherwise objectionable.</li>
            <li>Infringe on the intellectual property rights, privacy rights, or other legal rights of others.</li>
            <li>Use the Services to distribute spam, unsolicited communications, or other forms of mass messaging.</li>
            <li>Interfere with or disrupt the operation of the Services or the servers or networks connected to the Services.</li>
            <li>Use the Services to harvest, collect, or gather user data without their consent.</li>
            <li>Impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity.</li>
            <li>Upload or transmit viruses, worms, or other malicious code.</li>
            <li>Attempt to gain unauthorized access to any portion of the Services or any other accounts, computer systems, or networks connected to the Services.</li>
            <li>Engage in any activity that could disable, overburden, or impair the proper working of the Services.</li>
          </ul>

          <h2>6. Payments and Financial Transactions</h2>
          <p>All payments and transactions made through our Services are processed using Stripe, a secure payment gateway. You agree to provide accurate and complete payment information. Chequeai is not responsible for any loss or damage resulting from inaccurate or incomplete payment information. All fees and charges are non-refundable except as expressly stated in these Terms or as required by applicable law. You acknowledge and agree that any payment disputes must be resolved directly with the other party involved, and Chequeai will not be liable for any such disputes.</p>
          <p>You are solely responsible for any taxes applicable to payments you make or receive through our Services. Chequeai is not responsible for calculating, collecting, reporting, or remitting any taxes to any tax authority.</p>

          <h2>7. Intellectual Property Rights</h2>
          <p>All content and materials available on our Services, including text, graphics, logos, icons, images, audio clips, video clips, data compilations, and software, are the property of Chequeai or its licensors and are protected by copyright, trademark, and other intellectual property laws. Unauthorized use of any materials may violate copyright, trademark, and other laws. You may not use, reproduce, modify, adapt, translate, distribute, transmit, publish, or create derivative works of any content or materials from our Services without our prior written permission.</p>

          <h2>8. User-Generated Content</h2>
          <p>As a User of our Services, you retain ownership of any content you create and share. By sharing content on our platform, you grant Chequeai a non-exclusive, royalty-free, worldwide, perpetual, irrevocable license to use, display, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display your content in connection with operating and promoting our Services. You represent and warrant that you have the necessary rights to grant this license and that your content does not violate the rights of any third party. Additionally, for the duration of a campaign, you grant the participating Brand the right to use your likeness and content created for the campaign. All materials produced for the campaign will be co-owned by you and the Brand unless otherwise agreed upon in writing.</p>
          <p>You acknowledge that Chequeai has no obligation to monitor or enforce your intellectual property rights in your content but has the right to protect and enforce its rights to use your content under this agreement.</p>

          <h2>9. Brand Rights and Obligations</h2>
          <p>Brands using our Services agree to respect the rights of Creators. Brands are granted a limited, non-exclusive license to use the Creator's likeness and materials for the duration of the campaign, solely for the purposes agreed upon. Brands must comply with all applicable laws and regulations and must not engage in any fraudulent, deceptive, or misleading practices. Brands are responsible for vetting Creators to ensure they do not engage in spam, fraud, or any other deceptive practices. Chequeai is not liable for any actions or omissions of the Creators.</p>
          <p>Brands agree to indemnify and hold harmless Chequeai from any claims arising out of their use of Creator content, including but not limited to claims of intellectual property infringement or violation of privacy rights.</p>

          <h2>10. Creator Responsibilities</h2>
          <p>Creators using Chequeai's Services are responsible for maintaining their brand reputation and online presence. Creators must ensure that their content is genuine, free from fraudulent activities, and does not infringe upon the rights of others. Additionally, Creators are expected to handle all deal processes professionally, ensuring that all agreed-upon work is completed to the satisfaction of the Brand. Chequeai is not liable for any incomplete, delayed, or substandard work performed by Creators.</p>
          <p>Creators must disclose any sponsored content in compliance with applicable laws and regulations, including the Federal Trade Commission's guidelines.</p>

          <h2>11. Brand Responsibilities</h2>
          <p>Brands using Chequeai's Services are responsible for vetting Creators to ensure they do not engage in spam, fraud, or any other deceptive practices. Brands must conduct due diligence to verify the authenticity and reliability of Creators before entering into any agreements or partnerships. Failure to properly vet Creators may result in reputational damage and financial loss, for which Chequeai will not be held liable.</p>
          <p>Brands are also responsible for complying with all advertising and marketing laws, including the Federal Trade Commission's guidelines on endorsements and testimonials.</p>

          <h2>12. Privacy and Data Protection</h2>
          <p>We are committed to protecting your privacy and personal information. Our <Link to="/privacy-policy">Privacy Policy</Link> explains how we collect, use, and share your information. By using our Services, you agree to our privacy practices as described in our Privacy Policy. Chequeai employs reasonable security measures to protect your information but does not guarantee absolute security.</p>
          <p>You acknowledge that internet transmissions are never completely private or secure and that any information you send or receive may be intercepted by others, even if encrypted.</p>

          <h2>13. Data Retention</h2>
          <p>We will retain your information for as long as your account is active or as needed to provide you Services. We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, enforce our agreements, and protect Chequeai's legal rights.</p>

          <h2>14. Cookies and Tracking Technologies</h2>
          <p>We use cookies and other tracking technologies to enhance your experience on our platform. Cookies help us understand how you interact with our Services, personalize your experience, and improve our offerings. By using our Services, you consent to the use of cookies and tracking technologies as described in our <Link to="/privacy-policy">Privacy Policy</Link>. You can manage your cookie preferences through your browser settings.</p>

          <h2>15. Advertisements</h2>
          <p>Our Services may display advertisements, some of which may be tailored to your interests. By using our Services, you agree that we may display advertisements in connection with your use of the Services. You agree not to block such advertisements from being displayed.</p>

          <h2>16. Third-Party Services</h2>
          <p>Our Services may contain links to third-party websites or services that are not owned or controlled by Chequeai. We are not responsible for the content, privacy policies, or practices of any third-party websites or services. You access any third-party websites or services at your own risk, and you acknowledge and agree that Chequeai shall not be liable for any damages or losses arising from your use of them.</p>
          <p>You should review the applicable terms and policies of any third-party websites or services before using them.</p>

          <h2>17. Feedback</h2>
          <p>Any feedback, comments, or suggestions you may provide regarding Chequeai or the Services is entirely voluntary and we will be free to use such feedback, comments, or suggestions as we see fit and without any obligation to you. You hereby grant Chequeai a perpetual, irrevocable, transferable, sublicensable, royalty-free license to use your feedback for any purpose.</p>

          <h2>18. Content Monitoring</h2>
          <p>We reserve the right, but are not obligated, to monitor and review user content and activities on our Services to ensure compliance with these Terms. We may remove or disable access to any content that violates these Terms or is otherwise objectionable at our sole discretion and without prior notice.</p>
          <p>We may also terminate your access to the Services if you repeatedly infringe the intellectual property rights of others.</p>

          <h2>19. Prohibited Activities</h2>
          <p>You agree not to engage in any of the following prohibited activities:</p>
          <ul>
            <li>Circumventing or attempting to circumvent any security measures of the Services.</li>
            <li>Reverse engineering, decompiling, or disassembling any portion of the Services.</li>
            <li>Using any automated means to access the Services for any purpose without our express written permission.</li>
            <li>Engaging in "framing," "mirroring," or otherwise simulating the appearance or function of the Services.</li>
            <li>Harassing, abusing, or harming another person or entity.</li>
          </ul>

          <h2>20. Geographic Restrictions</h2>
          <p>Chequeai makes no representations that the Services are appropriate or available for use in all locations. Accessing the Services from territories where the content is illegal is prohibited. If you choose to access the Services from such locations, you do so on your own initiative and are responsible for compliance with local laws.</p>

          <h2>21. Reporting Violations</h2>
          <p>If you become aware of any misuse of the Services or any violation of these Terms, please report it immediately to <a href="mailto:support@chequeai.com">support@chequeai.com</a>.</p>

          <h2>22. Third-Party Rights</h2>
          <p>These Terms are not intended to grant rights to anyone except you and Chequeai, and in no event shall these Terms create any third-party beneficiary rights.</p>

          <h2>23. Assignment</h2>
          <p>You may not assign or transfer any of your rights or obligations under these Terms without our prior written consent. We may assign or transfer our rights and obligations under these Terms without restriction and without notice to you.</p>

          <h2>24. Force Majeure</h2>
          <p>We will not be liable for any delay or failure to perform any obligation under these Terms if the delay or failure is due to events or circumstances beyond our reasonable control, including but not limited to acts of God, natural disasters, war, terrorism, civil unrest, pandemics, governmental actions, or failures of the internet or telecommunications systems.</p>

          <h2>25. Notices</h2>
          <p>All notices, requests, or other communications required or permitted to be given under these Terms will be in writing and will be deemed to have been duly given when delivered by hand, email, or mailed by certified or registered mail, return receipt requested, with postage prepaid. Notices to Chequeai should be sent to <a href="mailto:support@chequeai.com">support@chequeai.com</a>.</p>

          <h2>26. Dispute Resolution and Arbitration</h2>
          <p>Any disputes arising out of or related to these Terms or our Services will be resolved through binding arbitration in accordance with the rules of the American Arbitration Association. You agree to waive your right to a jury trial and to participate in a class action lawsuit or class-wide arbitration. The arbitration will be conducted in the State of Delaware, and the arbitrator's decision will be binding and may be entered as a judgment in any court of competent jurisdiction.</p>
          <p>This arbitration agreement shall be governed by the Federal Arbitration Act and federal arbitration law.</p>

          <h2>27. Governing Law and Jurisdiction</h2>
          <p>These Terms and any disputes arising out of or related to these Terms or our Services will be governed by and construed in accordance with the laws of the State of Delaware, without regard to its conflict of law principles. You agree to submit to the exclusive personal jurisdiction of the state and federal courts located in Delaware for the purpose of litigating all such claims or disputes.</p>

          <h2>28. Disclaimer of Warranties</h2>
          <p>YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. THE SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. CHEQUEAI EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. CHEQUEAI MAKES NO WARRANTY THAT THE SERVICES WILL MEET YOUR REQUIREMENTS, BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE.</p>
          <p>CHEQUEAI DOES NOT WARRANT THE ACCURACY OR COMPLETENESS OF ANY INFORMATION, TEXT, GRAPHICS, LINKS, OR OTHER ITEMS CONTAINED WITHIN THE SERVICES.</p>

          <h2>29. Limitation of Liability</h2>
          <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL CHEQUEAI, ITS AFFILIATES, DIRECTORS, EMPLOYEES, AGENTS, OR LICENSORS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES, ARISING OUT OF OR RELATING TO YOUR ACCESS TO OR USE OF, OR INABILITY TO ACCESS OR USE, THE SERVICES. IN NO EVENT SHALL CHEQUEAI'S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION EXCEED THE AMOUNT YOU HAVE PAID TO CHEQUEAI IN THE LAST SIX (6) MONTHS, OR ONE HUNDRED US DOLLARS ($100), WHICHEVER IS GREATER.</p>
          <p>THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN CHEQUEAI AND YOU.</p>

          <h2>30. Indemnification</h2>
          <p>You agree to indemnify, defend, and hold harmless Chequeai and its affiliates, officers, directors, employees, agents, and licensors from and against any and all claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys' fees) that such parties may incur as a result of or arising from your use of the Services, your violation of these Terms, your violation of any rights of another, or your violation of any applicable laws, rules, or regulations. This indemnification obligation will survive the termination of your account and these Terms.</p>
          <p>Chequeai reserves the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, and you agree to cooperate as requested by Chequeai in the defense of any such claim.</p>

          <h2>31. Termination and Suspension of Services</h2>
          <p>We reserve the right to terminate or suspend your account and access to our Services at our sole discretion, without notice, for conduct that we believe violates these Terms or is harmful to other Users, us, or third parties. Upon termination, your right to use the Services will immediately cease. All provisions of these Terms that by their nature should survive termination shall survive, including ownership provisions, warranty disclaimers, indemnity, and limitations of liability. Chequeai shall not be liable to you or any third party for any termination of your access to the Services.</p>
          <p>If your account is terminated, you may not re-register or access the Services without our prior written consent.</p>

          <h2>32. User Rating and Platform Suspension</h2>
          <p>All Brands and Creators (Users) on Chequeai are subject to a user rating system. If a User's rating falls below 3.1, Chequeai reserves the right to suspend or ban the User from the platform. Users with a rating below 3.1 may have their access restricted until their rating improves to meet the platform's standards. Chequeai will notify Users prior to suspension or banning, providing details about the rating and steps to address any issues.</p>
          <p>Users have the opportunity to improve their ratings by adhering to the platform's guidelines and addressing any feedback provided by other Users or Chequeai. Repeated failure to maintain a satisfactory rating may result in permanent removal from the platform.</p>

          <h2>33. Limitation of Liability for Incomplete Work</h2>
          <p>Chequeai acts as an intermediary platform facilitating connections between Brands and Creators. While we strive to ensure the integrity and reliability of our Services, Chequeai is not responsible for the performance, conduct, or contractual obligations of Brands or Creators. In the event of incomplete work, breach of contract, or any disputes arising from partnerships facilitated through our platform, Chequeai shall not be held liable. Users are encouraged to establish clear terms and agreements with each other to mitigate potential risks.</p>
          <p>Any disputes between Brands and Creators must be resolved between the parties involved.</p>

          <h2>34. Waiver</h2>
          <p>The failure of Chequeai to enforce any right or provision of these Terms will not constitute a waiver of future enforcement of that right or provision. The waiver of any such right or provision will be effective only if in writing and signed by a duly authorized representative of Chequeai.</p>

          <h2>35. Survival</h2>
          <p>All provisions of these Terms which by their nature should survive termination shall survive termination, including without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</p>

          <h2>36. Entire Agreement</h2>
          <p>These Terms constitute the entire agreement between you and Chequeai regarding your use of our Services and supersede all prior agreements and understandings, whether written or oral, regarding the subject matter. Any waiver of any provision of these Terms will be effective only if in writing and signed by a duly authorized representative of Chequeai.</p>

          <h2>37. Language</h2>
          <p>These Terms of Service and all related documents are written in English. Any translations provided are for convenience only. In the event of any conflict between the English version and a translated version, the English version will prevail.</p>

          <h2>38. No Agency Relationship</h2>
          <p>No joint venture, partnership, employment, or agency relationship exists between you and Chequeai as a result of these Terms or your use of the Services.</p>

          <h2>39. Electronic Communications</h2>
          <p>By using the Services, you consent to receiving electronic communications from Chequeai. These communications may include notices about your account and information concerning or related to the Services. You agree that any notices, agreements, disclosures, or other communications that we send to you electronically will satisfy any legal communication requirements, including that such communications be in writing.</p>

          <h2>40. Consent to Electronic Transactions and Disclosures</h2>
          <p>Because Chequeai operates only on the internet, it is necessary for you to consent to transact business with us online and electronically. You consent to receive electronically all documents, communications, notices, contracts, and agreements arising from or relating to your use of the Services.</p>

          <h2>41. Headings</h2>
          <p>The headings used in these Terms are included for convenience only and will not limit or otherwise affect these Terms.</p>

          <h2>42. User Dispute Resolution Procedures</h2>
          <p>In the event of a dispute between Users, Chequeai encourages the parties to resolve the issue directly through communication. If a resolution cannot be reached, the dispute may be escalated to Chequeai for mediation. Chequeai will facilitate a fair and impartial mediation process but does not guarantee a resolution.</p>
          <p>For disputes involving Chequeai, refer to the <strong>Dispute Resolution and Arbitration</strong> section above.</p>
          {/* New Sections Start Here */}

          <h2>43. API and Integrations</h2>
          <p>Chequeai may offer Application Programming Interfaces (APIs) and integration capabilities to allow Users to connect third-party applications or services. By using our APIs and integrations, you agree to comply with our API usage policies, which include but are not limited to:</p>
          <ul>
            <li>Using the APIs only for lawful purposes and in accordance with these Terms.</li>
            <li>Not exceeding the usage limits or quotas set by Chequeai.</li>
            <li>Ensuring that your use of the APIs does not disrupt or interfere with the Services or other Users' use of the Services.</li>
            <li>Not reverse engineering, decompiling, or disassembling the APIs or any part thereof.</li>
            <li>Not using the APIs to create products or services that compete with Chequeai.</li>
          </ul>
          <p>Chequeai reserves the right to revoke access to its APIs and integrations at any time without notice for any violation of these policies.</p>

          <h2>44. Data Portability</h2>
          <p>You have the right to request a copy of your personal data that Chequeai holds in a structured, commonly used, and machine-readable format. Upon request, we will provide you with a copy of your data within a reasonable timeframe. To request your data, please contact us at <a href="mailto:support@chequeai.com">support@chequeai.com</a>.</p>

          <h2>45. Children’s Privacy</h2>
          <p>Our Services are not intended for individuals under the age of 18. We do not knowingly collect personal information from children under 18. If you become aware that a child has provided us with personal information, please contact us immediately at <a href="mailto:support@chequeai.com">support@chequeai.com</a>, and we will take steps to remove such information from our servers.</p>

          <h2>46. Marketing Communications</h2>
          <p>By using our Services, you consent to receive marketing communications from Chequeai. You can opt-out of receiving these communications at any time by following the unsubscribe instructions included in the emails or by contacting us at <a href="mailto:support@chequeai.com">support@chequeai.com</a>.</p>

          <h2>47. User Account Suspension Procedures</h2>
          <p>If a User's rating falls below 3.1, Chequeai will initiate the following suspension procedures:</p>
          <ol>
            <li><strong>Notification:</strong> The User will receive an email outlining their current rating and the reasons for the suspension.</li>
            <li><strong>Grace Period:</strong> The User will be granted a 14-day grace period to address the issues and improve their rating.</li>
            <li><strong>Review:</strong> After the grace period, Chequeai will evaluate the User's actions to determine whether to reinstate the account or proceed with a ban.</li>
            <li><strong>Final Decision:</strong> If the User fails to improve their rating, their account will be permanently banned from the platform.</li>
          </ol>
          <p>While Chequeai consistently follows the above procedures, we reserve the right to remove any User from our platform at our sole discretion, regardless of their rating or compliance with these guidelines and terms.</p>


          <h2>48. Security Incident Reporting</h2>
          <p>If you become aware of any security incident, such as unauthorized access to your account or any other breach, please report it immediately to <a href="mailto:support@chequeai.com">support@chequeai.com</a>. Chequeai will investigate all reported incidents and take appropriate measures to protect your account and information.</p>

          <h2>49. Accessibility Commitment</h2>
          <p>Chequeai is committed to ensuring that our Services are accessible to all Users, including those with disabilities. We strive to comply with the Web Content Accessibility Guidelines (WCAG) and continuously improve the accessibility of our platform. If you encounter any accessibility issues, please contact us at <a href="mailto:support@chequeai.com">support@chequeai.com</a> so we can address them promptly.</p>

          <h2>50. Automated Decision Making</h2>
          <p>Chequeai may use automated processes, including algorithms and machine learning models, to make decisions that affect your use of the Services. These automated decisions may include rating assignments, content moderation, and service recommendations. While we strive for accuracy and fairness, you have the right to request a review of any automated decision by contacting us at <a href="mailto:support@chequeai.com">support@chequeai.com</a>.</p>


          <h2>51. Acknowledgment</h2>
          <p>BY USING THE SERVICES, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE, UNDERSTAND THEM, AND AGREE TO BE BOUND BY THEIR TERMS AND CONDITIONS.</p>


          <h2>52. Data Protection Officer Contact</h2>
          <p>If you have any questions or concerns about our data protection practices, please contact our Data Protection Officer at:</p>
          <p><strong>Data Protection Officer</strong><br />
            Email: <a href="mailto:dpo@chequeai.com">dpo@chequeai.com</a></p>

        </Card>
      </div>
    )
  }

  if (user === null) {
    return (
      TermsPage()
    );
  }

  return (
    <UserRoute username={username}>
      {TermsPage()}
    </UserRoute>
  );
};

export default TermsOfService;
