import React from 'react';

const AddIcon = ({ themeMode }) => {
  const color = themeMode === 'light' ? 'black' : 'white';
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 6V18M6 12H18" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default AddIcon;
