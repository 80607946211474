import React from 'react';
import './PayoutComponent.scss'; // Import SCSS for styling

const PayoutComponent = ({ payout, themeClass }) => {
    const formattedAmount = `$${(payout.amount / 100).toFixed(2)} ${payout.currency.toUpperCase()}`; // Convert amount to dollars with currency


    const monthFormart = window.innerWidth <= 768 ? 'short' : 'long';

    const formatDate = (timestamp) => {
        return new Date(timestamp * 1000).toLocaleDateString('en-US', { month: monthFormart, day: 'numeric', year: 'numeric' });
    };

    const arrivalDate = formatDate(payout.arrival_date);
    const createdDate = formatDate(payout.created);

    return (
        <div className={`payout-component payout-component--${themeClass}`}>
            <div className="payout-header">

                <div style={{ display: 'flex', gap: '0.5rem' }}>
                    <span className={`status ${payout.status}`}>{payout.status.toUpperCase()}</span>
                    <h2 className="brand">{"Chequeai"}</h2>
                </div>

                <div style={{ display: 'flex', gap: '0.5rem' }}>
                    <h2 className="amount">{formattedAmount}</h2>
                </div>

            </div>
            <div className={`details details--${themeClass}`}>
                <div className="left-details">

                    <div className="detail-item">
                        {/* Conditional rendering based on screen size */}
                        <span className={`label label--${themeClass} transfer-label`}>Transfer Speed</span>
                        <span className={`label label--${themeClass} transfer-mobile-label`}>Transfer</span>
                        <span className={`value value--${themeClass}`}>{payout.method.charAt(0).toUpperCase() + payout.method.slice(1)}</span>
                    </div>
                    <div className="detail-item">
                        <span className={`label label--${themeClass}`}>Method</span>
                        <span className={`value value--${themeClass}`}>{payout.description.replace(/stripe payout/i, 'Stripe Payout')}</span>
                    </div>

                </div>
                <div className="right-details">

                    <div className="detail-item">
                        <span className={`label label--${themeClass}`}>Initiated</span>
                        <span className={`value value--${themeClass}`}>{createdDate}</span>
                    </div>
                    <div className="detail-item">
                        <span className={`label label--${themeClass}`}>Payout</span>
                        <span className={`value value--${themeClass}`}>{arrivalDate}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PayoutComponent;
