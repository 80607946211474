import React, { useState } from "react";
import PropTypes from "prop-types";
import "./step.scss";
import ClipBoardIcon from "../../../Icons/ClipBoardIcon";
import Toggle from "../../Toggle/Toggle";

function PreferencesStep({
  data,
  onChange,
  onSubmit,
  onBack,
  themeMode,
  username,
}) {
  const [isPrivate, setIsPrivate] = useState(data.isPrivate || false);
  const profileLink = `https://www.chequeai.com/${username}`;
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

  const handleSubmit = () => {
    onChange({ isPrivate });
    onSubmit();
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(profileLink).then(() => {});
  };

  return (
    <div className={`step step--${themeClass}`}>
      <h2 className={`step__title ${themeClass}`}>Complete</h2>
      <form>
        <p className={`onBoarding__final-step__copy-text onBoarding__final-step__copy-text--${themeClass}`}>
          Add this link to your Instagram & TikTok bio, so{" "}
          <strong>brands</strong> and <strong>others</strong> can{" "}
          <span className="highlight">
            connect with you for business inquiries and deals
          </span>
          .
        </p>

        <div className="form-group copy-prompt">
          <div className={`form-group profile-link profile-link--${themeClass} `}>
            <p>
              <a href={profileLink} target="_blank" rel="noopener noreferrer">
                {profileLink}
              </a>
            </p>
            <div
              type="button"
              onClick={copyToClipboard}
              className="copy-button"
            >
              <ClipBoardIcon themeMode={themeMode} />
            </div>
          </div>
        </div>

        {/* Private Account (Checkbox) */}
        <div className={`onBoarding__final-step__private-account onBoarding__final-step__private-account--${themeClass}`}>
          <label className="checkbox-label">
            <span>Private Account</span> 
            <Toggle value={isPrivate} onChange={setIsPrivate} modifier="mb" />
          </label>
        </div>

        {/* Navigation Buttons */}
        <div className="navigation-buttons">
          <button type="button" onClick={onBack} className={`back-button back-button--${themeClass}`}>
            Back
          </button>
          <button type="button" onClick={handleSubmit} className={`next-button next-button--${themeClass}`}>
            Finish
          </button>
        </div>
      </form>
    </div>
  );
}

PreferencesStep.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
};

export default PreferencesStep;
