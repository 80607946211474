// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-billing-pg {
  padding: 20px;
}
.settings-billing-pg .settings-billing-pg__form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.settings-billing-pg .settings-billing-pg__form h2 {
  font-size: 1.25rem;
  margin-top: 20px;
  color: #333;
}
.settings-billing-pg .input-field label {
  font-size: 0.9rem;
  color: #555;
}
.settings-billing-pg .input-field input {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 1rem;
}
.settings-billing-pg .button {
  margin-top: 20px;
  padding: 10px 15px;
  font-size: 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.settings-billing-pg .button:hover {
  background-color: #0056b3;
}`, "",{"version":3,"sources":["webpack://./src/Pages/SettingsBillingPage/SettingsBillingPage.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;AACI;EACE,kBAAA;EACA,gBAAA;EACA,WAAA;AACN;AAII;EACE,iBAAA;EACA,WAAA;AAFN;AAKI;EACE,aAAA;EACA,kBAAA;EACA,sBAAA;EACA,eAAA;AAHN;AAOE;EACE,gBAAA;EACA,kBAAA;EACA,eAAA;EACA,yBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;AALJ;AAOI;EACE,yBAAA;AALN","sourcesContent":[".settings-billing-pg {\n  padding: 20px;\n\n  .settings-billing-pg__form {\n    display: flex;\n    flex-direction: column;\n    gap: 15px;\n\n    h2 {\n      font-size: 1.25rem;\n      margin-top: 20px;\n      color: #333;\n    }\n  }\n\n  .input-field {\n    label {\n      font-size: 0.9rem;\n      color: #555;\n    }\n    \n    input {\n      padding: 10px;\n      border-radius: 4px;\n      border: 1px solid #ccc;\n      font-size: 1rem;\n    }\n  }\n\n  .button {\n    margin-top: 20px;\n    padding: 10px 15px;\n    font-size: 1rem;\n    background-color: #007bff;\n    color: #fff;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n\n    &:hover {\n      background-color: #0056b3;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
