import React, { useState } from "react";
import "./CampaignInfoModal.scss";
import chequeaiLogo from "../../assets/icons/chequai-logo.png";

const CampaignInfoModal = ({ isOpen, onClose, themeMode, profileUrl }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const themeClass = themeMode === "light" ? "light-theme" : "campaign-info-modal--dark";
  

  const steps = [
    {
      title: "",
      content: (
        <>
          <div className={`campaign-info-modal__welcome-section campaign-info-modal__welcome-section--${themeClass}`}>
            <div className="campaign-info-modal__welcome-section__logo">
              <img src={chequeaiLogo} alt="Chequeai Logo" />
              <h1 className="campaign-info-modal__welcome-section__logo__title">Campaigns</h1>
            </div>
            <p>The Home for Creators and Brands</p>
          </div>
          <p>Introduce yourself 👋 and say hi to your collaborators while you wait for the brand’s campaign concepts.</p>
        </>
      ),
    },
    {
      title: "",
      content: (
        <>
          <div className={`campaign-info-modal__welcome-section campaign-info-modal__welcome-section--${themeClass}`}>
            <div className="campaign-info-modal__welcome-section__logo">
              <h1 className="campaign-info-modal__welcome-section__logo__title">Brand Concepts</h1>
            </div>
          </div>
          <p>
            Brand concepts are here to help you craft campaign ads and placements that perfectly capture the brand's vision and goals.
          </p>
        </>
      ),
    },
    {
      title: "",
      content: (
        <>
          <div className={`campaign-info-modal__welcome-section campaign-info-modal__welcome-section--${themeClass}`}>
            <div className="campaign-info-modal__welcome-section__logo">
              <h1 className="campaign-info-modal__welcome-section__logo__title">Collaborating</h1>
            </div>
          </div>
          <p>
            Keep an open line with the brand by staying active and engaged. This helps ensure that objectives, goals, and important details—like location, product specifics, campaign goals, and aesthetics—are clearly understood.
          </p>
        </>
      ),
    },
    {
      title: "",
      content: (
        <>
          <div className={`campaign-info-modal__welcome-section campaign-info-modal__welcome-section--${themeClass}`}>
            <div className="campaign-info-modal__welcome-section__logo">
              <h1 className="campaign-info-modal__welcome-section__logo__title">Payments & Transfer</h1>
            </div>
          </div>
          <p>
            The brand has the flexibility to transfer campaign funds to the creator whenever they feel the campaign is on the right track. If preferred, the funds will be shared with the creator as the campaign wraps up, once it reaches "Completed" status.
          </p>
        </>
      ),
    },
    {
      title: "",
      content: (
        <>
          <div className={`campaign-info-modal__welcome-section campaign-info-modal__welcome-section--${themeClass}`}>
            <div className="campaign-info-modal__welcome-section__logo">
              <h1 className="campaign-info-modal__welcome-section__logo__title">Track Performance</h1>
            </div>
          </div>
          <p>
            When your campaign ad posts and placements are ready to go, reach out to the brand and get them live! Don’t forget to link the social posts to the campaign in the analytics tab, so you can easily follow its success and impact.
          </p>
        </>
      ),
    },
    {
      title: "",
      content: (
        <>
          <div className={`campaign-info-modal__welcome-section campaign-info-modal__welcome-section--${themeClass}`}>
            <div className="campaign-info-modal__welcome-section__logo">
              <h1 className="campaign-info-modal__welcome-section__logo__title">Featuring Products</h1>
            </div>
          </div>
          <p>
            Brands can easily add their products to campaigns, making them available for creators to feature. Creators can then add these products to their profiles directly from the products tab within each campaign.
          </p>
        </>
      ),
    },
    {
      title: "",
      content: (
        <>
          <div className={`campaign-info-modal__welcome-section campaign-info-modal__welcome-section--${themeClass}`}>
            <div className="campaign-info-modal__welcome-section__logo">
              <h1 className="campaign-info-modal__welcome-section__logo__title">Campaign Review</h1>
            </div>
          </div>
          <p>
            At the end of each campaign, brands and creators exchange feedback, helping brands refine campaigns and giving creators insights to enhance their profiles. Helps ensure our platform features only high quality creators and brands.
          </p>
        </>
      ),
    },
  ];

  if (!isOpen) return null;

  const handleNext = () =>
    currentStep < steps.length - 1 ? setCurrentStep(currentStep + 1) : onClose();
  const handlePrev = () => currentStep > 0 && setCurrentStep(currentStep - 1);

  return (
    <div className="campaign-info-modal__overlay" onClick={onClose}>
      <div
        className={`campaign-info-modal__content campaign-info-modal__content--${themeClass}`}
        onClick={(e) => e.stopPropagation()}
      >
        {steps[currentStep].title !== "" && (
          <div className="campaign-info-modal__header">
            <h2 className="campaign-info-modal__header__title">{steps[currentStep].title}</h2>
          </div>
        )}
        <div className={`campaign-info-modal__body ${themeClass}`}>
          {steps[currentStep].image && (
            <img
              src={steps[currentStep].image}
              alt={steps[currentStep].title}
              className="campaign-info-modal__body__image"
            />
          )}
          {steps[currentStep].content}
        </div>
        <div className="campaign-info-modal__footer">
          <button
            className={`campaign-info-modal__footer__button campaign-info-modal__footer__button--prev ${themeClass}`}
            onClick={handlePrev}
            disabled={currentStep === 0}
          >
            Previous
          </button>
          <button
            className={`campaign-info-modal__footer__button campaign-info-modal__footer__button--next ${themeClass}`}
            onClick={handleNext}
          >
            {currentStep === steps.length - 1 ? "Finish" : "Next"}
          </button>
        </div>
        <div className="campaign-info-modal__progress">
          {steps.map((_, index) => (
            <div
              key={index}
              className={`campaign-info-modal__progress__dot ${
                index === currentStep ? "campaign-info-modal__progress__dot--active" : ""
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CampaignInfoModal;
