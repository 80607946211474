export const selectLightMode = {
    control: (base) => ({
        ...base,
        backgroundColor: "#EcEcEc",
        borderRadius: "8px",
        border: "none",
        boxShadow: "none",
        fontSize:  "0.85rem",
        padding: "0rem 0rem 0 0.5rem",
        "&:hover": {
            border: "none",
            boxShadow: "none",
        },
    }),
    menu: (base) => ({
        ...base,
        backgroundColor: "#E1E1E1",
        borderRadius: "4px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
        fontSize:  "0.94rem",

        "&:hover": {
            backgroundColor: "neutral70",
        },
    }),
    option: (base, state) => ({
        ...base,
        color: "black",
        backgroundColor: state.isFocused ? "neutral70" : "#E1E1E1",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.1)",
        },
    }),
    singleValue: (base) => ({
        ...base,
        color: "black",
    }),
    dropdownIndicator: (base) => ({
        ...base,
        color: "black", // Set the dropdown arrow color to darkgrey
        svg: {
            fill: "black", // Make sure the SVG icon also changes to darkgrey
        },
        "&:hover": {
            color: "black", // Ensure the hover state is also darkgrey
            svg: {
                fill: "black", // Ensure the SVG remains darkgrey on hover
            },
        },
    }),
    indicatorSeparator: (base) => ({
        ...base,
        display: "none", // Remove the line between the input and the dropdown icon (optional)
    }),
};

export const selectDarkMode = {
    control: (base) => ({
        ...base,
        backgroundColor: "#141414",
        borderRadius: "8px",
        border: "none",
        boxShadow: "none",
        fontSize:  "0.85rem",
        padding: "0rem 0rem 0 0.5rem",
        "&:hover": {
            border: "none",
            boxShadow: "none",
        },
    }),
    menu: (base) => ({
        ...base,
        backgroundColor: "#141414",
        borderRadius: "4px",
        fontSize:  "0.94rem",

    }),
    option: (base, state) => ({
        ...base,
        color: "white",
        // Set background color based only on focus state
        backgroundColor: state.isFocused ? "#555555" : "#141414", // Replace "#555555" with your desired focus color
        // Ensure that selected options do not have a different background
        ...(state.isSelected && {
            backgroundColor: "#141414", // Same as the default background color
        }),
        cursor: "pointer", // Optional: Changes cursor to pointer on hover
    }),
    multiValue: (base) => ({
        ...base,
        backgroundColor: "dangerLight",
        borderRadius: "4px",
        display: "inline-flex",
        alignItems: "center",
        padding: "2px 6px",
        marginRight: "4px",
        marginTop: "4px",
    }),
    multiValueLabel: (base) => ({
        ...base,
        color: "white",
        fontSize: "14px",
        fontWeight: "600",
    }),
    multiValueRemove: (base) => ({
        ...base,
        color: "white",
        cursor: "pointer",
        ":hover": {
            backgroundColor: "none",
            color: "dangerLight",
        },
    }),
    dropdownIndicator: (base) => ({
        ...base,
        color: "black", // Set the dropdown arrow color to black
        svg: {
            fill: "black", // Ensure the SVG icon also changes to black
        },
        "&:hover": {
            color: "black", // Keep the hover state color as black
            svg: {
                fill: "black", // Keep the SVG icon color as black on hover
            },
        },
    }),
    indicatorSeparator: (base) => ({
        ...base,
        display: "none", // Remove the separator between the input and the dropdown icon
    }),
};

export const onSelectLightMode = {
    control: (base) => ({
        ...base,
        backgroundColor: "#f5f5f5",
        borderRadius: "8px",
        border: "none",
        boxShadow: "none",
        fontSize:  "0.85rem",
        padding: "0rem 0rem 0 0.5rem",
        "&:hover": {
            border: "none",
            boxShadow: "none",
        },
    }),
    menu: (base) => ({
        ...base,
        backgroundColor: "#f5f5f5",
        borderRadius: "4px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
        fontSize:  "0.94rem",

        "&:hover": {
            backgroundColor: "neutral70",
        },
    }),
    option: (base, state) => ({
        ...base,
        color: "black",
        backgroundColor: state.isFocused ? "neutral70" : "#f5f5f5",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.1)",
        },
    }),
    singleValue: (base) => ({
        ...base,
        color: "black",
    }),
    dropdownIndicator: (base) => ({
        ...base,
        color: "black", // Set the dropdown arrow color to darkgrey
        svg: {
            fill: "black", // Make sure the SVG icon also changes to darkgrey
        },
        "&:hover": {
            color: "black", // Ensure the hover state is also darkgrey
            svg: {
                fill: "black", // Ensure the SVG remains darkgrey on hover
            },
        },
    }),
    indicatorSeparator: (base) => ({
        ...base,
        display: "none", // Remove the line between the input and the dropdown icon (optional)
    }),
};

export const onSelectDarkMode = {
    control: (base) => ({
        ...base,
        backgroundColor: "#222222",
        borderRadius: "8px",
        border: "none",
        boxShadow: "none",
        fontSize:  "0.85rem",
        padding: "0rem 0rem 0 0.5rem",
        "&:hover": {
            border: "none",
            boxShadow: "none",
        },
    }),
    menu: (base) => ({
        ...base,
        backgroundColor: "#141414",
        borderRadius: "4px",
        fontSize:  "0.94rem",

    }),
    option: (base, state) => ({
        ...base,
        color: "white",
        // Set background color based only on focus state
        backgroundColor: state.isFocused ? "#555555" : "#141414", // Replace "#555555" with your desired focus color
        // Ensure that selected options do not have a different background
        ...(state.isSelected && {
            backgroundColor: "#141414", // Same as the default background color
        }),
        cursor: "pointer", // Optional: Changes cursor to pointer on hover
    }),
    multiValue: (base) => ({
        ...base,
        backgroundColor: "dangerLight",
        borderRadius: "4px",
        display: "inline-flex",
        alignItems: "center",
        padding: "2px 6px",
        marginRight: "4px",
        marginTop: "4px",
    }),
    multiValueLabel: (base) => ({
        ...base,
        color: "white",
        fontSize: "14px",
        fontWeight: "600",
    }),
    multiValueRemove: (base) => ({
        ...base,
        color: "white",
        cursor: "pointer",
        backgroundColor: "none",
        ":hover": {
            backgroundColor: "none",
            color: "dangerLight",
        },
    }),
    dropdownIndicator: (base) => ({
        ...base,
        color: "white", // Set the dropdown arrow color to black
        svg: {
            fill: "white", // Ensure the SVG icon also changes to black
        },
        "&:hover": {
            color: "black", // Keep the hover state color as black
            svg: {
                fill: "black", // Keep the SVG icon color as black on hover
            },
        },
    }),
    indicatorSeparator: (base) => ({
        ...base,
        display: "none", // Remove the separator between the input and the dropdown icon
    }),
    
};


// src/utils/addProductStyles.js

// src/utils/addProductStyles.js

export const addProduct = {
    control: (base) => ({
      ...base,
      backgroundColor: "#222222",
      borderRadius: "4px",
      border: "none",
      boxShadow: "none",
      fontSize: "0.8rem",
      padding: "2px 5px 2px 0px", // Reduced padding for a slimmer look
      minHeight: "30px", // Set a minimum height
      height: "auto", // Allow height to adjust based on content
      "&:hover": {
        border: "none",
        boxShadow: "none",
      },
      color: "darkgrey", // Set control font color to dark grey
      // Optionally, set the input text color
      input: {
        ...base.input,
        color: "darkgrey", // Set input text color to dark grey
      },
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: "#141414",
      borderRadius: "4px",
      fontSize: "0.94rem",
      zIndex: 9999, // Ensure the menu appears above other elements
      color: "darkgrey", // Set menu font color to dark grey
    }),
    option: (base, state) => ({
      ...base,
      color: "darkgrey", // Change option text color to dark grey
      backgroundColor: state.isFocused ? "#555555" : "#141414",
      ...(state.isSelected && {
        backgroundColor: "#555555", // Highlight selected option
        color: "white", // Keep selected option text white for contrast
      }),
      cursor: "pointer",
      padding: "8px 12px", // Adjust padding for options
      opacity: state.isFocused || state.isSelected ? 1 : 0.8, // Reduce opacity when not focused or selected
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: "#555555", // Use a consistent color
      borderRadius: "4px",
      display: "inline-flex",
      alignItems: "center",
      padding: "2px 6px",
      marginRight: "4px",
      marginTop: "2px",
      color: "darkgrey", // Set multiValue text color to dark grey
      opacity: 0.9, // Slightly reduce opacity for multiValue
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: "darkgrey", // Change label color to dark grey
      fontSize: "12px",
      fontWeight: "600",
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: "darkgrey", // Change remove button color to dark grey
      cursor: "pointer",
      backgroundColor: "transparent",
      ":hover": {
        backgroundColor: "transparent",
        color: "#FF5A5F", // Change to a distinct color on hover
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "darkgrey", // Set the dropdown arrow color to dark grey
      padding: "0px",
      transition: "transform 0.2s ease",
      "&:hover": {
        color: "#FF5A5F",
      },
      svg: {
        fill: "currentColor",
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    input: (base) => ({
      ...base,
      margin: "0px",
      padding: "0px",
      color: "darkgrey", // Ensure input text is dark grey
    }),
    placeholder: (base) => ({
      ...base,
      color: "rgba(169, 169, 169, 0.7)", // Set placeholder to dark grey with 70% opacity
      fontSize: "0.8rem",
    }),
  };
  