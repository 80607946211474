import { Link } from 'react-router-dom';
import './NotFoundPage.scss';
import React, { useContext } from 'react';
import { Context } from "../../context/index";
import UserRoute from '../../Routes/Auth';


export default function NotFoundPage() {

  const { state: { user } } = useContext(Context);

  if (!user) {
    return (
      <div className='error-pg'>
        <h1 className='error-pg__title'>Page not found</h1>
        <p className='error-pg__text'>Sorry, we're unable to find what you're looking for.</p>
        <p className='error-pg__link'>
          <Link to="/" className='error-pg__link-text'>Home</Link>
        </p>
      </div>
    );
  }


  return (
    <UserRoute>
      <div className='error-pg'>
        <h1 className='error-pg__title'>Page not found</h1>
        <p className='error-pg__text'>Sorry, we're unable to find what you're looking for.</p>
        <p className='error-pg__link'>
          <Link to="/" className='error-pg__link-text'>Home</Link>
        </p>
      </div>
    </UserRoute>
  );
}