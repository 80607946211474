// AddPromotionPage.jsx

import React, { useState, useContext } from 'react';
import UserRoute from '../../Routes/Auth';
import Card from '../../Components/Card/Card';
import "./AddPromotionPage.scss";
import axios from 'axios';
import Button from '../../Components/Button/Button';
import InputField from '../../Components/InputField/InputField';
import SocialFieldCard from '../../Components/SocialField/SocialField';
import { useNavigate } from "react-router-dom";
import Navigation from '../../Components/Navigation/Navigation';
import Select from 'react-select';
import { selectLightMode, selectDarkMode } from '../../utils/stylesUtils';
import ThemeContext from '../../context/ThemeContext';
import { Context } from "../../context/index"; // Adjust the path as necessary
import StripeConnectCard from '../../Components/StripeConnectCard/StripeConnectCard';
import { currencyOptions } from '../../utils/constants';

export default function AddPromotionPage() {
    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === "light" ? "light-theme" : "dark-theme";

    const navigate = useNavigate();
    const { state: { user }, } = useContext(Context);

    // Form state variables
    const [promotionName, setPromotionName] = useState("");
    const [promotionDescription, setPromotionDescription] = useState("");
    const [price, setPrice] = useState("");
    const [instagramPosts, setInstagramPosts] = useState(0);
    const [tikTokPosts, setTikTokPosts] = useState(0);
    const [youtubePosts, setYoutubePosts] = useState(0);
    const [podcastPosts, setPodcastPosts] = useState(0);


    const disabledCurrencies = ["JPY", "AUD", "NZD", "INR", "SGD", "AED", "CHF", "ZAR", "KRW"];

    const contentRightsOptions = [
        { value: 'exclusive', label: 'Exclusive Rights' },
        { value: 'non_exclusive', label: 'Non-Exclusive Rights' },
        { value: 'limited', label: 'Limited Rights' },
    ];

    const durationOptions = [
        { value: '1_month', label: '1 Month' },
        { value: '3_months', label: '3 Months' },
        { value: '6_months', label: '6 Months' },
        { value: '12_months', label: '12 Months' },
        // Add more options as needed
    ];

    const reuseOptions = [
        { value: 'allowed', label: 'Allowed' },
        { value: 'not_allowed', label: 'Not Allowed' },
        // Add more options as needed
    ];

    // Select state variables with default values
    const [currency, setCurrency] = useState(currencyOptions[0]); // Defaults to USD
    const [contentRights, setContentRights] = useState(contentRightsOptions[1]); // Defaults to Non-Exclusive Rights
    const [duration, setDuration] = useState(durationOptions[1]); // Defaults to 3 Months (adjust index if needed)
    const [reuseAfter, setReuseAfter] = useState(reuseOptions[0]); // Adjust based on intended default

    // Messages and submission status
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Form validation errors
    const [formErrors, setFormErrors] = useState({});

    // Form validation function
    const validateForm = () => {
        const errors = {};
        if (!promotionName.trim()) errors.promotionName = "Promotion name is required.";
        if (!promotionDescription.trim()) errors.promotionDescription = "Description is required.";
        if (!price || price <= 0) errors.price = "Price must be a positive number.";
        if (!currency) errors.currency = "Please select a currency.";
        if (!contentRights) errors.contentRights = "Please select content rights.";
        if (!duration) errors.duration = "Please select the duration of the promotion.";
        if (!reuseAfter) errors.reuseAfter = "Please select the reuse period after the campaign.";
        // Add more validations as needed
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const addPromotion = async (event) => {
        event.preventDefault();

        // Validate the form before submission
        if (!validateForm()) return;

        setIsSubmitting(true);
        try {
            await axios.put(`${process.env.REACT_APP_API}/user/edit-profile/promotion`, {
                promotionName,
                promotionDescription,
                price,
                instagramPosts,
                tikTokPosts,
                youtubePosts,
                podcastPosts,
                currency: currency.value,
                contentRights: contentRights.value,
                duration: duration.value,
                reuseAfter: reuseAfter.value,
            });
            setSuccessMessage("Promotion added successfully!");
            setErrorMessage(""); // Clear any existing errors
            navigate(`/settings/promotions/`);
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage("An unexpected error occurred. Please try again.");
            }
            setSuccessMessage(""); // Clear any existing success messages
        } finally {
            setIsSubmitting(false);
        }
    }


    if (user?.stripeAccountId === "" || Object.keys(user?.stripeSeller).length === 0 || user?.stripeSeller?.capabilities.transfers === "inactive") {
        return (
            <UserRoute>
                <div className='manage-promo-pg'>
                    <Navigation heading={"Add Promotions"} add={false} username={user.username} back={true} payments={true} navRight={true} />
                    <StripeConnectCard themeClass={themeClass} user={user} type="promotions" isOwner={true} />
                </div>
            </UserRoute>
        );
    }

    return (
        <UserRoute>
            <div className='add-promo-pg'>
                <Navigation heading={"Add Promotion"} back={true} />
                <Card className="card--input" >

                    <InputField
                        label={"Title"}
                        name={"promotionName"}
                        type={"text"}
                        value={promotionName}
                        onChange={e => setPromotionName(e.target.value)}
                    />
                    {formErrors.promotionName && (
                        <div className="add-promo-pg__validation-error">
                            {formErrors.promotionName}
                        </div>
                    )}

                    <InputField
                        label={"Description"}
                        name={"promotionDescription"}
                        type={"text"}
                        value={promotionDescription}
                        onChange={e => setPromotionDescription(e.target.value)}
                    />
                    {formErrors.promotionDescription && (
                        <div className="add-promo-pg__validation-error">
                            {formErrors.promotionDescription}
                        </div>
                    )}

                    <div className='add-promo-pg__row '>
                        <label className="add-promo-pg__label  add-promo-pg__label--select">Currency</label>
                        <Select
                            value={currency}
                            onChange={setCurrency}
                            options={currencyOptions}
                            placeholder="Select Currency"
                            classNamePrefix="react-select"
                            isClearable={false} // Disable clearing since there's a default
                            isOptionDisabled={(option) => disabledCurrencies.includes(option.value)}
                            styles={themeMode === 'light' ? selectLightMode : selectDarkMode}
                        />
                        {formErrors.currency && (
                            <div className="add-promo-pg__validation-error">
                                {formErrors.currency}
                            </div>
                        )}
                    </div>

                    <InputField
                        label={'Price'}
                        name={'price'}
                        type={'number'}
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        prefix={currency.symbol} // Pass the currency symbol as prefix
                        className="add-promo-pg__price add-promo-pg__price--modifier"
                    />
                    {formErrors.price && (
                        <div className="add-promo-pg__validation-error">{formErrors.price}</div>
                    )}

                    <div className='add-promo-pg__socials'>
                        <SocialFieldCard name={"Instagram"} setPosts={setInstagramPosts} posts={instagramPosts} />
                        <SocialFieldCard name={"TikTok"} setPosts={setTikTokPosts} posts={tikTokPosts} />
                        <SocialFieldCard name={"Youtube"} setPosts={setYoutubePosts} posts={youtubePosts} />
                        <SocialFieldCard name={"Podcasts"} setPosts={setPodcastPosts} posts={podcastPosts} />
                    </div>

                    {/* Content Rights Select */}
                    <div className='add-promo-pg__row'>
                        <label className="add-promo-pg__label add-promo-pg__label--select">Content Rights</label>
                        <Select
                            value={contentRights}
                            onChange={setContentRights}
                            options={contentRightsOptions}
                            placeholder="Select Content Rights"
                            classNamePrefix="react-select"
                            isClearable={false} // Disable clearing since there's a default
                            styles={themeMode === 'light' ? selectLightMode : selectDarkMode}
                        />
                        {formErrors.contentRights && (
                            <div className="add-promo-pg__validation-error">
                                {formErrors.contentRights}
                            </div>
                        )}
                    </div>

                    {/* Duration Select */}
                    <div className='add-promo-pg__row'>
                        <label className="add-promo-pg__label add-promo-pg__label--select">Duration</label>
                        <Select
                            value={duration}
                            onChange={setDuration}
                            options={durationOptions}
                            placeholder="Select Duration"
                            classNamePrefix="react-select"
                            isClearable={false} // Disable clearing since there's a default
                            styles={themeMode === 'light' ? selectLightMode : selectDarkMode}
                        />
                        {formErrors.duration && (
                            <div className="add-promo-pg__validation-error">
                                {formErrors.duration}
                            </div>
                        )}
                    </div>

                    {/* Reuse After Campaign Select */}
                    <div className='add-promo-pg__row add-promo-pg__row--modifier'>
                        <label className="add-promo-pg__label add-promo-pg__label--select">Modify & Reuse</label>
                        <Select
                            value={reuseAfter}
                            onChange={setReuseAfter}
                            options={reuseOptions}
                            placeholder="Select Reuse Period"
                            classNamePrefix="react-select"
                            isClearable={false} // Disable clearing since there's a default
                            styles={themeMode === 'light' ? selectLightMode : selectDarkMode}
                        />
                        {formErrors.reuseAfter && (
                            <div className="add-promo-pg__validation-error">
                                {formErrors.reuseAfter}
                            </div>
                        )}
                    </div>

                    {/* Social Fields */}


                    {/* Submit Button */}
                    <Button
                        modifier={"button"}
                        onClick={addPromotion}
                        disabled={isSubmitting} // Disable button while submitting
                    >
                        {isSubmitting ? "Adding..." : "Add Promotion"}
                    </Button>

                    {/* Display Success Message */}
                    {successMessage && (
                        <div className="add-promo-pg__message add-promo-pg__message--success">
                            {successMessage}
                        </div>
                    )}

                    {/* Display Error Message */}
                    {errorMessage && (
                        <div className="add-promo-pg__message add-promo-pg__message--error">
                            {errorMessage}
                        </div>
                    )}
                </Card>
            </div>
        </UserRoute>
    )
}
