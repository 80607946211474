// ConnectAccountSignupErrorPage.jsx
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import './ConnectAccountSignupErrorPage.scss';
import ThemeContext from '../../context/ThemeContext';
import UserRoute from '../../Routes/Auth';

function ConnectAccountSignupErrorPage() {
  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

  return (
    <UserRoute>
      <div className="connect-account-signup-error-page">
        <h1 className="connect-account-signup-error-page__title">
          Something Went Wrong.
        </h1>
        <p
          className={`connect-account-signup-error-page__text connect-account-signup-error-page__text--${themeClass}`}
        >
          There was an issue connecting your account with Stripe. Please try again later.
        </p>
        <div className="connect-account-signup-error-page__link">
          <Link
            to="/settings/payments"
            className={`connect-account-signup-error-page__link--text connect-account-signup-error-page__link--text--${themeClass}`}
          >
            Return to Payments
          </Link>
        </div>
      </div>
    </UserRoute>
  );
}

export default ConnectAccountSignupErrorPage;
