import React, { useEffect, useContext } from 'react';
import addPortfolio from '../../assets/defaults/addCampaign.png';
import addPortfolioLightmode from '../../assets/defaults/addCampaignLightmode.png';
import './UploadImageComponent.scss';
import ThemeContext from '../../context/ThemeContext';


const UploadImageComponent = ({ tag, imagePreview, setImagePreview, videoData, image, setImage, handleAdd, type = "image/png, image/jpeg, video/mp4" }) => {

  const validateImg = (e) => {
    const file = e.target.files[0];
    console.log(file);
    if (file.size > 10048576) {
      console.error('Max file size 1mb', { position: 'top-center' });
    } else {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };
  const { themeMode } = useContext(ThemeContext);


  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

  if ( themeMode === 'light'){
    addPortfolio = addPortfolioLightmode;
  }

  useEffect(() => { 
    // console.log(imagePreview) 
  }, [imagePreview]);

  return (
    <>
      <label htmlFor={tag} className="upload-image__label">
        <img
          className="upload-image__image"
          src={imagePreview ? imagePreview : addPortfolio}
          alt="Add Portfolio"
        />
      </label>
      <input
        type="file"
        id={tag}
        hidden
        accept={type}
        onChange={validateImg}
      />
      {imagePreview && (<><button className={`upload-image__button upload-image__button--${themeClass}`}onClick={() => { window.location.reload();}}>-</button><button className={`upload-image__button upload-image__button--${themeClass}`}onClick={() => {handleAdd()}}>+</button></>)}
    </>
  );
};

export default UploadImageComponent;
