import axios from 'axios';
import moment from 'moment';

export const addEvent = async (event) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_API}/calendar/create`, event);
    return response.data;
  } catch (error) {
    console.error('Error adding event:', error);
    throw error;
  }
};

export const getEvents = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API}/calendar/events`);
    const events = response.data.map(event => ({
      ...event,
      startDateTime: moment(event.startDateTime),
      endDateTime: moment(event.endDateTime),
    }));
    return events;
  } catch (error) {
    console.error('Error fetching events:', error);
    throw error;
  }
};


export const getEvent = async (eventId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API}/calendar/event/${eventId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching event:', error);
    throw error;
  }
};

export const updateEvent = async (eventId, updatedEvent) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_API}/calendar/event/${eventId}`, updatedEvent);
    return response.data;
  } catch (error) {
    console.error('Error updating event:', error);
    throw error;
  }
};

export const deleteEvent = async (eventId) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_API}/calendar/event/${eventId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting event:', error);
    throw error;
  }
};
