import React, { useEffect, useState, useContext } from 'react';
import UserRoute from '../../Routes/Auth';
// import CampaignCard from '../../Components/CampaignCard/CampaignCard'
import "./CampaignsPage.scss"
import Navigation from "../../Components/Navigation/Navigation";
import axios from 'axios'
import CampaignLinkCard from '../../Components/CampaignLinkCard/CampaignLinkCard';
import ThemeContext from '../../context/ThemeContext';
import { Context } from '../../context';
import Card from '../../Components/Card/Card';
import { Link } from 'react-router-dom';
export default function CampaignsPage() {

    const [campaigns, setCampaigns] = useState(null);
    const { state: { user }, } = useContext(Context);
    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';


    const fetchCampaigns = async () => {
        try {
            const response = await axios.get(`/campaigns`);
            const fetchedCampaigns = response.data?.campaigns || [];
            setCampaigns(fetchedCampaigns);
            // console.log(response.data);
        } catch (error) {
            console.error(error);
        }
    };


    useEffect(() => {
        if (campaigns === null) {
            fetchCampaigns();
        }
    }, [campaigns])


    if (user?.stripeAccountId === "" || Object.keys(user?.stripeSeller).length === 0 || user?.stripeSeller?.charges_enabled === false) {
        return (
            <UserRoute>
                <div className={`donations-settings donations-settings--${themeClass}`}>
                    <Navigation heading={"Campaigns"} back={true} />
                    <Card className={`card card--donations card--donations--${themeClass}`}>
                        <h2 className={`donations-settings__title ${themeClass}`}>
                            <span className="donations-settings__branding">Connect Stripe</span>
                        </h2>
                        <p className="donations-settings__message">
                            {user?.stripeSeller && Object.keys(user?.stripeSeller).length === 0 && user?.stripeAccountId !== ""
                                ? "Your Stripe account is connected to this account. Please complete the Stripe connection." : "Stripe is not connected to this account. Please connect your Stripe account to enable Campaigns."
                            }
                        </p>
                        <div className="donations-settings__connect-button__div">
                            <Link to="/settings/payments/"
                                className={`donations-settings__connect-button donations-settings__connect-button--${themeClass}`}
                            >
                                Connect Stripe
                            </Link>
                        </div>
                    </Card>
                </div>
            </UserRoute>
        );
    }





    return (
        <UserRoute>
          <div className='campaigns-pg'>
            <Navigation heading={"Active Campaigns"} back={true} edit={"/settings/promotions"} />
      
            {campaigns && campaigns.length > 0 ? (
              campaigns.map((campaign) => (
                <CampaignLinkCard
                  key={campaign._id}
                  to={`/campaigns/${campaign._id}/`}
                  title={campaign.campaignName}
                  brand={campaign?.brand}
                  category={campaign?.category}
                  productName={campaign?.productName}
                  tiktok={campaign?.tiktok}
                  instagram={campaign?.instagram}
                  youtube={campaign?.youtube}
                  podcast={campaign?.podcast}
                  image={campaign?.image}
                  id={campaign._id}
                  status={campaign?.status}
                  location={campaign?.location}
                />
              ))
            ) : (
                <div className="campaigns-pg__message__container">
                <p className="campaigns-pg__message">No active campaigns available at this time.</p>
                </div>
             
            )}
          </div>
        </UserRoute>
      );
      
}
