import React, { useContext, useState } from 'react';
import UserRoute from '../../Routes/Auth';
import Navigation from '../../Components/Navigation/Navigation';
import './PostPage.scss';
import { Link } from 'react-router-dom';
import Card from '../../Components/Card/Card';
import PostModal from '../../Components/PostModal/PostModal';
import FeedbackModal from '../../Components/FeedbackModal/FeedbackModal'; // Import the FeedbackModal
import ThemeContext from '../../context/ThemeContext';
import { Context } from "../../context/index";

export default function PostPage() {
    const [post, setPost] = useState(false);
    const [isFeedbackModalOpen, setFeedbackModalOpen] = useState(false); // State for feedback modal
    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

    const { state: { user }, } = useContext(Context);

    return (
        <UserRoute>
            <div className='manage-portfolio-pg'>
                <Navigation title={'Portfolio'} />
                
                {/* Other portfolio links */}
                <Link to='/settings/update_portfolio' className={`manage-portfolio-pg__link manage-portfolio-pg__link--${themeClass}`}>
                    <Card className={"card card--link"}>
                        <div className='manage-portfolio-pg__container'>
                            <h2 className='manage-portfolio-pg__link-text'>Portfolio Shots</h2>
                        </div>
                    </Card>
                </Link>

                <Link to='/settings/socials/' className={`manage-portfolio-pg__link manage-portfolio-pg__link--${themeClass}`}>
                    <Card className={"card card--link"}>
                        <div className='manage-portfolio-pg__container'>
                            <h2 className='manage-portfolio-pg__link-text'>Social Platforms</h2> 
                        </div>
                    </Card>
                </Link>

                {/* <Link to='/donations' className={`manage-portfolio-pg__link manage-portfolio-pg__link--${themeClass}`}>
                    <Card className={"card card--link"}>
                        <div className='manage-portfolio-pg__container'>
                            <h2 className='manage-portfolio-pg__link-text'>Donations</h2>
                        </div>
                    </Card>
                </Link> */}

                <Link to='/settings/payments/' className={`manage-portfolio-pg__link manage-portfolio-pg__link--${themeClass}`}>
                    <Card className={"card card--link"}>
                        <div className='manage-portfolio-pg__container'>
                            <h2 className='manage-portfolio-pg__link-text'>My Wallet</h2>
                        </div>
                    </Card>
                </Link>

                {/* Feedback link */}
                <div 
                    onClick={() => setFeedbackModalOpen(true)} // Open feedback modal on click
                    className={`manage-portfolio-pg__link manage-portfolio-pg__link--margin manage-portfolio-pg__link--${themeClass}`}
                >
                    <Card className={"card card--link"}>
                        <div className='manage-portfolio-pg__container '>
                            <h2 className='manage-portfolio-pg__link-text'>Support & Feedback</h2>
                        </div>
                    </Card>
                </div>

                {/* Post modal */}
                {post && <PostModal isOpen={post} setIsOpen={setPost} onClose={() => setPost(false)} user={user} />}
                
                {/* Feedback modal */}
                {isFeedbackModalOpen && (
                    <FeedbackModal 
                        isOpen={isFeedbackModalOpen} 
                        onClose={() => setFeedbackModalOpen(false)} 
                    />
                )}
            </div>
        </UserRoute>
    );
}
