import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import "./LoginModal.scss";
import logo from "../../assets/icons/chequai-logo.png"; // Adjust the path as necessary
import { Context } from "../../context/index"; // Adjust the path as necessary
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
// import ThemeContext from "../../context/ThemeContext"; // Import ThemeContext for dark mode


const LoginModal = ({ showModal, setShowModal, setShowSignUpModal }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { dispatch } = useContext(Context);
  const navigate = useNavigate();
  // const { themeMode } = useContext(ThemeContext);
  // const themeClass = themeMode === "light" ? "light-theme" : "dark-theme";

  useEffect(() => {
    // Function to handle keydown events
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setShowModal(false);
      }
    };

    if (showModal) {
      // Add event listener when the modal is shown
      document.addEventListener("keydown", handleKeyDown);
    }

    // Clean up the event listener when the component is unmounted or modal is closed
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [showModal, setShowModal]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle login logic here
    try {
      const response = await axios.post(`${process.env.REACT_APP_API}/auth/login`, {
        username,
        password,
        
      }, { withCredentials: true }); 

  
        if (response.data.token && response.data.user) {
          Cookies.set('token', response.data.token, { 
            expires: 7, 
            secure: process.env.NODE_ENV === 'production', 
            sameSite: 'None' 
          });
          axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.token}`;
          dispatch({
            type: 'LOGIN',
            payload: response.data.user,
          });
  
          window.localStorage.setItem('user', JSON.stringify(response.data.user));
          window.localStorage.setItem('token', response.data.token);

        // Close the modal
        setShowModal(false);

        // Redirect to user's page or refresh
        navigate(`/${username}`);
      } else {
        console.error("Unexpected server response", response.data);
        // Optionally show an error message to the user
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
        // Optionally show error message to the user
      } else {
        console.log("Error", error.message);
        // Optionally show error message to the user
      }
    }
  };

  const handleSignUpClick = (e) => {
    e.preventDefault();
    setShowModal(false); // Close the login modal
    setShowSignUpModal(true); // Open the sign-up modal
  };

  return (
    <>
      {showModal && (
        <div className="modal-overlay" onClick={() => setShowModal(false)}>
          <div className={`modal-content` } onClick={(e) => e.stopPropagation()}>
            <img src={logo} alt="ChequeAI Logo" className="logo-image" />
            <h2>Login to Chequeai</h2>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                placeholder="Username or email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button type="submit" className="login-button">
                Log In
              </button>
            </form>
            <p className="signup-text">
              Don't have an account?{" "}
              <span className="signup-link" onClick={handleSignUpClick}>
              Sign Up
              </span>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginModal;
