import React, { useContext } from "react";
import "./SubscriptionPlanCard.scss";
import ThemeContext from "../../context/ThemeContext";

const SubscriptionPlanCard = ({
  name,
  price,
  duration,
  features,
  handleCheckout,
  priceId,
  buttonLabel,
  enabled,
}) => {
  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === "light" ? "light-theme" : "dark-theme";

  return (
    <div className={`subscription-card subscription-card--${themeClass}`}>
      <div className="subscription-card__header">
        <h4 className="subscription-card__name">{name}</h4>
        <div>
          <p className="subscription-card__price">
            {price}/{""}
            <span
              className={`subscription-card__duration subscription-card__duration--${themeClass}`}
            >
              {duration}
            </span>
          </p>
        </div>

      </div>
      <ul className="subscription-card__features">
        {features.map((feature, index) => (
          <li key={index} className="subscription-card__feature">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="green"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="icon icon-tabler icons-tabler-outline icon-tabler-circle-dashed-check"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M8.56 3.69a9 9 0 0 0 -2.92 1.95" />
              <path d="M3.69 8.56a9 9 0 0 0 -.69 3.44" />
              <path d="M3.69 15.44a9 9 0 0 0 1.95 2.92" />
              <path d="M8.56 20.31a9 9 0 0 0 3.44 .69" />
              <path d="M15.44 20.31a9 9 0 0 0 2.92 -1.95" />
              <path d="M20.31 15.44a9 9 0 0 0 .69 -3.44" />
              <path d="M20.31 8.56a9 9 0 0 0 -1.95 -2.92" />
              <path d="M15.44 3.69a9 9 0 0 0 -3.44 -.69" />
              <path d="M9 12l2 2l4 -4" />
            </svg>
            {feature}
          </li>
        ))}
      </ul>
      <button
        className={
          enabled
            ? `subscription-card__button 
            subscription-card__button--${themeClass} subscription-card__button--green`
            : `
            subscription-card__button 
            subscription-card__button--${themeClass}`
        }
        onClick={() => {
          handleCheckout({ id: priceId });
        }}
      >
        {buttonLabel}
      </button>
    </div>
  );
};

export default SubscriptionPlanCard;
