// src/Components/CategoryPage/CategoryPage.jsx

import React, { useContext, useEffect, useState, useCallback } from 'react';
import axios from "axios";
import { useParams } from 'react-router-dom';
import UserRoute from '../../Routes/Auth';
import { Context } from "../../context/index";
import UserCard from '../../Components/UserCard/UserCard';
import Navigation from '../../Components/Navigation/Navigation';
import Dropdown from '../../Components/Dropdown/Dropdown';
import './CategoryPage.scss';
import ThemeContext from '../../context/ThemeContext';
import { 
    languageFilter, 
    ageFilter, 
    childrenFriendlyFilter, 
    platforms, 
    followingFilter, 
    pricingFilter, 
    ratingFilter, 
    ethnicityFilter, 
    countryFilter 
} from "../../utils/serachFilters"; // Corrected the path

export default function CategoryPage() {
    const { category } = useParams();
    const { state: { user, csrfToken } } = useContext(Context);
    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

    const [filters, setFilters] = useState({
        selectedPlatforms: [],
        selectedFollowing: "",    // Changed from array to string
        selectedPricing: "",      // Changed from array to string
        selectedRating: "",       // Changed from array to string
        selectedEthnicity: "",
        selectedCountry: "",
        selectedLanguages: [],    // Handle multiple languages
        selectedAge: "",
        isChildrenFriendly: null, // Boolean filter
    });

    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [username, setUsername] = useState("");

    const capitalizedCategory = category.replace(/_/g, ' ')
        .toLowerCase()
        .replace(/(?:^|\s)\S/g, char => char.toUpperCase());

    useEffect(() => {
        if (user) {
            setUsername(user.username);
        }
    }, [user]);

    const fetchUsers = useCallback(async () => {
        try {
            // console.log("Sending Filters:", {
            //     ...filters,
            //     page
            // });
            const res = await axios.post(`/user/all/${category}`, {
                ...filters,
                page
            }, {
                headers: { "xsrf-token": csrfToken }
            });
            setUsers(res.data.users);
            setTotalPages(res.data.totalPages);
        } catch (err) {
            console.error("Error fetching users:", err);
            // Optionally, you can set an error state here to display a message to the user
        }
    }, [filters, page, csrfToken, category]);

    useEffect(() => {
        if (csrfToken) {
            fetchUsers();
        }
    }, [fetchUsers, csrfToken]);

    // Handle Dropdown selection changes
    const handleFilterChange = (filterName, value) => {
        console.log(`Filter Changed: ${filterName} =`, value);
        setFilters(prevFilters => ({
            ...prevFilters,
            [filterName]: value
        }));
        setPage(1); // Reset to first page whenever a filter changes
    };

    return (
        <UserRoute username={username}>
            <div className='category-pg'>
                <Navigation heading={capitalizedCategory} back={true} />
                
                <div className='sub-filters'>
                    <Dropdown 
                        label={"Socials"} 
                        links={platforms} 
                        selected={filters.selectedPlatforms} 
                        setSelected={(value) => handleFilterChange('selectedPlatforms', value)} 
                        multiSelection // Assuming Socials can be multi-selected
                    />
                    <Dropdown 
                        label={"Following"} 
                        links={followingFilter} 
                        selected={filters.selectedFollowing} 
                        setSelected={(value) => handleFilterChange('selectedFollowing', value)} 
                        singleSelection 
                    />
                    <Dropdown 
                        label={"Promotion"} 
                        links={pricingFilter} 
                        selected={filters.selectedPricing} 
                        setSelected={(value) => handleFilterChange('selectedPricing', value)} 
                        singleSelection 
                    />
                    <Dropdown 
                        label={"Rating"} 
                        links={ratingFilter} 
                        selected={filters.selectedRating} 
                        setSelected={(value) => handleFilterChange('selectedRating', value)} 
                        singleSelection 
                    />
                    <Dropdown 
                        label={"Ethnicity"} 
                        links={ethnicityFilter} 
                        selected={filters.selectedEthnicity} 
                        setSelected={(value) => handleFilterChange('selectedEthnicity', value)} 
                        singleSelection 
                    />
                           <Dropdown 
                        label={"Children Friendly"} 
                        links={childrenFriendlyFilter} 
                        selected={filters.isChildrenFriendly} 
                        setSelected={(value) => handleFilterChange('isChildrenFriendly', value)} 
                        singleSelection 
                    />
              
                    <Dropdown 
                        label={"Language"} 
                        links={languageFilter} 
                        selected={filters.selectedLanguages} 
                        setSelected={(value) => handleFilterChange('selectedLanguages', value)} 
                        multiSelection // Ensure Dropdown can handle multiple selections
                    />
                    <Dropdown 
                        label={"Age"} 
                        links={ageFilter} 
                        selected={filters.selectedAge} 
                        setSelected={(value) => handleFilterChange('selectedAge', value)} 
                        singleSelection 
                    />
             
                          <Dropdown 
                        label={"Country"} 
                        links={countryFilter} 
                        selected={filters.selectedCountry} 
                        setSelected={(value) => handleFilterChange('selectedCountry', value)} 
                        singleSelection 
                    />
                </div>

                <div className="category-pg__filter-container">
                    {/* Additional filter components can be added here */}
                </div>

                {/* Display users */}
                {users.length > 0 ? (
                    users.map(user => (
                        <UserCard 
                            key={user._id} 
                            username={user.username} 
                            location={user.location} 
                            category={user.categories[0]} 
                            picture={user.picture} 
                        />
                    ))
                ) : (
                    <p className="category-pg__no-users">No users found.</p>
                )}

                {/* Pagination controls */}
                <div className="category-pg__pagination">
                    <button 
                        className={`category-pg__pagination__button category-pg__pagination__button--${themeClass}`} 
                        disabled={page === 1} 
                        onClick={() => setPage(prev => prev - 1)}
                    >
                        Prev
                    </button>
                    <span className={`category-pg__pagination__text category-pg__pagination__text--${themeClass}`}>
                        Page {page} of {totalPages}
                    </span>
                    <button 
                        className={`category-pg__pagination__button category-pg__pagination__button--${themeClass}`} 
                        disabled={page === totalPages} 
                        onClick={() => setPage(prev => prev + 1)}
                    >
                        Next
                    </button>
                </div>
            </div>
        </UserRoute>
    );
}
