import React from "react";

const BackIcon = ({ themeMode }) => {
  const color = themeMode === "light" ? "black" : "white";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
      className="icon icon-tabler icons-tabler-outline icon-tabler-circle-arrow-left"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path stroke={color}  d="M12 21a9 9 0 1 0 0 -18a9 9 0 0 0 0 18" />
      <path stroke={color}  d="M8 12l4 4" />
      <path stroke={color}  d="M8 12h8" />
      <path stroke={color} d="M12 8l-4 4" />
    </svg>
  );
};

export default BackIcon;
