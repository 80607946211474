
export const GetStartedIcon = (themeMode) => {
    const color = themeMode.themeMode === "light" ? "black" : "white";
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        className="icon icon-tabler icons-tabler-outline icon-tabler-info-square-rounded"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M12 9h.01" />
        <path d="M11 12h1v4h1" />
        <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" />
      </svg>
    );
  };