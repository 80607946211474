import React, { useContext, useEffect, useState } from 'react';
import BlogComponent from '../../Components/BlogComponenet/BlogComponent';
import './BlogPage.scss';
import UserRoute from '../../Routes/Auth';
import ThemeContext from "../../context/ThemeContext";
import { Context } from "../../context/index";
import { apiGet } from "../../utils/apiUtils";
import Header from '../../Components/Header/Header';
import MobileMenuModal from '../../Components/MobileMenuModal/MobileMenuModal';

const BlogPage = () => {
      const {
            state: { user },
      } = useContext(Context);
      const { themeMode } = useContext(ThemeContext);
      const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';
      const [posts, setPosts] = useState([]);
      const [loading, setLoading] = useState(true);
      const [error, setError] = useState(null);
      const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

      // Fetch blog posts from the backend on component mount
      useEffect(() => {
            const fetchPosts = async () => {
                  try {
                        const blogPosts = await apiGet('/blog/');
                        setPosts(blogPosts.data);
                        setLoading(false);
                  } catch (error) {
                        console.error('Error fetching blog posts:', error);
                        setError('Failed to load blog posts.');
                        setLoading(false);
                  }
            };
            fetchPosts();
      }, []);

      if (loading) {
            return (
                  <div className="blog-page">
                        <header className="blog-page__header">
                              <h1 className="blog-page__header__title">Blog</h1>
                        </header>
                        <main className="blog-page__content">
                              <p>Loading...</p>
                        </main>
                  </div>
            );
      }

      if (error) {
            return (
                  <div className="blog-page">
                        <header className="blog-page__header">
                              <h1>Blog</h1>
                        </header>
                        <main className="blog-page__content">
                              <p className="blog-page__error">{error}</p>
                        </main>
                  </div>
            );
      }


      if (!user) {
            return (
                  <div className="blog-page">
                        <header className="blog-page__header">
                        <Header user={user} themeClass={themeClass} isMobileMenuOpen={isMobileMenuOpen} setIsMobileMenuOpen={setIsMobileMenuOpen} />

                              <h1 className="blog-page__header__title">Blog</h1>
                        </header>

                        <main className="blog-page__content">
                              <BlogComponent themeClass={themeClass} blogs={posts} />
                        </main>
                  <MobileMenuModal onClose={() => setIsMobileMenuOpen(false)} isMobileMenuOpen={isMobileMenuOpen} setIsMobileMenuOpen={setIsMobileMenuOpen} user={user} themeClass={themeClass} />

                  </div>
            )
      }

      return (
            <UserRoute>
                  <div className="blog-page">
                        <header className="blog-page__header">
                              <title>Chequeai Blog</title>
                        </header>

                        <main className="blog-page__content">
                              <BlogComponent themeClass={themeClass} blogs={posts} />
                        </main>
                  </div>
            </UserRoute>

      );
};

export default BlogPage;
