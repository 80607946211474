import React from 'react';

const PodcastIcon = ({ themeMode }) => {
  const color = themeMode === 'light' ? 'black' : 'white';
  return (
<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-brand-apple-podcast">
  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <path d="M18.364 18.364a9 9 0 1 0 -12.728 0" />
  <path d="M11.766 22h.468a2 2 0 0 0 1.985 -1.752l.5 -4a2 2 0 0 0 -1.985 -2.248h-1.468a2 2 0 0 0 -1.985 2.248l.5 4a2 2 0 0 0 1.985 1.752z" />
  <path d="M12 9m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
</svg>
  );
};

export default PodcastIcon;
