import { Context } from "../../context/index";
import React, { useContext, useEffect, useState } from 'react';
import UserRoute from '../../Routes/Auth';
import PromoteCard from '../../Components/PromoteCard/PromoteCard';
import "./PromotePage.scss";
import { loadStripe } from "@stripe/stripe-js";
import { useParams } from "react-router-dom";
import axios from 'axios';
import Navigation from '../../Components/Navigation/Navigation';
import PromotionCheckoutModal from "../../Components/PromotionCheckoutModal/PromotionCheckoutModal";
const stripe = await loadStripe(process.env.REACT_APP_PUBLIC_STRIPE_KEY);

export default function PromotePage() {
    const { username } = useParams();
    const { state: { user } } = useContext(Context);
    const [promotions, setPromotions] = useState([]);
    const [promotionCheckoutModalOpen, setPromotionCheckoutModalOpen] = useState(false);
    const [promotionDetails, setPromotionDetails] = useState(null);
    const [purchaseConfirmed, setPurchaseConfirmed] = useState(false);
    const [message, setMessage] = useState("");
    const [taxCalculation, setTaxCalculation] = useState(0); // Initialize as 0
    const [billingDetails, setBillingDetails] = useState({}); // Stores billing details from AddressForm
    const [stripeSeller, setStripeSeller] = useState(null);
    const [loading, setLoading] = useState(true);



    const sendCheckoutRequest = async (promotionId) => {
        try {
            // Option B: If server sends { sessionId: "..." }
            const { data } = await axios.post(`${process.env.REACT_APP_API}/creator/checkout/${username}/`, {
                promotionId: promotionId,
                taxCalculation: taxCalculation,
            });

            // console.log(data)

            if (data.transactionId) {
                setPurchaseConfirmed("confirmed");
                setTimeout(() => {
                    setPromotionCheckoutModalOpen(false); // Close the modal after showing the confirmation
                    setPurchaseConfirmed(false); // Reset the purchase confirmation state
                }, 1250); // Adjust this delay as needed
            } else {
                const { error } = await stripe.redirectToCheckout({ sessionId: data });
                if (error) {
                    console.error("Stripe Checkout Error:", error);
                    console.log(error.response.data.message)
                    setMessage(error.response.data.message)
                    setPurchaseConfirmed("failed")
                }

            }
        } catch (error) {
            console.error("Checkout Error:", error);
            console.log(error.response.data.message)
            setMessage(error.response.data.message)
            setPurchaseConfirmed("failed")

        }
    }

    const handleCheckout = async (promotionId) => {
        setPromotionCheckoutModalOpen(promotionId)
        setPromotionDetails(promotions.find(promotion => promotion._id === promotionId))
    }

    useEffect(() => {
        const fetchPromotions = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API}/user/${username}/promotions`);
                console.log(response.data)
                setPromotions(response.data.promotions);
                setBillingDetails({
                    name: response.data.stripeCustomer?.name,
                    address: response.data.stripeCustomer?.address || {}
                  });
                setStripeSeller(response.data.stripeSeller);
                setLoading(false);
                  
                  
            } catch (error) {
                console.log(error);
                // Optionally, handle the error (e.g., display a notification)
            }
        }
        fetchPromotions();
    }, [username]);

    if (user.username === username) {
        return (
            <UserRoute>
                <div className='promote-pg'>
                    <Navigation heading={"Promote"} add={false} username={user.username} back={true} />
                    <div className="no-promotions">
                        <p className='no-promotions__caption'>You cannot promote your own account</p>
                    </div>
                </div>
            </UserRoute>
        )
    }

    if ( loading || !stripeSeller || Object.keys(stripeSeller).length === 0  || stripeSeller?.id === "" || stripeSeller?.capabilities.transfers === "inactive") {
        return (
            <UserRoute>
                <div className='promote-pg'>
                    <Navigation heading={"Promote"} add={false} username={user.username} back={true} />
                    <div className="no-promotions">
                        <p className='no-promotions__caption'>{username}'s promotions are not <br /> available currently.</p>

                    </div>
                </div>
            </UserRoute>
        );
    }

    return (
        <UserRoute username={user.username}>
            <div className='promote-pg'>
                <Navigation heading={"Promote"} add={false} username={user.username} back={true} />
                {promotions.length > 0 ?
                    promotions.map(promotion => (
                        <PromoteCard
                            name={promotion.name}
                            description={promotion.description}
                            price={promotion.price}
                            images={promotion.portfolio}
                            handleCheckout={() => handleCheckout(promotion._id)}
                            tikTok={promotion && promotion.tiktok}
                            instagram={promotion && promotion.instagram}
                            podcast={promotion && promotion.podcast}
                            youtube={promotion && promotion.youtube}
                            key={promotion._id}
                            contentRights={promotion.contentRights || "Non-Exclusive Rights"}
                            duration={promotion.duration || "3 Months"}
                            reuseAfter={promotion.reuseAfter || "Allowed"}
                            setPromotionCheckoutModalOpen={setPromotionCheckoutModalOpen}
                            currency={promotion.currency}
                        />
                    )) :
                    <div className="no-promotions">
                        <h3 className='no-promotions__title'>No Promotions</h3>
                        <p className='no-promotions__caption'>No promotions available at this time</p>
                    </div>
                }
            </div>
            {promotionCheckoutModalOpen &&
                <PromotionCheckoutModal
                    isOpen={promotionCheckoutModalOpen}
                    setIsOpen={setPromotionCheckoutModalOpen}
                    promotionDetails={promotionDetails}
                    purchaseConfirmed={purchaseConfirmed}
                    setPurchaseConfirmed={setPurchaseConfirmed}
                    sendCheckoutRequest={sendCheckoutRequest}
                    message={message}
                    setMessage={setMessage}
                    allowPreApprovalCheckout={user?.allowPreApprovalCheckout}
                    taxCalculation={taxCalculation}
                    setTaxCalculation={setTaxCalculation}
                    billingDetails={billingDetails}
                    setBillingDetails={setBillingDetails}
                />
            }
        </UserRoute>
    )
}
