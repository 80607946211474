// src/Components/EditProductModal/EditProductModalForm.jsx

import React, { useState, useEffect } from 'react';
import './EditProductModal.scss';
import InputField from '../InputField/InputField';
import Button from '../Button/Button';
import TextArea from '../TextArea/TextArea';
import axios from 'axios';
import { formatLabel } from '../../utils/helperFunctions';

const EditProductModalForm = ({ isOpen, setIsOpen, onClose, onSave }) => {
  // Rename isOpen to product for clarity
  const product = isOpen;

  // Initialize collectionOptions with "No Collection" group
  const [collectionOptions, setCollectionOptions] = useState( [
          {
            label: "No Collection",
            options: [{ label: "None", value: "" }],
          },]
  );

  // Handle input changes for text fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setIsOpen((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  // Handle collection selection change
  const handleCollectionChange = (selectedOption) => {
    // console.log(selectedOption);
    setIsOpen((prevProduct) => ({
      ...prevProduct,
      productGroup: selectedOption ? selectedOption.target.value : '',
    }));
  };

  // Handle creation of a new collection
  const handleCollectionCreate = (inputValue) => {
    const newOption = { label: inputValue, value: inputValue.toLowerCase().replace(/\s+/g, '_') };

    // Find the "Other Collections" group
    const otherGroupIndex = collectionOptions.findIndex(group => group.label === "Other Collections");

    if (otherGroupIndex !== -1) {
      // Add the new option to the "Other Collections" group
      const updatedCollectionOptions = [...collectionOptions];
      updatedCollectionOptions[otherGroupIndex].options.push(newOption);
      setCollectionOptions(updatedCollectionOptions);
    } else {
      // If "Other Collections" group doesn't exist, create it
      setCollectionOptions([
        ...collectionOptions,
        {
          label: "Other Collections",
          options: [newOption],
        },
      ]);
    }

    // Update the selected collection
    setIsOpen((prevProduct) => ({
      ...prevProduct,
      productGroup: newOption.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(product);
    onClose();
  };

  // Fetch existing collections from the server
  useEffect(() => {
    const fetchCollections = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/campaigns/product-collections`);
        // Assuming the API returns an array of collections
        // console.log(response.data);
        const fetchedCollections = response.data.collections.map(collection => ({
          label: formatLabel(collection),
          value: collection,
        }));

        // Update collectionOptions with fetched collections
        setCollectionOptions(prevOptions => {
          // Remove existing "Fetched Collections" if present to avoid duplicates
          const filteredOptions = prevOptions.filter(group => group.label !== "Fetched Collections");

          return [
            ...filteredOptions,
            {
              label: "User Collections",
              options: fetchedCollections,
            },
          ];
        });
      } catch (error) {
        console.error("Failed to fetch collections:", error);
      }
    };

    if (isOpen && collectionOptions.length === 1){
      fetchCollections();
    }
  }, [isOpen, collectionOptions]);

  return (
    <div className={`edit-product-modal ${isOpen ? 'open' : ''}`}>
      <div className="edit-product-modal__overlay" onClick={onClose} />
      <div className="edit-product-modal__content">
        <div className='edit-product-modal__container'>
          <form onSubmit={handleSubmit}>
            <div className='edit-product-modal__body'>
              <InputField
                label="Collections"
                name="collection"
                isSelect={true} // Enable react-select
                isCreatable={true} // Allow creating new collections
                options={collectionOptions} // Pass the grouped collection options
                value={product?.productGroup || ''}
                onChange={handleCollectionChange}
                onCreateOption={handleCollectionCreate} // Handle creating new collections
                placeholder="Select or create a collection"
                required
              />
              <InputField
                label="SKU"
                name="sku"
                type="text"
                value={product?.sku || ''}
                onChange={handleInputChange}
                placeholder="Enter SKU"
                required
              />
              <InputField
                label="Product Name"
                name="name"
                type="text"
                value={product?.name || ''}
                onChange={handleInputChange}
                placeholder="Enter Product Name"
                required
              />
              <InputField
                label="Price"
                name="price"
                type="number"
                value={product?.price || ''}
                onChange={handleInputChange}
                placeholder="Enter Price"
                required
              />
              <InputField
                label="Product Page"
                name="url"
                type="url"
                value={product?.url || ''}
                onChange={handleInputChange}
                placeholder="Enter Product Page URL"
              />
              <TextArea
                label="Description"
                name="description"
                value={product?.description || ''}
                onChange={handleInputChange}
                placeholder="Enter product description"
              />
            </div>
            <div className='edit-product-modal__footer'>
              <Button modifier="button" type="submit">
                Update Product
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditProductModalForm;
