import React, { useState } from 'react';
import moment from 'moment-timezone';
import './AddEventModalForm.scss';
import InputField from '../InputField/InputField';
import Button from '../Button/Button';
import TextArea from '../TextArea/TextArea';

const AddEventModalForm = ({ isOpen, onClose, onSave }) => {
  const now = moment();
  const currentDate = now.format('YYYY-MM-DD');
  const currentTime = now.format('HH:mm');
  const currentTimeZone = moment.tz.guess(); // Automatically get the user's timezone

  // Calculate the default end time as 30 minutes after the current time
  const defaultEndTime = moment(now).add(30, 'minutes').format('HH:mm');

  const [eventDetails, setEventDetails] = useState({
    title: '',
    startDate: currentDate,
    startTime: currentTime,
    endDate: currentDate,
    endTime: defaultEndTime,
    location: '',
    description: '',
    timeZone: currentTimeZone, // Include the timezone in the initial state
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEventDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(eventDetails);
    onClose();
  };

  return (
    isOpen && (
      <div className="add-event-modal">
        <div className="add-event-modal__overlay" onClick={onClose} />
        <div className="add-event-modal__content">
          <div className="add-event-modal__container">
            <div className="add-event-modal__body">
              <InputField
                label="Event Title"
                name="title"
                type="text"
                value={eventDetails.title}
                onChange={handleInputChange}
              />
              <InputField
                label="Start Date"
                name="startDate"
                type="date"
                value={eventDetails.startDate}
                onChange={handleInputChange}
              />
              <InputField
                label="Start Time"
                name="startTime"
                type="time"
                value={eventDetails.startTime}
                onChange={handleInputChange}
              />
              <InputField
                label="End Date"
                name="endDate"
                type="date"
                value={eventDetails.endDate}
                onChange={handleInputChange}
              />
              <InputField
                label="End Time"
                name="endTime"
                type="time"
                value={eventDetails.endTime}
                onChange={handleInputChange}
              />
              <InputField
                label="Location"
                name="location"
                type="text"
                value={eventDetails.location}
                onChange={handleInputChange}
              />
              <TextArea
                label="Description"
                name="description"
                value={eventDetails.description}
                onChange={handleInputChange}
              />
            </div>
            <div className="add-event-modal__footer">
              <Button modifier="button" type="submit" onClick={handleSubmit}>
                Add Event
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default AddEventModalForm;
