
import Card from "../../Components/Card/Card"
import UserLinkCard from '../../Components/UserLinkCard/UserLinkCard'
import "./SettingsPage.scss"
import { Context } from "../../context/index"
import React, { useContext, useEffect, useState } from 'react';
import UserRoute from '../../Routes/Auth';
import Navigation from "../../Components/Navigation/Navigation";
import GetStartedModal from "../../Components/GetStartedModal/GetStartedModal";
import ThemeContext from "../../context/ThemeContext";

export default function SettingsPage() {
  const [username, setUsername] = useState("");
  const { state: { user }, } = useContext(Context);
  const [isGetStartedModalOpen, setGetStartedModalOpen] = useState(false);
  const { themeMode } = useContext(ThemeContext);

  useEffect(() => {
    if (user !== null) {
      setUsername(user.username)
    }

  }, [user]);
  return (
    <UserRoute username={username}>

      <div className='settings-pg'>
        <Navigation heading={"Settings"} username={username} back={true} darkMode={true} navRight={true} />
        <Card className={"card--ptb"}>
          {/* <UserLinkCard title="Account Details" src="/settings/account" />       */}
          <button className="settings-pg__get-started-button" onClick={() => setGetStartedModalOpen(true)}>
            Get Started
          </button>

          {/* <UserLinkCard title="Get Started" src="/" modifier="user-link--hide-td" /> */}
          <UserLinkCard title="Edit Profile" src="/settings/general" />
          <UserLinkCard title="Change Email" src="/settings/email" />
          <UserLinkCard title="Fan Donations" src="/settings/donations" />
          <UserLinkCard title={user?.stripeAccountId === "" ? "Connect Payments" : "Creator Monetization"} src="/settings/payments" />
          {/* <UserLinkCard title="Update Portfolio" src="/settings/update_portfolio" /> */}
          {/* <UserLinkCard title="Manage Promotions" src="/settings/promotions" /> */}


          {/* <UserLinkCard title="Manage Socials" src="/settings/socials" /> */}
          {/* <UserLinkCard title="Integrate Third Party Socials (Coming Soon)" src="/settings/account" /> */}
          <UserLinkCard title="Account Security" src="/settings/password" />
          <UserLinkCard title="App Subscription" src="/settings/subscription" />
          <UserLinkCard title="Teams" src="/settings/teams" />
          {/* <UserLinkCard title="Billing" src="/settings/billing" /> */}


          { user.stripeSeller && <UserLinkCard title="Billing" src={ user.stripeSeller?.type === 'express' ? process.env.REACT_APP_STRIPE_BILLING : "https://dashboard.stripe.com/login" }/> }

          {/* <UserLinkCard title="Terms of Service" src="/termsofservice" /> */}
          {/* <UserLinkCard title="Privacy Policy" src="/privacypolicy" /> */}
          {/* <UserLinkCard title="Support" src="/support" /> */}
          <UserLinkCard title="Logout" src="/logout" modifier="user-link--hide-td" />
        </Card>
        {isGetStartedModalOpen && (
          <GetStartedModal
            isOpen={isGetStartedModalOpen}
            onClose={() => setGetStartedModalOpen(false)}
            themeMode={themeMode}
            profileUrl={user?.username || ""}
          />
        )}
      </div>
    </UserRoute>

  )
}
