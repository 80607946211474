import React from 'react';

const YoutubeIcon = ({ themeMode }) => {
  const color = themeMode === 'light' ? 'black' : 'white';
  return (
<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-brand-youtube">
  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <path d="M2 8a4 4 0 0 1 4 -4h12a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-12a4 4 0 0 1 -4 -4v-8z" />
  <path d="M10 9l5 3l-5 3z" />
</svg>
  );
};

export default YoutubeIcon;
