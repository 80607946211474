// PromotionRights.jsx

import React from 'react';
import DurationIcon from './icons/DurationIcon';
import ReuseIcon from './icons/ReuseIcon';
import RightsIcon from './icons/RightsIcon';
import PropTypes from 'prop-types';
import './PromotionRights.scss'; // Ensure you have the SCSS file updated accordingly

export default function PromotionRights({ contentRights, duration, reuseAfter, themeClass }) {
    // Mapping internal values to user-friendly labels
    const contentRightsMap = {
        'exclusive': 'Exclusive',
        'non_exclusive': 'Non-Exclusive',
        'limited': 'Limited',
    };

    const durationMap = {
        '1_month': '1 Month',
        '3_months': '3 Months',
        '6_months': '6 Months',
        '12_months': '12 Months',
    };

    const reuseAfterMap = {
        'allowed': 'Allowed',
        'not_allowed': 'Not Allowed',
        '1_year': '1 Year After',
    };

    const isMobile = window.innerWidth <= 768; // Detect mobile based on screen width

    // Array of rights to make the code DRY
    const rightsData = [
        {
            icon: <DurationIcon className="promotion-rights__icon" />,
            title: 'Duration Usage',
            value: durationMap[duration],
        },
        {
            icon: <ReuseIcon className="promotion-rights__icon" />,
            title: isMobile ? 'Modify & Reuse' : 'Modify & Reuse',
            value: reuseAfterMap[reuseAfter],
        },
        {
            icon: <RightsIcon className="promotion-rights__icon" />,
            title: 'Content Rights',
            value: contentRightsMap[contentRights],
        },
    ];

    return (
        <div className="promotion-rights">
            {rightsData.map((item, index) => (
                <div className={`promotion-rights__item promotion-rights__item--${themeClass}`} key={index}>
                    {item.icon}
                    <div className={`promotion-rights__content promotion-rights__content--${themeClass}`}>
                        {/* Swapped the order: Value first with h4, Title second with p */}
                        <h4 className="promotion-rights__value">{item.value}</h4>
                        <p className="promotion-rights__title">{item.title}</p>
                    </div>
                </div>
            ))}
        </div>
    );
}

PromotionRights.propTypes = {
    contentRights: PropTypes.string.isRequired,
    duration: PropTypes.string.isRequired,
    reuseAfter: PropTypes.string.isRequired,
};
