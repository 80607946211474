// src/pages/InstagramErrorPage.js
import React, { useEffect } from 'react';
import UserRoute from '../../Routes/Auth';
import './InstagramErrorPage.scss'

const InstagramErrorPage = () => {

  useEffect(() => {
    const logErrorToBackend = async () => {
      try {
        const response = await fetch('/api/instagram/error-log', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ message: 'Instagram linking error occurred' }),
        });

        if (!response.ok) {
          console.error("Failed to log error to backend");
        }
      } catch (error) {
        console.error("Error logging to backend:", error);
      }
    };

    logErrorToBackend();
  }, []);

  return (
    <UserRoute>
      <div className="instagram-error">
        <h1 className="instagram-error__title">Error</h1>
        <p className="instagram-error__message">
          There was an error linking your Instagram account. Please try again.
        </p>
      </div>
    </UserRoute>
  );
};

export default InstagramErrorPage;
