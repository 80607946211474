// src/pages/TikTokCallback.js
import React, { useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import UserRoute from "../../Routes/Auth";
import { apiGet } from "../../utils/apiUtils";
import './TikTokCallbackPage.scss';

const TikTokCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const fetchToken = useCallback(
    async (code, state) => {
      try {
        const response = await apiGet(`/auth/tiktok/callback/${code}/${state}`);
        // console.log(response);
        if (response) {
          navigate(`/settings/socials/tiktok/callback/success?token=${response.token}`);
        } else {
          navigate("/settings/socials/tiktok/callback/error");
        }
      } catch (error) {
        console.error("Token fetch error:", error);
        navigate("/settings/socials/tiktok/callback/error");
      }
    },
    [navigate]
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");
    const state = searchParams.get("state");

    if (code && state) {
      fetchToken(code, state);
    } else {
      navigate("/settings/socials/tiktok/callback/error");
    }
  }, [location.search, navigate, fetchToken]);

  return (
    <UserRoute>
      <div className="tiktok-callback">Linking Account...</div>
    </UserRoute>
  );
};

export default TikTokCallback;
