import React from 'react';
import BackIcon from '../Navigation/icons/BackIcon';
import NextIcon from '../Navigation/icons/NextIcon';

export default function DaysFilter({ themeClass, themeMode, handleBackDateChange, handleNextDateChange, currentMode }) {
    return (
        <div className="donations__tf">
            <button
                className="navigation__back-button donations-tf--button"
                onClick={handleBackDateChange}
            >
                <BackIcon themeMode={themeMode} />
            </button>
            <h2 className={`donations-tf__title donations-tf__title--${themeClass}`}>
                {currentMode}
            </h2>
            <button
                className="navigation__back-button donations-tf--button"
                onClick={handleNextDateChange}
            >
                <NextIcon themeMode={themeMode} />
            </button>
        </div>
    );
}
