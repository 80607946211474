import React, { useEffect, useContext, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import './CancelStripeCampaignPage.scss';
import axios from 'axios';
import ThemeContext from '../../context/ThemeContext';
// import { Context } from '../../context';
import UserRoute from '../../Routes/Auth';

function CancelStripeCampaignPage() {
  const { sessionId } = useParams(); // Get the session ID from the URL
  const { themeMode } = useContext(ThemeContext);
  // const {
  //   state: { user },
  // } = useContext(Context);
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

  // Memoize the cancelCampaignPayment function to avoid re-creating it on every render
  const cancelCampaignPayment = useCallback(async () => {
    try {
      // Send the cancellation r
      
      const { data } = await axios.get(`/failure/campaigns/${sessionId}`);

      if (!data.success) {
        console.error('Failed to cancel campaign payment');
      }
    } catch (error) {
      console.error('Error cancelling campaign payment:', error);
    }
  }, [sessionId]);

  // Trigger the cancellation when the sessionId changes
  useEffect(() => {
    if (sessionId) {
      cancelCampaignPayment();
    }
  }, [sessionId, cancelCampaignPayment]);

//   if (!user) {
//     return (
//       <div className="cancel-stripe-campaign-page">
//         <h1 className="cancel-stripe-campaign-page__title">
//           Campaign Payment Canceled
//         </h1>
//         <p
//           className={`cancel-stripe-campaign-page__text cancel-stripe-campaign-page__text--${themeClass}`}
//         >
//           It looks like the campaign payment was not completed. Feel free to
//           explore more on our platform or try again.
//         </p>
//         <div className="cancel-stripe-campaign-page__link">
//           <Link
//             to="/"
//             className={`cancel-stripe-campaign-page__link--text cancel-stripe-campaign-page__link--text--${themeClass}`}
//           >
//             Home
//           </Link>
//         </div>
//       </div>
//     );
//   }

  return (
    <UserRoute>
      <div className="cancel-stripe-campaign-page">
        <h1 className="cancel-stripe-campaign-page__title">
          Promotion Canceled
        </h1>
        <p
          className={`cancel-stripe-campaign-page__text cancel-stripe-campaign-page__text--${themeClass}`}
        >
          Your promotion payment was not completed. You can try again, or keep exploring
          more on Chequeai.
        </p>
        <div className="cancel-stripe-campaign-page__link">
          <Link
            to="/home"
            className={`cancel-stripe-campaign-page__link--text cancel-stripe-campaign-page__link--text--${themeClass}`}
          >
            Home
          </Link>
        </div>
      </div>
    </UserRoute>
  );
}

export default CancelStripeCampaignPage;
