// CpgSupportModal.jsx
import React, { useState, useContext } from 'react';
import Select from 'react-select';
import './CampaignSupportModal.scss';
import { apiPost } from '../../utils/apiUtils';
import ThemeContext from '../../context/ThemeContext';
import { selectDarkMode, selectLightMode } from '../../utils/stylesUtils';

const CampaignSupportModal = ({ isOpen, onClose, campaignId }) => {
      const [subject, setSubject] = useState('');
      const [supportCategory, setSupportCategory] = useState(null);
      const [message, setMessage] = useState('');
      const [isSubmitting, setIsSubmitting] = useState(false);

      const { themeMode } = useContext(ThemeContext);
      const themeClass = themeMode === 'light' ? 'light-theme' : '';

      const supportOptions = [
            { value: 'general-inquiry', label: 'General Inquiry' },
            { value: 'technical-support', label: 'Technical Support' },
            { value: 'billing-payment', label: 'Billing & Payments' },
            { value: 'campaign-help', label: 'Campaign Assistance' },
            { value: 'creator-support', label: 'Creator Support' },
            { value: 'brand-support', label: 'Brand Support' },
            { value: 'report-issue', label: 'Report an Issue' },
          ];
          
      const handleSubmit = async (e) => {
            e.preventDefault();
            setIsSubmitting(true);

            try {
                  const endpoint = '/campaign/support/';

                  const response = await apiPost(endpoint, {
                        campaignId,
                        subject,
                        supportCategory: supportCategory?.value,
                        message
                  });

                  console.log('Support request submitted successfully:', response);

                  // Reset form and close modal after successful submission
                  setSubject('');
                  setSupportCategory(null);
                  setMessage('');
                  onClose();
            } catch (error) {
                  console.error('Error submitting support request:', error);
            } finally {
                  setIsSubmitting(false);
            }
      };

      if (!isOpen) return null;

      return (
            <div className={`cpg-support-modal__overlay cpg-support-modal__overlay--${themeClass}`} onClick={onClose}>
                  <div className={`cpg-support-modal cpg-support-modal--${themeClass}`} onClick={(e) => e.stopPropagation()}>
                        <h2 className={`cpg-support-modal__title cpg-support-modal__title--${themeClass}`}>Campaign Support</h2>
                        <form onSubmit={handleSubmit} className="cpg-support-modal__form">
                              <div className="cpg-support-modal__select-category">
                                    <Select
                                          id="supportCategory"
                                          value={supportCategory}
                                          onChange={setSupportCategory}
                                          options={supportOptions}
                                          placeholder="Select a category..."
                                          styles={themeMode === 'light' ? selectLightMode : selectDarkMode}
                                    />
                              </div>
                              <input
                                    type="text"
                                    className={`cpg-support-modal__input-field cpg-support-modal__input-field--${themeClass}`}
                                    placeholder="Subject"
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)}
                                    required
                              />

                              <textarea
                                    className={`cpg-support-modal__input-textarea cpg-support-modal__input-textarea--${themeClass}`}
                                    placeholder="Describe your issue or question..."
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    required
                              />
                              <div className="cpg-support-modal__actions">
                                    <button type="button" className={`cpg-support-modal__close-button cpg-support-modal__close-button--${themeClass}`} onClick={onClose}>
                                          Close
                                    </button>
                                    <button type="submit" className={`cpg-support-modal__submit-button cpg-support-modal__submit-button--${themeClass}`} disabled={isSubmitting}>
                                          {isSubmitting ? 'Submitting...' : 'Submit'}
                                    </button>
                              </div>
                        </form>
                  </div>
            </div>
      );
};

export default CampaignSupportModal;
