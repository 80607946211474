import React, { useEffect, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import './TeamPaymentSuccessPage.scss';
import UserRoute from '../../Routes/Auth';
import ThemeContext from '../../context/ThemeContext';
import { Context } from '../../context';
import { apiGet } from '../../utils/apiUtils';

function TeamPaymentSuccessPage() {
    const { teamId } = useParams(); // Get the team ID from the URL
    const navigate = useNavigate();
    const { themeMode } = useContext(ThemeContext);
    const { state: { user } } = useContext(Context);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

    useEffect(() => {
        const successRequest = async () => {
            try {
                // Send request to confirm team member payment was successful
                const data = await apiGet(`/teams/${teamId}/success/`);
                console.log(data);
                if (data.success) {
                    navigate(`/settings/teams/`);
                }
            } catch (err) {
                console.error('Error confirming team member addition:', err);
            }
        };

        if (user) {
            successRequest();
        }

    }, [teamId, navigate, user]);

    return (
        <UserRoute>
            <div className='team-payment-success-pg'>
                <h1 className='team-payment-success-pg__title'>Team Member Added Successfully</h1>
                <p className='team-payment-success-pg__text'>
                    Your new team member has been added successfully. You can now manage your team members and assign tasks.
                </p>
                <p className='team-payment-success-pg__link'>
                    <Link to="/settings/teams/" className={`team-payment-success-pg__link--text team-payment-success-pg__link--text--${themeClass}`}>Manage Team</Link>
                </p>
            </div>
        </UserRoute>
    );
}

export default TeamPaymentSuccessPage;
