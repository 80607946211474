import React, { useEffect, useState, useCallback, useContext } from "react";
import Button from "../../Components/Button/Button";
import InputField from "../../Components/InputField/InputField";
import "./SettingsGeneralPage.scss";
import UserRoute from "../../Routes/Auth";
import displayPicture from "../../assets/defaults/displayPicture.png";
import Resizer from "react-image-file-resizer";
import Toggle from "../../Components/Toggle/Toggle";
import Navigation from "../../Components/Navigation/Navigation";
import PhoneNumberInput from "../../Components/PhoneNumberInput/PhoneNumberInput";
import axios from "axios";
import { Context } from "../../context/index";
import CurrentLocationIcon from "./CurrentLocationIcon";
import UserSettings from "../../Components/UserSettings/UserSettings";
import { Link } from "react-router-dom";
// import Select from 'react-select';
// import { Context } from "../../context/index";
// import ThemeContext from '../../context/ThemeContext';
// import { selectLightMode, selectDarkMode } from "../../utils/stylesUtils";

export default function SettingsGeneralPage() {
  const [userLogged, setUserLogged] = useState("");
  // const { themeMode } = useContext(ThemeContext);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    username: "",
    location: "",
    bio: "",
    website: "",
    country: "",
    ethnicity: "",
  });
  const { state } = useContext(Context);
  const { user } = state;
  const [image, setImage] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPrivate, setIsPrivate] = useState(false);
  const [error, setError] = useState(null);



  const fetchProfileData = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/user/edit-profile/general-profile`);
      if (response && response.data && response.data.user) {
        // console.log(response.data.user);
        const user = response.data.user;
        setFormData({
          firstName: user.firstName || '',
          lastName: user.lastName || '',
          username: user.username || '',
          location: user.location || '',
          bio: user.bio || '',
          website: user.website || '',
          country: user.country || '',
          phoneNumber: user.phoneNumber || '',
          ethnicity: user.ethnicity || '',
          companyName: user?.userType !== "creator" ? user.name : ""

        });
        setIsPrivate(user.isPrivate);
        setPhoneNumber(user.phoneNumber || '');
        setImagePreview(user.picture === "/avatar.png" ? displayPicture : user.picture);
        setUserLogged(user.username);
      }
    } catch (err) {
      console.error("Failed to fetch profile data", err);
    }
  }, []);

  useEffect(() => {
    fetchProfileData();
  }, [fetchProfileData]);

  const { firstName, lastName, username, bio, website, country, ethnicity, companyName, location } = formData;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateImg = (e) => {
    const file = e.target.files[0];
    if (file.size > 2048576) {
      console.error("Max file size 1mb", { position: "top-center" });
    } else {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleImage = async (e) => {
    e.preventDefault();
    // console.log("image", image);
    if (image) {
      Resizer.imageFileResizer(image, 500, 500, "PNG", 100, 0, async (uri) => {
        try {
          let { data } = await axios.put(
            `${process.env.REACT_APP_API}/user/edit-profile/image`,
            {
              image: uri,
            }
          );
          console.log("IMAGE UPLOADED", data);
          setImage(data);
          console.log("Profile Picture Updated");
          window.location.reload();
        } catch (err) {
          console.log("Profile Picture failed to upload. Try later.", err);
        }
      });
    }
  };

  const changePrivate = async (newPrivateValue) => {
    // console.log("Private Account: ", newPrivateValue);
    try {
      let { data } = await axios.put(
        `${process.env.REACT_APP_API}/user/edit-profile/private`,
        {
          private: newPrivateValue,
        }
      );
      console.log("Account Private", data);
      setIsPrivate(newPrivateValue);
    } catch (err) {
      console.log("Private failed to change. Try later.", err);
    }
  };

  /**
   * Reverse geocodes latitude and longitude to retrieve city and country using GeoPlugin.
   * @param {number} lat - Latitude
   * @param {number} lng - Longitude
   * @returns {Object} - An object containing city and country
   */
  const reverseGeocode = async (lat, lng) => {
    try {
      const response = await axios.get('/reverse-geocode', {
        params: { lat, long: lng },
      });

      // console.log(response.data)
      const country = response.data.country;
      const city = response.data.city;

      return { city, country };
    } catch (error) {
      console.error('Error retrieving location data:', error);
      throw new Error('Error retrieving location data');
    }
  };

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem('token'); // Ensure token retrieval is correct
      const { data } = await axios.put(
        `${process.env.REACT_APP_API}/user/edit-profile/general-profile`,
        {
          username,
          location,
          bio,
          website,
          phoneNumber,
          ethnicity,
          companyName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Profile Updated: ", data);
      setImage(null);
      // Update formData with the response data from the server
      setFormData({
        ...formData,
        username: data.username || username,
        location: data.location || location,
        bio: data.bio || bio,
        website: data.website || website,
        phoneNumber: data.phoneNumber || phoneNumber,
        ethnicity: data.ethnicity || ethnicity,
        companyName: data.companyName || companyName,
      });

    } catch (err) {
      console.log("Profile failed to update. Try later.", err);
    }
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          try {
            const { city, country } = await reverseGeocode(latitude, longitude);
            setFormData({ ...formData, location: `${city}, ${country}` }); // Update formData location
          } catch (error) {
            setError('Unable to retrieve location data');
          }
        },
        (error) => {
          setError('Unable to retrieve your location');
        }
      );
    } else {
      setError('Geolocation is not supported by your browser');
    }
  };

  return (
    <UserRoute username={userLogged}>
      <div className="settings_general-pg">
        <Navigation heading={"Edit Profile"} back={true} />
        <UserSettings className="card--padding card--settings">
          <div className="settings_general-pg__profile">
            <div className="settings_general-pg__profile-img">
              <label htmlFor="file">
                <img
                  className="settings_general-pg__img"
                  src={imagePreview || displayPicture}
                  alt="profile"
                />
              </label>
              <input
                type="file"
                id="file"
                hidden
                accept="image/png, image/jpeg"
                onChange={validateImg}
              />
            </div>
            {imagePreview && image && (
              <div className="settings_general-pg__profile__upload">
                <Button modifier={"button button__update--avatar"} onClick={handleImage}>
                  <span>Change</span>
                </Button>
              </div>
            )}
          </div>

          {/* {user?.userType === "creator" && (
            <div className="settings_general-pg__select-container">
              <label htmlFor="ethnicity" className="settings_general-pg__ethic-label">
                Ethnicity
              </label>
              <div className="settings_general-pg__select-div">
                <Select
                  id="ethnicity"
                  name="ethnicity"
                  value={ethnicityOptions.find((option) => option.value === ethnicity)}
                  onChange={(selectedOption) => {
                    setFormData({ ...formData, ethnicity: selectedOption ? selectedOption.value : '' });
                  }}
                  options={ethnicityOptions}
                  styles={themeMode === 'light' ? selectLightMode : selectDarkMode}
                />
              </div>
            </div>
          )} */}

          <Link to="/settings/general/advanced" className="settings_general-pg__advanced-link">
              <span className="settings_general-pg__advanced-link__text">Advanced Settings</span>
          </Link>

          {user?.userType === "brand" ? (<InputField
            label="Company Name"
            name="companyName"
            type="text"
            value={companyName}
            onChange={handleInputChange}
          />) : (
            <>
              <InputField
                label="First Name"
                name="firstName"
                type="text"
                value={firstName}
                onChange={handleInputChange}
                disabled={firstName !== ""}

              />

              <InputField
                label="Last Name"
                name="lastName"
                type="text"
                value={lastName}
                onChange={handleInputChange}
                disabled={lastName !== ""}

              />
            </>
          )}

          <InputField
            label="Username"
            name="username"
            type="text"
            value={username}
            onChange={handleInputChange}
          />

          <InputField label="Location" name="location" modifier={"icon"}
            type="text"
            value={location}
            onChange={handleInputChange}>
            <CurrentLocationIcon handleClick={getLocation} />
          </InputField>
          <InputField
            label="Bio"
            name="bio"
            type="text"
            value={bio}
            onChange={handleInputChange}
          />
          <InputField
            label="Website"
            name="website"
            type="text"
            value={website}
            onChange={handleInputChange}
          />

          <div className="settings_general-pg__last-row-container">
            <div className="settings_general-pg__phoneNumber-container">
              <label className="settings_general-pg__phoneNumber-label" htmlFor="phoneNumber">
                Phone Number
              </label>
              <PhoneNumberInput
                label="Phone Number"
                name="phoneNumber"
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                setIsPhoneNumberValid={setIsPhoneNumberValid}
                isValid={isPhoneNumberValid}
                modifier="settings_general-pg__phoneNumber-container"
                themeClass="light-theme"
              />
            </div>
            <Toggle
              label="Private Account"
              onChange={changePrivate}
              value={isPrivate}
            />
          </div>

          <InputField
            label="Country"
            name="country"
            type="text"
            value={country}
            onChange={handleInputChange}
            disabled={true}
          />
          {error && <p className="error-message">{error}</p>}

          <Button modifier="update button" onClick={handleSubmit}>
            <span style={{ fontWeight: "700" }}>Change</span>
          </Button>
        </UserSettings>
      </div>
    </UserRoute>
  );
}
