// src/components/Carousel/Carousel.jsx

import React, { useState } from "react";
import "./Carousel.scss";
import PlatformPlaceholderCard from "../../Components/PlatformPlaceholderCard/PlatformPlaceholderCard";
import AnalyticsCard from "../../Components/AnalyticsCard/AnalyticsCard"; // Ensure correct import path
import PropTypes from "prop-types";

export default function Carousel({
  campaign,
  currentIndex,
  setAnalyticsModal,
  themeClass,
  analyticsData,
}) {
  const postIds = campaign?.postIds || {};
  // console.log(analyticsData)

  // Convert postIds object to an array of platforms with their IDs
  const platforms = [
    { platform: "instagram", ids: postIds.instagram || [] },
    { platform: "spotifyPodcast", ids: postIds.spotifyPodcast || [] },
    { platform: "youtube", ids: postIds.youtube || [] },
    { platform: "tiktok", ids: postIds.tiktok || [] },
    { platform: "spotifyMusic", ids: postIds.spotifyMusic || [] },
    // Add more platforms here if needed
  ];

  // Mapping of platforms to button labels
  const platformButtonLabels = {
    instagram: "Post",
    tiktok: "Post",
    spotifyPodcast: "Episode",
    youtube: "Video",
    spotifyMusic: "Song",
    // Add more platforms and their labels if needed
  };

  // Initialize selectedPosts state to track the selected post for each platform
  const [selectedPosts, setSelectedPosts] = useState(() => {
    const initialSelected = {};
    platforms.forEach((platform) => {
      initialSelected[platform.platform] = 0; // Default to the first post
    });
    return initialSelected;
  });

  return (
    <div className="carousel-campaign">
      <div className="carousel-wrapper">
        {platforms.map((platformObj, index) => (
          <div
            key={index}
            className={`carousel-item ${index === currentIndex ? "active" : ""
              }`}
            aria-hidden={index !== currentIndex}
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {/* Placeholder card rendering when there are no post IDs */}
            {platformObj.ids.length === 0 && (
              <PlatformPlaceholderCard
                themeClass={themeClass}
                platform={platformObj.platform || "default"}
                setAnalyticsModal={setAnalyticsModal}
              />
            )}


            {/* Display the iframe for the selected post */}
            {(() => {
              const selectedPostIndex =
                selectedPosts[platformObj.platform] || 0;
              const postId = platformObj.ids[selectedPostIndex];
              const platform = platformObj.platform;

              if (postId && platform === "instagram") {
                return (
                  <iframe
                    title="Instagram Post"
                    src={`https://www.instagram.com/p/${postId}/embed`}
                    width="400"
                    height="516"
                    frameBorder="0"
                    scrolling="yes"
                    allowtransparency="true"
                    className={`iframe-container ${index === currentIndex
                      ? "iframe-container--active"
                      : ""
                      }`}
                  ></iframe>
                );
              } else if (postId && platform === "youtube") {
                return (
                  <iframe
                    title="YouTube Video"
                    width="430"
                    height="300"
                    src={`https://www.youtube.com/embed/${postId}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className={`iframe-container ${index === currentIndex
                      ? "iframe-container--active"
                      : ""
                      }`}
                  ></iframe>
                );
              } else if (postId && platform === "spotifyPodcast") {
                return (
                  <iframe
                    title="Spotify Podcast"
                    src={`https://open.spotify.com/embed-podcast/episode/${postId}`}
                    width="400"
                    height="232"
                    frameBorder="0"
                    allowtransparency="true"
                    allow="encrypted-media"
                    className={`iframe-container ${index === currentIndex
                      ? "iframe-container--active"
                      : ""
                      }`}
                  ></iframe>
                );
              } else if (postId && platform === "tiktok") {
                return (
                  <iframe
                    title="TikTok Video"
                    src={`https://www.tiktok.com/embed/${postId}`}
                    width="325"
                    height="576"
                    frameBorder="0"
                    scrolling="yes"
                    allow="encrypted-media"
                    allowtransparency="true"
                    className="iframe-container"
                  ></iframe>


                );
              } else if (postId && platform === "spotifyMusic") {
                return (
                  <iframe
                    title="Spotify Music"
                    src={`https://open.spotify.com/embed/track/${postId}`}
                    width="400"
                    height="232"
                    frameBorder="0"
                    allowTransparency="true"
                    allow="encrypted-media"
                    className={`iframe-container ${index === currentIndex
                      ? "iframe-container--active"
                      : ""
                      }`}
                  ></iframe>
                );
              } else {
                return null;
              }
            })()}


            {/* Display the iframe and post selector if there are post IDs */}
            {platformObj.ids.length > 0 && (
              <>
                {/* Analytics Card Placement: Above Post Selector */}
                {(() => {
                  // Get the analytics data for the current platform and selected post
                  const platformAnalytics = analyticsData ? analyticsData[platformObj.platform] : null;

                  const selectedPostIndex =
                    selectedPosts[platformObj.platform] || 0;
                  const postId = platformObj.ids[selectedPostIndex];

                  const currentPostAnalytics =
                    platformAnalytics &&
                    platformAnalytics.find(
                      (analytic) => analytic.postId === postId
                    );
                  console.log(currentPostAnalytics)
                  return currentPostAnalytics ? (
                    <AnalyticsCard
                      views={currentPostAnalytics.views}
                      likes={currentPostAnalytics.likes}
                      comments={currentPostAnalytics.comments}
                    />
                  ) : null;
                })()}

                {/* If there are multiple post IDs, display buttons to switch between them */}
                {platformObj.ids.length > 1 && (
                  <div className="post-selector">
                    {platformObj.ids.map((id, idx) => {
                      // Get the button label based on the platform
                      const label =
                        platformButtonLabels[platformObj.platform] || "Post";
                      return (
                        <button
                          key={idx}
                          className={`${selectedPosts[platformObj.platform] === idx
                            ? "post-selector-button post-selector-button--active"
                            : "post-selector-button"
                            }`}
                          onClick={() =>
                            setSelectedPosts((prev) => ({
                              ...prev,
                              [platformObj.platform]: idx,
                            }))
                          }
                        >
                          {label} {idx + 1}
                        </button>
                      );
                    })}
                  </div>
                )}
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

// Define PropTypes for better type checking
Carousel.propTypes = {
  campaign: PropTypes.object.isRequired,
  currentIndex: PropTypes.number.isRequired,
  setAnalyticsModal: PropTypes.func.isRequired,
  themeClass: PropTypes.string.isRequired,
  analyticsData: PropTypes.object, // Can be null initially
};
