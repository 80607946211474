import React from 'react';

const DragIcon = ({ themeMode }) => {
    const strokeColor = themeMode === 'light' ? 'black' : 'darkgrey';

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke={strokeColor} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="drag-icon">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M4 3h16" />
            <path d="M4 9h16" />
            <path d="M4 15h16" />
            <path d="M4 21h16" />
        </svg>
    );
};

export default DragIcon;
