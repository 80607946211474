import React, { useContext } from 'react';
import './EditPostModelForm.scss';
import InputField from '../InputField/InputField';
import Button from '../Button/Button';
import TextArea from '../TextArea/TextArea';
import ThemeContext from '../../context/ThemeContext';

const EditPostModelForm = ({ isOpen, setIsOpen, onClose, onSave }) => {
  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setIsOpen((prevPost) => ({
      ...prevPost,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(isOpen.key, isOpen);
    onClose();
  };

  return (
    <div className={`edit-post-modal`}>
      <div className="edit-post-modal__overlay" onClick={onClose} />
      <div className={`edit-post-modal__content edit-post-modal__content--${themeClass}`}>
        <div className='edit-post-modal__container'>
          <div className='edit-post-modal__body'>
            <InputField
              label="Title"
              name="title"
              type="text"
              value={isOpen?.title}
              onChange={handleInputChange}
            />
            <InputField
              label="Caption"
              name="caption"
              type="text"
              value={isOpen?.caption}
              onChange={handleInputChange}
            />
            <InputField
              label="Location"
              name="location"
              type="text"
              value={isOpen?.location}
              onChange={handleInputChange}
            />
            <TextArea
              label="Products"
              name="products"
              value={isOpen?.products}
              onChange={handleInputChange}
            />
          </div>
          <div className='edit-post-modal__footer'>
            <Button modifier="button button--transparnet" type="submit" onClick={handleSubmit}>
              <p>Update Post</p>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPostModelForm;
