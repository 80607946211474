import React from "react";
import "./EditCampaignTeamModel.scss";
import InputField from "../InputField/InputField";
import Button from "../Button/Button";
import TextArea from "../TextArea/TextArea";
import Instagram from "../../assets/icons/instagram.png";
import Chequeai from "../../assets/icons/chequai-logo.png";


const EditProductModelForm = ({ isOpen, setIsOpen, onClose, onSave }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setIsOpen((prevPost) => ({
      ...prevPost,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(isOpen);
    onClose();
  };

  return (
    <div className={`edit-post-modal`}>
      <div className="edit-post-modal__overlay" onClick={onClose} />
      <div className="edit-post-modal__content">
        <div className="edit-post-modal__container">
          <div className="edit-post-modal__body">
            <InputField
              label="Name"
              name="name"
              type="text"
              value={isOpen?.name}
              onChange={handleInputChange}
            />
            <InputField
              label="Prod. Title"
              name="jobTitle"
              type="text"
              value={isOpen?.jobTitle}
              onChange={handleInputChange}
            />
            <InputField
              label="Link"
              name="link"
              type="text"
              value={isOpen?.link}
              onChange={handleInputChange}
            />
            <InputField
              label="Email"
              name="email"
              type="text"
              value={isOpen?.email}
              onChange={handleInputChange}
            />
            <div style={{ display: "flex", gap: "0.33rem", flexDirection: 'row' }}>
              <InputField
                label="Start Date"
                name="startDate"
                type="date"
                value={isOpen?.startDate}
                onChange={handleInputChange}
              />
              <InputField
                label="End Date"
                name="endDate"
                type="date"
                value={isOpen?.endDate}
                onChange={handleInputChange}
                modifier="centre"
              />
            </div>
            <div style={{ display: "flex", gap: "0.33rem", flexDirection: 'row' }}>
              <InputField
                icon={Instagram}
                name="instagram"
                type="text"
                value={isOpen?.instagram}
                onChange={handleInputChange}
              />
              <InputField
                icon={Chequeai}
                name="chequeai"
                type="text"
                value={isOpen?.chequeai}
                onChange={handleInputChange}
                modifier="centre"
              />
            </div>

            <TextArea
              label="Description"
              name="description"
              value={isOpen?.description}
              onChange={handleInputChange}
            />
          </div>
          <div className="edit-post-modal__footer">
            <Button modifier="button" type="submit" onClick={handleSubmit}>
              Update Info
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProductModelForm;
