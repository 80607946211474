// ReportUserModal.jsx
import React, { useState, useContext } from 'react';
import Select from 'react-select';
import './ReportUserModal.scss';
import { apiPost } from '../../utils/apiUtils';  
import ThemeContext from '../../context/ThemeContext';  
import { selectDarkMode, selectLightMode } from '../../utils/stylesUtils';

const ReportUserModal = ({ isOpen, onClose, userId }) => {
  const [reportedUser, setReportedUser] = useState(userId);
  const [reportReason, setReportReason] = useState(null);
  const [additionalDetails, setAdditionalDetails] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { themeMode } = useContext(ThemeContext); 
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';  

  const reportReasons = [
    { value: 'spam', label: 'Spam' },
    { value: 'harassment', label: 'Harassment' },
    { value: 'inappropriate-content', label: 'Inappropriate Content' },
    { value: 'fake-account', label: 'Fake Account' },
    { value: 'other', label: 'Other' }
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const endpoint = '/user/report/';
      
      const response = await apiPost(endpoint, { 
        reportedUser, 
        reportReason: reportReason?.value,
        additionalDetails
      });

      console.log('Report submitted successfully:', response);

      // Reset form and close modal after successful submission
      setReportedUser('');
      setReportReason(null);
      setAdditionalDetails('');
      onClose();
    } catch (error) {
      console.error('Error submitting report:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className={`rpt-modal__overlay rpt-modal__overlay--${themeClass}`} onClick={onClose}>
      <div className={`rpt-modal rpt-modal--${themeClass}`} onClick={(e) => e.stopPropagation()}>
        <h2 className={`rpt-modal__title rpt-modal__title--${themeClass}`}>Report User</h2>
        <form onSubmit={handleSubmit} className="rpt-modal__form">
          <div className="rpt-modal__select-reason">
            <Select
              id="reportReason"
              value={reportReason}
              onChange={setReportReason}
              options={reportReasons}
              placeholder="Select a reason..."
              styles={themeMode === 'light' ? selectLightMode : selectDarkMode}
            />
          </div>
          <textarea
            className={`rpt-modal__input-textarea rpt-modal__input-textarea--${themeClass}`}
            placeholder="Additional details (optional)"
            value={additionalDetails}
            onChange={(e) => setAdditionalDetails(e.target.value)}
          />
          <div className="rpt-modal__actions">
            <button type="button" className={`rpt-modal__close-button rpt-modal__close-button--${themeClass}`} onClick={onClose}>
              Close
            </button>
            <button type="submit" className={`rpt-modal__submit-button rpt-modal__submit-button--${themeClass}`} disabled={isSubmitting}>
              {isSubmitting ? 'Sending...' : 'Send'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ReportUserModal;
