
import React from 'react';
import { Link } from 'react-router-dom';
import Card from '../../Components/Card/Card';
import './DonationsCard.scss';

export default function DonationsCard({ totalDonations, totalAmount, newSupporters, currency }) {
  const formatMoney = (amount) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(amount);
  };

  return (
    <Link className='donations-card' to="/donations">
      <Card>
        <div className='donations-card__content'>
        
          <div className='donations-card__content__info'>
            <p className='donations-card__content__info-label'>Total Amount</p>
            <h3 className='donations-card__content__info-value'>{formatMoney(totalAmount)}</h3>
          </div>
          <div className='donations-card__content__info'>
            <p className='donations-card__content__info-label'>Total Donations</p>
            <h3 className='donations-card__content__info-value'>{totalDonations}</h3>
          </div>
          <div className='donations-card__content__info'>
            <p className='donations-card__content__info-label'>New Supporters</p>
            <h3 className='donations-card__content__info-value'>{newSupporters}</h3>
          </div>
        </div>
      </Card>
    </Link>
  );
}
