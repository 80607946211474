import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { Context } from "../../context/index";
import UserRoute from '../../Routes/Auth';
import "./ManagePromotionsPage.scss";
import PromoteCard from '../../Components/PromoteCard/PromoteCard';
import Navigation from '../../Components/Navigation/Navigation';
import OptionsModalBox from '../../Components/OptionsModalBox/OptionsModalBox';
import { useNavigate } from 'react-router-dom';
import ThemeContext from '../../context/ThemeContext';
import StripeConnectCard from '../../Components/StripeConnectCard/StripeConnectCard';
import MessageModal from '../../Components/MessageModal/MessageModal';

export default function ManagePromotionsPage() {
  const { state } = useContext(Context);
  const { user } = state;
  const [promotions, setPromotions] = useState([]);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const navigate = useNavigate();
  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === "light" ? "light-theme" : "";
  const [serverResponse, setServerResponse] = useState("");

  const fetchPromotions = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/user/edit-profile/promotion`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setPromotions(data.promotions);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPromotions();
  }, [user]);

  const handleEditPromotion = (id) => {
    navigate("/settings/promotions/edit/" + id);
  };

  const handleDeletePromotion = async (promotionId) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API}/settings/promotions/${promotionId}`);
      setServerResponse(response.data.message);
      setIsMessageModalOpen(true); // Open the message modal
      setPromotions(promotions.filter(promotion => promotion._id !== promotionId));
    } catch (error) {
      setServerResponse("Failed to delete promotion.");
      setIsMessageModalOpen(true); // Open the message modal on error
      console.log(error);
    }
  };

  useEffect(() => {
    if (isMessageModalOpen) {
      const timer = setTimeout(() => setIsMessageModalOpen(false), 2000);
      return () => clearTimeout(timer); // Cleanup the timer on unmount or before re-running
    }
  }, [isMessageModalOpen]);

  if (user?.stripeAccountId === "" || Object.keys(user?.stripeSeller).length === 0 || user?.stripeSeller?.capabilities.transfers === "inactive") {
    return (
      <UserRoute>
        <div className='manage-promo-pg'>
          <Navigation heading={"Manage Promotions"} add={false} username={user.username} back={true} payments={true} navRight={true} />
          <StripeConnectCard themeClass={themeClass} user={user} type="promotions" isOwner={true} />
        </div>
      </UserRoute>
    );
  }

  return (
    <UserRoute>
      <div className='manage-promo-pg'>
        <Navigation heading={"Manage Promotions"} add={true} username={user.username} back={true} payments={true} navRight={true} />
        {promotions.length > 0 ?
          promotions.map(promotion => (
            <PromoteCard
              name={promotion.name}
              description={promotion.description}
              price={promotion.price}
              images={promotion.portfolio}
              handleCheckout={() => { }}
              tikTok={promotion && promotion.tiktok}
              instagram={promotion && promotion.instagram}
              podcast={promotion && promotion.podcast}
              youtube={promotion && promotion.youtube}
              edit={true}
              duration={promotion.duration}
              contentRights={promotion.contentRights}
              reuseAfter={promotion.reuseAfter}
              key={promotion._id}
              id={promotion._id}
              setMenu={setIsOptionsOpen}
              currency={promotion.currency}
            />
          )) :
          <div className="no-promotions">
            <h3 className='no-promotions__title'>No Promotions</h3>
            <p className='no-promotions__caption'>Tap + to create a new promotion</p>
          </div>
        }

        {isOptionsOpen !== false && (
          <OptionsModalBox
            isOpen={isOptionsOpen}
            setIsOpen={setIsOptionsOpen}
            handleEditPromotion={() => handleEditPromotion(isOptionsOpen)}
            handleDeletePromotion={() => handleDeletePromotion(isOptionsOpen)}
          />
        )}

        <MessageModal
          isOpen={isMessageModalOpen}
          setIsOpen={setIsMessageModalOpen}
          message={serverResponse}
        />
      </div>
    </UserRoute>
  );
}
