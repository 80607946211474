// LogoutPage.js

import React, { useEffect, useContext, useState } from 'react';
import { Context } from "../../context/index";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie'; // Import js-cookie for cookie management
import './LogoutPage.scss'; // Import the SCSS file

export default function LogoutPage() {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(Context);
  const { user } = state;
  const [logOutMessage, setLogOutMessage] = useState("");

  useEffect(() => {
    const handleLogout = async () => {
      // Dispatch logout action and clear local storage
      dispatch({ type: "LOGOUT" });
      window.localStorage.removeItem("user");
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("twoFactorVerified");
      window.localStorage.removeItem("fcmToken");

      // Remove token cookies
      Cookies.remove("token"); // Replace with your actual token cookie name
      Cookies.remove("_token"); // Replace with your actual token cookie name

      try {
        // Get the service worker registration
        const registration = await navigator.serviceWorker.getRegistration();
        if (registration) {
          const subscription = await registration.pushManager.getSubscription();
          if (subscription) {
            // Unsubscribe from push notifications on the client side
            await subscription.unsubscribe();
            console.log('Unsubscribed from push notifications on client side.');

            // Inform the backend to remove this subscription
            await axios.post('/webpush/unsubscribe', { endpoint: subscription.endpoint });
            console.log('Unsubscribed from push notifications on backend.');
          }
        }

        // Send logout request to the server
        const { data } = await axios.get(`/logout`);
        //console.log(data);
        setLogOutMessage(data.message);
      } catch (error) {
        console.error("Logout error:", error);
      }

      // Unregister all service workers
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations()
          .then((registrations) => {
            if (registrations.length === 0) {
              console.log('No service workers to unregister.');
              return;
            }

            // Unregister each service worker
            registrations.forEach((registration) => {
              registration.unregister()
                .then((boolean) => {
                  if (boolean) {
                    console.log('Service worker unregistered:', registration.scope);
                  } else {
                    console.warn('Service worker failed to unregister:', registration.scope);
                  }
                })
                .catch((error) => {
                  console.error('Error unregistering service worker:', error);
                });
            });
          })
          .catch((error) => {
            console.error('Error fetching service worker registrations:', error);
          });

        // Unsubscribe from push notifications (already handled above)
      } else {
        console.log('Service workers are not supported in this browser.');
      }

      // Optionally, clear caches
      if ('caches' in window) {
        caches.keys()
          .then((cacheNames) => {
            return Promise.all(
              cacheNames.map((cacheName) => {
                return caches.delete(cacheName)
                  .then((deleted) => {
                    if (deleted) {
                      console.log(`Cache deleted: ${cacheName}`);
                    }
                  })
                  .catch((error) => {
                    console.error(`Error deleting cache ${cacheName}:`, error);
                  });
              })
            );
          })
          .catch((error) => {
            console.error('Error fetching cache names:', error);
          });
      }

      // Navigate to the login page after all cleanup
      navigate("/login");
    };

    if (user) {
      handleLogout();
    } else {
      navigate("/login");
    }
  }, [user, dispatch, navigate]);

  return (
    <div className="logout-container">
      <h2>{ logOutMessage !== "" ? logOutMessage : "See you soon"}</h2>
    </div>
  );
}
