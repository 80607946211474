import React from 'react';
import { Link } from 'react-router-dom';
import Card from '../Card/Card'; // Adjust the path based on your project structure

export default function StripeConnectCard({ isOwner, themeClass, user }) {
  // Determine if Stripe is connected based on user data
  const isStripeConnected =
    user?.stripeSeller &&
    Object.keys(user.stripeSeller).length === 0 &&
    user.stripeAccountId !== '';

  // Define messages based on Stripe connection status and user role
  let title = 'Connect Stripe';
  let message = '';
  let actionText = 'Connect Stripe';
  let actionLink = '/settings/payments/';

  if (isOwner) {
    if (isStripeConnected) {
      message =
        'Your Stripe account is connected. Please complete the Stripe setup.';
    } else {
      message =
        'Stripe is not connected to your account. Please connect your Stripe account to enable donations.';
    }
  } else {
    if (isStripeConnected) {
      message = 'The owner has connected their Stripe account.';
    } else {
      message =
        'The owner has not set up Stripe yet. Donations are currently disabled.';
      // Optionally, you can hide the connect button for visitors
      actionText = '';
      actionLink = '';
    }
  }

  return (
    <Card className={`card card--donations card--donations--${themeClass}`}>
      <h2 className={`donations-settings__title ${themeClass}`}>
        <span className="donations-settings__branding">{title}</span>
      </h2>
      <p className="donations-settings__message">{message}</p>
      {isOwner && (
        <div className="donations-settings__connect-button__div">
          <Link
            to={actionLink}
            className={`donations-settings__connect-button donations-settings__connect-button--${themeClass}`}
          >
            {actionText}
          </Link>
        </div>
      )}
      {!isOwner && !isStripeConnected && (
        <div className="donations-settings__connect-button__div">
          {/* Optionally, provide a way to notify the owner or inform the visitor */}
          <span className="info">Please check back later.</span>
        </div>
      )}
    </Card>
  );
};
