import React, { useContext, useEffect, useState } from 'react';
import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import "./UserPage.scss";
import UserRoute from '../../Routes/Auth';
import Card from "../../Components/Card/Card";
import UserProfile from "../../Components/UserProfile/UserProfile";
import UserPortfolio from "../../Components/UserPortfolio/UserPortfolio";
import ImageModal from "../../Components/ImageModal/ImageModal";
import TikTokEmbed from "../../Components/TikTokEmbed/TikTokEmbed";
import Link from "../../Components/Link/Link";
import ConfirmModal from '../../Components/ConfirmModal/ConfirmModal';
import ThemeContext from '../../context/ThemeContext';
import ProductCard from "../../Components/ProductCard/ProductCard";
import { Context } from '../../context';
import OptionsModalBox from '../../Components/OptionsModalBox/OptionsModalBox';
import UserNotFound from '../../Components/UserNotFound/UserNotFound';
import LoginModal from '../../Components/LoginModal/LoginModal';
import SignUpModal from '../../Components/SignUpModal/SignUpModal';
import PlatformPlaceholderCard from '../../Components/PlatformPlaceholderCard/PlatformPlaceholderCard';
import ProductPlaceholderCard from '../../Components/ProductPlaceholderCard/ProductPlaceholderCard';
import MenuIcon from './icons/MenuIcon';
import PortfolioIcon from './icons/PortfolioIcon';
import MusicPlayerIcon from './icons/MusicPlayerIcon';
import AddIcon from './icons/AddIcon';
import TikTokIcon from './icons/TikTokIcon';
import YoutubeIcon from './icons/YoutubeIcon';
import ShopIcon from './icons/ShopIcon';
import PodcastIcon from './icons/PodcastIcon';
import ShareIcon from './icons/ShareIcon';
import LoginHeader from '../../Components/LoginHeader/LoginHeader';
import DonateModal from '../../Components/DonateModal/DonateModal';
import MessageModal from '../../Components/MessageModal/MessageModal';
import InfoCircleIcon from './icons/InfoCircleIcon';
import ReportUserModal from '../../Components/ReportUserModal/ReportUserModal';


export default function UserPage() {
    const { username, subMode } = useParams();
    const [otherUser, setOtherUser] = useState(null);
    const [loggedInUser, setLoggedInUser] = useState("");
    const { state: { user } } = useContext(Context);
    const [connected, setConnected] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [donateModal, setDonateModal] = useState(false);
    const [modalImage, setModalImage] = useState('');
    const [roomId, setRoomId] = useState(null);
    const [mode, setMode] = useState("");
    const [changeTiktok, setChangeTiktok] = useState(false);
    const [changeYoutubeVideo, setChangeYoutubeVideo] = useState(false);
    const [shopModule, setShopModule] = useState(false);
    const [productModal, setProductModal] = useState(false);
    const navigate = useNavigate();
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showSignUpModal, setShowSignUpModal] = useState(false);
    const authorized = loggedInUser?.username === otherUser?.username || false ;
    const [reportModal, setReportModal] = useState(false);

    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

    const extractYouTubeVideoId = (urlOrId) => {
        if (urlOrId.includes('youtube.com') || urlOrId.includes('youtu.be')) {
            try {
                const urlObj = new URL(urlOrId);
                return urlObj.searchParams.get('v') || urlObj.pathname.split('/').pop();
            } catch (error) {
                console.error('Invalid YouTube URL:', urlOrId);
                return urlOrId; // Fallback to original if URL parsing fails
            }
        }
        return urlOrId;
    };

    const youtubeEmbedUrl = otherUser?.socialMedia?.youtubeEmbed
        ? `https://www.youtube.com/embed/${extractYouTubeVideoId(otherUser.socialMedia.youtubeEmbed)}`
        : '';

    useEffect(() => {
        if (user !== null) {
            setLoggedInUser(user);
        }
    }, [user]);

    useEffect(() => {
        setMode(subMode || "");
    }, [subMode]);

    const handleImageClick = (image) => {
        setShowModal(true);
        // console.log(image);
        setModalImage(image);
    };

    useEffect(() => {
        setOtherUser(null);
    }, [username]);

    const handleConnect = async () => {
        try {
            const response = await axios.post(`/user/${username}/connect`);
            console.log(response);
            window.location.reload();
            // setConnected(true); // Update state upon successful connection
        } catch (error) {
            console.log(error);
            // Optionally, handle the error
        }
    };

    const handleDisconnect = async () => {
        try {
            const response = await axios.put(`/user/${username}/disconnect`);
            console.log(response);
            setConnected(false); // Update state upon successful disconnection
        } catch (error) {
            console.log(error);
            // Optionally, handle the error
        }
    };

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await axios.get(`/user/${username}`);
                setOtherUser(response.data);
            } catch (error) {
                console.log(error);
                // Optionally, handle the error
            }
        };

        const checkConnection = async () => {
            try {
                const response = await axios.get(`/user/${username}/connect`);
                setConnected(response.data.isConnected);
            } catch (error) {
                console.log(error);
                // Optionally, handle the error
            }
        };

        const fetchRoom = async () => {
            try {
                const response = await axios.get(`/user/${username}/roomId`);
                setRoomId(response.data.roomId);
            } catch (error) {
                console.log(error);
                // Optionally, handle the error
            }
        };

        if (otherUser === null) {
            fetchUser();
        }

        if (user && otherUser && user.username !== otherUser.username) {
            checkConnection();
            fetchRoom();
        }
    }, [username, user, otherUser]);

    const renderMusic = () => {
        if (mode !== "music") return null;
        return (
            <div className="user-pg__music user-pg__music--show">
                {otherUser?.socialMedia?.spotifyMusic ? (
                    <iframe
                        className="iframe"
                        src={`https://open.spotify.com/embed/artist/${otherUser.socialMedia.spotifyMusic}`}
                        allow="encrypted-media"
                        title="Spotify Music"
                        onError={(e) => console.error("Spotify Music iframe failed to load:", e)}
                    ></iframe>
                ) : (
                    <Card className="placeholder-card__container">
                        <PlatformPlaceholderCard platform="spotifyMusic"
                            themeClass={themeClass}
                            title={loggedInUser.username === username ? "Add Spotify Artist ID" : `No Spotify Artist ID`}
                            description={loggedInUser.username === username ? "Add your Spotify Artist ID to show preview" : `Waiting for ${username} to add a Spotify Artist ID`}
                        />
                    </Card>
                )}
            </div>
        );
    };


    const renderPodcast = () => {
        if (mode !== "podcast") return null;
        return (
            <div className="user-pg__podcast user-pg__podcast--show">
                {otherUser?.socialMedia?.spotifyPodcast ? (
                    <iframe
                        className="iframe--podcast"
                        style={{ borderRadius: "12px" }}
                        src={`https://open.spotify.com/embed/show/${otherUser.socialMedia.spotifyPodcast}/video?utm_source=generator`}
                        width="100%"
                        height="250"
                        allowFullScreen
                        allow="true"
                        title="Spotify Podcast"
                        onError={(e) => console.error("Spotify Music iframe failed to load:", e)}
                    ></iframe>
                ) : (
                    <Card className="placeholder-card__container">
                        <PlatformPlaceholderCard platform="spotifyPodcast"
                            themeClass={themeClass}

                            appStatus={loggedInUser.username === username}
                            title={loggedInUser.username === username ? "Add Spotify Show ID" : `No Spotify Show ID`}
                            description={loggedInUser.username === username ? "Add your Spotify Show ID to show preview" : `Waiting for ${username} to add a Spotify Show ID`}
                        />
                    </Card>
                )}
            </div>
        );
    };

    const renderShare = () => {
        if (mode !== "share") return null;

        const hasLinks = otherUser?.websiteLinks && otherUser.websiteLinks.length > 0;

        return (
            <div className="user-pg__share user-pg__share--show">
                {loggedInUser.username === username && (
                    <div className="user-pg__share-actions">
                        <RouterLink to={`/settings/links/add`}>
                            <AddIcon themeMode={themeMode} />
                        </RouterLink>
                    </div>
                )}

                {hasLinks ? (
                    otherUser.websiteLinks.map((link, index) => (
                        <Card key={index}>
                            <Link
                                title={link.title}
                                url={loggedInUser.username === username ? `/settings/links/${index}` : link.url}
                            />
                        </Card>
                    ))
                ) : (
                    (
                        <Card key={0}>
                            <Link
                                title={loggedInUser.username === username ? "Add Link (URL) Shorts" : `No links posted yet`}
                                url={loggedInUser.username === username ? "/settings/links/add" : ""}
                                modifiy={`--no-links`}
                            />
                        </Card>

                    )
                )}
            </div>
        );
    };



    const renderVideo = () => {
        if (mode !== "video") return null;

        return (
            <div className="user-pg__video user-pg__video--show">
                {loggedInUser.username === username && (
                    <div className="user-pg__share-actions" onClick={() => setChangeYoutubeVideo(true)}>
                        <MenuIcon themeMode={themeMode} />
                    </div>
                )}

                {otherUser?.socialMedia?.youtubeEmbed ? (
                    <Card className="card--padding">
                        <div className="user-pg__youtube-container">
                            <iframe
                                width="100%"
                                height="300"
                                src={youtubeEmbedUrl}
                                title="YouTube video player"
                                className="iframe--youtube"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen
                                onError={(e) => console.error("Spotify Music iframe failed to load:", e)}
                            ></iframe>
                        </div>
                    </Card>
                ) : (
                    <Card className="placeholder-card__container">
                        <PlatformPlaceholderCard platform="youtube"
                            themeClass={themeClass}

                            setAnalyticsModal={() => setChangeYoutubeVideo(true)}
                            title={loggedInUser.username === username ? "Add YouTube Video ID" : "No YouTube Video ID"}
                            description={loggedInUser.username === username ? "A YouTube Post ID is required to continue" : `Waiting for ${username} to add a Video ID`}
                        />
                    </Card>
                )}
            </div>
        );
    };


    const renderTikTok = () => {
        if (mode !== "tiktok") return null;

        return (
            <div className="user-pg__tiktok user-pg__tiktok--show">
                {loggedInUser.username === username && (
                    <div className="user-pg__share-actions" onClick={() => setChangeTiktok(true)}>
                        <MenuIcon themeMode={themeMode} />
                    </div>
                )}

                {otherUser?.socialMedia?.tiktok ? (
                    <Card className="card--padding">
                        <TikTokEmbed username={otherUser.socialMedia.tiktok} />
                    </Card>
                ) : (
                    <Card className="placeholder-card__container">
                        <PlatformPlaceholderCard platform="tiktok" title={loggedInUser.username === username ? "Add TikTok Username" : `No TikTok Username`}
                            description={loggedInUser.username === username ? "Add your TikTok username to show preview" : " Waiting for " + username + " to add their TikTok username"}
                            setAnalyticsModal={() => setChangeTiktok(true)}
                            themeClass={themeClass}

                        />
                    </Card>
                )}
            </div>
        );
    };

    const renderShop = () => {
        if (mode !== "shop") return null;

        // Check if there are products
        const hasProducts = otherUser?.products && otherUser.products.length > 0;
        return (
            <div className={`user-pg__shop user-pg__shop--show`}>
                {loggedInUser.username === username && (
                    <div className="user-pg__share-actions" onClick={() => setProductModal(true)}>
                        <InfoCircleIcon themeMode={themeMode} />
                    </div>
                )}
                {hasProducts ? (
                    otherUser.products.map((product, index) => (
                        <ProductCard
                            key={index}
                            product={product}
                            index={index}
                            themeMode={themeMode}
                            themeClass={themeClass}
                            setShopModule={setShopModule}
                        />
                    ))
                ) : (
                    <Card className="placeholder-card__container">
                        <ProductPlaceholderCard
                            setModal={() => setProductModal(true)}
                            isUser={loggedInUser.username === username}
                            username={username}
                            themeClass={themeClass}
                        />
                    </Card>
                )}
            </div>
        );
    };


    const handleAction = (newMode) => {
        if (mode === newMode) {
            return;
        }
        setMode(newMode);
        navigate(`/${username}/${newMode}`);
    };

    const handleChangeTikTok = async (tiktokVideo) => {
        try {
            const response = await axios.put(`/user/edit-profile/social-media/tiktok-video`, { tiktokVideo });
            console.log(response);
            window.location.reload();
        } catch (error) {
            console.log(error);
            // Optionally, handle the error
        }
    };

    const handleChangeYoutubeVideo = async (youtubeEmbed) => {
        try {
            const response = await axios.put(`/user/edit-profile/social-media/youtube-video`, { youtubeEmbed });
            console.log(response);
            window.location.reload();
        } catch (error) {
            console.log(error);
            // Optionally, handle the error
        }
    };

    const handleAccept = async () => {
        try {
            const response = await axios.put(`/connect/accept`, { username });
            console.log(response);
            window.location.reload();
        } catch (error) {
            console.log(error);
            // Optionally, handle the error
        }
    };

    const handleDecline = async () => {
        try {
            const response = await axios.put(`/connect/decline`, { username });
            console.log(response);
            window.location.reload();
        } catch (error) {
            console.log(error);
            // Optionally, handle the error
        }
    };





    const handleDeleteProduct = async (id) => {
        try {
            const { data } = await axios.delete(`/user/edit-profile/shop/${id}`);
            console.log('Product removed', data);
            window.location.reload();
        } catch (err) {
            console.log('Failed to remove. Try again later.', err);
            // Optionally, handle the error
        }
    };

    if (!user && otherUser === null) {
        return (
            <UserNotFound />
        );
    }

    if (!user && otherUser !== null) {
        return (
            <div className='user-pg'>
                <LoginHeader />
                <UserProfile
                    otherUser={otherUser}
                    loggedInUser={loggedInUser}
                    username={username}
                    category={otherUser?.categories?.[0]}
                    location={otherUser?.location}
                    img={otherUser?.picture}
                    bio={otherUser?.bio}
                    dp={otherUser?.picture}
                    handleConnect={handleConnect}
                    handleDisconnect={handleDisconnect}
                    isPrivate={otherUser?.isPrivate}
                    isConnected={connected}
                    roomId={roomId}
                    otherRole={otherUser?.roles ? otherUser.roles[0] : otherUser?.userType}
                    incomingRequest={otherUser?.incomingRequests}
                    outgoingRequest={otherUser?.outgoingRequests}
                    handleAccept={handleAccept}
                    setShowLoginModal={setShowLoginModal}
                    setDonateModal={setDonateModal}

                />

                <Card className="card--row">
                    <div className="user-pg__action-item" onClick={() => handleAction("")}>
                        <PortfolioIcon themeMode={themeMode} />
                    </div>
                    <div className="user-pg__action-item" onClick={() => handleAction("shop")}>
                        <ShopIcon themeMode={themeMode} />
                    </div>
                    <div className="user-pg__action-item" onClick={() => handleAction("share")}>
                        <ShareIcon themeMode={themeMode} />
                    </div>
                    <div className="user-pg__action-item" onClick={() => handleAction("music")}>
                        <MusicPlayerIcon themeMode={themeMode} />
                    </div>
                    <div className="user-pg__action-item" onClick={() => handleAction("podcast")}>
                        <PodcastIcon themeMode={themeMode} />
                    </div>
                    <div className="user-pg__action-item" onClick={() => handleAction("tiktok")}>
                        <TikTokIcon themeMode={themeMode} />
                    </div>
                    <div className="user-pg__action-item" onClick={() => handleAction("video")}>
                        <YoutubeIcon themeMode={themeMode} />
                    </div>
                </Card>

                {mode === "" && (
                    <UserPortfolio imageUrls={otherUser?.portfolioImages} handleImageClick={handleImageClick} />
                )}
                {renderMusic()}
                {renderPodcast()}
                {renderShare()}
                {renderVideo()}
                {renderTikTok()}
                {renderShop()}

                {showModal && (
                    <ImageModal username={username} displayPicture={otherUser?.picture} image={modalImage} onClose={() => setShowModal(false)} />
                )}
                {shopModule !== false && (
                    <OptionsModalBox
                        isOpen={shopModule}
                        setIsOpen={setShopModule}
                        handleRemoveProduct={() => handleDeleteProduct(shopModule)}
                    />
                )}

                {showLoginModal && (
                    <LoginModal showModal={showLoginModal} setShowModal={setShowLoginModal}
                        setShowSignUpModal={setShowSignUpModal}
                    />
                )}
                {showSignUpModal && (
                    <SignUpModal showModal={showSignUpModal} setShowModal={setShowSignUpModal} setShowLoginModal={setShowLoginModal} />
                )}
                {donateModal !== false && (
                    <DonateModal
                        user={otherUser?.username}
                        isOpen={donateModal}
                        onClose={() => setDonateModal(false)}
                        anon={true}
                        loggedInUser={false}
                        suggestedAmounts={otherUser?.donationSettings?.suggestedAmounts}
                        customDonations={otherUser?.donationSettings?.customAmountEnabled}
                        defaultUserCurrency={otherUser?.donationSettings?.defaultCurrency}
                    />
                )}
            </div>
        );
    }

    if (otherUser !== null) {
        return (
            <UserRoute username={otherUser.username}>
                <div className='user-pg'>
                    <UserProfile
                        otherUser={otherUser}
                        loggedInUser={loggedInUser}
                        username={username}
                        category={otherUser?.categories?.[0]}
                        location={otherUser?.location}
                        img={otherUser?.picture}
                        bio={otherUser?.bio}
                        dp={otherUser?.picture}
                        handleConnect={handleConnect}
                        handleDisconnect={handleDisconnect}
                        isPrivate={otherUser?.isPrivate}
                        isConnected={connected}
                        roomId={roomId}
                        otherRole={otherUser?.roles ? otherUser.roles[0] : otherUser?.userType}
                        incomingRequest={otherUser?.incomingRequests}
                        outgoingRequest={otherUser?.outgoingRequests}
                        handleAccept={handleAccept}
                        handleDecline={handleDecline}
                        setDonateModal={setDonateModal}
                        setReportModal={setReportModal}
                    />

                    <Card className="card--row">
                        <div className="user-pg__action-item" onClick={() => handleAction("")}>
                            <PortfolioIcon themeMode={themeMode} />
                        </div>
                        <div className="user-pg__action-item" onClick={() => handleAction("shop")}>
                            <ShopIcon themeMode={themeMode} />
                        </div>
                        <div className="user-pg__action-item" onClick={() => handleAction("share")}>
                            <ShareIcon themeMode={themeMode} />
                        </div>
                        <div className="user-pg__action-item" onClick={() => handleAction("music")}>
                            <MusicPlayerIcon themeMode={themeMode} />
                        </div>
                        <div className="user-pg__action-item" onClick={() => handleAction("podcast")}>
                            <PodcastIcon themeMode={themeMode} />
                        </div>
                        <div className="user-pg__action-item" onClick={() => handleAction("tiktok")}>
                            <TikTokIcon themeMode={themeMode} />
                        </div>
                        <div className="user-pg__action-item" onClick={() => handleAction("video")}>
                            <YoutubeIcon themeMode={themeMode} />
                        </div>
                    </Card>

                    {mode === "" && (
                        <UserPortfolio imageUrls={otherUser?.portfolioImages} handleImageClick={handleImageClick} authorized={authorized} />
                    )}
                    {renderMusic()}
                    {renderPodcast()}
                    {renderShare()}
                    {renderVideo()}
                    {renderTikTok()}
                    {renderShop()}

                    {changeTiktok && (
                        <ConfirmModal
                            isOpen={changeTiktok}
                            onClose={() => setChangeTiktok(false)}
                            setIsOpen={setChangeTiktok}
                            message={"Would you like to change your main TikTok video"}
                            onConfirm={(newTikTokVideo) => handleChangeTikTok(newTikTokVideo)}
                            label={"TikTok Video ID"}
                        />
                    )}

                    {changeYoutubeVideo && (
                        <ConfirmModal
                            isOpen={changeYoutubeVideo}
                            onClose={() => setChangeYoutubeVideo(false)}
                            setIsOpen={setChangeYoutubeVideo}
                            message={"Would you like to change your main YouTube video"}
                            onConfirm={(newYoutubeEmbed) => handleChangeYoutubeVideo(newYoutubeEmbed)}
                            label={"YouTube Video ID"}
                        />
                    )}
                    {showModal && (
                        <ImageModal username={username} displayPicture={otherUser?.picture} image={modalImage} onClose={() => setShowModal(false)} />
                    )}
                    {shopModule !== false && (
                        <OptionsModalBox
                            isOpen={shopModule}
                            setIsOpen={setShopModule}
                            handleRemoveProduct={() => handleDeleteProduct(shopModule)}
                        />
                    )}
                    {donateModal !== false && (
                        <DonateModal
                            user={otherUser?.username}
                            userEmail={user?.email}
                            isOpen={donateModal}
                            onClose={() => setDonateModal(false)}
                            customDonations={otherUser?.donationSettings?.customAmountEnabled}
                            loggedInUser={user !== null}
                            suggestedAmounts={otherUser?.donationSettings?.suggestedAmounts}
                            defaultUserCurrency={otherUser?.donationSettings?.defaultCurrency}
                        />
                    )}
                    {productModal !== false && (
                        <MessageModal
                            isOpen={productModal}
                            setIsOpen={setProductModal}
                            message={"Add Products to Profile"}
                            subMessage={"Make your campaigns even more impactful by showcasing the products you're passionate about! Simply add them via the 'Products' tab in your campaign."}
                            ctaMessage={"Get started today and let your products take the spotlight"}
                            ctaLink="/campaigns/" // Replace with the appropriate route
                        />
                    )}
                    {reportModal && (
                        <ReportUserModal
                            isOpen={reportModal}
                            onClose={() => setReportModal(false)}
                            userId={otherUser._id}
                        />
                    )}

                </div>
            </UserRoute>
        );
    }

    return null;
}
