import React from 'react';
import productIcon from '../../assets/defaults/product.png';
// import { Link } from 'react-router-dom';
import './ViewPostModal.scss'

const ViewPostModel = ({ isOpen, setIsOpen, onClose }) => {




  return (
    <div className={`view-post-modal`}>
      <div className="view-post-modal__overlay" onClick={onClose} />
      <div className="view-post-modal__content">
        <div className='view-post-modal__container'>
          <div className='view-post-modal__header'>
            <img className='view-post-modal__img' src={isOpen ? (isOpen?.thumbnail ? isOpen.thumbnail : isOpen.url) : productIcon } alt={isOpen?.name}/>
          </div>
          <div className='view-post-modal__body'>
            <div className='view-post-modal__row'>
              <p className='view-post-modal__name'>{isOpen?.title}</p>
              {/* <Link to={isOpen?.url} className='view-product-modal__link'>
                <p>$ {isOpen?.price}</p>
              </Link> */}
            </div>
           { isOpen?.sku && <p className='view-post-modal__details'>{isOpen?.sku}</p>}

            {/* <p>{isOpen?.url}</p> */}
            <p className='view-post-modal__description'>{isOpen?.caption}</p>
          </div>
          <div className='edit-post-modal__footer'>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPostModel;
