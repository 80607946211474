import React, { useEffect, useState, useContext, useCallback, useRef } from 'react';
import UserRoute from '../../Routes/Auth';
import Navigation from '../../Components/Navigation/Navigation';
import './CampaignRequestsPage.scss';
import CampaignRequest from '../../Components/CampaignRequest/CampaignRequest';
import axios from 'axios';
import ThemeContext from '../../context/ThemeContext';
import { Context } from '../../context';
import Card from '../../Components/Card/Card';
import { Link } from 'react-router-dom';
import MessageModal from '../../Components/MessageModal/MessageModal';

// Optional: Import the SVG as a React component for a loading spinner
// import { ReactComponent as SpinnerSVG } from '../../assets/icons/spinner.svg'; // Uncomment if you have a spinner SVG

export default function CampaignRequestsPage() {
  const [requestCampaigns, setRequestCampaigns] = useState([]);
  const {
    state: { user },
  } = useContext(Context);
  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';
  const [listView, setListView] = useState(false);

  // Pagination state
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // State for MessageModal
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [serverResponse, setServerResponse] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [subServerResponse, setSubServerResponse] = useState("");

  // Refs for the sentinel element and the scrollable container
  const observerRef = useRef();
  const containerRef = useRef(null);

  /**
   * Fetch campaigns based on the current page.
   * Uses functional updates to ensure state consistency.
   */
  const fetchCampaigns = useCallback(async (currentPage) => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API}/campaigns/requests`, {
        params: { page: currentPage, limit: 8 }, // Adjust limit as needed
      });
      const fetchedCampaigns = response.data?.campaigns || [];
      const total = response.data?.total || 0;

      setRequestCampaigns(prev => {
        const newCampaigns = [...prev, ...fetchedCampaigns];
        setHasMore(newCampaigns.length < total);
        return newCampaigns;
      });
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  /**
   * Handles accepting a campaign request.
   * Removes the campaign from the list upon successful acceptance.
   */
  const handleAccept = async (campaignId, brand) => {
    try {
      setIsProcessing(true);
      await axios.put(`${process.env.REACT_APP_API}/campaigns/requests/`, {
        campaignId,
        brand,
      });
      setRequestCampaigns(prev => prev.filter(campaign => campaign._id !== campaignId));
      setServerResponse('Campaign Approved');
      setSubServerResponse('Your campaign is now live in Active Campaigns. Introduce yourself while you wait for the brand’s campaign concepts.');
    } catch (error) {
      setServerResponse('Error accepting campaign. Please try again, or contact support.');
      setSubServerResponse('');
      console.error('Error accepting campaign:', error);
    } finally {
      setIsMessageModalOpen(true);
      setIsProcessing(false);
    }
  };

  /**
   * Handles declining a campaign request.
   * Removes the campaign from the list upon successful decline.
   */
  const handleDecline = async (campaignId, brand) => {
    try {
      setIsProcessing(true);
      await axios.delete(`${process.env.REACT_APP_API}/campaigns/requests/`, {
        data: { campaignId, brand },
      });
      setRequestCampaigns(prev => prev.filter(campaign => campaign._id !== campaignId));
      setServerResponse('Campaign was declined');
      setSubServerResponse('The brand has been notified of your decision.');
    } catch (error) {
      setServerResponse('Error declining campaign. Please try again, or contact support.');
      setSubServerResponse('');
      console.error('Error declining campaign:', error);
    } finally {
      setIsMessageModalOpen(true);
      setIsProcessing(false);
    }
  };

  /**
   * Automatically closes the message modal after a specified duration.
   */
  useEffect(() => {
    if (isMessageModalOpen) {
      const timer = setTimeout(() => setIsMessageModalOpen(false), 2050);
      return () => clearTimeout(timer);
    }
  }, [isMessageModalOpen]);

  /**
   * Initial fetch and fetch on page change.
   */
  useEffect(() => {
    fetchCampaigns(page);
  }, [page, fetchCampaigns]);

  /**
   * Sets up the Intersection Observer to implement infinite scrolling.
   * Observes the sentinel element and triggers page increment when it enters the viewport.
   */
  useEffect(() => {
    if (isLoading || !hasMore) return;

    // Capture the current observerRef.current to avoid ESLint warning
    const currentObserver = observerRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          console.log('Sentinel is intersecting, loading more data');
          setPage(prevPage => prevPage + 1);
        }
      },
      {
        root: containerRef.current || null, // Use the container as root if available
        rootMargin: '0px',
        threshold: 0.1, // Trigger when 10% of the sentinel is visible
      }
    );

    if (currentObserver) {
      observer.observe(currentObserver);
    }

    // Cleanup the observer using the captured currentObserver
    return () => {
      if (currentObserver) {
        observer.unobserve(currentObserver);
      }
    };
  }, [isLoading, hasMore]);

  /**
   * Sets up a scroll event listener to implement infinite scrolling.
   * This handles cases where IntersectionObserver might not work as expected, such as in tablet mode.
   */
  useEffect(() => {
    const handleScroll = () => {
      if (isLoading || !hasMore) return;
      const container = containerRef.current;
      if (container) {
        const { scrollTop, scrollHeight, clientHeight } = container;
        if (scrollTop + clientHeight >= scrollHeight - 100) {
          setPage(prevPage => prevPage + 1);
        }
      } else {
        // Fallback to window scroll
        const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
        if (scrollTop + clientHeight >= scrollHeight - 100) {
          setPage(prevPage => prevPage + 1);
        }
      }
    };

    const throttledHandleScroll = throttle(handleScroll, 200); // Throttle to improve performance

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', throttledHandleScroll);
    } else {
      window.addEventListener('scroll', throttledHandleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', throttledHandleScroll);
      } else {
        window.removeEventListener('scroll', throttledHandleScroll);
      }
    };
  }, [isLoading, hasMore]);

  /**
   * Check if content height is less than viewport and fetch more if needed
   * This ensures that if the initial data doesn't fill the viewport, more data is fetched automatically.
   */
  useEffect(() => {
    const checkContentHeight = () => {
      const container = containerRef.current;
      if (container) {
        if (container.scrollHeight <= container.clientHeight && hasMore && !isLoading) {
          setPage(prevPage => prevPage + 1);
        }
      } else {
        if (document.body.scrollHeight <= window.innerHeight && hasMore && !isLoading) {
          setPage(prevPage => prevPage + 1);
        }
      }
    };

    checkContentHeight();
  }, [requestCampaigns, hasMore, isLoading]);

  /**
   * Conditional rendering based on Stripe account connection status.
   */
  if (
    user?.stripeAccountId === '' ||
    Object.keys(user?.stripeSeller).length === 0 ||
    user?.stripeSeller?.charges_enabled === false
  ) {
    return (
      <UserRoute>
        <div className={`donations-settings donations-settings--${themeClass}`}>
          <Navigation heading={'Campaign Requests'} back={true} />
          <Card className={`card card--donations card--donations--${themeClass}`}>
            <h2 className={`donations-settings__title ${themeClass}`}>
              <span className="donations-settings__branding">Connect Stripe</span>
            </h2>
            <p className="donations-settings__message">
              {user?.stripeSeller && Object.keys(user?.stripeSeller).length === 0 && user?.stripeAccountId !== ''
                ? 'Your Stripe account is connected to this account. Please complete the Stripe connection.'
                : 'Stripe is not connected to this account. Please connect your Stripe account to enable Campaigns.'}
            </p>
            <div className="donations-settings__connect-button__div">
              <Link
                to="/settings/payments/"
                className={`donations-settings__connect-button donations-settings__connect-button--${themeClass}`}
              >
                Connect Stripe
              </Link>
            </div>
          </Card>
        </div>
      </UserRoute>
    );
  }

  return (
    <UserRoute>
      <div className="campaign-requests-pg" ref={containerRef}>
        <Navigation
          heading="Campaign Requests"
          back={true}
          listView={listView}
          listViewEnabled={true}
          navRight={true}
          handleListView={() => setListView(!listView)}
        />

        {requestCampaigns.length === 0 && !isLoading ? (
          <div className="campaign-requests-pg__message__container">
            <p className="campaign-requests-pg__message">
              No campaign requests available at this time.
            </p>
          </div>
        ) : (
          <div className={`campaign-requests-pg__list ${listView ? 'list-view' : 'grid-view'}`}>
            {requestCampaigns.map((campaign) => (
              <CampaignRequest
                key={campaign._id}
                campaign={campaign}
                actions={true}
                listView={listView}
                handleAccept={() => handleAccept(campaign._id, campaign.brand.username)}
                handleDecline={() => handleDecline(campaign._id, campaign.brand.username)}
                id={campaign._id}
                isProcessing={isProcessing}
                themeClass={themeClass}
              />
            ))}
          </div>
        )}

        {/* Loading Spinner */}
        {isLoading && (
          <div className="campaign-requests-pg__spinner">
            {/* Uncomment the line below if you have a SpinnerSVG */}
            {/* <SpinnerSVG className="spinner-svg" /> */}
            <div className="spinner"></div> {/* Simple CSS spinner as a fallback */}
          </div>
        )}

        {/* Sentinel Element for Intersection Observer */}
        <div ref={observerRef} className="sentinel"></div>

        {/* Message Modal */}
        <MessageModal
          isOpen={isMessageModalOpen}
          setIsOpen={setIsMessageModalOpen}
          message={serverResponse}
          subMessage={subServerResponse}
          modifier={'center'}
        />
      </div>
    </UserRoute>
  );
}

/**
 * Utility function to throttle another function.
 * Ensures that the function is called at most once every delay milliseconds.
 */
function throttle(func, delay) {
  let lastCall = 0;
  return function (...args) {
    const now = new Date().getTime();
    if (now - lastCall < delay) {
      return;
    }
    lastCall = now;
    return func(...args);
  };
}
