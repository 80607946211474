// Header.jsx
import React from 'react';
import './Header.scss';
import ChequeaiLogo from '../../assets/icons/chequai-logo.png';
import { Link, NavLink } from 'react-router-dom';
import MenuIcon from './MenuIcon';


export default function Header({ user = null, themeClass, isMobileMenuOpen, setIsMobileMenuOpen, modifier }) {

      const isMoblieView = window.innerWidth <= 768;

      return (
            <header className={`header__header header__header--${modifier}`}>
                  <div className="header__header-left">
                 
                        <div className='header__header-left-container'>
                              <img
                                    src={ChequeaiLogo}
                                    alt="Search for Influencers"
                                    className="header__header__logo-img"
                              />
                              <Link to="/" className="header__logo">
                                    <h1 className="header__header__title">Chequeai</h1>
                              </Link>
                        </div>
                        <div
                              className="header__hamburger"
                              onClick={() => setIsMobileMenuOpen(true)}
                        >
                              {isMoblieView && !isMobileMenuOpen && <MenuIcon themeClass={themeClass} />}
                        </div>
                  </div>

                  <div className="header__header-right">
                        <a
                              href="/#features"
                              className={`header__nav-link header__nav-link--${themeClass}`}
                        >
                              Features
                        </a>
                        <a
                              href="/#subscriptions"
                              className={`header__nav-link header__nav-link--${themeClass}`}
                        >
                              Subscriptions
                        </a>
                        <a
                              href="/#subscriptions"
                              className={`header__nav-link header__nav-link--${themeClass}`}
                        >
                              Start now
                        </a>
                        <NavLink to="/login">
                              <button
                                    className={`header__btn header__btn--login header-pg__btn--translucent header__btn--translucent--${themeClass}`}
                              >
                                    {user ? (
                                          <span className="landing-pg__login-text">Home</span>
                                    ) : (
                                          <span className="landing-pg__login-text">Login</span>
                                    )}
                              </button>
                        </NavLink>

                  </div>
            </header>
      );
}
