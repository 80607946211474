// src/Components/AddressForm/AddressForm.jsx

import React, { useState, useEffect } from 'react';
import { AddressElement, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import './AddressForm.scss';

const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_STRIPE_KEY);

const AddressForm = ({ onAddressComplete, handleNext, handleClose, themeClass, billingDetails }) => {
  // console.log(addressData);
  const [errorMessage, setErrorMessage] = useState('');
  const [isAddressComplete, setIsAddressComplete] = useState(false);
  const [addressData, setAddressData] = useState(billingDetails); // Initialize with billingDetails

  const options = {
    appearance: {
      theme: 'flat',
      variables: {
        colorPrimary: '#0570de',
        colorBackground: themeClass === 'dark-theme' ? 'rgb(0,0,0)' : '#f1f1f1',
        colorText: 'darkgray',
        colorDanger: '#df1b41',
        fontFamily: 'Ideal Sans, system-ui, sans-serif',
        spacingUnit: '3px',
        borderRadius: '8px',
      },
      rules: {
        '.Input': {
          boxShadow: 'black',
        },
      }
    }
  };

  const handleAddressChange = (event) => {
    if (event.error) {
      setErrorMessage(event.error.message);
    } else {
      setErrorMessage('');
    }

    if (event.complete) {
      const { address, name, phone } = event.value;
      const x = { name, phone, address: { ...address } };
      setAddressData(x);
      setIsAddressComplete(true);
      onAddressComplete(x);
    } else {
      setIsAddressComplete(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isAddressComplete) {
      handleNext();
    } else {
      setErrorMessage('Please complete your address before proceeding.');
    }
  };

  useEffect(() => {
    if (billingDetails) {
      setAddressData(billingDetails); // Update address data if billing details change
    }
  }, [billingDetails]);

  return (
    <div className={`address-form address-form--${themeClass}`}>
      <h3 className='address-form__title'>Billing Info</h3>
      <Elements stripe={stripePromise} options={options}>
        <form onSubmit={handleSubmit}>
          <div className="address-form__address-element">
          <AddressElement
            options={{
              mode: 'billing',
              autocomplete: {
                mode: 'google_maps_api',
                apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
              },
              allowedCountries: ['US', 'CA'],
              fields: {
                phone: 'always',
              },
              contacts: [{ // Prefilling data in AddressElement
                name: addressData.name || '',
                address: {
                  line1: addressData.address.line1 || '',
                  line2: addressData.address.line2 || '',
                  city: addressData.address.city || '',
                  state: addressData.address.state || '',
                  postal_code: addressData.address.postal_code || '',
                  country: addressData.address.country || '',
                },
              }],
            }}
            onChange={handleAddressChange}
          />
          </div>
          {errorMessage && <p className="address-form__error">{errorMessage}</p>}
          <div className="address-form__actions">
            <button
              type="button"
              className={`address-form__button address-form__button--cancel address-form__button--${themeClass}`}
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className={`address-form__button address-form__button--confirm address-form__button--${themeClass}`}
              type="submit"
              disabled={!isAddressComplete}
            >
              Next
            </button>
          </div>
        </form>
      </Elements>
    </div>
  );
};

export default AddressForm;
