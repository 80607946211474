import React, { useContext, useState } from 'react';
import './FAQComponent.scss';
import ThemeContext from '../../context/ThemeContext';
import Tabs from '../../Components/Tabs/Tabs';

const creatorsFaqs = [
    {
        question: "What is Chequeai, and how does it work?",
        answer: "Chequeai is a platform that streamlines managing brand collaborations and payments. Simply create a profile, connect with brands, negotiate deals, and receive payments directly through the platform. Chequeai automates payments, ensures data security, and facilitates smooth interactions between creators and brands."
    },
    {
        question: "How will I receive my payments?",
        answer: "Chequeai handles payments through Stripe. Once a brand finalizes a transaction, the funds are transferred to your Stripe account. You can then withdraw your balance to your preferred bank or other supported payment method."
    },
    {
        question: "Are there any fees for using Chequeai?",
        answer: "Chequeai charges a small transaction fee on each completed deal to cover platform and processing costs. The fee percentage varies depending on your subscription plan."
    },
    {
        question: "Can I choose which brand deals to accept?",
        answer: "Yes, you have full control over your brand collaborations. Chequeai lets you accept or decline brand deal requests, allowing you to partner only with brands that align with your mission."
    },
    {
        question: "Which social media platforms are supported on Chequeai?",
        answer: "Chequeai supports campaign management and payment processing for major social platforms, including Instagram, TikTok, YouTube, Twitter, Twitch and Podcasts. This flexibility lets you work across multiple channels seamlessly."
    },
    {
        question: "What countries are supported by Chequeai?",
        answer: "Currently, Chequeai is available in 5 countries. For a complete list of supported countries, please refer to our country list documentation."
    },
    {
        question: "Do I need to sign any contracts with Chequeai?",
        answer: "No contracts are required with Chequeai. The platform is self-service, meaning you’re free to negotiate terms directly with brands on a case-by-case basis."
    },
    {
        question: "Is my payment secure with Chequeai?",
        answer: "Yes, Chequeai relies on Stripe to securely process payments and hold funds until both you and the brand confirm the work is complete. This setup ensures that payments are protected, reducing the risk of disputes and providing security for both parties involved."
    },
    {
        question: "Does Chequeai operate as an agency?",
        answer: "No, Chequeai is not an agency. It is a tool that helps creators manage their own brand deals and payments. We simply provide the platform and resources for you to handle collaborations independently."
    }
];

const brandsFaqs = [
    {
        question: "How does Chequeai work for brands?",
        answer: "Chequeai allows brands to connect with creators across various platforms like Instagram, TikTok, and YouTube. You can search for creators, negotiate deals, and manage payments all in one place, streamlining your campaign workflow."
    },
    {
        question: "How are creators verified on Chequeai?",
        answer: "Chequeai conducts thorough checks on all creators, including identity verification and social media audits, to ensure their authenticity and engagement quality."
    },
    {
        question: "How does shipping work for product collaborations?",
        answer: "After an order is placed, creators will provide shipping information. You can choose your preferred shipping method to send products directly to them."
    },
    {
        question: "How long does a creator have to accept an offer?",
        answer: "Creators have 48 hours to accept new orders before they automatically expire."
    },
    {
        question: "What happens if a creator declines my offer?",
        answer: "If a creator declines your offer, no action is needed from you, and you are not charged."
    },
    {
        question: "How do I know I’ll receive the work I paid for?",
        answer: "Chequeai holds the funds securely until the work is completed and approved by you."
    },
    {
        question: "What types of payment does Chequeai accept?",
        answer: "Chequeai uses Stripe to process payments, allowing you to pay with major credit and debit cards."
    },
    {
        question: "How can I send a custom offer to a creator?",
        answer: "If you have specific requirements not covered by a creator’s existing packages, you can send them a custom offer directly from their profile page by clicking the 'Send Custom Offer' button."
    },
];
function FAQComponent() {
    const [activeTab, setActiveTab] = useState(0); // 0 for Creators, 1 for Brands
    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

    return (

        <div className={`faq-component faq-component--${themeClass}`}>
            <Tabs
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                label1={"Creators"}
                label2={"Brands"}
            />
            {activeTab === 0 ? (
                <>
                    {creatorsFaqs.map((faq, index) => (
                        <div key={index} className="faq-component__item">
                            <h2 className="faq-component__question">{faq.question}</h2>
                            <p className={`faq-component__answer faq-component__answer--${themeClass}`}>{faq.answer}</p>
                        </div>
                    ))}
                </>
            ) : (
                <>
                    {brandsFaqs.map((faq, index) => (
                        <div key={index} className="faq-component__item">
                            <h2 className="faq-component__question">{faq.question}</h2>
                            <p className={`faq-component__answer faq-component__answer--${themeClass}`}>{faq.answer}</p>
                        </div>
                    ))}
                </>
            )}

        </div>

    );
}

export default FAQComponent;