import { Context } from "../../context/index"
import React, { useContext, useEffect, useState } from 'react';
import UserRoute from '../../Routes/Auth';
import Card from "../../Components/Card/Card"
import "./AccountSettingsPage.scss"
import Button from '../../Components/Button/Button';
import Navigation from "../../Components/Navigation/Navigation";


export default function AccountSettingsPage() {

  const [username, setUsername] = useState("");
  const { state: { user }, } = useContext(Context);
  const [formData, setFormData] = useState({
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    address: "",
    isAddressVerified: false,
    idImage: null,
  });

  useEffect(() => {
    if (user !== null) {
      setUsername(user.username);
    }
  }, [user]);

  // If these variables are not being used in the component, there's no need to destructure them
  const { address, isAddressVerified, idImage } = formData;
  console.log(address, idImage, isAddressVerified, setFormData);

  // Remove unused handleInputChange function
  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };

  // Remove unused handleSubmit function
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // Handle form submission here
  // };

  return (
    <UserRoute username={username}>
      <div className="accounts__settings-pg">
        <Navigation heading={"Edit Account"} back={true} />
        <Card className="card--padding">
          <Button>
            <p>Save</p>
          </Button>
        </Card>
      </div>
    </UserRoute>
  );
}
