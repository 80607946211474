import React, { useEffect, useState, useCallback, useContext } from 'react';
import UserRoute from '../../Routes/Auth';
import Navigation from '../../Components/Navigation/Navigation';
import axios from 'axios';
import CampaignRequest from '../../Components/CampaignRequest/CampaignRequest';
import './CampaignsOutBoxPage.scss';
import { Context } from '../../context';

export default function CampaignsOutBoxPage() {
    const [outboxCampaigns, setOutboxCampaigns] = useState([]);
    const [listView, setListView] = useState(false);
    const { state: { user } } = useContext(Context);

    const fetchCampaigns = useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/campaigns/outgoing`);
            // console.log(response.data);
            const fetchedCampaigns = response.data?.campaigns || [];
            setOutboxCampaigns(fetchedCampaigns);
        } catch (error) {
            console.error('Error fetching campaigns:', error);
        }
    }, []);

    useEffect(() => {
        fetchCampaigns();
    }, [fetchCampaigns]);

    return (
        <UserRoute>
            <div className='cp-outbox-pg'>
                <Navigation
                    heading='Outgoing Campaigns'
                    back={true}
                    navRight={true}
                    listViewEnabled={true}
                    handleListView={() => setListView(!listView)}
                    listView={listView}
                />
                <div className='campaigns-outbox-pg__campaigns'>
                    {outboxCampaigns && outboxCampaigns.length > 0 ? (
                        outboxCampaigns.map((campaign) => (
                            <CampaignRequest
                                key={campaign._id}
                                campaign={campaign}
                                actions={false}
                                edit={true}
                                listView={listView}
                                username={user?.username || ''}
                            />
                        ))
                    ) : (
                        <div className="cp-outbox-pg__message__container">
                            <p className="cp-outbox-pg__message">No outgoing campaigns available at this time.</p>
                            </div>
                        
                    )}
                </div>
            </div>
        </UserRoute>

    );
}
