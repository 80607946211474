import React, { useContext, useState } from 'react';
import Card from '../Card/Card';
import PostModal from '../PostModal/PostModal';
import ThemeContext from '../../context/ThemeContext';
import { Context } from "../../context/index";
import './UserPortfolio.scss';
import ChequaiLogo from "../../assets/icons/chequai-logo.png";

export default function UserPortfolio({ imageUrls, handleImageClick, authorized }) {
  const [isPostModalOpen, setIsPostModalOpen] = useState(false);
  const { themeMode } = useContext(ThemeContext);
  const { state: { user } } = useContext(Context);
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

  const gridImages = imageUrls.map((imageUrl, index) => (
    <div className="user-portfolio__portfolio-item" key={index}>
      <img
        src={imageUrl.url}
        alt={`Portfolio ${index + 1}`}
        onClick={() => handleImageClick(imageUrl)}
      />
    </div>
  ));

  return (<>
    <Card>
      <div className="user-portfolio">
        <div className="user-portfolio__header">
          <img className="user-portfolio__icon" src={ChequaiLogo} alt="Chequeai" />
          <h4 className="user-portfolio__title">Chequeai</h4>
        </div>

        <div className={`user-portfolio__portfolio ${themeClass}`}>

          {gridImages}
          {authorized && (
            <div className={`user-portfolio__upload-post user-portfolio__upload-post--${themeClass}`} onClick={() => setIsPostModalOpen(true)}> 
              +
            </div>
          )}

        </div>


      </div>
    </Card>
    {isPostModalOpen && authorized && (
      <PostModal
        isOpen={isPostModalOpen}
        setIsOpen={setIsPostModalOpen}
        onClose={() => setIsPostModalOpen(false)}
        type={`/portfolio/${gridImages.length}`}
        user={user}
      />
    )}
  </>
  );
}
