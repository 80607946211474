import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './StripeSuccessPage.scss';
import UserRoute from '../../Routes/Auth';
import { apiGet } from '../../utils/apiUtils'; // Adjust path as needed

function StripeSuccessPage() {
    useEffect(() => {
        const updatePaymentSettings = async () => {
            try {
                await apiGet("settings/payments/success/");
                console.log("Payment settings updated successfully.");
            } catch (error) {
                console.error("Error updating payment settings:", error);
            }
        };

        updatePaymentSettings();
    }, []);

    return (
        <UserRoute>
            <div className='stripe-success-pg'>
                <h1 className='stripe-success-pg__title'>Stripe Account Linked 🎉</h1>
                <p className='stripe-success-pg__sub-message'>
                    Ready to receive payments for brand deals, and fan donations.
                </p>
                <p className='stripe-success-pg__link'>
                    <Link to="/home" className='stripe-success-pg__link-text'>Home</Link>
                </p>
            </div>
        </UserRoute>
    );
}

export default StripeSuccessPage;
