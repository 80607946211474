import React from 'react'

export default function MenuIcon({ themeClass }) {
      console.log(themeClass)
      const color = themeClass === 'light-theme' ? 'darkgray' : 'white';
      console.log(color)
      return (
            <div style={{ cursor: 'pointer', marginTop: '0.8rem' }}>
            <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke={color}
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="icon icon-tabler icons-tabler-outline icon-tabler-menu-2"
            >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M4 6l16 0" />
                  <path d="M4 12l16 0" />
                  <path d="M4 18l16 0" />
            </svg>
      </div>
      )
}
