// src/pages/InstagramSuccessPage.js
import React from 'react';
import UserRoute from '../../Routes/Auth';
import "./InstagramSuccessPage.scss";

const InstagramSuccessPage = () => {
  // const searchParams = new URLSearchParams(location.search);
  // const token = searchParams.get('token');

  return (
    <UserRoute>
      <div className="instagram-success">
        <h1 className="instagram-success__title">Account Linked</h1>
        <p className="instagram-success__message">
          Your Instagram account has been successfully linked.
        </p>
        {/* {token && (
          <p className="instagram-success__token">
            Your token: <span className="instagram-success__token-value">{token}</span>
          </p>
        )} */}
      </div>
    </UserRoute>
  );
};

export default InstagramSuccessPage;
