import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import UserRoute from '../../Routes/Auth';
import UserCard from '../../Components/UserCard/UserCard';
// import Navigation from '../../Components/Navigation/Navigation';
import Search from '../../Components/Search/Search';
import "./SearchPage.scss";
import ThemeContext from '../../context/ThemeContext';

export default function SearchPage() {
    const { term } = useParams();
    // const capitalizedTerm = term.charAt(0).toUpperCase() + term.slice(1);

    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(1);  // Track current page
    const [totalPages, setTotalPages] = useState(1);  // Track total pages available
    const USERS_PER_PAGE = 6;  // Constant to determine the number of users per page
    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';


    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API}/search/${term}?page=${page}&limit=${USERS_PER_PAGE}`);

                // Check if response data and users exist
                if (res.data && res.data.data && res.data.data.users) {
                    setUsers(res.data.data.users);
                    setTotalPages(res.data.data.pagination.totalPages);  // Set total pages from backend response
                } else {
                    setUsers([]);  // Set users to empty array if no users are found
                    setTotalPages(1);  // Default to 1 page if no users are found
                }
            } catch (err) {
                console.error(err);
                setUsers([]);  // Set users to empty array on error
                setTotalPages(1);  // Default to 1 page on error
            }
        };
        fetchUsers();
    }, [term, page]);

    return (
        <UserRoute>
            <div className='category-pg'>
                <Search term={term} />
                <div className="category-pg__filter-container">
                    {/* Optional filter dropdowns can be added here */}
                </div>


                {/* Display users */}
                <div className='search-pg__users'>
                    {users && users.length > 0 ? (
                        users.map(user => (
                            <UserCard key={user._id} username={user.username} location={user.location} category={user.categories[0]} picture={user.picture} />
                        ))
                    ) : (
                        <p className='search-pg__no-results'>no users found</p>
                    )}
                </div>


                {/* Pagination controls */}
                <div className="search-pg__pagination">
                    <button className={`search-pg__pagination__button search-pg__pagination__button--${themeClass}`}
                        disabled={page === 1} onClick={() => setPage(prev => prev - 1)}>Previous</button>
                    <span  className={`search-pg__pagination__text search-pg__pagination__text--${themeClass}`}>Page {page} of {totalPages}</span>
                    <button className={`search-pg__pagination__button search-pg__pagination__button--${themeClass}`}
                        disabled={page === totalPages} onClick={() => setPage(prev => prev + 1)}>Next</button>
                </div>
            </div>
        </UserRoute>
    );
}
