import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './step.scss';
import TextInput from '../../TextInput/TextInput';
import { parsePhoneNumberFromString, AsYouType } from 'libphonenumber-js';
import { countryNameToCode, countryToPhoneNumberCode } from '../../../utils/authUtils';

function ContactInfoStep({ data, onChange, onNext, onBack, themeMode }) {
  const [phoneNumber, setPhoneNumber] = useState(data.phoneNumber || '');
  const [location, setLocation] = useState(data.location || '');
  const [errors, setErrors] = useState({});
  const [countryCode, setCountryCode] = useState('');
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

  const emailRef = useRef(null);

  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus();
    }

  // Set country code based on the selected country
    const code = countryToPhoneNumberCode(countryNameToCode(data.country)) || '+1'; // Default to '+1' (USA/Canada)
    setCountryCode(code);

    if (!phoneNumber.startsWith(code)) {
      setPhoneNumber(`${code} `); // Ensure country code is prepended
    }

  }, [data.country, phoneNumber]);

  const validatePhoneNumber = (number) => {
    // Validate using libphonenumber-js
    const parsedPhoneNumber = parsePhoneNumberFromString(number);
    return parsedPhoneNumber ? parsedPhoneNumber.isValid() : false;
  };

  const formatPhoneNumber = (number) => {
    const formatter = new AsYouType(); // This handles formatting as you type
    return formatter.input(number);
  };

  const validateLocation = (locationInput) => {
    const newErrors = {};
    
    // Split input by comma to separate city and country
    const parts = locationInput.split(',').map(part => part.trim());
    
    if (parts.length !== 2) {
      newErrors.location = 'Please enter the location in "City, Country" format';
    } else {
      const [city, country] = parts;

      if (!city) {
        newErrors.location = 'City is required';
      }
      if (!country) {
        newErrors.location = 'Country is required';
      }
    }

    return newErrors;
  };

  const validate = () => {
    const newErrors = {};

    // Validate phone number
    if (phoneNumber.trim() && !validatePhoneNumber(phoneNumber)) {
      newErrors.phoneNumber = 'Please enter a valid phone number';
    }

    // Validate location
    const locationErrors = validateLocation(location);
    if (Object.keys(locationErrors).length > 0) {
      Object.assign(newErrors, locationErrors);
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNextClick = (e) => {
    e.preventDefault();
    if (validate()) {
      onChange({ phoneNumber, location });
      onNext();
    }
  };

  const handleBackClick = (e) => {
    e.preventDefault();
    onChange({ phoneNumber, location });
    onBack();
  };

  // Function to format phone number as the user types
  const handlePhoneNumberChange = (e) => {
    let rawValue = e.target.value;

    // Ensure the country code stays fixed at the beginning
    if (!rawValue.startsWith(countryCode)) {
      rawValue = `${countryCode} ` + rawValue.replace(countryCode, '').trim();
    }

    const formattedNumber = formatPhoneNumber(rawValue);
    setPhoneNumber(formattedNumber);
  };

  return (
    <div className={`step ${themeMode}`}>
      <h2 className={`step__title step__title--${themeClass}`}>Profile Setup</h2>
      <form>
        <TextInput
          id="phoneNumber"
          label="Phone Number"
          type="tel"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          placeholder={`${countryCode} (999) - 999 - 9999`} // Include country code in placeholder
          error={errors.phoneNumber}
          themeMode={themeMode}
        />

        <TextInput
          id="location"
          label="Location (City, Country)"
          type="text"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          placeholder="Enter your location (City, Country)"
          error={errors.location}
          themeMode={themeMode}
        />

        <div className="navigation-buttons">
          <button type="button" onClick={handleBackClick} className={`back-button back-button--${themeClass}`}>
            Back
          </button>
          <button type="button" onClick={handleNextClick} className={`next-button next-button--${themeClass}`}>
            Next
          </button>
        </div>
      </form>
    </div>
  );
}

ContactInfoStep.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
};

export default ContactInfoStep;
