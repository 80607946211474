// src/pages/InstagramCallback.js
import React, { useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import UserRoute from "../../Routes/Auth";
import { apiGet } from "../../utils/apiUtils";
import './InstagramCallbackPage.scss';

const InstagramCallbackPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Extract query parameters
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get("code");

  const fetchToken = useCallback(
    async (authCode) => {
      try {
        const response = await apiGet(`/auth/instagram/callback?code=${authCode}`);
        console.log("Response from backend:", response);

        if (response && response.success) {
          navigate(`/settings/socials/instagram/callback/success`);
        } else {
          navigate("/settings/socials/instagram/callback/error");
        }
      } catch (error) {
        console.error("Token fetch error:", error);
        navigate("/settings/socials/instagram/callback/error");
      }
    },
    [navigate]
  );

  useEffect(() => {
    if (code) {
      fetchToken(code);
    } else {
      navigate("/settings/socials/instagram/callback/error");
    }
  }, [code, fetchToken, navigate]);

  return (
    <UserRoute>
      <div className="instagram-callback"></div>
    </UserRoute>
  );
};

export default InstagramCallbackPage;
