import React, { useEffect, useState, useContext, useMemo, useCallback } from "react";
import UserRoute from "../../Routes/Auth";
import Card from "../../Components/Card/Card";
import Navigation from "../../Components/Navigation/Navigation";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import OptionsIcon from "../../assets/icons/three-dots.svg";
import OptionsModalBox from "../../Components/OptionsModalBox/OptionsModalBox";
import ConfirmModal from "../../Components/ConfirmModal/ConfirmModal";
import teamImage from "../../assets/defaults/team.png";
import teamLightImage from "../../assets/defaults/teamLight.png";
import CampaignHeader from "../../Components/CampaignHeader/CampaignHeader";
import ThemeContext from "../../context/ThemeContext";
import "./CampaignTeamsPage.scss";
import CampaignSubHeader from "../../Components/CampaignSubHeader/CampaignSubHeader";
import PhoneIcon from "./PhoneIcon";
import MailIcon from "./MailIcon";
import InstagramIcon from "../../assets/icons/instagram.png";
import PortfolioIcon from "./PortfolioIcon";
import ViewCampaignTeamModal from "../../Components/ViewCampaignTeamModal/ViewCampaignTeamModal";
import EditCampaignTeamModel from "../../Components/EditCampaignTeamtModal/EditCampaignTeamModel";
import DarkLoadingSpinner from "../../Components/DarkLoadingSpinner/DarkLoadingSpinner";
import CampaignSupportModal from '../../Components/CampaignSupportModal/CampaignSupportModal';
import CampaignInfoModal from '../../Components/CampaignInfoModal/CampaignInfoModal';


// Custom hook to detect if the viewport is mobile
const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
};

const CampaignTeamsPage = () => {
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [campaignStatus, setCampaignStatus] = useState(null);
  const [campaignTeam, setCampaignTeam] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [isViewProductModalOpen, setIsViewProductModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(null);
  const [isConfirmAddShop, setIsConfirmAddShop] = useState(null);
  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === "light" ? "light-theme" : "dark-theme";
  const picture = themeClass === "light-theme" ? teamLightImage : teamImage;
  const [mode, setMode] = useState('Pre Production');
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [isCampaignInfoModalOpen, setIsCampaignInfoModalOpen] = useState(false);



  // Use the custom hook to determine if it's mobile view
  const isMobile = useIsMobile();

  const fetchCampaign = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/campaigns/${id}/teams`
      );

      if (response.data) {
        // console.log(response.data);
        setTitle(response.data.campaignName);
        setCampaignTeam(response.data.campaignTeamMembers || []); // Ensure it's an array
        setCampaignStatus(response.data.status);
      }
    } catch (error) {
      console.error("Failed to fetch campaign:", error);
    }
  }, [id]);

  useEffect(() => {
    if (!title) {
      fetchCampaign();
    }
  }, [campaignTeam, fetchCampaign, title]);

  const handleUpdateTeamMember = useCallback(async (teamMember) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API}/campaigns/${id}/teams/`,
        { teamMember }
      );
      fetchCampaign();
    } catch (err) {
      console.log("Failed to update team member.", err);
    }
  }, [id, fetchCampaign]);

  const handleDeleteTeamMember = useCallback(async (teamMember) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API}/campaigns/${id}/teams/${teamMember._id}`
      );
      fetchCampaign();
    } catch (err) {
      console.log("Failed to delete team member.", err);
    }
  }, [id, fetchCampaign]);

  const filteredTeamMembers = useMemo(() =>
    campaignTeam.filter((teamMember) => teamMember.category === mode),
    [campaignTeam, mode]
  );

  // Date formatting function
  const formatDate = (dateString, mobile = false) => {
    const date = new Date(dateString);
    if (mobile) {
      // Format: MM/DD/YY
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const year = String(date.getFullYear()).slice(-2);
      return `${month}/${day}/${year}`;
    } else {
      // Full format: Weekday, Month Day, Year
      const options = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      };
      return date.toLocaleDateString(undefined, options);
    }
  };

  if (!campaignTeam) {
    return <DarkLoadingSpinner />;
  }

  return (
    <UserRoute>
      <div className="cp-prod-pg">
        <Navigation
          heading={title}
          back={true}
          edit={`/campaigns/edit/${id}`}
        />
        <Card className="card--margin">
          <div className="campaign-card__header cp-ct-pg__header">
            <div className="campaign-card__header__left">
              <CampaignHeader status={campaignStatus}
                setIsCampaignInfoModalOpen={setIsCampaignInfoModalOpen} setShowOptions={setShowOptions}
                setIsSupportModalOpen={setIsSupportModalOpen} showOptions={showOptions} isSupportModalOpen={isSupportModalOpen}
              />
            </div>
            <div className="cp-ct-pg__sub-header">
              <CampaignSubHeader status={campaignStatus} mode={mode} setMode={setMode} />
            </div>
          </div>
          {filteredTeamMembers && filteredTeamMembers.length > 0 ? (
            filteredTeamMembers.map((teamMember, index) => (
              <div key={index} className={`cp-prod-pg__product cp-prod-pg__product--${themeClass}`}>
                <div className="cp-prod-pg__product__left">
                  <img
                    src={teamMember?.image ? teamMember.image.url : picture}
                    alt={teamMember.name}
                    className="cp-prod-pg__product__img"
                    onClick={() => setIsViewProductModalOpen(teamMember)}
                  />
                </div>
                <div className="cp-prod-pg__product__right">
                  <div className="cp-ct-pg__title-section">
                    <p className="cp-ct-pg__product__name">
                      {teamMember.name}
                      <span className="cp-prod-pg__product__job-title">{teamMember.jobTitle}</span>
                    </p>
                    <img
                      className="cp-dets-pg__post__actions__icons"
                      src={OptionsIcon}
                      alt="options icon"
                      onClick={() => setIsOptionsOpen(teamMember)}
                    />
                  </div>
                  <div style={{ display: "flex", width: "100%", gap: "0.33rem" }} className="cp-prod-pg__link-section">
                    {!isMobile && (
                      <div className="cp-ct-pg__insta__div">
                        <img
                          src={InstagramIcon}
                          alt="instagram icon"
                          className="cp-prod-pg__product__img"
                          style={{ width: "18px", height: "18px" }}
                        />
                        <p className="cp-prod-pg__insta__text">
                          {teamMember.instagram} {!isMobile && `| ${teamMember.email}`}
                        </p>
                      </div>
                    )}
                    <div style={{ display: "flex", gap: "0.33rem" }}>
                      <div
                        className="cp-prod-pg__clipboard"
                        onClick={() => window.open(`https://instagram.com/${teamMember.instagram}`, '_blank')}
                        alt="instagram icon"
                      >
                        <PortfolioIcon themeClass={themeClass} />
                      </div>
                      <div
                        className="cp-prod-pg__clipboard"
                        onClick={() => setIsConfirmModalOpen(teamMember)}
                        alt="mail icon"
                      >
                        <MailIcon themeClass={themeClass} />
                      </div>
                      <div
                        className="cp-prod-pg__clipboard"
                        onClick={() => setIsConfirmAddShop(teamMember)}
                        alt="phone icon"
                      >
                        <PhoneIcon themeClass={themeClass} />
                      </div>
                    </div>
                  </div>
                  <div className="cp-ct-pg__link-section cp-ct-pg__link-section--date">
                    <span className="cp-prod-pg__product__start-date">
                      {isMobile ? "Start:" : "Start Date:"} {formatDate(teamMember.startDate, isMobile)}
                    </span>
                    <span className="cp-prod-pg__product__start-date">
                      {isMobile ? "End:" : "Est. End Date:"} {formatDate(teamMember.endDate, isMobile)}
                    </span>
                  </div>
                  <p className="cp-prod-pg__product__sku">
                    {teamMember.description}
                  </p>
                </div>
              </div>
            ))
          ) : (
            <p className="cp-ct-pg__no-team-members">No campaign team members</p>


          )}

          {campaignStatus !== "Completed" && campaignStatus !== "Post Campaign" && (
            <Link
              className={`cp-prod-pg__add-product cp-prod-pg__add-product--${themeClass}`}
              to={`/campaigns/${id}/teams/add`}
            >
              <p className="cp-prod-pg__add">+</p>
              <p className="cp-prod-pg__text">Add Team Member</p>
            </Link>
          )}
        </Card>
        {isOptionsOpen && (
          <OptionsModalBox
            isOpen={isOptionsOpen}
            setIsOpen={setIsOptionsOpen}
            handleRemoveTeamMember={() => handleDeleteTeamMember(isOptionsOpen)}
            handleViewTeamMember={() => setIsViewProductModalOpen(isOptionsOpen)}
            handleEditTeamMember={() => setIsEditModalOpen(isOptionsOpen)}
          />
        )}
        {isConfirmAddShop && (
          <ConfirmModal
            isOpen={!!isConfirmAddShop}
            onClose={() => setIsConfirmAddShop(null)}
            setIsOpen={setIsConfirmAddShop}
            message={"Would you like to call this person now"}
            onConfirm={() => window.location.href = `tel:${isConfirmAddShop.phoneNumber}`}
          />
        )}
        {isConfirmModalOpen && (
          <ConfirmModal
            isOpen={!!isConfirmModalOpen}
            onClose={() => setIsConfirmModalOpen(null)}
            setIsOpen={setIsConfirmModalOpen}
            message={"Would you send an email to this person"}
            onConfirm={() => window.location.href = `mailto:${isConfirmModalOpen.email}`}
          />
        )}
        {isEditModalOpen && (
          <EditCampaignTeamModel
            isOpen={isEditModalOpen}
            onClose={() => setIsEditModalOpen(false)}
            onSave={handleUpdateTeamMember}
            setIsOpen={setIsEditModalOpen}
          />
        )}
        {isViewProductModalOpen && (
          <ViewCampaignTeamModal
            isOpen={isViewProductModalOpen}
            onClose={() => setIsViewProductModalOpen(false)}
          />
        )}
        {isSupportModalOpen && (
          <CampaignSupportModal
            isOpen={isSupportModalOpen}
            onClose={() => setIsSupportModalOpen(false)}
            campaignId={id}
          />
        )
        }
        <CampaignInfoModal isOpen={isCampaignInfoModalOpen} onClose={() => setIsCampaignInfoModalOpen(false)} themeMode={themeMode} />

      </div>
    </UserRoute>
  );
};

export default CampaignTeamsPage;
