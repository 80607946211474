import { Link } from 'react-router-dom';
import React, { useContext } from 'react';
import '../StripeSuccessPage/StripeSuccessPage.scss';
import UserRoute from '../../Routes/Auth';
import { Context } from "../../context/index"


function StripeCancelPage() {
    const { state: { user } } = useContext(Context);

    if (!user) {
        return (
            <div className='stripe-success-pg'>
                <h1 className='stripe-success-pg__title'>Stripe Authorization Cancelled</h1>
                <p className='stripe-success-pg__text'>You have cancelled the authorization to connect your Stripe account.</p>
                <p className='stripe-success-pg__link'>
                    <Link to="/home" className='stripe-success-pg__link-text'>Home</Link>
                </p>
            </div>
        );
    }

    return (
        <UserRoute>
            <div className='stripe-success-pg'>
                <h1 className='stripe-success-pg__title'>Stripe Authorization Cancelled</h1>
                <p className='stripe-success-pg__text'>You have cancelled the authorization to connect your Stripe account.</p>
                <p className='stripe-success-pg__link'>
                    <Link to="/home" className='stripe-success-pg__link-text'>Home</Link>
                </p>
            </div>
        </UserRoute>
    );
}

export default StripeCancelPage;
