import React from 'react';

const LeftArrow = ({ onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    stroke="black"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    opacity={0.25}
    className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-left"
    onClick={onClick}
    style={{ cursor: 'pointer' }}
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M5 12l14 0" />
    <path d="M5 12l6 6" />
    <path d="M5 12l6 -6" />
  </svg>
);

export default LeftArrow;
