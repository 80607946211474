// /src/utils/countriesUtils.jss

export const stripeSupportedCountries = [
  { code: 'US', name: '🇺🇸 United States' },
  { code: 'CA', name: '🇨🇦 Canada' },
  { code: 'AE', name: '🇦🇪 United Arab Emirates (UAE)' },
  { code: 'AT', name: '🇦🇹 Austria' },
  { code: 'AU', name: '🇦🇺 Australia' },
  { code: 'BE', name: '🇧🇪 Belgium' },
  { code: 'CH', name: '🇨🇭 Switzerland' },
  { code: 'DE', name: '🇩🇪 Germany' },
  { code: 'DK', name: '🇩🇰 Denmark' },
  { code: 'ES', name: '🇪🇸 Spain' },
  { code: 'FI', name: '🇫🇮 Finland' },
  { code: 'FR', name: '🇫🇷 France' },
  { code: 'GB', name: '🇬🇧 United Kingdom' },
  { code: 'IE', name: '🇮🇪 Ireland' },
  { code: 'IN', name: '🇮🇳 India' },
  { code: 'IT', name: '🇮🇹 Italy' },
  { code: 'JP', name: '🇯🇵 Japan' },
  { code: 'NL', name: '🇳🇱 Netherlands' },
  { code: 'NO', name: '🇳🇴 Norway' },
  { code: 'NZ', name: '🇳🇿 New Zealand' },
  { code: 'PT', name: '🇵🇹 Portugal' },
  { code: 'SE', name: '🇸🇪 Sweden' }
  // { code: 'PL', name: '🇵🇱 Poland' },
  // { code: 'BR', name: '🇧🇷 Brazil' }
];



export const categoryOptions = [
  { value: "Artist", label: "Artist" },
  { value: "Actor", label: "Actor" },
  { value: "Athlete", label: "Athlete" },
  { value: "Automotive", label: "Automotive" },
  { value: "Beauty", label: "Beauty" },
  { value: "Content Producer", label: "Content Producer" },
  { value: "Content Creator", label: "Content Creator" },
  { value: "Comedian", label: "Comedian" },
  { value: "Content Pages", label: "Content Pages" },
  { value: "Creative", label: "Creative" },
  { value: "Fashion", label: "Fashion" },
  { value: "Fitness", label: "Fitness" },
  { value: "Food", label: "Food" },
  { value: "Gaming", label: "Gaming" },
  { value: "Health", label: "Health" },
  { value: "Lifestyle", label: "Lifestyle" },
  { value: "Live Streaming", label: "Live Streaming" },
  { value: "Music", label: "Music" },
  { value: "Music Production", label: "Music" },
  { value: "Model", label: "Model" },
  { value: "Photographer", label: "Photographer" },
  { value: "Podcasting", label: "Podcasting" },
  { value: "Street Wear", label: "Street Wear" },
  { value: "Tech", label: "Tech" },
  { value: "Travel", label: "Travel" },
  { value: "Writer", label: "Writer" },
  { value: "Copy-Writer", label: "Copy-Writer" },
  { value: "Video Editor", label: "Video Editor" },
];

