// src/components/PaymentCardComponent/PaymentCardComponent.jsx

import React from 'react';
import { Tooltip } from 'react-tooltip'; // Import Tooltip from react-tooltip
import './PaymentCardComponent.scss'; // Import SCSS for styling
import { formatMoney } from '../../utils/moneyUtils';

const PaymentCardComponent = ({ payment, themeClass }) => {
    // Format the payment amount
    const formattedAmount = `$${(payment.amount / 100).toFixed(2)} ${payment.currency.toUpperCase()}`;

    // Detect if the device is mobile based on screen width
    const isMobile = window.innerWidth <= 768; // Adjust breakpoint as needed
    const monthFormat = isMobile ? 'short' : 'long';

    // Function to format the timestamp into a readable date
    const formatDate = (timestamp) => {
        return new Date(timestamp * 1000).toLocaleDateString('en-US', { 
            month: monthFormat, 
            day: 'numeric', 
            year: 'numeric' 
        });
    };

    // Format the creation date
    const createdDate = formatDate(payment.created);    

    // Generate a unique tooltip ID for the amount
    const amountTooltipId = `tooltip-amount-${payment.id}`;

    // Prepare the tooltip content for the amount
    const amountTooltipContent = payment.balance_transaction && payment.balance_transaction.amount
        ? `$${(payment.balance_transaction.amount / 100).toFixed(2)} ${payment.balance_transaction.currency.toUpperCase()}`
        : 'N/A';

    return (
        <div className={`payment-card payment-card--${themeClass}`}>
            <div className="payment-header">
                {/* Status Section */}
                <div style={{ display: 'flex', gap: '0.5rem' }}>
                    <span className={`status ${payment.status}`}>{payment.status.toUpperCase()}</span>
                </div>

                {/* Amount Section with Tooltip */}
                <div style={{ display: 'flex', gap: '0.5rem' }}>
                    <h2
                        className="amount"
                        {...(amountTooltipContent && {
                            'data-tooltip-id': amountTooltipId,
                            'data-tooltip-content': `Before Processing Fees: ${amountTooltipContent}`,
                            style: { cursor: 'pointer' },
                        })}
                    >
                        {formattedAmount}
                    </h2>
                    {/* Render Tooltip only if amountTooltipContent is provided */}
                    {amountTooltipContent && payment.balance_transaction.amount !== payment.balance_transaction.net && (
                        <Tooltip
                            id={amountTooltipId}
                            place="top"
                            effect="solid"
                            className={`tooltip--${themeClass}`} // Optional: For custom styling based on theme
                        />
                    )}
                </div>
            </div>

            {/* Details Section */}
            <div className={`details details--${themeClass}`}>
                <div className="left-details">
                    {/* Initiated Date */}
                    <div className="detail-item">
                        <span className={`label label--${themeClass}`}>Initiated</span>
                        <span className={`value value--${themeClass}`}>{createdDate}</span>
                    </div>

                    {/* Payment ID with Tooltip */}
                    <div
                        className="detail-item"
                        data-tooltip-id={`tooltip-id-${payment.id}`}
                        data-tooltip-content={`Payment ID: ${payment.id}`}
                    >
                        <span className={`label label--${themeClass}`}>
                            ID
                        </span>
                        <span className={`value value--${themeClass}`}>{payment.id || 'N/A'}</span>
                        {/* Tooltip for Payment ID */}
                        <Tooltip
                            id={`tooltip-id-${payment.id}`}
                            place="top"
                            effect="solid"
                            className='payment-id-tooltip' // Optional: For custom styling
                        />
                    </div>
                </div>

                <div className="right-details">
                    {/* Net Amount */}
                    <div className="detail-item">
                        <span className={`label label--${themeClass}`}>Net Amount</span>
                        <span className={`value value--${themeClass}`}>
                            {payment.balance_transaction && payment.balance_transaction.net
                                ? formatMoney(payment.balance_transaction.net, payment.balance_transaction.currency, true)
                                : '0.00'}{' '}
                        </span>
                    </div>

                    {/* Platform Fee */}
                    <div className="detail-item">
                        <span className={`label label--${themeClass} platform-fee-mobile`}>Platform Fee</span>
                        <span className={`label label--${themeClass} platform-fee-tablet`}>Platform Transaction Fee</span>
                        <span className={`value value--${themeClass}`}>
                            {payment.balance_transaction && payment.balance_transaction.fee
                                ? formatMoney(payment.balance_transaction.fee, payment.balance_transaction.fee_currency, true)
                                : 'N/A'}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentCardComponent;
