import moment from 'moment';

export const formatTimestamp = (createdAt) => {
  const now = moment();
  const notificationTime = moment(createdAt);
  const diffInDays = now.diff(notificationTime, 'days');
  const diffInHours = now.diff(notificationTime, 'hours');
  const diffInMinutes = now.diff(notificationTime, 'minutes');

  if (diffInDays > 0) {
    return `${diffInDays} d`;
  } else if (diffInHours > 0) {
    return `${diffInHours} h`;
  } else if (diffInMinutes > 0) {
    return `${diffInMinutes} m`;
  } else {
    return '';
  }
};


export const formatCurrency = (value, currency = 'USD') => {
  return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
  }).format(value / 100 );
};
