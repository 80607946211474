import React from 'react';
import { Link } from 'react-router-dom';
import Card from '../../Components/Card/Card';
import './EarningsCard.scss';

export default function EarningsCard({grossVolume, numberOfCustomersToday, numberOfPayments, currency}) {
  const formatMoney = (amount) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(amount);
  };

  return (
    <Link className='earnings-card' to="/earnings">
      <Card>
        <div className='earnings-card__content'>
          <div className='earnings-card__content__info'>
            <p className='earnings-card__content__info-label'>Gross Volume</p>
            <h3 className='earnings-card__content__info-value'>{formatMoney(grossVolume)}</h3>
          </div>
          <div className='earnings-card__content__info'>
            <p className='earnings-card__content__info-label'>Payments</p>
            <h3 className='earnings-card__content__info-value'>{numberOfPayments}</h3>
          </div>
          <div className='earnings-card__content__info'>
            <p className='earnings-card__content__info-label'>Campaigns</p>
            <h3 className='earnings-card__content__info-value'>{numberOfCustomersToday}</h3>
          </div>
        </div>
      </Card>
    </Link>
  );
}
