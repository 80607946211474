// src/Components/PROMOTIONCHECKOUTMODAL/PromotionCheckoutModal.jsx

import React, { useContext, useEffect, useState, useCallback } from 'react';
import './PromotionCheckoutModal.scss';
import ThemeContext from '../../context/ThemeContext';
import TikTokIcon from "../../assets/icons/tiktok.png";
import YoutubeIcon from "../../assets/icons/youtube.svg";
import Spotify from "../../assets/icons/spotify.png";
import InstagramIcon from "../../assets/icons/instagram.png";
import { formatMoney } from "../../utils/moneyUtils";
import ConfirmedIcon from './ConfirmedIcon';
import SpinnerIcon from './SpinnerIcon';
import FailedIcon from './FailedIcon';
import { apiPost } from '../../utils/apiUtils';
import AddressForm from '../AddressForm/AddressForm';

const PromotionCheckoutModal = ({
      isOpen,
      setIsOpen,
      promotionDetails,
      sendCheckoutRequest,
      purchaseConfirmed,
      setPurchaseConfirmed,
      message,
      setMessage,
      allowPreApprovalCheckout,
      taxCalculation,
      setTaxCalculation,
      billingDetails,
      setBillingDetails,
}) => {
      // console.log(billingDetails)
      const { themeMode } = useContext(ThemeContext);
      const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';
      // State Management
      const [taxError, setTaxError] = useState(null); // For error handling during tax calculation
      const [isTaxLoading, setIsTaxLoading] = useState(false); // Loading state for tax calculation
      const [currentStep, setCurrentStep] = useState(1); // Step management: 1 - Address, 2 - Confirmation

      // Reset modal state on close
      const handleClose = () => {
            setIsOpen(false);
            setMessage("");
            setPurchaseConfirmed(false);
            setCurrentStep(1);
            // setBillingDetails({});
            setTaxError(null);
      };

      // Proceed to next step (Confirmation)
      const handleNext = () => {
            setCurrentStep(2);
      };

      // Confirm and process the purchase
      const handleConfirmPurchase = async () => {
            setPurchaseConfirmed("processing");
            setMessage("");

            // Calculate total amount (assuming promotionDetails.price is in main currency unit, e.g., dollars)
            const taxAmount = taxCalculation?.tax_breakdown?.[0]?.amount || 0;
            const totalAmount = promotionDetails.price + taxAmount;

            try {
                  await sendCheckoutRequest(promotionDetails._id, totalAmount, billingDetails);
            } catch (error) {
                  console.error("Checkout error:", error);
                  setMessage("Failed to process the purchase. Please try again.");
                  setPurchaseConfirmed("failed");
            }
      };

      // Format text utility
      const formatText = (text) => {
            return text
                  .replace(/_/g, ' ')
                  .toLowerCase()
                  .replace(/(^\w|\s\w)/g, m => m.toUpperCase());
      };

      // Fetch tax calculation based on billing details
      const fetchTaxCalculation = useCallback(async () => {
            setIsTaxLoading(true);
            setTaxError(null);
            try {
                  const { taxCalculation } = await apiPost(`/creator/tax-calculation/${promotionDetails._id}`, {
                        billingDetails: billingDetails.address,
                  },);
                  // console.log("Tax calculation result:", taxCalculation);
                  setTaxCalculation(taxCalculation);
            } catch (error) {
                  console.error("Error fetching tax calculation:", error);
                  setTaxError("Failed to calculate taxes. Please try again later.");
            } finally {
                  setIsTaxLoading(false);
            }
      }, [promotionDetails._id, setTaxCalculation, billingDetails]);

      // Trigger tax calculation when moving to confirmation step
      useEffect(() => {
            if (isOpen && !allowPreApprovalCheckout && currentStep === 2) { // Fetch only when modal is open and on step 2
                  fetchTaxCalculation();
            }
      }, [allowPreApprovalCheckout, fetchTaxCalculation, isOpen, currentStep]);

      // Prevent rendering if modal is not open
      if (!isOpen) return null;

      //   console.log("Promotion Details:", promotionDetails);

      return (
            <div className="promotion-checkout-modal">
                  <div className="promotion-checkout-modal__overlay" onClick={handleClose} />
                  <div className={`promotion-checkout-modal__content promotion-checkout-modal__content--${themeClass}`}>
                        <div className="promotion-checkout-modal__container">
                              {currentStep === 1 ? (
                                    <AddressForm
                                          handleNext={handleNext}
                                          handleClose={handleClose}
                                          onAddressComplete={(address) => setBillingDetails(address)}
                                          themeClass={themeClass}
                                          billingDetails={billingDetails}
                                    />
                              ) : (
                                    // Step 2: Confirmation and Purchase
                                    <>
                                          {/* Header */}
                                          <div className="promotion-checkout-modal__header">
                                                <div className="promotion-checkout-modal__title-container">
                                                      <h2 className={`promotion-checkout-modal__title ${purchaseConfirmed ? "promotion-checkout-modal__title--ml" : ""}`}>
                                                            Checkout
                                                            <SpinnerIcon themeClass={themeClass} purchaseConfirmed={purchaseConfirmed} />
                                                            <ConfirmedIcon themeClass={themeClass} purchaseConfirmed={purchaseConfirmed} />
                                                            <FailedIcon themeClass={themeClass} purchaseConfirmed={purchaseConfirmed} />
                                                      </h2>
                                                      {/* Status Icons */}

                                                </div>
                                                <p className="promotion-checkout-modal__subtitle">Promotion</p>
                                          </div>

                                          {/* Promotion Information */}
                                          <div className="promotion-checkout-modal__info">
                                                <div className="promotion-checkout-modal__name-price">
                                                      <p className="promotion-checkout-modal__name">{promotionDetails.name || "Promotion"}</p>
                                                      <p className="promotion-checkout-modal__price">{formatMoney(promotionDetails.price, promotionDetails.currency)}</p>
                                                </div>
                                                <p className="promotion-checkout-modal__description">
                                                      {promotionDetails.description.length > 100
                                                            ? `${promotionDetails.description.substring(0, 100)}...`
                                                            : promotionDetails.description}
                                                </p>

                                                <div className="promotion-checkout-modal__platform-details">
                                                      <span className="promotion-checkout-modal__detail">Content Rights: {formatText(promotionDetails.contentRights)}</span>
                                                      <span className="promotion-checkout-modal__divider"> | </span>
                                                      <span className="promotion-checkout-modal__detail">Duration: {formatText(promotionDetails.duration)}</span>
                                                      <span className="promotion-checkout-modal__divider"> | </span>
                                                      <span className="promotion-checkout-modal__detail">Reuse: {formatText(promotionDetails.reuseAfter)}</span>
                                                </div>
                                                <div className="promotion-checkout-modal__platform-details">
                                                      <div className={`promotion-checkout-modal__detail promotion-checkout-modal__detail--${themeClass}`}>
                                                            <img src={YoutubeIcon} className='promotion-checkout-modal__icon' alt='YouTube icon' /> {promotionDetails?.youtube?.quantity || 0} Post
                                                      </div>
                                                      <span className="promotion-checkout-modal__divider"> | </span>
                                                      <div className={`promotion-checkout-modal__detail promotion-checkout-modal__detail--${themeClass}`}>
                                                            <img src={InstagramIcon} className='promotion-checkout-modal__icon' alt='Instagram icon' /> {promotionDetails?.instagram?.quantity || 0} Post
                                                      </div>
                                                      <span className="promotion-checkout-modal__divider"> | </span>
                                                      <div className={`promotion-checkout-modal__detail promotion-checkout-modal__detail--${themeClass}`}>
                                                            <img src={TikTokIcon} className='promotion-checkout-modal__icon' alt='TikTok icon' /> {promotionDetails?.tiktok?.quantity || 0} Post
                                                      </div>
                                                      <span className="promotion-checkout-modal__divider"> | </span>
                                                      <div className={`promotion-checkout-modal__detail promotion-checkout-modal__detail--${themeClass}`}>
                                                            <img src={Spotify} className='promotion-checkout-modal__icon' alt='Spotify icon' /> {promotionDetails?.spotifyPodcast?.quantity || 0} Episode
                                                      </div>
                                                </div>
                                          </div>

                                          {/* Billing Details */}
                                          {/* <div className="promotion-checkout-modal__billing-details">
                                                <h3>Billing Details</h3>
                                                <p>{billingDetails.name}</p>
                                                <p>{billingDetails.line1}</p>
                                                {billingDetails.line2 && <p>{billingDetails.line2}</p>}
                                                <p>{billingDetails.city}, {billingDetails.state} {billingDetails.postal_code}</p>
                                                <p>{billingDetails.country}</p>
                                                </div> 
                                          */}

                                          {/* Line Items (Taxes) */}
                                          <div className="promotion-checkout-modal__line-items">
                                                <ul>
                                                      <li className="promotion-checkout-modal__line-item">
                                                            <span className="promotion-checkout-modal__line-item-description">Taxes</span>
                                                            <span className="promotion-checkout-modal__price">
                                                                  {isTaxLoading
                                                                        ? "Calculating..."
                                                                        : formatMoney(taxCalculation?.tax_breakdown?.[0]?.amount || 0, promotionDetails.currency, true)}
                                                            </span>
                                                      </li>
                                                </ul>
                                          </div>

                                          {/* Total Amount */}
                                          <div className="promotion-checkout-modal__total">
                                                <span>Total ({promotionDetails.currency}):</span>
                                                <span className="promotion-checkout-modal__price">
                                                      {isTaxLoading
                                                            ? "Calculating..."
                                                            : formatMoney((promotionDetails.price * 100) + (taxCalculation?.tax_breakdown?.[0]?.amount || 0), promotionDetails.currency, true)}
                                                </span>
                                          </div>

                                          {/* Disclaimer */}
                                          <p className="promotion-checkout-modal__disclaimer">
                                                By confirming this purchase, you authorize us to charge your linked payment method
                                                (card or pre-authorized bank account) upon the creator's acceptance of the campaign request.
                                                The creator reserves the right to accept or decline the campaign request,
                                                and no charges will apply if the request is declined. By clicking "Purchase", you agree to the terms and conditions
                                                of our website www.chequeai.com and the creator's terms and conditions.
                                          </p>

                                          {/* Purchase Confirmation or Purchase Actions */}
                                          {purchaseConfirmed ? (
                                                <div>
                                                      <div className={`promotion-checkout-modal__confirmation promotion-checkout-modal__confirmation--${themeClass}`}>
                                                            <p>
                                                                  {purchaseConfirmed === "processing"
                                                                        ? "Processing..."
                                                                        : purchaseConfirmed === "failed"
                                                                              ? "Payment Failed"
                                                                              : "Purchased"}
                                                            </p>
                                                      </div>
                                                      {message && (
                                                            <div className="promotion-checkout-modal__error">
                                                                  <p className="promotion-checkout-modal__error-message">{message}</p>
                                                            </div>
                                                      )}
                                                </div>
                                          ) : (
                                                <div className="promotion-checkout-modal__actions">
                                                      <button
                                                            className="promotion-checkout-modal__button promotion-checkout-modal__button--cancel"
                                                            onClick={handleClose}
                                                            disabled={purchaseConfirmed === "processing"} // Prevent closing during processing
                                                      >
                                                            Cancel
                                                      </button>
                                                      <button
                                                            className={`promotion-checkout-modal__button promotion-checkout-modal__button--confirm promotion-checkout-modal__button--confirm--${themeClass}`}
                                                            onClick={handleConfirmPurchase}
                                                            disabled={isTaxLoading || purchaseConfirmed === "processing"} // Disable during tax loading or processing
                                                      >
                                                            {purchaseConfirmed === "processing" ? "Processing..." : "Purchase"}
                                                      </button>
                                                </div>
                                          )}

                                          {/* Display Tax Calculation Error if any */}
                                          {taxError && (
                                                <div className="promotion-checkout-modal__error">
                                                      <p className="promotion-checkout-modal__error-message">{taxError}</p>
                                                </div>
                                          )}
                                    </>
                              )}
                        </div>
                  </div>
            </div>
      );
};

export default PromotionCheckoutModal;
