import React from 'react';
import './ViewProductModal.scss';
import productIcon from '../../assets/defaults/product.png';
import { Link } from 'react-router-dom';

const ViewProductModel = ({ isOpen, setIsOpen, onClose }) => {




  return (
    <div className={`view-product-modal`}>
      <div className="view-product-modal__overlay" onClick={onClose} />
      <div className="view-product-modal__content">
        <div className='view-product-modal__container'>
          <div className='view-product-modal__header'>
            <img className='view-product-modal__img' src={isOpen.images.length > 0 ? isOpen.images[0].url : productIcon} alt={isOpen?.name} />
          </div>
          <div className='view-product-modal__body'>
            <div className='view-product-modal__row'>
              <p className='view-product-modal__name'>{isOpen?.name}</p>
              <Link to={isOpen?.url} className='view-product-modal__link'>
                <p>$ {isOpen?.price}</p>
              </Link>
            </div>
            <p className='view-product-modal__details'>{isOpen?.sku}</p>

            {/* <p>{isOpen?.url}</p> */}
            <p className='view-product-modal__description'>{isOpen?.description}</p>
          </div>
          <div className='edit-post-modal__footer'>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProductModel;
