
import React, { useContext } from 'react';
import './ColorDropDown.scss';
import ThemeContext from '../../context/ThemeContext';

const ColorDropdown = ({ label, selectedColor, setSelectedColor }) => {
    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

    const handleColorChange = (color) => {
        setSelectedColor(color);
    };

    return (
        <div className={`color-dropdown color-dropdown--${themeClass}`}>
            <button style={{ backgroundColor: selectedColor }} className={`color-dropbtn color-dropbtn--${themeClass}`}>
                <p className={`color-dropdown-label color-dropdown-label--${themeClass}`}>{""}</p>
            </button>
            <div className={`color-dropdown-content color-dropdown-content--${themeClass}`}>
           
            
                <div
                    style={{ backgroundColor: 'lightgrey' }}
                    className={`color-dropdown-item color-dropdown-item--${themeClass}`}
                    onClick={() => handleColorChange('lightgrey')}
                ></div>
                <div
                    style={{ backgroundColor: '#000000' }}
                    className={`color-dropdown-item color-dropdown-item--${themeClass}`}
                    onClick={() => handleColorChange('black')}
                ></div>
                <div
                    style={{ backgroundColor: '#c326ae' }}
                    className={`color-dropdown-item color-dropdown-item--${themeClass}`}
                    onClick={() => handleColorChange('#c326ae')}
                ></div>
            </div>
        </div>
    );
};

export default ColorDropdown;
