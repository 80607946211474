import React, { useState, useContext } from "react";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import ThemeContext from "../../context/ThemeContext";
import "./DonateModal.scss";

// Initialize Stripe with your public key
const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_STRIPE_KEY);

// Constants for minimum and maximum donation amounts
const MINIMUM_DONATION_AMOUNT = 0.5; // Minimum Stripe donation amount in USD
const MAXIMUM_DONATION_AMOUNT = 250000; // Maximum donation amount, can be easily changed

const DonateModal = ({
  user,
  onClose,
  suggestedAmounts = [5, 10, 20, 50],
  anon = false,
  loggedInUser,
  userEmail = "",
  customDonations,
  defaultUserCurrency,
}) => {
  const [amount, setAmount] = useState("");
  const [customAmount, setCustomAmount] = useState(false);
  const [message, setMessage] = useState("");
  const [anonymous, setAnonymous] = useState(anon);
  const [email, setEmail] = useState(userEmail); // State to store email input
  const [currency, setCurrency] = useState(defaultUserCurrency || "USD");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(""); // New state to store error messages
  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === "light" ? "light-theme" : "dark-theme";

  // Mapping of currency codes to symbols
  const currencySymbols = {
    USD: "$",
    CAD: "$",
    EUR: "€",
    GBP: "£",
    AUD: "A$",
    JPY: "¥",
    // Add other currencies as needed
  };

  // Formatter for amounts with commas and two decimal places, using selected currency
  const formatAmount = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 2,
    }).format(value);
  };

  const handleDonate = async () => {
    // Clear any previous errors
    setError("");

    // Convert amount to a number to ensure proper validation
    const donationAmount = parseFloat(amount);

    // Validate the donation amount is within the allowed range
    if (!donationAmount || donationAmount < MINIMUM_DONATION_AMOUNT) {
      return setError(
        `Please enter a valid donation amount of at least ${formatAmount(
          MINIMUM_DONATION_AMOUNT
        )}.`
      );
    }
    if (donationAmount > MAXIMUM_DONATION_AMOUNT) {
      return setError(
        `The maximum allowed donation amount is ${formatAmount(
          MAXIMUM_DONATION_AMOUNT
        )}.`
      );
    }

    if (!anonymous && !email) {
      return setError("Please enter your email address.");
    }

    try {
      setLoading(true);

      // Create donation session on the backend
      let response;
      if (loggedInUser) {
        response = await axios.post(`/user/${user}/donations/create`, {
          amount: donationAmount,
          message,
          currency,
          anonymous,
          email: anonymous ? null : email, // Only send email if not anonymous
        });
      } else {
        response = await axios.post(
          `/user/${user}/donations/create/anonymous`,
          {
            amount: donationAmount,
            message,
            currency,
            anonymous,
            email: anonymous ? null : email, // Only send email if not anonymous
          }
        );
      }

      const { sessionId } = response.data;

      // Redirect to Stripe Checkout page
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({ sessionId });

      if (error) {
        console.error("Stripe Checkout error:", error);
        setError("An error occurred while redirecting to Stripe.");
        setLoading(false);
      } else {
        setSuccess(true);
      }
    } catch (error) {
      console.error("Error creating donation:", error);
      setError("An error occurred while processing your donation.");
      setLoading(false);
    }
  };

  const handleSuggestedAmountClick = (value) => {
    setAmount(value.toFixed(2)); // Ensure two decimal places for suggested amounts
    setCustomAmount(false); // Hide custom input when suggested amount is selected
    setError(""); // Clear any previous errors
  };

  // Enforce max limit for custom amount input
  const handleAmountInputChange = (e) => {
    const inputAmount = parseFloat(e.target.value);

    if (inputAmount <= MAXIMUM_DONATION_AMOUNT) {
      setAmount(inputAmount.toFixed(2)); // Ensure two decimal places
    } else {
      setAmount(MAXIMUM_DONATION_AMOUNT.toFixed(2)); // Limit to max amount
    }
  };

  return (
    <div className="donate-modal">
      <div className="donate-modal__overlay" onClick={onClose} />
      <div
        className={`donate-modal__content donate-modal__content--${themeClass}`}
      >
        <h2 className="donate-modal__title">
          Support {user.charAt(0).toUpperCase() + user.slice(1)}
        </h2>
        {success ? (
          <p className="donate-modal__success">
            Thank you for your donation!
          </p>
        ) : (
          <>
            {amount && (
              <h1
                key={amount + currency} // Include currency in key to re-render on currency change
                className="donate-modal__final-amount"
              >
                {formatAmount(parseFloat(amount))}
              </h1>
            )}

            {/* Suggested Amount Buttons */}
            <div className="donate-modal__suggested-amounts">
              {suggestedAmounts.map((value) => (
                <button
                  key={value}
                  className={`donate-modal__suggested-amount${
                    parseFloat(value) === parseFloat(amount)
                      ? ` donate-modal__suggested-amount--active donate-modal__suggested-amount--active--${themeClass}`
                      : ""
                  } donate-modal__suggested-amount--${themeClass}`}
                  onClick={() => handleSuggestedAmountClick(value)}
                >
                  {formatAmount(value)}
                </button>
              ))}
            </div>

            {/* Custom Amount Input */}

            {customDonations && (
              <button
                className={`donate-modal__custom-amount-btn donate-modal__custom-amount-btn--${themeClass}`}
                onClick={() => setCustomAmount(true)}
              >
                Custom Amount
              </button>
            )}

            {customAmount && (
              <div className="donate-modal__input-group">
                <label
                  htmlFor="amount"
                  className={`donate-modal__label donate-modal__label--${themeClass}`}
                >
                  Amount
                </label>
                <div
                  className={`donate-modal__input-wrapper donate-modal__input-wrapper--${themeClass}`}
                >
                  <div
                    className="donate-modal__input-container"
                    data-symbol={currencySymbols[currency]}
                  >
                    <input
                      type="number"
                      id="amount"
                      className={`donate-modal__input donate-modal__input--${themeClass}`}
                      value={amount}
                      onChange={handleAmountInputChange} // Enforce max limit here
                      min={MINIMUM_DONATION_AMOUNT}
                      max={MAXIMUM_DONATION_AMOUNT}
                      step="0.01" // Allow decimals
                    />
                  </div>
                  <select
                    className={`donate-modal__currency-select donate-modal__currency-select--${themeClass}`}
                    value={currency}
                    onChange={(e) => setCurrency(e.target.value)}
                  >
                    <option value="USD">USD</option>
                    <option value="CAD">CAD</option>
                    <option value="EUR">EUR</option>
                    <option value="GBP">GBP</option>
                    <option value="AUD">AUD</option>
                    <option value="JPY">JPY</option>
                  </select>
                </div>
              </div>
            )}

            {/* Donate Anonymously Checkbox */}
            <div className="donate-modal__options-wrapper">
              <div className="donate-modal__checkbox-group">
                <label
                  className={`donate-modal__checkbox-label donate-modal__checkbox-label--${themeClass}`}
                >
                  Donate Anonymously
                  <input
                    type="checkbox"
                    checked={anonymous}
                    onChange={() => setAnonymous(!anonymous)}
                  />
                </label>
              </div>
              {!customAmount && (
                <div className="donate-modal__options-wrapper__left">
                  <select
                    className={`donate-modal__currency-select donate-modal__currency-select--${themeClass}`}
                    value={currency}
                    onChange={(e) => setCurrency(e.target.value)}
                  >
                    <option value="USD">USD</option>
                    <option value="CAD">CAD</option>
                    <option value="EUR">EUR</option>
                    <option value="GBP">GBP</option>
                    <option value="AUD">AUD</option>
                    <option value="JPY">JPY</option>
                  </select>
                </div>
              )}
            </div>

            {/* Email Input for non-anonymous donations */}
            {!anonymous && (
              <div className="donate-modal__input-group">
                <label
                  htmlFor="email"
                  className={`donate-modal__label donate-modal__label--${themeClass}`}
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  className={`donate-modal__input donate-modal__input--${themeClass}`}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="fan.account@chequeai.com"
                  required={!anonymous}
                />
              </div>
            )}

            {/* Message Input */}
            <div className="donate-modal__input-group">
              <label
                htmlFor="message"
                className={`donate-modal__label donate-modal__label--${themeClass}`}
              >
                Message
              </label>
              <textarea
                id="message"
                className={`donate-modal__textarea donate-modal__textarea--${themeClass}`}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows="3"
                maxLength="500"
                placeholder={`Add a message for ${user}`}
              />
            </div>

            {/* Error Message */}
            {error && <p className="donate-modal__error">{error}</p>}

            {/* Donate Button */}
            <button
              className={`donate-modal__button donate-modal__button--${themeClass}`}
              onClick={handleDonate}
              disabled={loading}
            >
              {loading ? "Processing..." : "Donate"}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default DonateModal;
