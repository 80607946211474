// src/utils/categoryOptions.js

import { formatLabel } from './helperFunctions';

export const groupedCategoryOptions = [
  {
    label: "Men's Categories",
    options: [
      { label: formatLabel("mens_clothing"), value: "mens_clothing" },
      { label: formatLabel("mens_shirts"), value: "mens_shirts" },
      { label: formatLabel("mens_pants"), value: "mens_pants" },
      { label: formatLabel("mens_accessories"), value: "mens_accessories" },
      { label: formatLabel("mens_shoes"), value: "mens_shoes" },
      { label: formatLabel("mens_grooming"), value: "mens_grooming" },
    ],
  },
  {
    label: "Women's Categories",
    options: [
      { label: formatLabel("womens_clothing"), value: "womens_clothing" },
      { label: formatLabel("womens_dresses"), value: "womens_dresses" },
      { label: formatLabel("womens_tops"), value: "womens_tops" },
      { label: formatLabel("womens_bottoms"), value: "womens_bottoms" },
      { label: formatLabel("womens_outerwear"), value: "womens_outerwear" },
      { label: formatLabel("womens_activewear"), value: "womens_activewear" },
      { label: formatLabel("womens_lingerie"), value: "womens_lingerie" },
      { label: formatLabel("womens_swimwear"), value: "womens_swimwear" },
      { label: formatLabel("womens_makeup"), value: "womens_makeup" },
      { label: formatLabel("womens_skincare"), value: "womens_skincare" },
      { label: formatLabel("womens_haircare"), value: "womens_haircare" },
      { label: formatLabel("womens_fragrances"), value: "womens_fragrances" },
      { label: formatLabel("womens_nail_care"), value: "womens_nail_care" },
      { label: formatLabel("womens_accessories"), value: "womens_accessories" },
      { label: formatLabel("womens_shoes"), value: "womens_shoes" },
    ],
  },
  {
    label: "General Categories",
    options: [
      { label: formatLabel("electronics"), value: "electronics" },
      { label: formatLabel("home_kitchen"), value: "home_kitchen" },
      { label: formatLabel("beauty"), value: "beauty" },
      { label: formatLabel("health_personal_care"), value: "health_personal_care" },
      { label: formatLabel("sports"), value: "sports" },
      { label: formatLabel("shoes"), value: "shoes" },
      { label: formatLabel("watches"), value: "watches" },
      { label: formatLabel("books"), value: "books" },
      { label: formatLabel("grocery"), value: "grocery" },
      { label: formatLabel("toys_games"), value: "toys_games" },
      { label: formatLabel("jewelry"), value: "jewelry" },
      { label: formatLabel("furniture"), value: "furniture" },
      { label: formatLabel("pet_supplies"), value: "pet_supplies" },
      { label: formatLabel("automotive"), value: "automotive" },
      { label: formatLabel("video_games"), value: "video_games" },
      { label: formatLabel("office_electronics"), value: "office_electronics" },
      { label: formatLabel("office_supplies"), value: "office_supplies" },
      { label: formatLabel("software"), value: "software" },
      { label: formatLabel("tools_home_improvement"), value: "tools_home_improvement" },
      { label: formatLabel("musical_instruments"), value: "musical_instruments" },
      { label: formatLabel("baby_products"), value: "baby_products" },
      { label: formatLabel("garden_outdoor"), value: "garden_outdoor" },
      { label: formatLabel("smart_home_devices"), value: "smart_home_devices" },
      { label: formatLabel("art_crafts"), value: "art_crafts" },
      { label: formatLabel("lighting"), value: "lighting" },
      { label: formatLabel("outdoor_gear"), value: "outdoor_gear" },
      { label: formatLabel("travel_accessories"), value: "travel_accessories" },
      { label: formatLabel("seasonal_items"), value: "seasonal_items" },
      { label: formatLabel("kids_clothing"), value: "kids_clothing" },
      { label: formatLabel("unisex_beauty"), value: "unisex_beauty" },
      { label: formatLabel("industrial_scientific"), value: "industrial_scientific" },
    ],
  },
  {
    label: "Other Categories", // Group for newly created categories
    options: [],
  },
];
