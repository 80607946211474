import React, { useContext } from "react";
import "./ViewCampaignTeamModal.scss";
import team from "../../assets/defaults/team.png";
import teamLight from "../../assets/defaults/teamLight.png";
import InstagramIcon from "../../assets/icons/instagram.png";
import ThemeContext from "../../context/ThemeContext";

const ViewCampaignTeamModal = ({ isOpen, setIsOpen, onClose }) => {
  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === "light" ? "light-theme" : "dark-theme";

  const picture = themeClass === "light-theme" ? teamLight : team;

  return (
    <div className={`view-product-modal`}>
      <div className="view-product-modal__overlay" onClick={onClose} />
      <div className="view-product-modal__content">
        <div className="view-product-modal__container">
          <div className="view-product-modal__header">
            <img
              className="view-product-modal__img"
              src={isOpen?.image ? isOpen.image.url : picture}
              alt={isOpen?.name}
            />
          </div>
          <div className="view-product-modal__body">
            <div className="view-product-modal__row">
              <p className="view-product-modal__name">{isOpen?.name} <span style={{ color: "#aaa", opacity: '0.33', marginLeft: '0.2rem', fontSize: '1.2rem' }}>{isOpen?.jobTitle}</span></p>  
              <div onClick={() => window.open(`https://instagram.com/${isOpen?.instagram}`, '_blank')} className="view-product-modal__link">
                <img
                  src={InstagramIcon}
                  alt="instagram icon"
                  className="cp-prod-pg__product__img"
                  style={{ width: "20px", height: "20px", borderRadius: "0", marginRight: "0.25rem" }}
                />
                <p className="view-product-modal__insta__text">{isOpen?.instagram}</p>
              </div>
            </div>
            <p className="view-product-modal__details">{isOpen?.email}</p>

            {/* <p>{isOpen?.url}</p> */}
            <p className="view-product-modal__description">
              {isOpen?.description}
            </p>
          </div>
          <div className="edit-post-modal__footer"></div>
        </div>
      </div>
    </div>
  );
};

export default ViewCampaignTeamModal;
