import React, { useContext, useState, useEffect, useCallback, useMemo } from 'react';
import './DonationsPage.scss';
import ThemeContext from '../../context/ThemeContext';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import UserRoute from '../../Routes/Auth';
import { apiGet } from '../../utils/apiUtils';
import { Tooltip } from 'react-tooltip';
import SettingsIcon from './SettingsIcon';
import StatsWidget from '../../Components/StatsWidget/StatsWidget';
import BackIcon from '../../Components/Navigation/icons/BackIcon';
import NextIcon from '../../Components/Navigation/icons/NextIcon';
import { Context } from '../../context';
import { formatCurrency } from '../../utils/dateUtils';
import Navigation from '../../Components/Navigation/Navigation';
import Card from '../../Components/Card/Card';

const ITEMS_PER_PAGE = 7;

export default function DonationsPage() {
    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';
    const { state: { user }, } = useContext(Context);

    const [donations, setDonations] = useState([]);
    const [stats, setStats] = useState({
        newSupporters: 0,
        donationsAmount: 0,
        repeatSupporters: 0,
        totalDonations: 0,
    });
    const [stripeData, setStripeData] = useState({
        totalAmount: 0,
        currency: "USD",
        totalDonations: 0,
    });

    const location = useLocation();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);

    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const tfParam = queryParams.get('tf') || 'today';

    const [tf, setTf] = useState(tfParam);
    const [mode, setMode] = useState(tfParam);

    const timeframes = useMemo(() => [
        { tf: 'today', mode: 'Today' },
        { tf: '1W', mode: 'Week' },
        { tf: '1M', mode: 'Month' },
        { tf: '1Y', mode: 'Year' },
    ], []);

    const getModeFromTf = useCallback((tfValue) => {
        const timeframe = timeframes.find(item => item.tf === tfValue);
        return timeframe ? timeframe.mode : 'Today';
    }, [timeframes]);

    useEffect(() => {
        setMode(getModeFromTf(tf));
    }, [tf, getModeFromTf]);

    const currentIndex = timeframes.findIndex(item => item.tf === tf);

    const fetchDonations = useCallback(async () => {
        try {
            const response = await apiGet(`/donations?tf=${tf}`);
            setDonations([...response].reverse());

        } catch (error) {
            console.error('Error fetching donations:', error);
            setDonations([]);
        }
    }, [tf]);

    const fetchStats = useCallback(async () => {
        try {
            const response = await apiGet(`/donations/stats?tf=${tf}`);
            // console.log(response);
            setStats(response.dbData || {
                newSupporters: 0,
                donationsAmount: 0,
                repeatSupporters: 0,
                totalDonations: 0,
            });
            setStripeData(response.stripeData || {
                totalAmount: 0,
                currency: "USD",
                totalDonations: 0,
            });
        } catch (error) {
            console.error('Error fetching stats:', error);
            setStats({
                newSupporters: 0,
                donationsAmount: 0,
                repeatSupporters: 0,
                totalDonations: 0,
            });
        }
    }, [tf]);

    useEffect(() => {
        if (user && user?.stripeAccountId !== "" && Object.keys(user?.stripeSeller).length !== 0 && user?.stripeSeller?.charges_enabled === true )  {
            fetchDonations();
            fetchStats();
        }
   
    }, [fetchDonations, fetchStats, user]);

    useEffect(() => {
        const currentTf = queryParams.get('tf') || 'today';
        setTf(currentTf);
    }, [location.search, queryParams]);

    
    const isMobile = window.innerWidth <= 768; // Detect mobile based on screen width (adjust as needed)

    const formatDate = (dateString) => {
        if (!dateString) return 'Date not available';
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'Invalid Date';
    
        const options = isMobile
            ? { month: '2-digit', day: '2-digit', year: '2-digit' }
            : { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
    
        return new Intl.DateTimeFormat('en-US', options).format(date);
    };

    

    const handleNextPage = () => {
        if (currentPage * ITEMS_PER_PAGE < donations.length) {
            setCurrentPage((prev) => prev + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prev) => prev - 1);
        }
    };

    const currentDonations = donations.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
    );

    const nextButtonDisabled = currentPage * ITEMS_PER_PAGE >= donations.length;
    const prevButtonDisabled = currentPage === 1;

    const handleNextDateChange = () => {
        const nextIndex = (currentIndex + 1) % timeframes.length;
        const nextTf = timeframes[nextIndex].tf;

        const newQueryParams = new URLSearchParams(location.search);
        newQueryParams.set('tf', nextTf);
        navigate({ search: newQueryParams.toString() });
    };

    const handleBackDateChange = () => {
        const prevIndex = (currentIndex - 1 + timeframes.length) % timeframes.length;
        const prevTf = timeframes[prevIndex].tf;

        const newQueryParams = new URLSearchParams(location.search);
        newQueryParams.set('tf', prevTf);
        navigate({ search: newQueryParams.toString() });
    };

    if ( user?.stripeAccountId === "" || Object.keys(user?.stripeSeller).length === 0 || user?.stripeSeller?.charges_enabled === false) {
        return (
          <UserRoute>
            <div className={`donations-settings donations-settings--${themeClass}`}>
              <Navigation heading={"Donations"} back={true} />
              <Card className={`card card--donations card--donations--${themeClass}`}>
                <h2 className={`donations-settings__title ${themeClass}`}>
                  <span className="donations-settings__branding">Connect Stripe</span>
                </h2>
                <p className="donations-settings__message">
                  { user?.stripeSeller && Object.keys(user?.stripeSeller).length === 0 && user?.stripeAccountId !== "" 
                    ? "Your Stripe account is connected to this account. Please complete the Stripe connection." : "Stripe is not connected to this account. Please connect your Stripe account to enable donations."
                    }
                </p>
                <div className="donations-settings__connect-button__div">
                  <Link to="/settings/payments/"
                    className={`donations-settings__connect-button donations-settings__connect-button--${themeClass}`}
                  >
                    Connect Stripe
                  </Link>
                </div>
              </Card>
            </div>
          </UserRoute>
        );
      }

    return (
        <UserRoute>
            <div className={`donations-page donations-page--${themeClass}`}>
                <div className="donations-page__header">
                    <div className='donations-page__header-container'>
                        <h2 className="donations-page__title">Donations</h2>
                        <div className="donations__tf">
                            <button
                                className="navigation__back-button donations-tf--button"
                                onClick={handleBackDateChange}
                            >
                                <BackIcon themeMode={themeMode} />
                            </button>
                            <h2 className={`donations-tf__title donations-tf__title--${themeClass}`}>
                                {mode}
                            </h2>
                            <button
                                className="navigation__back-button donations-tf--button"
                                onClick={handleNextDateChange}
                            >
                                <NextIcon themeMode={themeMode} />
                            </button>
                        </div>
                    </div>
                    <Link to="/settings/donations" className="donations-page__settings-link">
                        <SettingsIcon themeMode={themeMode} />
                    </Link>
                </div>
                <div className="donations-page__stats">
                    <StatsWidget
                        title="New Supporters"
                        value={stats.newSupporters || 0}
                        themeClass={themeClass}
                        color="primary"
                    />
                    <StatsWidget
                        title="($) Donations"
                        // value={`$${(stripeData.donationsAmount / 100 || 0).toFixed(2)}${stripeData?.currency?.toUpperCase() || user?.currency}`}
                        value={`${formatCurrency(stripeData.donationsAmount, stripeData?.currency?.toUpperCase() || user?.currency)}`}
                        themeClass={themeClass}
                        color="secondary"
                        toolTipValue={`Total After Platform Fees: ${formatCurrency(stripeData.net, stripeData?.currency?.toUpperCase() || user?.currency) }`}
                    />
                    <StatsWidget
                        title="Donations"
                        value={stats.totalDonations || 0}
                        themeClass={themeClass}
                        color="tertiary"
                    />
                </div>

                <div className="donations-page__list">
                    {currentDonations.length === 0 ? (
                        <p className="donations-page__empty-message">No donations yet</p>
                    ) : (
                        currentDonations.map((donation) => (
                            <div key={donation._id} className={`donation-card donation-card--${themeClass}`}>
                                <div className={`donation-card__header donation-card__header--${themeClass}`}>
                                    <div className="donation-card__info">
                                        <p className={`donation-card__donator donation-card__donator--${themeClass}`}>
                                            {donation.supporterName || 'Anonymous'}

                                        </p>
                                        <span className={`donation-card__status donation-card__status--${themeClass}`}>
                                            {donation.status.slice(0, 1).toUpperCase() + donation.status.slice(1) }
                                        </span>
                                        <p
                                            className={`donation-card__date donation-card__date--${themeClass}`}
                                            data-tooltip-id={`tooltip-${donation._id}`}
                                            data-tooltip-content={`Donation ID: ${donation._id}`}
                                        >
                                            {formatDate(donation.timestamp)}
                                            <Tooltip id={`tooltip-${donation._id}`} place="top" effect="solid" />
                                        </p>

                                    </div>
                                    <div className="donation-card__amount-date">

                                        <p className={`donation-card__message donation-card__message--${themeClass}`}>
                                            {donation.message || 'No message provided'}
                                        </p>

                                        <p
                                            className={`donation-card__amount donation-card__amount--${themeClass}`}
                                            data-tooltip-id={`tooltip-amount-${donation._id}`}
                                            data-tooltip-content={`Total After Platform Fees: $${(donation.totalAfterFees / 100 || 0).toFixed(2)} ${donation.currency}`}
                                        >
                                            {formatCurrency(donation.amount, donation.currency)}
                                        </p>
                                        <Tooltip id={`tooltip-amount-${donation._id}`} place="top" effect="solid" />
                                    </div>
                                </div>
                                <div className="donation-card__body">
                                    {/* Additional content */}
                                </div>
                            </div>
                        ))
                    )}
                </div>

                <div className="pagination-controls">
                    <button
                        className={`pagination-button pagination-button--${themeClass}`}
                        onClick={handlePreviousPage}
                        disabled={prevButtonDisabled}
                        style={{ opacity: prevButtonDisabled ? 0.5 : 1 }}
                    >
                        Prev
                    </button>
                    <button
                        className={`pagination-button pagination-button--${themeClass}`}
                        onClick={handleNextPage}
                        disabled={nextButtonDisabled}
                        style={{ opacity: nextButtonDisabled ? 0.5 : 1 }}
                    >
                        Next
                    </button>
                </div>
            </div>
        </UserRoute>
    );
}
