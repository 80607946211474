import React, { useState, useEffect } from "react";
import axios from "axios";
import "./TwoFactorAuth.scss";
import QrCodeIcon from "./QrCodeIcon.jsx";
import ClickIcon from "./ClickIcon.jsx";

const TwoFactorAuth = ({ user, themeMode }) => {
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [enabledDevices, setEnabledDevices] = useState([]); // List of 2FA devices
  const [message, setMessage] = useState({ type: "", content: "" }); // Notification message
  const [isSettingUp, setIsSettingUp] = useState(false); // State to manage setup process

  const themeModifier = themeMode === "light" ? "light" : "dark";

  // Get list of 2FA devices on component mount
  useEffect(() => {
    get2FADevices();
  }, []);

  const get2FADevices = async () => {
    try {
      const response = await axios.get(`/2fa/devices`);
      setEnabledDevices(response.data.devices);
    } catch (error) {
      console.error("Error fetching 2FA devices:", error);
    }
  };

  const handle2FASetup = async () => {
    try {
      const setupResponse = await axios.get(`/2fa/setup`);
      const { qrCodeUrl } = setupResponse.data;
      setQrCodeUrl(qrCodeUrl);
      setIsSettingUp(true); // Set isSettingUp to true while processing
      setMessage({
        type: "success",
        content: "Scan the QR code to set up 2FA.",
      });
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      setMessage({
        type: "error",
        content: `Error during 2FA setup: ${errorMessage}`,
      });
    }
  };

  const handle2FAVerification = async () => {
    try {
      const verificationResponse = await axios.post(`/2fa/verify`, {
        verificationCode,
        deviceName,
      });
      if (verificationResponse.data.verified) {
        setMessage({ type: "success", content: "2FA Setup Successful!" });
        get2FADevices(); // Refresh device list after setup
        setVerificationCode(""); // Clear the verification code input
        setDeviceName(""); // Clear the device name input
        setIsSettingUp(false); // Set isSettingUp to false after setup
      } else {
        setMessage({ type: "error", content: "2FA Setup Failed!" });
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      setMessage({
        type: "error",
        content: `Error during 2FA verification: ${errorMessage}`,
      });
    }
  };

  const remove2FADevice = async (deviceId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API}/2fa/device/${deviceId}`);
      setEnabledDevices(
        enabledDevices.filter((device) => device._id !== deviceId)
      );
      setMessage({ type: "success", content: "2FA Device Removed." });
    } catch (error) {
      console.error("Error removing 2FA device:", error);
      setMessage({ type: "error", content: "Failed to Remove 2FA Device." });
    }
  };

  return (
    <div className={`two-factor-auth two-factor-auth--${themeModifier}`}>
      {/* Inline Notification Message */}
      {message.content && (
        <div
          className={`two-factor-auth__message two-factor-auth__message--${message.type}`}
        >
          {message.content}
        </div>
      )}

      {!isSettingUp && (
        <button
          className={`two-factor-auth__setup-button two-factor-auth__setup-button--${themeModifier}`}
          onClick={handle2FASetup}
        >
          <div className="two-factor-auth__qr-icon">
            <QrCodeIcon themeMode={themeModifier} size={32} color="black" />

          </div>
          <p className="two-factor-auth__text">Setup 2-FA</p>
          <div className="two-factor-auth__qr-icon">
            <ClickIcon themeMode={themeModifier} size={32} color={themeModifier === 'light' ? '#e4e4e4;' : '181818'} />

          </div>
        </button>
      )}

      {/* {isSettingUp && (
        <div className="two-factor-auth__loading">Setting up 2FA, please wait...</div>
      )} */}

      {qrCodeUrl && isSettingUp && (
        <div className="two-factor-auth__qr-code-container">
          <img
            src={qrCodeUrl}
            alt="QR Code for 2FA"
            className="two-factor-auth__qr-code"
          />
          <div className="two-factor-auth__verification">
            <label className="two-factor-auth__verification-label">
              2FA Device Name
            </label>
            <input
              type="text"
              placeholder="Enter device name (e.g., My Phone)"
              value={deviceName}
              onChange={(e) => setDeviceName(e.target.value)}
              className={`two-factor-auth__input two-factor-auth__input--${themeModifier}`}
            />
            <label className="two-factor-auth__verification-label">
              Enter Verification Code
            </label>
            <input
              type="tel" // Changed to 'tel' to display numeric keypad
              pattern="[0-9]*" // Optional: Helps ensure numeric input
              inputMode="numeric" // Suggests numeric input mode to mobile devices
              placeholder="Enter verification code (e.g., 123 456)"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              className={`two-factor-auth__input two-factor-auth__input--${themeModifier}`}
            />
            <button
              className={`two-factor-auth__verify-button two-factor-auth__verify-button--${themeMode}`}
              onClick={handle2FAVerification}
            >
              Verify 2FA
            </button>
          </div>
        </div>
      )}

      {enabledDevices.length > 0 && (
        <div className="two-factor-auth__devices-list">
          <h3 className="two-factor-auth__devices-title">Devices</h3>
          <ul className="two-factor-auth__device-list">
            {enabledDevices.map((device) => (
              <li
                key={device._id}
                className={`two-factor-auth__device-item two-factor-auth__device-item--${themeMode}`}
              >
                <div className="two-factor-auth__device-details">
                  <span>{device.deviceName}</span>
                  {device.enabled ? (
                    <span className="two-factor-auth__device-enabled">
                      Enabled
                    </span>
                  ) : (
                    <span className="two-factor-auth__device-disabled">
                      Disabled
                    </span>
                  )}
                </div>
                <button
                  className="two-factor-auth__remove-button"
                  onClick={() => remove2FADevice(device._id)}
                >
                  <i className="two-factor-auth__remove-icon">❌</i>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default TwoFactorAuth;
