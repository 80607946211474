// src/pages/FacebookCallbackPage.js

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserRoute from "../../Routes/Auth";
import { apiPost } from "../../utils/apiUtils";

const FacebookCallbackPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Extract access_token from URL fragment
    const fragment = window.location.hash.substring(1); // Remove the '#' character
    const params = new URLSearchParams(fragment);
    const accessToken = params.get('access_token');

    if (accessToken) {
      // Send the access token to the backend
      const sendTokenToBackend = async () => {
        try {
          const response = await apiPost('/auth/facebook/callback', { accessToken });
          console.log(response);
          navigate('/settings/socials/facebook/callback/success');
        } catch (error) {
          console.error('Error sending token to backend:', error);
          navigate('/settings/socials/facebook/callback/error');
        }
      };
      sendTokenToBackend();
    } else {
      navigate('/settings/socials/facebook/callback/error');
    }
  }, [navigate]);

  return (
    <UserRoute>
      <div className="facebook-callback">
        Processing...
      </div>
    </UserRoute>
  );
};

export default FacebookCallbackPage;
