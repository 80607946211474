import React, { useContext } from 'react';
import './ConfirmModal.scss';
import InputField from '../../Components/InputField/InputField'
import ThemeContext from '../../context/ThemeContext';

const ConfirmModal = ({ children, isOpen, setIsOpen, onClose, message, onConfirm, label, subMessage }) => {

    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

    const handleConfirm = () => {
        onConfirm(isOpen)
        onClose()
    }


    return (
        <div className={`confirm-modal`}>
            <div className="confirm-modal__overlay" onClick={onClose} />
            <div className={`confirm-modal__content confirm-modal__content--${themeClass}`}>
                <div className='confirm-modal__container'>
                    <div className={`confirm-modal__header confirm-modal__header--${themeClass}`}>
                        <p>{message}?</p>
                        {subMessage && <p className='confirm-modal__sub-message'>{subMessage}</p>}
                    </div>
                    {
                        label && (
                            <div className={`confirm-modal__body confirm-modal__body--${themeClass}`}>
                                <InputField
                                    label={label}
                                    name="isOpen"
                                    type="text"
                                    value={isOpen === true ? "" : isOpen}
                                    onChange={(event) => setIsOpen(event.target.value)}
                                />
                            </div>
                        )
                    }
                    {children}
                    <div className={`confirm-modal__footer confirm-modal__footer--${themeClass}`}>
                        <button className={`confirm-modal__button confirm-modal__button--cnl confirm-modal__button--cnl--${themeClass} confirm-modal__button--${themeClass}`} onClick={onClose}>
                            <p className='confirm-modal__cancel'>Cancel</p>
                        </button>
                        <button className={`confirm-modal__button confirm-modal__button--${themeClass}`} onClick={() => handleConfirm()}>Confirm</button>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmModal;
