import React, { useContext } from 'react';

import './TimelineOverview.scss'
import ThemeContext from '../../context/ThemeContext';

export default function TimelineOverview({ tf, setTf }) {

    const { themeMode } = useContext(ThemeContext);

    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';
    return (
        <div className='timeline-container'>
            <div className={`timeline-container__content timeline-container__content--${themeClass} ${tf === 'today' ? `timeline-container__content--active timeline-container__content--active--${themeClass}` : ''}`} onClick={() => { setTf("today") }}>
                <p className='timeline-container__text'>Today</p>
            </div>
            <div className={`timeline-container__content timeline-container__content--${themeClass} ${tf === '1W' ? `timeline-container__content--active timeline-container__content--active--${themeClass}` : ''}`} onClick={() => { setTf("1W") }}>
                <p className='timeline-container__text'>1W</p>
            </div>
            <div className={`timeline-container__content timeline-container__content--${themeClass} ${tf === '1M' ? `timeline-container__content--active timeline-container__content--active--${themeClass}` : ''}`} onClick={() => { setTf("1M") }}>
                <p className='timeline-container__text'>4W</p>
            </div>
            <div className={`timeline-container__content timeline-container__content--${themeClass} ${tf === '1Y' ? `timeline-container__content--active timeline-container__content--active--${themeClass}` : ''}`} onClick={() => { setTf("1Y") }}>
                <p className='timeline-container__text'>1Y</p>
            </div>
            <div className={`timeline-container__content timeline-container__content--${themeClass} ${tf === 'MTD' ? `timeline-container__content--active timeline-container__content--active--${themeClass}` : ''}`} onClick={() => { setTf("MTD") }}>
                <p className='timeline-container__text'>MTD</p>
            </div>
            <div className={`timeline-container__content timeline-container__content--${themeClass} ${tf === 'QTD' ? `timeline-container__content--active timeline-container__content--active--${themeClass}` : ''}`} onClick={() => { setTf("QTD") }}>
                <p className='timeline-container__text'>QTD</p>
            </div>
            <div className={`timeline-container__content timeline-container__content--${themeClass} ${tf === 'YTD' ? `timeline-container__content--active timeline-container__content--active--${themeClass}` : ''}`} onClick={() => { setTf("YTD") }}>
                <p className='timeline-container__text'>YTD</p>
            </div>
            <div className={`timeline-container__content timeline-container__content--${themeClass} ${tf === 'ALL' ? `timeline-container__content--active timeline-container__content--active--${themeClass}` : ''}`} onClick={() => { setTf("ALL") }}>
                <p className='timeline-container__text'>ALL</p>
            </div>
        </div >
    )
}
