import React, { useContext } from "react";
import { Link } from "react-router-dom";
import ThemeContext from "../../context/ThemeContext"; // Import ThemeContext for dark mode
import "./LoginHeader.scss"; // Import the SCSS file
import ChequeaiLogo from "../../assets/icons/chequai-logo.png";

const LoginHeader = ({ enabled = true, logout = false }) => {
  const { themeMode } = useContext(ThemeContext); // Get current theme (light or dark)
  const themeClass = themeMode === "light" ? "light-theme" : "dark-mode";

  return (
    <header className={`login-header ${themeClass}`}>
      <div className="login-header__content">
        {/* Logo */}
        <Link to="/" className="login-header__logo">
          <img src={ChequeaiLogo} alt="ChequeAI Logo" />
          <h1 className="login-header__title">Chequeai</h1>
        </Link>

        {/* Navigation buttons */}
        {enabled && (
          <div className="login-header__nav">
            <Link
              to="/signup"
              className={`login-header__nav__item login-header__nav__item--${themeClass}
          login-header__nav__item__hide-mobile`}
            >
              Sign Up
            </Link>
            <Link
              to={"/login"}
              className={`login-header__nav__item login-header__nav__item--${themeClass}`}
            >
              Login
            </Link>
          </div>
        )}

        {logout && (
          <div className="login-header__nav">
            <Link
              to="/logout"
              className={`login-header__nav__item login-header__nav__item--${themeClass}`}>
              Logout
            </Link>
          </div>
        )}
      </div>
    </header>
  );
};

export default LoginHeader;
