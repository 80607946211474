import React, { useEffect, useState, useContext } from 'react'
import UserRoute from '../../Routes/Auth'
import Card from '../../Components/Card/Card'
import Navigation from '../../Components/Navigation/Navigation'
import './CampaignDetailsPage.scss'
import { useParams, Link } from 'react-router-dom'
import axios from "axios"
import HeartIcon from '../../assets/icons/heart.svg'
import HeartFilledIcon from '../../assets/icons/heart-fill.svg'
// import DeleteIcon from '../../assets/icons/x-circle-fill.svg'
import EditPostModalForm from '../../Components/EditPostModalForm/EditPostModalForm'
import OptionsModalBox from '../../Components/OptionsModalBox/OptionsModalBox';
import OptionsIcon from '../../assets/icons/three-dots.svg'
import ViewPostModel from '../../Components/ViewPostModal/ViewPostModal'
import CampaignHeader from '../../Components/CampaignHeader/CampaignHeader'
import ThemeContext from '../../context/ThemeContext';
import CampaignInfoModal from '../../Components/CampaignInfoModal/CampaignInfoModal'
import CampaignSupportModal from '../../Components/CampaignSupportModal/CampaignSupportModal'



export default function CampaignDetailsPage() {
  const { id } = useParams();
  const [campaign, setCampaign] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(null);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [isViewPostModalOpen, setIsViewPostModalOpen] = useState(false);
  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';
  const [showOptions, setShowOptions] = useState(false);
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);
  const [isCampaignInfoModalOpen, setIsCampaignInfoModalOpen] = useState(false);



  useEffect(() => {
    const fetchCampaign = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/campaigns/${id}`);
        // console.log(response.data);
        const fetchedCampaign = response.data;
        setCampaign(fetchedCampaign);
      } catch (error) {
        console.error(error);
      }
    };
    if (!campaign) {
      fetchCampaign();
    }
  }, [campaign, id,]);

  const handleDeletePost = async (imageURL) => {
    try {
      const { data } = await axios.delete(`${process.env.REACT_APP_API}/campaigns/${id}/campaign-content/${imageURL}`);
      console.log('IMAGE REMOVED', data);
      // Handle the response data and state updates
      console.log('Updated');
      window.location.reload();

    } catch (err) {
      console.log('Failed to remove. Try again later.', err);
    }
  }

  const handleUpdatePost = async (imageURL, imageData) => {
    try {
      const { data } = await axios.put(`${process.env.REACT_APP_API}/campaigns/${id}/campaign-content/${imageURL}`, { imageData });
      console.log('IMAGE REMOVED', data);
      // Handle the response data and state updates
      console.log('Updated');
      window.location.reload();

    } catch (err) {
      console.log('Failed to remove. Try again later.', err);
    }
  }

  const handlePostConfirmed = async (imageURL, imageData) => {
    try {
      const { data } = await axios.put(`${process.env.REACT_APP_API}/campaigns/${id}/campaign-content/${imageURL}/is-confirmed`, { imageData });
      console.log('Updated', data);
      window.location.reload();

    } catch (err) {
      console.log('Failed to remove. Try again later.', err);
    }
  }


  return (
    <UserRoute>
      <div className='cp-dets-pg'>
        <Navigation heading={campaign?.campaignName} back={true} edit={`/campaigns/edit/${id}`} />
        <Card className="card--margin" >
          <div className="campaign-card__header">
            <div className='campaign-card__header__left'>
              <CampaignHeader setIsCampaignInfoModalOpen={setIsCampaignInfoModalOpen} setShowOptions={setShowOptions}
                setIsSupportModalOpen={setIsSupportModalOpen} showOptions={showOptions} isSupportModalOpen={isSupportModalOpen}

              />
            </div>
            <div className='campaign-card__header__right' style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem', alignItems: 'center', opacity: '0.65', marginRight: '1.2rem' }}>
              <span style={{ opacity: '0.40', marginBottom: '0.2rem' }}>Approve Campaign</span>
              <img src={HeartFilledIcon} alt='heart' />
            </div>
          </div>
          <div className="cp-dets-pg__body">
            {campaign && campaign.campaignPosts && campaign.campaignPosts.length > 0 ? (
              campaign.campaignPosts.map((post, index) => {
                return (
                  <div className={`cp-dets-pg__post cp-dets-pg__post--${themeClass}`} key={index}>
                    <div className='cp-dets-pg__post__left'>
                      <img
                        src={post.thumbnail ? post.thumbnail : post.url}
                        className='cp-dets-pg__img'
                        alt='product'
                        onClick={() => setIsViewPostModalOpen(post)}
                      />
                    </div>
                    <div className='cp-dets-pg__post__right'>
                      <div className='cp-dets-pg__post__info'>
                        <div className='cp-dets-pg__title-section'>
                          <h4 className='cp-dets-pg__text'>{`${index + 1}. Campaign Shot`}</h4>
                          <img
                            className='cp-dets-pg__post__actions__icons'
                            src={OptionsIcon}
                            alt="options icon"
                            onClick={() => setIsOptionsOpen(post)}
                          />
                        </div>
                        {post.location && (
                          <p className='cp-dets-pg__caption'>
                            {post.location ? post.location : 'Caption'}
                          </p>
                        )}
                        <p className='cp-dets-pg__caption'>
                          {post.caption ? post.caption : 'Caption'}
                        </p>
                      </div>
                      <div className='cp-dets-pg__post__actions'>
                        <img
                          className='cp-dets-pg__post__actions__icons'
                          src={post.isConfirmed ? HeartFilledIcon : HeartIcon}
                          alt="heart icon"
                          onClick={() => handlePostConfirmed(post.key, post)}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="cp-dets-pg__no-posts">No campaign posts available</p>
            )}

          </div>
          {(campaign?.status !== "Completed" && campaign?.status !== 'Post Campaign') && (
            <Link className={`cp-dets-pg__add-post cp-dets-pg__add-post--${themeClass}`} to={`/campaigns/${id}/post/add`}>
              <p className='cp-dets-pg__add'>+</p>
              <p className='cp-dets-pg__text'>Add Campaign Post</p>
            </Link>
          )}

        </Card>
        {isOptionsOpen !== false && (
          <OptionsModalBox
            isOpen={isOptionsOpen}
            setIsOpen={setIsOptionsOpen}
            handleRemovePost={() => handleDeletePost(isOptionsOpen)}
            handleViewPost={() => setIsViewPostModalOpen(isOptionsOpen)}
            handleEditPost={() => setIsEditModalOpen(isOptionsOpen)}
          />
        )}
        {isEditModalOpen && <EditPostModalForm isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)} onSave={(imageURL, imageData) => handleUpdatePost(imageURL, imageData)} setIsOpen={setIsEditModalOpen} />}
        {isViewPostModalOpen && <ViewPostModel isOpen={isViewPostModalOpen} onClose={() => setIsViewPostModalOpen(false)} />}
        {isSupportModalOpen && (
          <CampaignSupportModal
            isOpen={isSupportModalOpen}
            onClose={() => setIsSupportModalOpen(false)}
            campaignId={id}
          />
        )
        }
        <CampaignInfoModal isOpen={isCampaignInfoModalOpen} onClose={() => setIsCampaignInfoModalOpen(false)} themeMode={themeMode} />

      </div>
    </UserRoute>
  )
}
