import React, { useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './Navigation.scss';
import {askForNotificationPermission } from "../../Routes/Auth";

import ThemeContext from '../../context/ThemeContext';
import displayPicture from '../../assets/defaults/displayPicture.png';

import BackArrowIcon from './icons/BackArrowIcon'
import EditIcon from './icons/EditIcon';
import DollarIcon from './icons/DollarIcon';
import PlusIcon from './icons/PlusIcon';
import PushNotificationIcon from './icons/PushNotification';
import OutBoxIcon from './icons/OutBoxIcon';
import DeleteIcon from './icons/DeleteIcon';
import DarkModeIcon from './icons/DarkModeIcon';
import BackIcon from './icons/BackIcon';
import NextIcon from './icons/NextIcon';
import ListViewIcon from './icons/ListViewIcon';
import ListViewFilledIcon from './icons/ListViewFilledIcon';


export default function Navigation({ avatar, modifer, navRight, title, heading, add, edit, payments,
     back, link, outgoing, handleDelete, darkMode, pushNotification, handleDateChange, 
     tf, mode, listViewEnabled, handleListView, listView, backLink }) {
    const navigate = useNavigate();


    const handleGoBack = () => {
        navigate(-1);
    };

    const { themeMode, toggleTheme } = useContext(ThemeContext);


    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';






    return (
        <div className={`navigation navigation--${themeClass} ${modifer}`}>
            <div className={`navigation__left navigation__left--${modifer}`}> 
                {back && (
                    <div className="navigation__back">
                        <button className="navigation__back-button" onClick={handleGoBack}>
                            <BackArrowIcon themeMode={themeMode} />
                        </button>
                    </div>
                )}
                {backLink && (
                    <div className="navigation__back">
                        <Link className="navigation__back-button" to={backLink}>
                            <BackArrowIcon themeMode={themeMode} />
                        </Link>
                    </div>
                )}
                {(heading || title) && (<div className="navigation__heading">
                    {title && <h3>{title}</h3>}
                    {heading && <h4 className={`navigation__title--${modifer} navigation__title navigation__title--${themeClass}`}>{heading}</h4>}
                </div>)}
                {
                    tf && (
                        <div className="earnings-tf">
                            <button className="navigation__back-button earnings-tf--button" onClick={() => { handleDateChange(tf) }}>
                                <BackIcon themeMode={themeMode} />
                            </button>
                            <h2 className="">{mode}</h2>
                            <button className="navigation__back-button earnings-tf--button" onClick={() => { handleDateChange(tf) }}>
                                <NextIcon themeMode={themeMode} />
                            </button>
                        </div>

                    )
                }
                {link && (
                    <Link to={`/${link}`}>
                        {avatar && <img className="navigation__avatar" src={avatar !== "/avatar.png" ?  avatar : displayPicture } alt="profile" />}
                        <h2 style={{marginBottom: '0.24rem'}} className="">{link}</h2>
                    </Link>)
                }

            </div>
            {navRight && (
                    <div className='navigation__right'>
                        {outgoing && (
                            <div className="navigation__add">
                                <Link className="navigation__add-link" to={`/campaigns/outgoing`}>
                                    <OutBoxIcon themeMode={themeMode} />
                                </Link>
                            </div>
                        )}
                        {payments && (
                            <div className="navigation__add">
                                <Link className="navigation__add-link" to={`/settings/payments`}>
                                    {/* <img className="navigation__add-img" src={dollarIcon} alt="plus sign" /> */}
                                    <DollarIcon themeMode={themeMode} />
                                </Link>
                            </div>
                        )}
                        {edit && (
                            <div className="navigation__add">
                                <Link className="navigation__add-link" to={`${edit}`}>
                                    <EditIcon themeMode={themeMode} />
                                </Link>
                            </div>
                        )}
                        {add && (
                            <div className="navigation__add">
                                <Link className="navigation__add-link" to={`/settings/promotions/add`}>
                                    <PlusIcon themeMode={themeMode} />
                                </Link>
                            </div>
                        )}
                        {handleDelete && (
                            <div className="navigation__add" onClick={handleDelete} >
                                <DeleteIcon themeMode={themeMode} />
                            </div>
                        )}
                        {darkMode && (
                            <div className="navigation__add" onClick={toggleTheme} >
                                <DarkModeIcon themeMode={themeMode} />
                            </div>
                        )}
                        {pushNotification && (
                            <div className="navigation__add" onClick={askForNotificationPermission} >
                                <PushNotificationIcon themeMode={themeMode} />
                            </div>
                        )}
                        {listViewEnabled && (
                            <div className="navigation__add" onClick={handleListView} >
                                {listView ? <ListViewFilledIcon themeMode={themeMode} /> : <ListViewIcon themeMode={themeMode} />}
                            </div>
                        )}
                    </div>
                )
            }
        </div>
    );
}

