//./src/utils/constants.js

export const userTypeOptions = [
      { value: "creator", label: "Creator" },
      { value: "brand", label: "Brand" },
];

export const industryOptions = [
      { value: "Technology", label: "Technology" },
      { value: "Fashion", label: "Fashion" },
      { value: "Food & Beverage", label: "Food & Beverage" },
      { value: "Automotive", label: "Automotive" },
      { value: "Health & Wellness", label: "Health & Wellness" },
      { value: "Finance", label: "Finance" },
      { value: "Entertainment", label: "Entertainment" },
      { value: "Education", label: "Education" },
      { value: "Real Estate", label: "Real Estate" },
      { value: "Retail", label: "Retail" },
      { value: "Travel & Tourism", label: "Travel & Tourism" },
];
export const companySizeOptions = [
      { value: "1-10", label: "1-10 employees" },
      { value: "11-50", label: "11-50 employees" },
      { value: "51-200", label: "51-200 employees" },
      { value: "201-500", label: "201-500 employees" },
      { value: "500+", label: "500+ employees" },
];


export const currencyOptions = [
      { value: "USD", label: "USD: US Dollar", symbol: "$" },
      { value: "CAD", label: "CAD: Canadian Dollar", symbol: "$" },
      { value: "EUR", label: "EUR: Euro", symbol: "€" },
      { value: "GBP", label: "GBP: British Pound", symbol: "£" },
      { value: "AUD", label: "AUD: Australian Dollar", symbol: "$" },
      { value: "NZD", label: "NZD: New Zealand Dollar", symbol: "$" },
      { value: "JPY", label: "JPY: Japanese Yen", symbol: "¥" },
      { value: "AED", label: "AED: United Arab Emirates Dirham", symbol: "د.إ" },
      { value: "INR", label: "INR: Indian Rupee", symbol: "₹" },
      { value: "KRW", label: "KRW: South Korean Won", symbol: "₩" },
];

export const currencySymbols = {
      USD: "$",
      CAD: "$",
      EUR: "€",
      GBP: "£",
      JPY: "¥",
      AUD: "$",
      NZD: "$",
      AED: "د.إ",
      INR: "₹",
      KRW: "₩",
};



export const categoryOptionsSearch = [
      { name: "Artist", url: "artist", color: "linear-gradient(328deg, #9B30FF, #BA55D3)" }, // Purple shades for creativity
      { name: "Actor", url: "actor", color: "linear-gradient(328deg, #FFD700, #FFA500)" }, // Gold to Orange for the spotlight
      { name: "Athlete", url: "athlete", color: "linear-gradient(328deg, #32CD32, #00FF7F)" }, // Lime Green for energy and health
      { name: "Automotive", url: "automotive", color: "linear-gradient(328deg, #FFA500, #FF4500)" }, // Orange shades for speed and excitement
      { name: "Beauty", url: "beauty", color: "linear-gradient(328deg, #FF69B4, #FF1493)" }, // Pink shades for femininity and style
      { name: "Content Producer", url: "content_producer", color: "linear-gradient(328deg, #9370DB, #8A2BE2)" }, // Purple shades for creativity
      { name: "Content Creator", url: "content_creator", color: "linear-gradient(328deg, #8B008B, #9400D3)" }, // Dark Magenta for creativity
      { name: "Comedian", url: "comedian", color: "linear-gradient(328deg, #FFD700, #FF4500)" }, // Gold to Orange for humor and entertainment
      { name: "Content Pages", url: "content_pages", color: "linear-gradient(328deg, #00CED1, #20B2AA)" }, // Turquoise shades for calm and creativity
      { name: "Fashion", url: "fashion", color: "linear-gradient(321deg, rgba(193,34,34,1) 44%, rgba(214,8,139,1) 84%)" }, // Pink shades for style and elegance
      { name: "Fitness", url: "fitness", color: "linear-gradient(328deg, #32CD32, #3CB371)" }, // Green shades for health and vitality
      { name: "Food", url: "food", color: "linear-gradient(328deg, #FF4500, #FF6347)" }, // Orange Red for appetite and flavor
      { name: "Gaming", url: "gaming", color: "linear-gradient(328deg, #9400D3, #8A2BE2)" }, // Dark Violet for excitement and energy
      { name: "Health", url: "health", color: "linear-gradient(328deg, #1E90FF, #00BFFF)" }, // Blue shades for trust and tranquility
      { name: "Lifestyle", url: "lifestyle", color: "linear-gradient(328deg, #FF1493, #FF69B4)" }, // Pink shades for variety and vibrancy
      { name: "Live Streaming", url: "live_streaming", color: "linear-gradient(328deg, #4B0082, #6A5ACD)" }, // Indigo for live interaction
      { name: "Music", url: "music", color: "linear-gradient(321deg, rgba(193,34,34,1) 44%, rgba(214,8,139,1) 84%)" }, // Peach shades for creativity and emotion
      { name: "Model", url: "model", color: "linear-gradient(328deg, #FFFF00, #FFD700)" }, // Yellow shades for elegance and visibility
      { name: "Photographer", url: "photographer", color: "linear-gradient(328deg, #228B22, #32CD32)" }, // Green shades for nature and creativity
      { name: "Podcasting", url: "podcasting", color: "linear-gradient(328deg, #FF6347, #FF4500)" }, // Tomato shades for energy and engagement
      { name: "Street Wear", url: "street_wear", color: "linear-gradient(328deg, #808080, #A9A9A9)" }, // Gray shades for urban and modern
      { name: "Tech", url: "tech", color: "linear-gradient(328deg, #00BFFF, #1E90FF)" }, // Blue shades for innovation and trust
      { name: "Travel", url: "travel", color: "linear-gradient(328deg, #ADD8E6, #87CEFA)" }, // Light Blue shades for exploration and freedom
      { name: "Video Editor", url: "video_editor", color: "linear-gradient(328deg, #8FBC8F, #3CB371)" }, // Green shades for creativity and innovation
  ];
  

  export const languageOptions = [
      { value: "english", label: "English" },
      { value: "spanish", label: "Spanish" },
      { value: "french", label: "French" },
      { value: "german", label: "German" },
      { value: "chinese", label: "Chinese" },
      { value: "hindi", label: "Hindi" },
      { value: "punjabi", label: "Punjabi" },
      { value: "arabic", label: "Arabic" },
      { value: "japanese", label: "Japanese" },
      { value: "korean", label: "Korean" },
      { value: "portuguese", label: "Portuguese" },
      { value: "bengali", label: "Bengali" },
      { value: "russian", label: "Russian" },
      { value: "urdu", label: "Urdu" },
      { value: "indonesian", label: "Indonesian" },
      { value: "swahili", label: "Swahili" },
    ];
    

export const ethnicityOptions = [
      { value: 'asian', label: 'Asian' },
      { value: 'east_asian', label: 'East Asian' },
      { value: 'south_asian', label: 'South Asian' },
      { value: 'hispanic_latino', label: 'Hispanic/Latino' },
      { value: 'african_or_african_american', label: 'African/African American' },
      { value: 'middle_eastern', label: 'Middle Eastern/North African' },
      { value: 'caribbean', label: 'Caribbean' },
      { value: 'pacific_islander', label: 'Pacific Islander' },
      { value: 'caucasian_white', label: 'Caucasian/White' },
      { value: 'native_american', label: 'Native American/Indigenous' },
      { value: 'other', label: 'Other' },
    ];

    export const genderOptions = [
      { value: 'male', label: 'Male' },
      { value: 'female', label: 'Female' },
      { value: 'non_binary', label: 'Non-Binary' },
      { value: 'prefer_not_to_say', label: 'Prefer Not to Say' },
      { value: 'other', label: 'Other' },
    ];