import React from 'react';
import './Loading.scss';

const Loading = () => {
  return (
    <div className="loading">
      <span className="loading__emoji" role="img" aria-label="Loading">
        📱
      </span>
    </div>
  );
};

export default Loading;
