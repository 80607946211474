import React from 'react';
import './InsightsCard.scss';

export default function InsightsCard({ title, currentMetric = 0, timeframe, priorMetric = 0, dollar, count, percent, currency }) {
  const generateDates = (timeframe) => {
    const today = new Date();
    let startDate, endDate;

    if (timeframe === 'today') {
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
    } else if (timeframe === '1W') {
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
    } else if (timeframe === '1M') {
      startDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
    } else if (timeframe === '1Y') {
      startDate = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
    } else if (timeframe === 'MTD') {
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      endDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    } else if (timeframe === 'QTD') {
      const quarterStartMonth = Math.floor(today.getMonth() / 3) * 3;
      startDate = new Date(today.getFullYear(), quarterStartMonth, 1);
      endDate = new Date(today.getFullYear(), quarterStartMonth + 3, 1);
    } else if (timeframe === 'YTD') {
      startDate = new Date(today.getFullYear(), 0, 1);
      endDate = new Date(today.getFullYear() + 1, 0, 1);
    } else if (timeframe === 'ALL') {
      startDate = new Date(2000, 0, 1);
      endDate = new Date(today.getFullYear() + 1, 0, 1);
    }

    return { startDate, endDate };
  };

  const { startDate, endDate } = generateDates(timeframe);

  const getPriorDates = (startDate, endDate, timeframe) => {
    const priorStartDate = new Date(startDate);
    const priorEndDate = new Date(endDate);

    if (timeframe === 'today') {
      priorStartDate.setDate(priorStartDate.getDate() - 1);
      priorEndDate.setDate(priorEndDate.getDate() - 1);
    } else if (timeframe === '1W') {
      priorStartDate.setDate(priorStartDate.getDate() - 7);
      priorEndDate.setDate(priorEndDate.getDate() - 7);
    } else if (timeframe === '1M') {
      priorStartDate.setMonth(priorStartDate.getMonth() - 1);
      priorEndDate.setMonth(priorEndDate.getMonth() - 1);
    } else if (timeframe === '1Y') {
      priorStartDate.setFullYear(priorStartDate.getFullYear() - 1);
      priorEndDate.setFullYear(priorEndDate.getFullYear() - 1);
    } else if (timeframe === 'MTD') {
      priorStartDate.setDate(priorStartDate.getDate() - 30);
      priorEndDate.setDate(priorEndDate.getDate() - 30);
    } else if (timeframe === 'QTD') {
      priorStartDate.setMonth(priorStartDate.getMonth() - 3);
      priorEndDate.setMonth(priorEndDate.getMonth() - 3);
    } else if (timeframe === 'YTD') {
      priorStartDate.setDate(priorStartDate.getDate() - 365);
      priorEndDate.setDate(priorEndDate.getDate() - 365);
    } else if (timeframe === 'ALL') {
      priorStartDate.setFullYear(2000, 0, 1);
      priorEndDate.setFullYear(priorEndDate.getFullYear() - 1);
    }

    return { priorStartDate, priorEndDate };
  };

  const { priorStartDate, priorEndDate } = getPriorDates(startDate, endDate, timeframe);

  const formatDate = (date) => {
    if (date) {
      return new Date(date).toLocaleDateString();
    }
    return ''; // or some default value if the date is not valid
  };

  const formatDollarAmount = (amount) => {
    const formattedAmount = (amount / 100).toFixed(2);
    const parts = formattedAmount.split('.');
    const dollars = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return `${dollars}.${parts[1]}`;
  };


  return (
    <div className='insights-card'>
      <div className='insights-card__header'>
        <p className='insights-card__title'>{title}</p>
        <p className='insights-card__percent'>0.00%</p>
      </div>
      <div className='insights-card__body'>
        {dollar && <p className='insights-card__text'>${formatDollarAmount(currentMetric)} {currency}</p>}
        {dollar && <p className='insights-card__text'>${formatDollarAmount(priorMetric)} {currency}</p>}
        {count && <p className='insights-card__text'>{currentMetric}</p>}
        {count && <p className='insights-card__text'>{priorMetric}</p>}
        {percent && <p className='insights-card__text'>{currentMetric}%</p>}
        {percent && <p className='insights-card__text'>{priorMetric}%</p>}
      </div>
      <div className='insights-card__footer'>
        <p className='insights-card__date'>{formatDate(startDate)} - {formatDate(endDate)}</p>
        <p className='insights-card__date'>{`${timeframe}: ${formatDate(priorStartDate)} - ${formatDate(priorEndDate)}`}</p>
      </div>
    </div>
  );
}
