import React, { useContext, useState } from 'react';
import FAQComponent from '../../Components/FAQComponent/FAQComponent';
import UserRoute from '../../Routes/Auth';
import './FAQPage.scss';
import { Context } from '../../context';
import Header from '../../Components/Header/Header';
import ThemeContext from "../../context/ThemeContext";
import MobileMenuModal from '../../Components/MobileMenuModal/MobileMenuModal';


function FAQPage() {

    const { state: { user }, } = useContext(Context);
    const { themeMode } = useContext(ThemeContext);

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';


    const header = <Header user={null} themeClass={themeClass} isMobileMenuOpen={isMobileMenuOpen} setIsMobileMenuOpen={setIsMobileMenuOpen} modifier="padding"/>


    if (!user) {
        return (
            <div className="faq-pg">
                {header}
                <FAQComponent />
                <MobileMenuModal onClose={() => setIsMobileMenuOpen(false)} isMobileMenuOpen={isMobileMenuOpen} setIsMobileMenuOpen={setIsMobileMenuOpen} user={user} themeClass={themeClass} />

            </div>
        );
    }

    return (
        <UserRoute>
            <div className="faq-pg">
                <FAQComponent />
            </div>
        </UserRoute>
    );
}

export default FAQPage;
