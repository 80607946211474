// Tabs.jsx
import React, { useContext } from 'react';
import './Tabs.scss'; // Import the CSS file
import ThemeContext from '../../context/ThemeContext';


const Tabs = ({ activeTab, setActiveTab, label1, label2 }) => {

    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : '';


  return (
    <div className="container">
      <div className="box">
        <ul className="tab-list">
          <li
            className={`tab-item ${activeTab === 0 ? `active active--${themeClass}` : 'inactive'}`}
            onClick={() => setActiveTab(0)}
          >
            {label1}
          </li>
          <li
            className={`tab-item ${activeTab === 1 ? `active active--${themeClass}` : 'inactive'}`}
            onClick={() => setActiveTab(1)}
          >
           {label2}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Tabs;
