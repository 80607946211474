import React from 'react'
import { Link } from 'react-router-dom';
import "./UserLinkCard.scss";
import displayPicture from '../../assets/defaults/displayPicture.png'



export default function UserLinkCard(props) {
    return (

        <Link className={`${props.modifier} user-link`} to={`${props.src}`}>
            {props.icon && <img className={`${props.modifier}__img user-link__img`} src={props.icon === "/avatar.png" ? displayPicture : props.icon} alt="user profile" />}
            <div className={`${props.modifier} user-link__info-container`}>
                {props.title && <p className={`${props.modifier}__title user-link__title`}>{props.title}</p>}
                {props.category && <p className={`${props.modifier}__title user-link__category`}>{props.category}</p>}
            </div>
        </Link>
    )
}
