// src/components/TransactionFilters/TransactionFilters.js

import React from "react";
import Select from "react-select";
import "./TransactionFilters.scss"; // Import the SCSS file
import { selectLightMode, selectDarkMode } from "../../utils/stylesUtils";

const TransactionFilters = ({
  transactionTypeOptions,
  statusOptions,
  paidStatusOptions,
  moneyFilterOptions,
  selectedTransactionType,
  onTransactionTypeChange,
  selectedStatus,
  onStatusChange,
  selectedPaidStatus,
  onPaidStatusChange,
  selectedMoneyFilter,
  onMoneyFilterChange,
  themeMode,
  placeholderText,
}) => {
  return (
    <div className="transaction-filters">
      {/* Transaction Type Filter */}
      <div className="transaction-filters__filter">
        <Select
          classNamePrefix="transaction-filters-select"
          className="transaction-filters__select"
          options={transactionTypeOptions}
          value={selectedTransactionType}
          onChange={onTransactionTypeChange}
          placeholder="Product Category"
          isClearable
          styles={themeMode === "light" ? selectLightMode : selectDarkMode}
        />
      </div>

      {/* Status Filter */}
      <div className="transaction-filters__filter">
        <Select
          classNamePrefix="transaction-filters-select"
          className="transaction-filters__select"
          options={statusOptions}
          value={selectedStatus}
          onChange={onStatusChange}
          placeholder={placeholderText}
          isClearable
          styles={themeMode === "light" ? selectLightMode : selectDarkMode}
        />
      </div>



      {/* Money Filter */}
      <div className="transaction-filters__filter">
        <Select
          classNamePrefix="transaction-filters-select"
          className="transaction-filters__select"
          options={moneyFilterOptions}
          value={selectedMoneyFilter}
          onChange={onMoneyFilterChange}
          placeholder="Amount ($$$)"
          isClearable
          styles={themeMode === "light" ? selectLightMode : selectDarkMode}
        />
      </div>
      {/* Paid Status Filter */}
      <div className="transaction-filters__filter">
        <Select
          classNamePrefix="transaction-filters-select"
          className="transaction-filters__select"
          options={paidStatusOptions}
          value={selectedPaidStatus}
          onChange={onPaidStatusChange}
          placeholder="Payment Status"
          isClearable
          styles={themeMode === "light" ? selectLightMode : selectDarkMode}
        />
      </div>
    </div>
  );
};

export default TransactionFilters;
