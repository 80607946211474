import React, { useEffect, useContext, useCallback } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import './CancelDonatePage.scss';
import axios from 'axios';
import ThemeContext from '../../context/ThemeContext';
import { Context } from '../../context';
import UserRoute from '../../Routes/Auth';

function CancelDonatePage() {
  const { sessionId } = useParams(); // Get the session ID from the URL
  console.log(sessionId);
  const navigate = useNavigate();
  const { themeMode } = useContext(ThemeContext);
  const { state: { user } } = useContext(Context);
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

  // Memoize the cancelDonation function to avoid re-creating it on every render
  const cancelDonation = useCallback(async () => {
    try {
      // Send the cancellation request to the server
      const endpoint = user ? `/donations/failure/${sessionId}` : `/donations/failure/${sessionId}/anonymous`;
      const { data } = await axios.get(endpoint);

      if (!data.success) {
        console.error('Failed to cancel donation');
      }
    } catch (error) {
      console.error('Error cancelling donation:', error);
      // Optionally, redirect to a different failure page or handle the error
      navigate('/donations/failure');
    }
  }, [sessionId, user, navigate]);

  // Trigger the cancellation when the sessionId changes
  useEffect(() => {
    if (sessionId) {
      cancelDonation();
    }
  }, [sessionId, cancelDonation]);

  if (!user) {
    return (
      <div className='cancel-donate-page'>
        <h1 className='cancel-donate-page__title'>Donation Canceled</h1>
        <p className={`cancel-donate-page__text cancel-donate-page__text--${themeClass}`}> 
          It looks like the donation was not completed. Feel free to explore more on our platform or try again.
        </p>
        <div className='cancel-donate-page__link'>
          <Link to="/" className={`cancel-donate-page__link--text cancel-donate-page__link--text--${themeClass}`}>
            Home
          </Link>
        </div>
      </div>
    );
  }

  return (
    <UserRoute>
      <div className='cancel-donate-page'>
        <h1 className='cancel-donate-page__title'>Donation Canceled</h1>
        <p className={`cancel-donate-page__text cancel-donate-page__text--${themeClass} `}>Your donation was not completed. You can try again or explore more on our platform.</p>
        <div className='cancel-donate-page__link'>
          <Link to="/home" className={`cancel-donate-page__link--text cancel-donate-page__link--text--${themeClass}`}>
            Home
          </Link>
        </div>
      </div>
    </UserRoute>
  );
}

export default CancelDonatePage;
