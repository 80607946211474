import React, { useContext } from 'react';
import './PromotionPostCard.scss';
import ThemeContext from '../../context/ThemeContext';
import TikTokIcon from "../../assets/icons/tiktok.png";
import YoutubeIcon from "../../assets/icons/youtube.svg";
import Spotify from "../../assets/icons/spotify.png";
import InstagramIcon from "../../assets/icons/instagram.png";

export default function PromotionPostCard({ name, enabled, posts, urls, tag="Post", modifier="" }) {
    const { themeMode } = useContext(ThemeContext);

    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

    const cardClassName = `post-card${modifier ? ` ${modifier}` : ''}`;

    const getIcon = (name) => {
        switch (name) {
            case 'TikTok':
                return TikTokIcon;
            case 'Instagram':
                return InstagramIcon;
            case 'Youtube':
                return YoutubeIcon;
            case 'Podcast':
                return Spotify;
            default:
                return null;
        }
    };

    return (
        <div className={`${cardClassName} post-card--${themeClass}`}>
            <img src={getIcon(name)} alt={`${name} Icon`} className="" />
            <p className='post-card__details'>{name}</p>
            <p className='post-card__details'>{posts} {tag}</p>
        </div>
    );
}
