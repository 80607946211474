import React, { useState, useRef, useEffect, useContext } from "react";
import { io } from "socket.io-client";
import { differenceInMinutes, differenceInHours, differenceInDays, differenceInMonths, differenceInYears } from "date-fns";
import "./ChatRoom.scss";
import ThemeContext from '../../context/ThemeContext';
import { Context } from "../../context/index";
import { useNavigate } from "react-router-dom";
import Linkify from 'react-linkify';
import DropdownAttachments from "../DropDownAttachments/DropdownAttachments";
import SearchIcon from "./SearchIcon";

function ChatRoom({
  serverUrl,
  currentRoom,
  setCurrentRoom,
  username,
  isNewMessage,
  members,
  messages,
  setMessages,
  setIsViewPostModalOpen,
}) {
  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';
  const [inputValue, setInputValue] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const { state: { user } } = useContext(Context);
  const socketRef = useRef();
  const [recipient, setRecipient] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    socketRef.current = io(serverUrl, {
      query: {
        userId: user?._id,
      },
    });

    socketRef.current.emit("join room", {
      username,
      roomName: currentRoom,
    });

    socketRef.current.on("room created", (roomName) => {
      setCurrentRoom(roomName);
    });

    socketRef.current.on("room joined", (room) => {
      setCurrentRoom(room);
      if (room !== user?._id) {
        navigate(`/messages/${room}`);
      }
    });

    socketRef.current.on('new message', (msg) => {
      setMessages((prevMessages) => [...prevMessages, msg]);
    });

    socketRef.current.on("message history", (messages) => {
      console.log("message history", messages);
      setMessages(messages || []);
    });

    socketRef.current.on("error", ({ messages }) => {
      // console.log(messages);
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, [serverUrl, username, currentRoom, setCurrentRoom, setMessages, user?._id, navigate]);

  const handleCreateRoom = (e) => {
    e.preventDefault();
    if (recipient !== "") {
      socketRef.current.emit("create room", {
        senderName: username,
        recipientName: recipient,
      });
    }

  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newMessage = {
      roomName: currentRoom,
      sender: username,
      message: inputValue,
      timestamp: new Date().toISOString(), // Include a valid timestamp
    };

    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        newMessage.attachments = [
          {
            name: image.name,
            type: image.type,
            data: reader.result, // Base64 encoded image data
          },
        ];
        socketRef.current.emit("chat message", newMessage);
      };
      reader.readAsDataURL(image);
    } else {
      socketRef.current.emit("chat message", newMessage);
    }

    setInputValue("");
    setImage(null);
    setImagePreview(null);
  };


  const messageHistoryRef = useRef(null);

  useEffect(() => {
    const messageHistory = messageHistoryRef.current;
    if (messageHistory) {
      messageHistory.scrollTo(0, messageHistory.scrollHeight);
    }
  }, [messages]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    if (isNaN(date)) return ''; // Check if the date is valid

    const now = new Date();
    const minutes = differenceInMinutes(now, date);
    const hours = differenceInHours(now, date);
    const days = differenceInDays(now, date);
    const months = differenceInMonths(now, date);
    const years = differenceInYears(now, date);

    if (minutes < 1) {
      return 'Just now';
    } else if (minutes < 60) {
      return `${minutes}m`;
    } else if (hours < 24) {
      return `${hours}h`;
    } else if (days < 30) {
      return `${days}d`;
    } else if (months < 12) {
      return `${months}mo`;
    } else {
      return `${years}y`;
    }
  };

  return (
    <div className="new-message">
      {isNewMessage && (
        <form className={`new-message__form `} onSubmit={handleCreateRoom}>
          <input
            type="text"
            className={`new-message__search-input new-message__search-input--${themeClass}`}
            value={recipient}
            placeholder="@username"
            onChange={(e) => setRecipient(e.target.value || "")}
          />
          <button type="submit" className={`new-message__button new-message__button--${themeClass}`} disabled={recipient === ""}>
            <SearchIcon themeClass={themeClass} />
          </button>
        </form>
      )}
      <div
        className={`new-message__history message-history message-history--${themeClass} ${isNewMessage ? "message-history--new" : ""}`}
        ref={messageHistoryRef}
      >

        {messages?.length > 0 ? (
          messages.map((message, index) => (
            <div key={index} className={`message-history__item ${message.sender === username ? `message-history__item--sent message-history__item--sent--${themeClass}` : ""}`}>
              <span className="message-history__sender">{message.sender}</span>
              <div className={`message-history__message-wrapper ${message.sender === username ? `message-history__message-wrapper--sent message-history__message-wrapper--sent--${themeClass}` : ""}`}>
                {message.attachments && message.attachments.length > 0 && (
                  <div className="message-history__attachments">
                    {message.attachments.map((attachment, index) => (
                      <div key={index} className="message-history__attachment">
                        <img style={{ maxWidth: '10rem', maxHeight: '10rem', borderRadius: '1rem' }} onClick={() => { setIsViewPostModalOpen(attachment) }}
                          src={attachment?.url} alt={attachment.name} />
                      </div>
                    ))}
                  </div>
                )}
                <Linkify>
                  <span className={`message-history__message message-history__message--${themeClass}`}>{message.message}</span>
                </Linkify>
                <span className={`message-history__time message-history__time--${themeClass}`}>{formatTimestamp(message.timestamp)}</span>
              </div>
            </div>
          ))
        ) : (
          <div className="message-history--empty">Start a conversation</div>
        )}
      </div>
      <form className="new-message__form" onSubmit={handleSubmit}>
        <DropdownAttachments label={"+"} setImage={setImage} setImagePreview={setImagePreview} />
        <div className={`new-message__input new-message__input--${themeClass}`}>
          {imagePreview && <img className="new-message__img" src={imagePreview} alt="profile" onClick={""} />}
          <input
            className={`new-message__input-field new-message__input-field--${themeClass}`}
            type="text"
            onChange={(e) => setInputValue(e.target.value || "")}
            placeholder="message..."
            value={inputValue}
          />
        </div>
        <button
          type="submit"
          className={`new-message__button new-message__button--${themeClass}`}
          disabled={!inputValue}
        >
          Send
        </button>
      </form>
    </div>
  );
}

export default ChatRoom;
