import React from 'react';

const EditIcon = ({ themeMode }) => {
  const color = themeMode === 'light' ? 'black' : 'white';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28px" height="28px" viewBox="0 0 24 24">
      <g>
        <path d="M3.548,20.921c4.927,0,9.853,0,14.78,0c0.708,0,1.416,0,2.124,0c0.643,0,0.645-1,0-1c-4.927,0-9.853,0-14.78,0c-0.708,0-1.416,0-2.124,0C2.905,19.921,2.904,20.921,3.548,20.921L3.548,20.921z" fill={color} />
        <path d="M9.71,17.164c0.42-0.12,0.81-0.34,1.12-0.65l9.54-9.54c0.68-0.68,0.68-1.79,0-2.47l-0.94-0.93c-0.66-0.66-1.81-0.66-2.47,0l-9.54,9.53c-0.31,0.31-0.53,0.7-0.64,1.12l-0.74,2.76c-0.07,0.26,0,0.53,0.19,0.72c0.14,0.14,0.34,0.22,0.53,0.22L9.71,17.164z M10.12,15.804c-0.18,0.19-0.42,0.32-0.67,0.39l-0.97,0.26l-1-1l0.26-0.97c0.07-0.25,0.2-0.48,0.39-0.67l0.38-0.37l1.99,1.99L10.12,15.804z M11.21,14.724l-1.99-1.99l6.73-6.73l1.99,1.99L11.21,14.724z M19.66,6.274l-1.01,1.01l-1.99-1.99l1.01-1.02c0.14-0.14,0.33-0.22,0.53-0.22s0.39,0.08,0.53,0.22l0.93,0.94C19.95,5.504,19.95,5.974,19.66,6.274z" fill={color} />
      </g>
    </svg>
  );
};

export default EditIcon;
