import React, { useContext, useEffect, useState, useCallback, useMemo } from "react";
import { List, arrayMove } from "react-movable";
import Card from "../../Components/Card/Card";
import InputField from "../../Components/InputField/InputField";
import "./SettingsSocialPage.scss";
import { Context } from "../../context/index";
import UserRoute from "../../Routes/Auth";
import Button from "../../Components/Button/Button";
import Navigation from "../../Components/Navigation/Navigation";
import CheckIcon from "./CheckIcon";
import instagramIcon from "../../assets/icons/instagram.png";
import tiktokIcon from "../../assets/icons/tiktok2.webp";
import xIcon from "../../assets/icons/x.svg";
import snapchatIcon from "../../assets/icons/snapchat.svg";
import spotifyIcon from "../../assets/icons/spotify.png";
import youtubeIcon from "../../assets/icons/youtube1.svg";
import twitchIcon from "../../assets/icons/twitch1.png";
import pinterestIcon from "../../assets/icons/pinterest.png";
import kickIcon from "../../assets/icons/kickLogo.png";
import { apiGet, apiPut } from "../../utils/apiUtils"; // Adjust the import path as necessary
import DragIcon from "./DragIcon";
import FacebookTikTokConnect from "../../Components/FacebookTikTokConnect/FacebookTikTokConnect";

export default function SettingsSocialPage() {
  const [username, setUsername] = useState("");
  const { state: { user },} = useContext(Context);
  
  const initialSocialMediaFields = useMemo(() => [
    { name: "instagram", label: "Instagram", icon: instagramIcon, placeholder: "https://www.instagram.com/username" },
    { name: "tiktok", label: "TikTok", icon: tiktokIcon, placeholder: "https://www.tiktok.com/@username" },
    { name: "twitter", label: "X", icon: xIcon, placeholder: "https://www.x.com/@username" },
    { name: "snapchat", label: "Snapchat", icon: snapchatIcon, placeholder: "https://www.snapchat.com/add/username" },
    { name: "spotifyMusic", label: "Artist ID", icon: spotifyIcon, placeholder: "6DARBhWbfcS9E4yJzcliqQ" },
    { name: "spotifyPodcast", label: "Podcast ID", icon: spotifyIcon, placeholder: "5QaSbbv2eD4SFrlFR6IyY7" },
    { name: "youtubeEmbed", label: "Video ID", icon: youtubeIcon, placeholder: "D48XxB6yvaE" },
    { name: "youtube", label: "Channel", icon: youtubeIcon, placeholder: "https://www.youtube.com/@username" },
    { name: "liveStreaming", label: "Twitch", icon: twitchIcon, placeholder: "https://twitch.com/@username" },
    { name: "kick", label: "Kick", icon: kickIcon, placeholder: "https://www.kick.com/@username" },
    { name: "pinterest", label: "Pinterest", icon: pinterestIcon, placeholder: "https://www.pinterest.com/username" },
  ], []);

  // State to manage the order of fields
  const [orderedFields, setOrderedFields] = useState(initialSocialMediaFields);

  // State to manage form data
  const [formData, setFormData] = useState({
    instagram: "",
    tiktok: "",
    youtube: "",
    spotifyMusic: "",
    pinterest: "",
    podcast: "",
    liveStreaming: "",
    twitter: "",
    snapchat: "",
    spotifyPodcast: "",
    youtubeEmbed: "",
    kick: "",
  });

  const [changedFields, setChangedFields] = useState({});
  const [provider, setProvider] = useState("");
  console.log(provider)

  // Fetch social media data
  const fetchSocialMediaData = useCallback(async () => {
    try {
      const response = await apiGet("/settings/socials");
      // console.log(response.socialMedia)
      if (response && response.socialMedia) {
        const socialMedia = response.socialMedia;
        setFormData({
          instagram: socialMedia.instagram || "",
          tiktok: socialMedia.tiktok || "",
          youtube: socialMedia.youtube || "",
          spotifyMusic: socialMedia.spotifyMusic || "",
          podcast: socialMedia.podcast || "",
          liveStreaming: socialMedia.liveStreaming || "",
          pinterest: socialMedia.pinterest || "",
          twitter: socialMedia.twitter || "",
          snapchat: socialMedia.snapchat || "",
          spotifyPodcast: socialMedia.spotifyPodcast || "",
          youtubeEmbed: socialMedia.youtubeEmbed || "",
          kick: socialMedia.kick || "",
        });
        setUsername(user.username);

        // If the backend provides an order, set it
        if (socialMedia.order && socialMedia.order.length > 0 && Array.isArray(socialMedia.order)) {
          // Map the order to the corresponding field objects
          const ordered = socialMedia.order
            .map((fieldName) => initialSocialMediaFields.find(field => field.name === fieldName))
            .filter(field => field !== undefined);

          // Separate filled and non-filled fields
          const filledFields = ordered.filter(field => socialMedia[field.name]);
          const nonFilledFields = ordered.filter(field => !socialMedia[field.name]);

          // Combine filled and non-filled fields, filled first
          setOrderedFields([...filledFields, ...nonFilledFields]);
        } else {
          // If no order provided, sort based on filled status
          const filledFields = initialSocialMediaFields.filter(field => socialMedia[field.name]);
          const nonFilledFields = initialSocialMediaFields.filter(field => !socialMedia[field.name]);
          setOrderedFields([...filledFields, ...nonFilledFields]);
        }
      } else {
        console.error("Social media data is missing");
      }
    } catch (err) {
      console.error("Failed to fetch social media data", err);
    }
  }, [user.username, initialSocialMediaFields]);

  useEffect(() => {
    fetchSocialMediaData();
  }, [fetchSocialMediaData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setChangedFields(prevChangedFields => ({ ...prevChangedFields, [name]: true }));

    // Optional: Dynamic reordering based on input changes
    /*
    setOrderedFields(prevFields => {
      const updatedFields = [...prevFields];
      const fieldIndex = updatedFields.findIndex(field => field.name === name);

      if (fieldIndex !== -1) {
        const [updatedField] = updatedFields.splice(fieldIndex, 1);
        if (value) {
          // Move to the top among filled fields
          const firstEmptyIndex = updatedFields.findIndex(field => !formData[field.name] && field.name !== name);
          if (firstEmptyIndex === -1) {
            updatedFields.push(updatedField);
          } else {
            updatedFields.splice(firstEmptyIndex, 0, updatedField);
          }
        } else {
          // Move to the bottom among non-filled fields
          const lastFilledIndex = updatedFields
            .map(field => formData[field.name] ? 1 : 0)
            .lastIndexOf(1);
          updatedFields.splice(lastFilledIndex + 1, 0, updatedField);
        }
      }

      return updatedFields;
    });
    */
  };

  const handleUpdate = async () => {
    try {
      const payload = {...formData, order: orderedFields.map(field => field.name),};
      await apiPut("/user/edit-profile/social-media", payload,);
      // console.log(response);     
      setChangedFields({});   // Optionally, reset changedFields after successful update
    } catch (error) {
      console.error("Error updating social media data:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleUpdate();
  };

  return (
    <UserRoute username={username}>
      <div className="settings-social-pg">
        <Navigation heading={"Social Platforms"} back={true} />
        <Card className="card--padding">
          <FacebookTikTokConnect setProvider={setProvider} />
          <form onSubmit={handleSubmit} className="settings-social-pg__list">
            <List
              values={orderedFields}
              onChange={({ oldIndex, newIndex }) =>
                setOrderedFields((prevFields) =>
                  arrayMove(prevFields, oldIndex, newIndex)
                )
              }
              renderList={({ children, props }) => (
                <div {...props} className="social-media-list">
                  {children}
                </div>
              )}
              renderItem={({ value, props, isDragged }) => (
                <div {...props} key={value.name} className={`input-group ${isDragged ? 'dragged' : ''}`}>
                  <InputField
                    label={value.label}
                    name={value.name}
                    type="text"
                    value={formData[value.name]}
                    icon={value.icon}
                    onChange={handleInputChange}
                    placeholder={value.placeholder}
                    className="input-field"
                  />
                  <DragIcon />
                  {changedFields[value.name] && <CheckIcon themeMode={user.themeMode} className="check-icon" />}
                </div>
              )}
            />

            <Button type="submit">
              <p>Save</p>
            </Button>
          </form>
        </Card>
      </div>
    </UserRoute>
  );
}
