// components/ReactNotification/ReactNotification.jsx

import React, { useState, useEffect, useContext } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import './ReactNotification.scss';
import ThemeContext from '../../context/ThemeContext.jsx';
import { Context } from '../../context/index';
import displayProfile from '../../assets/defaults/displayPicture.png';
import { io } from 'socket.io-client';
const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET;


const ReactNotification = () => {
  const [notification, setNotification] = useState(null);
  const { themeMode } = useContext(ThemeContext);
  const { state } = useContext(Context);
  const { user } = state;
  const themeClass = themeMode === 'light' ? 'light-theme' : '';

  useEffect(() => {
    if (user && user._id) {
      const socket = io(SOCKET_SERVER_URL, {
        query: { userId: user._id },
      });

      socket.on('notification', (message) => {
        const { title, body, link } = message;
        setNotification({ title, body, link });
      });

      return () => {
        socket.disconnect();
      };
    }
  }, [user]);

  useEffect(() => {
    // Listen for messages from the service worker
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data && event.data.type === 'PUSH_NOTIFICATION') {
          const { title, body, icon, link } = event.data.payload;
          setNotification({ title, body, icon, link });
        }
      });
    }
  }, []);

  useEffect(() => {
    const notify = () => {
      toast.custom((t) => (
        <div
          className={`${t.visible ? 'animate-enter' : 'animate-leave'} notification notification--${themeClass}`}
        >
          <div className="notification__details">
            <div className="notification__body">
              {notification?.icon && (
                <div className="notification__avatar-containter">
                  <img
                    className="notification__avatar"
                    src={notification.icon !== '/avatar.png' ? notification.icon : displayProfile}
                    alt=""
                  />
                </div>
              )}
              <div className="notification__content">
                <p className={`notification__heading-text notification__heading-text--${themeClass}`}>
                  {notification?.title}
                </p>
                <p className={`notification__body-text notification__body-text--${themeClass}`}>
                  {notification?.body}
                </p>
              </div>
            </div>
          </div>
          <div className={`notification__btn-container notification__btn-container--${themeClass}`}>
            <button
              onClick={() => toast.dismiss(t.id)}
              className={`notification__btn notification__btn--${themeClass}`}
            >
              Close
            </button>
          </div>
        </div>
      ));
    };

    if (notification?.title) {
      notify();
    }
  }, [notification, themeClass]);

  return <Toaster position="top-right" />;
};

export default ReactNotification;
