import React, { useEffect, useContext, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import './CancelTeamSubscriptionPage.scss';
import axios from 'axios';
import ThemeContext from '../../context/ThemeContext';
import UserRoute from '../../Routes/Auth';

function CancelTeamSubscriptionPage() {
  const { teamId } = useParams(); // Get the team ID from the URL
  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

  // Memoize the cancelTeamSubscription function to avoid re-creating it on every render
  const cancelTeamSubscription = useCallback(async () => {
    try {
      // Send the cancellation request to the server
      const { data } = await axios.get(`/api/teams/${teamId}/cancel`);

      if (!data.success) {
        console.error('Failed to cancel team subscription');
      }
    } catch (error) {
      console.error('Error cancelling team subscription:', error);
    }
  }, [teamId]);

  // Trigger the cancellation when the teamId changes
  useEffect(() => {
    if (teamId) {
      cancelTeamSubscription();
    }
  }, [teamId, cancelTeamSubscription]);

  return (
    <UserRoute>
      <div className="cancel-team-subscription-page">
        <h1 className="cancel-team-subscription-page__title">
          Teams Subscription Canceled
        </h1>
        <p
          className={`cancel-team-subscription-page__text cancel-team-subscription-page__text--${themeClass}`}
        >
          Your team's subscription was not completed. You can try again or keep exploring
          more on Chequeai.
        </p>
        <div className="cancel-team-subscription-page__link">
          <Link
            to="/dashboard"
            className={`cancel-team-subscription-page__link--text cancel-team-subscription-page__link--text--${themeClass}`}
          >
            Dashboard
          </Link>
        </div>
      </div>
    </UserRoute>
  );
}

export default CancelTeamSubscriptionPage;
