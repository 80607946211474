import React from 'react'
import './CheckoutComponent.scss'
import Button from '../Button/Button'
import { formatMoney } from '../../utils/moneyUtils'
export default function CheckoutComponent({ price, handleCheckout, currency }) {

    return (
        <div className='checkout-cmp'>
            <div className='checkout-cmp__amount'>
                <Button modifier={"button--padding button--money"} onClick={handleCheckout}>{formatMoney(price, currency)}</Button>
            </div>
        </div>
    )
}
