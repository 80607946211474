import React, { useEffect, useContext, useState } from 'react';
import "./UserProfile.scss";
import Button from '../Button/Button';
import { Link } from 'react-router-dom';
import displayPicture from '../../assets/defaults/displayPicture.png';
import Instagram from "../../assets/icons/instagram.png";
import TikTok from "../../assets/icons/tiktok1.svg";
import Youtube from "../../assets/icons/youtubeIcon.png";
// import Xsvg from "../../assets/icons/x.svg";
import XAppIcon from "../../assets/icons/xAppIcon.png";
import Spotify from "../../assets/icons/spotify.png";
import Twitch from "../../assets/icons/twitch1.png";
import Linkify from "react-linkify";
import PinterestIcon from "../../assets/icons/pinterest.png";
import SnapchatIcon from "../../assets/icons/snapchat.svg";
import KickIcon from "../../assets/icons/kickLogo.png";
import UserLinkCard from '../UserLinkCard/UserLinkCard';
import Card from '../Card/Card';
import SettingsIcon from './SettingsIcon';
import ThemeContext from '../../context/ThemeContext';
import { getFullUrl } from '../../utils/socialUtils';
import VerifiedIcon from './VerifiedIcon';
import MenuIcon from '../../Pages/UserPage/icons/MenuIcon';

export default function UserProfile({
    otherUser,
    loggedInUser,
    username,
    category,
    location,
    img,
    bio,
    dp,
    handleConnect,
    handleAccept,
    handleDecline,
    handleDisconnect,
    isPrivate,
    isConnected,
    roomId,
    otherRole,
    incomingRequest,
    outgoingRequest,
    setShowLoginModal,
    setDonateModal,
    setReportModal,
}) {

    // console.log(loggedInUser._id, otherUser._id);

    let message = "Connect";
    let handle;

    if (isConnected) {
        message = "Connected";
        handle = handleDisconnect;
    } else if (incomingRequest.includes(loggedInUser._id)) {
        message = "Pending";
        handle = () => { };
    } else if (outgoingRequest.includes(loggedInUser._id)) {
        message = "Accept";
        handle = handleAccept;
    } else {
        message = "Connect";
        handle = handleConnect;
    }

    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';
    const [showOptions, setShowOptions] = useState(false);

    useEffect(() => { }, [
        loggedInUser, username, category, location, img, bio, dp, handleConnect,
        isPrivate, isConnected, roomId
    ]);

    // Retrieve the social media order from otherUser.socialMedia.order
    const socialOrder = otherUser?.socialMedia?.order.length > 0 ? otherUser?.socialMedia?.order : [
        "tiktok",
        "liveStreaming",
        "instagram",
        "snapchat",
        "youtube",
        "youtubeEmbed",
        "twitter",
        "spotifyMusic",
        "spotifyPodcast",
        "kick",
        "pinterest"
    ];

    // Mapping of social media types to their URL prefixes and icons
    const socialMediaMap = {
        tiktok: {
            urlPrefix: 'https://www.tiktok.com/@',
            icon: TikTok
        },
        liveStreaming: {
            urlPrefix: '', // Assuming liveStreaming URLs are already full
            icon: Twitch // Defaulting to Twitch; adjust if necessary
        },
        instagram: {
            urlPrefix: 'https://www.instagram.com/',
            icon: Instagram
        },
        snapchat: {
            urlPrefix: 'https://www.snapchat.com/add/',
            icon: SnapchatIcon // Replace with Snapchat icon if available
        },
        youtube: {
            urlPrefix: '', // Full URL provided
            icon: Youtube
        },
        twitter: {
            urlPrefix: 'https://x.com/',
            icon: XAppIcon
        },
        spotifyMusic: {
            urlPrefix: 'https://open.spotify.com/artist/',
            icon: Spotify
        },
        spotifyPodcast: {
            urlPrefix: 'https://open.spotify.com/show/',
            icon: Spotify
        },
        kick: {
            urlPrefix: 'https://kick.com/',
            icon: KickIcon // Replace with Kick icon if available
        },
        pinterest: {
            urlPrefix: 'https://www.pinterest.com/',
            icon: PinterestIcon // Replace with Pinterest icon if available
        },
    };

    // Function to generate the full URL based on the type and value
    // Function to generate the full URL based on the type and value
    const generateUrl = (type, value) => {
        if (!value) return '';

        // If the link doesn't include 'https://', prepend it
        const formattedValue = value.startsWith('http://') || value.startsWith('https://')
            ? value
            : `https://${value}`;

        if (type === 'youtube') {
            return formattedValue; // Assuming full YouTube URL is provided
        }

        if (type === 'liveStreaming') {
            return formattedValue.startsWith('https://twitch.com/') ? formattedValue : formattedValue;
        }

        // Generate the full URL using the prefix defined in socialMediaMap
        return getFullUrl(socialMediaMap[type].urlPrefix, formattedValue);
    };

    // Process the social media links according to the order and constraints
    const processedSocialLinks = [];
    let spotifyAdded = false; // Flag to ensure only one Spotify link is added

    for (const type of socialOrder) {
        // Stop if we have collected 6 links
        if (processedSocialLinks.length >= 6) break;

        const value = otherUser?.socialMedia?.[type];

        // Skip if the link is empty or undefined
        if (!value) continue;

        // Ensure that for YouTube, only the channel URL is added
        if (type === 'youtubeEmbed') continue;

        // Handle Spotify to ensure only one link is added
        if (type.startsWith('spotify') && spotifyAdded) continue;
        if (type.startsWith('spotify') && !spotifyAdded) {
            spotifyAdded = true;
        }

        // Determine the icon and URL
        const { icon } = socialMediaMap[type];
        const src = generateUrl(type, value);

        // Add the UserLinkCard to the processed list
        processedSocialLinks.push(
            <UserLinkCard
                key={type}
                title=""
                src={src}
                icon={icon}
            />
        );
    }

    const handleOptionsClick = () => {
        console.log('options clicked');
        setShowOptions(showOptions => !showOptions);
    };

    const handleReport = () => {
        console.log('report clicked');
    };

    return (
        <div className={`user-p user-p--${themeClass}`} >
            <div className='user-p__details'>
                <div className="user-p__img-container">
                    <img
                        className="user-p__img"
                        src={dp === "/avatar.png" ? displayPicture : dp}
                        alt="user profile"
                    />
                </div>
                <div className="user-p__info-container">
                    <div className='user-p__row'>
                        <p className="user-p__info-username">{username}</p>
                        {
                            otherUser.stripeSeller && otherUser.stripeAccountId !== "" &&
                            <div className="user-p__verified-icon">
                                <VerifiedIcon />
                            </div>
                        }
                        {
                            loggedInUser.username === username &&
                            <Link className="user-p__settings-icon" to={"/settings"}>
                                <SettingsIcon />
                            </Link>
                        }
                        {
                            loggedInUser.username !== username &&
                            <div className="image-modal__info-right">
                                <div className="user-p__settings-icon" onClick={handleOptionsClick}>
                                    <MenuIcon themeMode={themeMode} />
                                </div>
                                {showOptions && (
                                    <div className={`image-modal__options image-modal__options--${themeClass}`}>
                                        <div className={`image-modal__options-item image-modal__options-item--cancel image-modal__options-item--${themeClass}`} onClick={() => setShowOptions(false)}>Cancel</div>
                                        <div className={`image-modal__options-item image-modal__options-item--${themeClass}`} onClick={() => {
                                            navigator.clipboard.writeText(window.location.href)
                                                .then(() => {
                                                    setShowOptions(false);
                                                })
                                                .catch(() => {
                                                });
                                        }} >Copy Link</div>
                                        {/* <div className={`image-modal__options-item image-modal__options-item--${themeClass}`} >Share</div> */}
                                        {/* <div className="image-modal__options-item">Embed</div> */}
                                        {handleReport && <div className={`image-modal__options-item image-modal__options-item--${themeClass}`} onClick={() => setReportModal(true)}>Report</div>}
                                        {/* <div className="image-modal__options-item">Report</div> */}
                                    </div>
                                )}
                            </div>

                        }

                    </div>
                    <div className='user-p__subscriber-info'>
                        <Link
                            className="user-p_connections"
                            to={loggedInUser !== null ? `/${username}/connections/` : `/login`}
                        >
                            <p className="user-p__info-connections">
                                <span className='user-p__connections__text--b'>
                                    {otherUser?.connections?.length || 0}
                                </span>
                                {otherRole === "Brand" ? "Creators" : "Connections"}
                            </p>
                        </Link>
                        <p className="user-p__info-connections">
                            <span className='user-p__connections__text--b'>
                                {otherUser?.averageRating || 0.00}
                            </span>
                            {" Rating"}
                        </p>
                        {/* 
                        Uncomment if you want to display supporters and ratings separately
                        <Link className="user-p_connections" to={`/${username}/connections/`}>
                            <p className="user-p__info-connections">
                                <span className='user-p__connections__text--b'>
                                    {otherUser?.supporters?.length || 0}
                                </span>
                                {" Supporters"}
                            </p>
                        </Link> 
                        */}
                    </div>
                    <div>
                        <p className="user-p__info-category">
                            {category} | {location}
                        </p>
                        <Linkify>
                            <p className={`user-p__info-website user-p__info-website--${themeClass}`}>
                                {otherUser?.website}
                            </p>
                        </Linkify>
                    </div>
                    <div className='user-p__actions-row'>
                        {
                            loggedInUser ?
                                (loggedInUser.username !== username ?
                                    <div className="user-p__link">
                                        <Button modifier="button--user" onClick={handle}>
                                            {message}
                                        </Button>
                                        {message === "Accept" &&
                                            <Button
                                                modifier="button--user"
                                                onClick={handleDecline}
                                            >
                                                Decline
                                            </Button>
                                        }
                                    </div>
                                    :
                                    <Link className="user-p__link" to={"/settings/promotions"}>
                                        <Button modifier="button--user" >
                                            Edit Promotions
                                        </Button>
                                    </Link>
                                )
                                :
                                <div className="user-p__link" >
                                    <Button
                                        modifier="button--user"
                                        onClick={() => { setShowLoginModal(true) }}
                                    >
                                        {message}
                                    </Button>
                                </div>
                        }
                        {
                            isConnected && loggedInUser._id !== otherUser._id &&
                            <Link className="user-p__link user-p__link--left" to={`/messages/${roomId}`}>
                                <Button modifier="button--user" >
                                    Message
                                </Button>
                            </Link>
                        }
                        {/* 
                        Uncomment if you want to allow messaging when not connected and not private
                        {
                            !isPrivate && !isConnected &&  
                            <Link className="user-p__link user-p__link--left" to={`/messages/${roomId}`}>
                                <Button modifier="button--user" >
                                    Message
                                </Button>
                            </Link>
                        }
                        */}
                    </div>
                    <div className='user-p__actions-row'>
                        {
                            loggedInUser ? (
                                // Check if the current user is not the same as the profile owner and the profile is not private
                                loggedInUser.username !== username && (
                                    otherUser.isPrivate ? (
                                        // If otherUser is private, check if they are connected with the logged-in user
                                        otherUser?.connections?.includes(loggedInUser._id) ? (
                                            <Link className="user-p__link" to={`/${username}/promote`}>
                                                <Button modifier="button--user">Promote</Button>
                                            </Link>
                                        ) : (
                                            // If the user is private but not connected, show nothing or handle accordingly
                                            //<div className="user-p__link" onClick={() => setShowLoginModal(true)}>
                                            //    <Button modifier="button--user">Promote</Button>
                                            //</div>
                                            <></>
                                        )
                                    ) : (
                                        // If otherUser is not private, allow promotion
                                        <Link className="user-p__link" to={`/${username}/promote`}>
                                            <Button modifier="button--user">Promote</Button>
                                        </Link>
                                    )
                                )
                            ) : (
                                // If not logged in, prompt to log in
                                <div className="user-p__link" onClick={() => setShowLoginModal(true)}>
                                    <Button modifier="button--user">Promote</Button>
                                </div>
                            )
                        }
                        {
                            (loggedInUser.username !== username && otherUser?.donationSettings?.donationEnabled) &&
                            <div className="user-p__link user-p__link--left">
                                <Button
                                    modifier="button--user"
                                    onClick={() => setDonateModal(true)}
                                >
                                    Donate
                                </Button>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className='user-p__actions'>
                <div className='user-p__info'>
                    <p className="user-p__bio">{bio}</p>
                </div>
                <Card className="card--row user--links">
                    {processedSocialLinks}
                </Card>
            </div>
        </div>
    );
}
