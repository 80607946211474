export const SearchIcon = (themeMode) => {
    const color = themeMode.themeMode === "light" ? "black" : "white";
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="ionicon"
        viewBox="0 0 512 512"
        width={"23"}
        height={"23"}
      >
        <title>Search</title>
        <path
          d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="32"
        />
        <path
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="32"
          d="M338.29 338.29L448 448"
        />
      </svg>
    );
  };
