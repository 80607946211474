import React, { useEffect, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import './SuccessDonatePage.scss';
import ThemeContext from '../../context/ThemeContext';
import { Context } from '../../context';
import UserRoute from '../../Routes/Auth';
import axios from 'axios';

function SuccessDonatePage() {
  const { sessionId } = useParams(); // Get the session ID from the URL
  const navigate = useNavigate();
  const { themeMode } = useContext(ThemeContext);
  const { state: { user } } = useContext(Context);
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

  useEffect(() => {
    const successRequest = async () => {
      try {
        // Fetch donation data based on the Stripe session ID
        const endpoint = user ? `/donations/success/${sessionId}` : `/donations/success/${sessionId}/anonymous`;
        const { data } = await axios.get(endpoint);
        console.log(data);
      } catch (err) {
        console.error(err);
        // In case of an error, redirect to a failure page or show error message
        navigate('/donations/failure');
      }
    };

    successRequest();
  }, [sessionId, navigate, user]);

  if (!user) {
    return (
      <div className='success-donate-page'>
        <h1 className='success-donate-page__title'>Thanks for your Support :)</h1>

        <p className='success-donate-page__text'>
          We truly appreciate it. Explore more on our platform!
        </p>

        <div className='success-donate-page__link'>
          <Link to="/" className={`success-donate-page__link--text success-donate-page__link--text--${themeClass}`}>
            Home
          </Link>
        </div>
      </div>
    );
  }

  return (
    <UserRoute>
      <div className={`success-donate-page success-donate-page--${themeClass}`}>
        <h1 className={`success-donate-page__title success-donate-page__title--${themeClass}`}>Thanks for your Support :)</h1>
        <p className={`success-donate-page__text success-donate-page__text--${themeClass}`}>
          Explore more on Chequeai
        </p>
        <p className={`success-donate-page__link success-donate-page__link--${themeClass}`}>
          <Link to="/home" className={`success-donate-page__link--text success-donate-page__link--text--${themeClass}`}>
            Home
          </Link>
        </p>
      </div>
    </UserRoute>
  );
}

export default SuccessDonatePage;
