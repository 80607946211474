// src/components/StatsWidget/StatsWidget.jsx

import React from 'react';
import { Tooltip } from 'react-tooltip'; // Import Tooltip from react-tooltip
import './StatsWidget.scss'; // Import the corresponding SCSS

const StatsWidget = ({ title, value, color, themeClass, toolTipValue }) => {
    // Generate a unique tooltip ID if toolTipValue is provided
    const tooltipId = toolTipValue ? `tooltip-${title.replace(/\s+/g, '-').toLowerCase()}` : null;

    return (
        <div className={`stats-widget ${color ? `stats-widget--${color}` : ''} stats-widget--${themeClass}`}>
            <p className="stats-widget__title">{title}</p>
            <p
                className="stats-widget__value"
                {...(toolTipValue && {
                    'data-tooltip-id': tooltipId,
                    'data-tooltip-content': toolTipValue,
                    style: { cursor: 'pointer' },
                })}
            >
                {value}
            </p>
            {/* Render Tooltip only if toolTipValue is provided */}
            {toolTipValue && (
                <Tooltip id={tooltipId} place="top" effect="solid" />
            )}
        </div>
    );
};


export default StatsWidget;
