// ChangePasswordPage.jsx

import { Context } from "../../context/index";
import React, { useContext, useEffect, useState } from 'react';
import UserRoute from '../../Routes/Auth';
import InputField from '../../Components/InputField/InputField';
import Button from '../../Components/Button/Button';
import "./ChangePasswordPage.scss";
import Navigation from "../../Components/Navigation/Navigation";
import ThemeContext from "../../context/ThemeContext";
import TwoFactorAuth from "../../Components/TwoFactorAuth/TwoFactorAuth";
import UserSettings from "../../Components/UserSettings/UserSettings";
import { apiPost } from "../../utils/apiUtils";
// Import MessageModal
import MessageModal from '../../Components/MessageModal/MessageModal';

export default function ChangePasswordPage() {
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const [errors, setErrors] = useState({}); // State for errors

  const [username, setUsername] = useState("");
  const { state: { user }, } = useContext(Context);
  const { themeMode } = useContext(ThemeContext);

  // State for MessageModal
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [serverResponse, setServerResponse] = useState('');
  const [subServerResponse, setSubServerResponse] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (user !== null) {
      setUsername(user.username);
    }
  }, [user]);

  const { currentPassword, newPassword, confirmNewPassword } = formData;

  const validateConfirmPassword = (newPasswordValue, confirmNewPasswordValue) => {
    if (newPasswordValue !== confirmNewPasswordValue) {
      setErrors(prevErrors => ({
        ...prevErrors,
        confirmNewPassword: 'Passwords do not match',
      }));
    } else {
      setErrors(prevErrors => {
        const { confirmNewPassword, ...rest } = prevErrors;
        return rest;
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Validate confirmNewPassword field
    if (name === 'confirmNewPassword' || name === 'newPassword') {
      validateConfirmPassword(
        name === 'newPassword' ? value : newPassword,
        name === 'confirmNewPassword' ? value : confirmNewPassword
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check for validation errors before submitting
    if (Object.keys(errors).length > 0) {
      return;
    }
    if (newPassword !== confirmNewPassword) {
      setErrors(prevErrors => ({
        ...prevErrors,
        confirmNewPassword: 'Passwords do not match',
      }));
      return;
    }

    try {
      setIsProcessing(true);
      const response = await apiPost('/settings/auth/password', {
        currentPassword,
        newPassword,
      });

      if (response.success) {
        setServerResponse('Password Changed');
        setSubServerResponse("For your security, password changes are limited to once every 14 days. Thank you for understanding.");

        setFormData({
          currentPassword: '',
          newPassword: '',
          confirmNewPassword: '',
        });
      } else {
        setServerResponse(response.message || 'Error changing password. Please try again.');
        setSubServerResponse("We're sorry, but we couldn't update your password right now. Please try again later or reach out to our support team if the issue persists.");
      }
    } catch (error) {
      console.error('Error changing password:', error);
      setServerResponse('An error occurred. Please try again.');
      setSubServerResponse('');
    } finally {
      setIsMessageModalOpen(true);
      setIsProcessing(false);
    }
  };

  // Close the MessageModal after a certain time
  useEffect(() => {
    if (isMessageModalOpen) {
      const timer = setTimeout(() => setIsMessageModalOpen(false), 2500);
      return () => clearTimeout(timer);
    }
  }, [isMessageModalOpen]);

  return (
    <UserRoute username={username}>
      <div className="ch-pass-pg">
        <Navigation heading={"Account Security"} back={true} />
        <UserSettings className="card--padding card--settings--tp">
          <div>
            <div>
              <h2 className="passkeys-title">2-Factor Auth</h2>
              <TwoFactorAuth user={user} themeMode={themeMode} />
            </div>

            <form className="ch-pass-pg__form">
              <h2 className="passkeys-title ch-pass-pg__title">Change Password</h2>

              <InputField
                label="Current Password"
                name="currentPassword"
                type="password"
                value={currentPassword}
                onChange={handleInputChange}
                autocomplete="current-password"
                isPassword
              />
              <InputField
                label="New Password"
                name="newPassword"
                type="password"
                value={newPassword}
                onChange={handleInputChange}
                autocomplete="new-password"
                isPassword
              />
              <InputField
                label="Confirm New Password"
                name="confirmNewPassword"
                type="password"
                value={confirmNewPassword}
                onChange={handleInputChange}
                autocomplete="new-password"
                isPassword
                errorMessage={errors.confirmNewPassword} // Pass error message
              />
  
            </form>
         
          </div>
          <Button type="submit" disabled={isProcessing}  onClick={handleSubmit}>
                {isProcessing ? 'Changing...' : 'Change'}
              </Button>
        </UserSettings>

        {/* Message Modal */}
        <MessageModal
          isOpen={isMessageModalOpen}
          setIsOpen={setIsMessageModalOpen}
          message={serverResponse}
          subMessage={subServerResponse}
          modifier={'center'}
        />
      </div>
    </UserRoute>
  );
}
