import React, { useEffect, useState, useContext } from 'react'
import UserRoute from '../../Routes/Auth'
import Navigation from '../../Components/Navigation/Navigation'
import './EditLinksPage.scss'
import Card from '../../Components/Card/Card'
import InputField from '../../Components/InputField/InputField'
import Button from '../../Components/Button/Button'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import DarkLoadingSpinner from '../../Components/DarkLoadingSpinner/DarkLoadingSpinner';
import ConfirmModal from '../../Components/ConfirmModal/ConfirmModal';
import { Context } from "../../context/index";



export default function EditLinksPage() {
    const { id } = useParams();
    const {
        state: { user },
      } = useContext(Context);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [link, setLink] = useState({
        title: "",
        url: "",
    });


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "linkTitle") {
            setLink((prevLink) => ({
                ...prevLink,
                title: value,
            }));
        } else if (name === "linkURL") {
            setLink((prevLink) => ({
                ...prevLink,
                url: value,
            }));
        }
    };


    const updateLink = async () => {
        try {
            const { data } = await axios.put(`${process.env.REACT_APP_API}/user/edit-profile/link/${id}`, link);
            console.log(data);
            navigate(`/${user.username}/share`);

        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const fetchCampaign = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_API}/user/edit-profile/link/${id}`);
                console.log(data);
                setLink(data);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };

        fetchCampaign();
    }, [id]);

    const handleDelete = async () => {
        try {
            const { data } = await axios.delete(`${process.env.REACT_APP_API}/user/edit-profile/link/${id}`);
            console.log(data);
            navigate(`/${user.username}/share`);

        } catch (error) {
            console.log(error);
        }
    }

    return (
        <UserRoute>
            <div className='edit-links-pg'>
                <Navigation title={'Edit Links'} back={true} handleDelete={(id) => { setDeleteModal(id) }} navRight={true} />
                <Card className="card--input">
                    {loading ? (
                        <DarkLoadingSpinner />
                    ) : (
                        <>
                            <InputField
                                label="Link Title"
                                name="linkTitle"
                                type="text"
                                value={link.title}
                                onChange={handleInputChange}
                            />
                            <InputField label="Link URL" name="linkURL" type="url" value={link.url} onChange={handleInputChange} />
                            <Button modifier="button" onClick={updateLink}>Update</Button>
                        </>
                    )}
                </Card>
                {deleteModal && (
                    <ConfirmModal
                        isOpen={deleteModal}
                        onClose={() => setDeleteModal(false)}
                        setIsOpen={setDeleteModal}
                        message={"Are you sure you want to delete this link"}
                        onConfirm={(id) => handleDelete(id)}
                    />
                )}
            </div>

        </UserRoute>
    )
}
