import React, { useContext, useEffect, useState } from "react";
import Search from "../../Components/Search/Search";
import "./FeaturePage.scss";
import UserRoute from "../../Routes/Auth";
import { Link } from "react-router-dom";
import { Context } from "../../context/index";
import { categoryOptionsSearch } from "../../utils/constants";

export default function FeaturePage() {

    const [username, setUsername] = useState("");
    const {
        state: { user },
    } = useContext(Context);

    useEffect(() => {
        if (user !== null) {
            setUsername(user.username);
        }
    }, [user]);

    return (
        <UserRoute username={username}>
            <div className="feature-pg">
                <Search term="" />
                <div className="feature-pg__grid">
                    {categoryOptionsSearch.map((category, index) => (
                        <Link
                            to={`/featured/${category.url}`}
                            key={index}
                            className="feature-pg__card"
                            style={{ background: category.color }}
                        >
                            <div className="feature-pg__card-image">
                                <div className="feature-pg__card-title">
                                    {category.name}
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </UserRoute>
    );
}
