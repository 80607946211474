import React, { useEffect, useState, useContext, useCallback } from 'react';
import UserRoute from '../../Routes/Auth';
import Navigation from '../../Components/Navigation/Navigation';
import './AddCampaignPost.scss';
import Card from '../../Components/Card/Card';
import InputField from '../../Components/InputField/InputField';
import Button from '../../Components/Button/Button';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import TextArea from '../../Components/TextArea/TextArea';
import Resizer from "react-image-file-resizer";
import campaignPost from '../../assets/defaults/campaignPost.png';
import campaignPostLight from '../../assets/defaults/campaignPostLight.png';


import ThemeContext from '../../context/ThemeContext';

export default function AddCampaignPost() {
    const { id } = useParams();
    const [campaignStatus, setCampaignStatus] = useState(null);
    const navigate = useNavigate();
    const [post, setPost] = useState({
        location: '',
        caption: '',
        products: [],
    });
    const [image, setImage] = useState("");
    const [imagePreview, setImagePreview] = useState(null);

    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPost((prevProduct) => ({
            ...prevProduct,
            [name]: value,
        }));
    };

    let campaignPostImage = themeMode === 'light' ? campaignPostLight : campaignPost;

    const getCampaignStatus = useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/campaigns/${id}/campaign-status`);
            // console.log(response);
            setCampaignStatus(response.data);
        } catch (error) {
            console.error(error);
        }
    }, [id]); // Dependency array includes id

    useEffect(() => {
        if (id && !campaignStatus) {
            getCampaignStatus();
        }
    }, [id, campaignStatus, getCampaignStatus]); // Dependency array includes getCampaignStatus

    const handlePost = async (e) => {
        e.preventDefault();
        const data = new FormData();

        if (image) {
            data.append("image", image);
        }

        Resizer.imageFileResizer(image, 500, 500, "PNG", 100, 0, async (uri) => {
            try {
                let { data } = await axios.put(
                    `${process.env.REACT_APP_API}/campaigns/${id}/upload-creator-photo`,
                    {
                        image: uri,
                        caption: post.caption,
                        location: post.location,
                    }
                );
                console.log("IMAGE UPLOADED", data);
                setImage(data);
                console.log("Profile Picture Updated");
                navigate('/');

            } catch (err) {
                console.log("Profile Picture failed to upload. Try later.", err);
            }
        });
    };

    const validateImg = (e) => {
        const file = e.target.files[0];
        if (file.size > 2048576) {
            console.error("Max file size 1mb", { position: "top-center" });
        } else {
            setImage(file);
            setImagePreview(URL.createObjectURL(file));
        }
    };

    return (
        <UserRoute>
            <div className="add-camp-pg">
                <Navigation title={'New Post'} back={true} />
                {(campaignStatus !== 'Completed' && campaignStatus !== 'Post Campaign') ? (
                    <Card className="card--input">
                        <div className="add-camp-pg__profile-img">
                            <label htmlFor="file">
                                <img className="add-camp-pg__img" src={imagePreview ? imagePreview : campaignPostImage} alt="profile" />
                            </label>
                            <input type="file" id="file" hidden accept='image/png, image/jpeg' onChange={validateImg} />
                        </div>
                        <InputField
                            label="Title"
                            name="title"
                            type="text"
                            value={post.title}
                            onChange={handleInputChange}
                        />
                        <InputField
                            label="Caption"
                            name="caption"
                            type="text"
                            value={post.caption}
                            onChange={handleInputChange}
                        />
                        <InputField
                            label="Location"
                            name="location"
                            type="text"
                            value={post.location}
                            onChange={handleInputChange}
                        />
                        <TextArea
                            label="Products"
                            name="products"
                            type="text"
                            value={post.products}
                            onChange={handleInputChange}
                        />
                        <Button modifier="button" onClick={handlePost}>
                            Post Now
                        </Button>
                    </Card>

                ) : <Card className={`card--link campaign-complete campaign-complete--${themeClass}`}>
                    <p><span>Campaign</span> has been</p>
                    <p>{campaignStatus}</p>
                </Card>}
            </div>
        </UserRoute>
    );
}
