import React from 'react';
import "./ProductPlaceholderCard.scss"; // Ensure this style file exists
import ProductIcon from "./ProductIcon";

// Placeholder Card Component
const ProductPlaceholderCard = ({ productType, setModal, isUser, username, themeClass }) => {
  const productDetails = {
    digital: { name: 'Digital Product' },
    physical: { name: 'Physical Product' },
    service: { name: 'Service' },
  };

  const details = productDetails[productType] || { name: 'Product' };

  const handleClick = () => {
    if (setModal) {
      setModal(true);
    }
  };

  return (
    <div className={`product-placeholder-card product-placeholder-card--${themeClass}`} onClick={handleClick}>
      <div className="product-placeholder-card__content">
        <ProductIcon themeMode={themeClass} />
        <h3 className="product-placeholder-card__title">{ isUser ? `Add ${details.name}` : ``}</h3>
        <p className="product-placeholder-card__description">
          {isUser ? `Add your first product to start promoting.` : `Waiting for ${username} to add products...`}
        </p>
      </div>
    </div>
  );
};

export default ProductPlaceholderCard;
