import React, { useContext } from "react";
import ThemeContext from "../../context/ThemeContext";
import './Pill.scss';


const Pill = ({ label, onClick, isSelected }) => {

  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

  return (
    <div
      className={`pill pill--${themeClass} ${isSelected ? 'pill--selected' : ''}`}
      onClick={() => onClick(label)}
    >
      {label}
    </div>
  );
};

export default Pill;
