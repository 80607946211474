import React, { useState, useContext } from 'react';
import './PromoteHighlight.scss';
import ThemeContext from '../../context/ThemeContext';

export default function PromoteHighlight({ images }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
      const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme'

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
  };

  return (
    <div className='promote-highlight'>
      <div className='promote-highlight__container'>
        {images.map((image, index) => (
          <img
            className='promote-highlight__item'
            key={index}
            src={image}
            alt={` ${index + 1}`}
            onClick={() => handleImageClick(image)}
          />
        ))}
      </div>
      {isModalOpen && (
        <div className={`promote-highlight__modal promote-highlight__modal--${themeClass}`} onClick={handleCloseModal}>

            <img
              src={selectedImage}
              alt='Selected'
              className='promote-highlight__modal-image'
            />

        </div>
      )}
    </div>
  );
}
