// src/Components/InputField/InputField.jsx

import React, { useContext, useState } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable'; // Import CreatableSelect
import ThemeContext from '../../context/ThemeContext';
import './InputField.scss';
import { selectLightMode, addProduct, selectDarkMode } from '../../utils/stylesUtils';

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const groupBadgeStyles = {
  backgroundColor: 'transparent',
  borderRadius: '2em',
  color: 'darkgrey',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const InputField = ({
  children,
  label,
  name,
  type = 'text',
  value,
  onChange,
  errorMessage,
  placeholder,
  disabled = false,
  icon,
  modifier,
  autocomplete = 'off',
  prefix,
  isSelect = false,
  isCreatable = false, // New prop to determine if it's a creatable select
  options = [],
  onCreateOption, // Callback for creating new options
  isPassword = false, // New prop to determine if it's a password field
}) => {
  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';
  const [showPassword, setShowPassword] = useState(false);

  const handleSelectChange = (selectedOption) => {
    onChange({ target: { name, value: selectedOption ? selectedOption.value : '' } });
  };

  const handleCreate = (inputValue) => {
    if (onCreateOption) {
      onCreateOption(inputValue);
    }
  };

  return (
    <>
      <div className={`input-field input-field--${themeClass} input-field--${modifier}`}>
      { label && (
        <label className={`input-field__label ${modifier}`} htmlFor={name}>
          {icon && <img src={icon} alt="icon" className="input-field__icon" />}
          {label}
        </label>
      )}
    
        <div className="input-field__input-container">
          {prefix && <span className="input-field__prefix">{prefix}</span>}
          {isSelect ? (
            isCreatable ? (
              <CreatableSelect
                options={options}
                value={options.flatMap(group => group.options).find(option => option.value === value) || null}
                onChange={handleSelectChange}
                onCreateOption={handleCreate}
                placeholder={placeholder}
                isDisabled={disabled}
                styles={themeMode === 'light' ? selectLightMode : addProduct}
                formatCreateLabel={(inputValue) => `Create "${inputValue}"`}
                formatGroupLabel={formatGroupLabel}
                isClearable
                isSearchable
              />
            ) : (
              <Select
                options={options}
                value={options.flatMap(group => group.options).find(option => option.value === value) || null}
                onChange={handleSelectChange}
                placeholder={placeholder}
                isDisabled={disabled}
                styles={themeMode === 'light' ? selectLightMode : selectDarkMode}
                formatGroupLabel={formatGroupLabel}
                isClearable
                isSearchable
              />
            )
          ) : (
            <>
              <input
                className={`input-field__input input-field__input--${themeClass} input-field__input--${modifier} ${
                  prefix ? 'input-field__input--with-prefix' : ''
                }`}
                type={isPassword && showPassword ? 'text' : type}
                name={name}
                id={name}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                disabled={disabled}
                autoComplete={autocomplete}
              />
              {isPassword && (
                <button
                  type="button"
                  className="input-field__toggle"
                  onClick={() => setShowPassword(!showPassword)}
                  aria-label="Toggle password visibility"
                >
                  {showPassword ? 'Hide' : 'Show'}
                </button>
              )}
            </>
          )}
          {children}
        </div>
      </div>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </>
  );
};

export default InputField;
