import React, { useContext } from 'react';
import './CampaignLinkCard.scss';
import { Link } from 'react-router-dom';
import Card from '../Card/Card';
import CampaignPoster from '../../assets/defaults/campaignPosterBig.png';
import ThemeContext from '../../context/ThemeContext';
import PromotionPostCard from "../PromotionPostCard/PromotionPostCard";
import Indicator from './Indicator';

const statusColors = {
    'Pending': 'orange',
    'Approved': 'green',
    'In Progress': '#1A8CB8',
    'Check In': '#FFBF00',
    'Comments': 'purple',
    'Completed': 'green',
    'Post Campaign': '#eb348c',
    'Cancelled': 'red'
};

export default function CampaignLinkCard({
    id,
    username,
    status,
    image,
    to,
    title,
    brand,
    category,
    productName,
    tiktok,
    instagram,
    youtube,
    podcast,
    creator,
    location,
}) {
    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

    return (
        <Link className={`campaign-link-card campaign-link-card--${themeClass}`} to={to}>
            <Card className={`card--link card--padding card--link--${themeClass}`}>
                <div className='campaign-link-card'>
                    <div className='campaign-link-card__info'>
                        <div className='campaign-link-card__image-container'>
                            <img src={image || CampaignPoster} alt="Campaign" className="campaign-link-card__image" />
                        </div>
                        <div className='campaign-link-card__details'>
                            <div className='campaign-link-card__header'>
                                <p className='campaign-link-card__title'>{title}</p>
                                <div className='campaign-link-card__status-div campaign-link-card__status-div--hide'>
                                    <span className='campaign-link-card__id'>
                                        Campaign: {id.slice(-10)}
                                    </span>
                                    <div className='campaign-link-card__status-div'>
                                        <p className='campaign-link-card__status'>
                                            <span> 
                                                <Indicator color={statusColors[status]} />
                                                {status}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <p className='campaign-link-card__brand'>@{username === brand ? creator : brand}</p>
                            <p className='campaign-link-card__category'>{category} | {location}</p>
                            {productName && <p className='campaign-link-card__productName'>{productName}</p>}
                            {(tiktok.enabled || instagram.enabled || youtube.enabled || podcast.enabled) && (
                                <div className="campaign-link-card__channels campaign-link-card__channels--desktop">
                                    {tiktok.enabled && <PromotionPostCard name={"TikTok"} posts={tiktok?.quantity || 0} enabled={tiktok?.enabled} />}
                                    {instagram.enabled && <PromotionPostCard name={"Instagram"} posts={instagram?.quantity || 0} enabled={instagram?.enabled} />}
                                    {youtube.enabled && <PromotionPostCard name={"Youtube"} posts={youtube?.quantity || 0} enabled={youtube?.enabled} tag={"Spot"} />}
                                    {podcast.enabled && <PromotionPostCard name={"Podcast"} posts={podcast?.quantity || 0} enabled={podcast?.enabled} tag={"Spot"} />}
                                </div>
                            )}
                        </div>
                    </div>
                    {(tiktok.enabled || instagram.enabled || youtube.enabled || podcast.enabled) && (
                        <div className="campaign-link-card__channels">
                            {tiktok.enabled && <PromotionPostCard name={"TikTok"} posts={tiktok?.quantity || 0} enabled={tiktok?.enabled} modifier={"post-card--width"} />}
                            {instagram.enabled && <PromotionPostCard name={"Instagram"} posts={instagram?.quantity || 0} enabled={instagram?.enabled} modifier={"post-card--width"} />}
                            {youtube.enabled && <PromotionPostCard name={"Youtube"} posts={youtube?.quantity || 0} enabled={youtube?.enabled} tag={"Spot"} modifier={"post-card--width"} />}
                            {podcast.enabled && <PromotionPostCard name={"Podcast"} posts={podcast?.quantity || 0} enabled={podcast?.enabled} tag={"Spot"} modifier={"post-card--width"} />}
                        </div>
                    )}
                </div>
            </Card>
        </Link>
    );
}
