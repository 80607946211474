import React from 'react';
import './Toggle.scss';

function Toggle({ label, value, onChange, modifier, noC = false }) {

    const toggle = () => {
        onChange(!value);
    };

    if (noC === true) {
        return (
            <div className={`Toggle Toggle--${value ? 'on' : 'off'} Toggle--${modifier}`} onClick={toggle}>
                <div className={`Toggle__handle Toggle__handle--${modifier}`} />
            </div>
        )
    }

    return (
        <div className={`Toggle__container Toggle__container--${modifier}`}>
            {label && <label className={`Toggle__label Toggle__label--${modifier}`}>{label}</label>}
            <div className={`Toggle Toggle--${value ? 'on' : 'off'} Toggle--${modifier}`} onClick={toggle}>
                <div className={`Toggle__handle Toggle__handle--${modifier}`} />
            </div>
        </div>
    );
}

export default Toggle;
