import React, { useContext } from 'react';
import ThemeContext from '../../context/ThemeContext';
import './Comment.scss';
import axios from 'axios';
import moment from 'moment';


const Comment = ({ comment, }) => {

    const { author, content, likes, commentisLiked } = comment;



    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme'

    const LikeIcon = () => {
        let color = themeMode === 'light' ? 'darkgrey' : 'white';
        color = commentisLiked ? 'rgb(255, 0, 102)' : color;

        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill={color} className="" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z" />
            </svg>
        )
    }

    const getTimeDifference = () => {
        const now = moment();
        const notificationTime = moment(comment.createdAt);
        const diffInDays = now.diff(notificationTime, 'days');
        const diffInHours = now.diff(notificationTime, 'hours');
        const diffInMinutes = now.diff(notificationTime, 'minutes');

        if (diffInDays > 0) {
            return `${diffInDays} d`;
        } else if (diffInHours > 0) {
            return `${diffInHours} h`;
        } else if (diffInMinutes > 0) {
            return `${diffInMinutes} m`;
        } else {
            return 'Just now';
        }
    };

    const handleCommentLike = async (id) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_API}/posts/comment/${id}/like`);
            console.log(response)
            window.location.reload();
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <div className={`comment comment--${themeClass}`}>
            <div className={`comment__user comment__user--${themeClass}`}>
                <img className="comment__img" src={author?.picture } alt="profile" />
                <span className="comment__username">{author?.username || "username"}</span>
            </div>
            <div className={`comment__comment comment__comment--${themeClass}`}>
                <span className={`comment__text comment__text--${themeClass}`}>{content}</span>
                <span className={`comment__date comment__date--${themeClass}`}>{getTimeDifference()}</span>
            </div>
            <div className='comment__actions'>
                <div className='comment__likes' onClick={() => { handleCommentLike(comment._id) }}>
                    <div className='comment__icon' >
                        <LikeIcon />
                    </div>
                    <span className="comment__likes__text">{likes?.length || 0} likes</span>
                </div>
            </div>
        </div>
    );
};

export default Comment;
