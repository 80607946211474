import { Link as RouterLink } from 'react-router-dom';
import './Link.scss';
import ThemeContext from '../../context/ThemeContext';
import React, { useContext } from 'react';
import LinkIcon from './LinkIcon';
// import XIcon from './XIcon';

const Link = ({ title, url, modifiy }) => {

  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';


  return (
    <RouterLink to={url} className={`link-cmp link-cmp--${themeClass} link-cmp${modifiy}`}>
      <LinkIcon />
      <p className={`link-text link-text--${themeClass} link-text${modifiy}`}>{title}</p>
      <div className="link-cmp__icon">
        {/* <XIcon /> */}
      </div>
    </RouterLink>
  );
};

export default Link;
