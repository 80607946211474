import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import './CalendarPage.scss';
import UserRoute from '../../Routes/Auth';
import Todoist from '../../Components/Todo/Todo';
import LeftArrow from './LeftArrow';
import RightArrow from './RightArrow';
import PlusIcon from './PlusIcon';
import ThemeContext from '../../context/ThemeContext';
import AddEventModalForm from '../../Components/AddEventModalForm/AddEventModalForm';
import { addEvent, getEvents } from '../../api/calendar';

const CalendarPage = ({ user }) => {
  const [view, setView] = useState('day');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [eventList, setEventList] = useState([]);

  const { themeMode } = useContext(ThemeContext);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const events = await getEvents();
        setEventList(events);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchEvents();
  }, []);

  const handlePrev = () => {
    const newDate = moment(currentDate).subtract(1, view);
    setCurrentDate(newDate.toDate());
  };

  const handleNext = () => {
    const newDate = moment(currentDate).add(1, view);
    setCurrentDate(newDate.toDate());
  };

  const handleAddEvent = async (newEvent) => {
    try {
      const savedEvent = await addEvent(newEvent);
      setEventList([...eventList, savedEvent]);
    } catch (error) {
      console.error('Error adding event:', error);
    }
  };

  const renderView = () => {
    switch (view) {
      case 'day':
        return <DayView events={eventList} currentDate={currentDate} />;
      case 'week':
        return <WeekView events={eventList} />;
      case 'month':
        return <MonthView date={currentDate} />;
      case 'year':
        return <YearView date={currentDate} />;
      default:
        return <DayView events={eventList} currentDate={currentDate} />;
    }
  };

  const formatDate = (date) => {
    if (view === 'day') {
      return moment(date).format('ddd, MMM D, YYYY');
    } else if (view === 'week') {
      const startOfWeek = moment(date).startOf('week');
      const endOfWeek = moment(date).endOf('week');
      return `${startOfWeek.format('MMM D')} - ${endOfWeek.format('MMM D, YYYY')}`;
    } else if (view === 'month') {
      return moment(date).format('MMMM YYYY');
    } else if (view === 'year') {
      return moment(date).format('YYYY');
    }
  };

  return (
    <UserRoute username={user && user.username}>
      <div className="calendar">
        <div className="calendar-header">
          <div className='calendar-nav__left'>
            <LeftArrow onClick={handlePrev} />
            <div className="calendar-nav__btns">{formatDate(currentDate)}</div>
            <RightArrow onClick={handleNext} />
          </div>
          <div className="calendar-nav">
            <div className='calendar-nav__middle'>
              <PlusIcon onClick={() => setIsModalOpen(true)} themeMode={themeMode} />
              <div className="calendar-nav__btns" onClick={() => setView('day')}>Day</div>
              <div className="calendar-nav__btns" onClick={() => setView('week')}>Week</div>
              <div className="calendar-nav__btns" onClick={() => setView('month')}>Month</div>
              <div className="calendar-nav__btns" onClick={() => setView('year')}>Year</div>
            </div>
          </div>
        </div>
        <div className="calendar-container">
          <div className="calendar-body">
            {renderView()}
          </div>
          {view === 'day' && <Todoist />}
        </div>
        <AddEventModalForm
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSave={handleAddEvent}
        />
      </div>
    </UserRoute>
  );
};

const hours = Array.from({ length: 24 }, (_, i) => i);

const DayView = ({ events, currentDate }) => {
  const currentDayEvents = events.filter(event =>
    moment(event.startDateTime).isSame(currentDate, 'day')
  );

  return (
    <div className="day-view">
      {hours.map((hour) => (
        <div key={hour} className="hour-slot">
          <div className="hour-label">{`${hour}:00`}</div>
          <div className="events-container">
            {currentDayEvents
              .filter(event => {
                const eventStartHour = moment(event.startDateTime).hour();
                const eventEndHour = moment(event.endDateTime).hour();
                return eventStartHour <= hour && eventEndHour >= hour;
              })
              .map((event, index) => {
                const eventStart = moment(event.startDateTime);
                const eventEnd = moment(event.endDateTime);
                const durationInMinutes = eventEnd.diff(eventStart, 'minutes');
                const height = (durationInMinutes / 60) * 100; // Calculate height percentage

                return (
                  <div
                    key={index}
                    className={`calendar-event calendar-event-${event.type}`}
                    style={{
                      height: `${height}%`,
                      top: `${(eventStart.minute() / 60) * 100}%`,
                      position: 'absolute',
                      left: `${(index % 2) * 50}%`, // Ensure events are side by side
                      width: 'calc(50% - 10px)', // Set width to 50% minus gap
                    }}
                  >
                    <div className="calendar-event-title">{event.title}</div>
                    <div className="calendar-event-time">
                      {eventStart.format('h:mm A')} - {eventEnd.format('h:mm A')}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      ))}
    </div>
  );
};

const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const WeekView = ({ events }) => (
  <div className="week-view">
    <div className="week-grid">
      {daysOfWeek.map((day, dayIndex) => (
        <div key={dayIndex} className="day-column">
          <div className="day-label">{day}</div>
          {hours.map((hour) => (
            <div key={hour} className="hour-slot">
              <div className="hour-label">{`${hour}:00`}</div>
              {events
                .filter((event) => moment(event.startDateTime).hour() === hour && moment(event.startDateTime).day() === dayIndex)
                .map((event, index) => (
                  <div key={index} className={`calendar-event calendar-event-${event.type}`}>
                    <div className="calendar-event-time">{moment(event.startDateTime).format('h:mm A')}</div>
                    <div className="calendar-event-title">{event.title}</div>
                  </div>
                ))}
            </div>
          ))}
        </div>
      ))}
    </div>
  </div>
);

const MonthView = ({ date }) => {
  const { days, startDay } = generateDays(date.getFullYear(), date.getMonth());

  return (
    <div className="month-view">
      <div className="month-grid">
        {daysOfWeek.map((day) => (
          <div key={day} className="day-label">{day}</div>
        ))}
        {Array.from({ length: startDay }).map((_, i) => (
          <div key={`empty-${i}`} className="day-cell empty"></div>
        ))}
        {days.map((day) => (
          <div key={day} className="day-cell">{day}</div>
        ))}
      </div>
    </div>
  );
};

const YearView = ({ date }) => {
  const currentYear = date.getFullYear();

  return (
    <div className="year-view">
      <div className="year-grid">
        {monthsOfYear.map((month, monthIndex) => {
          const { days, startDay } = generateDays(currentYear, monthIndex);
          return (
            <div key={monthIndex} className="month-column">
              <div className="month-label">{month}</div>
              <div className="month-grid">
                {daysOfWeek.map((day) => (
                  <div key={day} className="day-label">{day}</div>
                ))}
                {Array.from({ length: startDay }).map((_, i) => (
                  <div key={`empty-${monthIndex}-${i}`} className="day-cell empty"></div>
                ))}
                {days.map((day) => (
                  <div key={`${monthIndex}-${day}`} className="day-cell">{day}</div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const monthsOfYear = [
  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];

const generateDays = (year, month) => {
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const startDay = new Date(year, month, 1).getDay();
  const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
  return { days, startDay };
};

export default CalendarPage;
