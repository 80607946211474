import { Context } from "../../context/index";
import React, { useContext, useEffect, useState } from 'react';
import UserRoute from '../../Routes/Auth';
import InputField from '../../Components/InputField/InputField';
import "./SettingsBillingPage.scss";
import Button from '../../Components/Button/Button';
import Navigation from "../../Components/Navigation/Navigation";
import axios from "axios";
import UserSettings from "../../Components/UserSettings/UserSettings";

export default function SettingsBillingPage() {
  const [formData, setFormData] = useState({
    cardNumber: "",
    expMonth: "",
    expYear: "",
    cvc: "",
    bankAccountNumber: "",
    bankRoutingNumber: "",
  });
  const [username, setUsername] = useState("");
  const { state: { user } } = useContext(Context);

  useEffect(() => {
    if (user !== null) {
      setUsername(user.username);
    }
  }, [user]);

  const { cardNumber, expMonth, expYear, cvc, bankAccountNumber, bankRoutingNumber } = formData;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleBillingPortal = () => {
    const billingUrl = user.stripeSeller?.type === 'express' 
      ? process.env.REACT_APP_STRIPE_BILLING 
      : "https://dashboard.stripe.com/login";
    window.location.href = billingUrl;
  };

  const handleAddPaymentMethod = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API}/stripe/add-payment-method`, {
        customerId: user.stripeCustomerId,
        paymentMethod: {
          card: { number: cardNumber, exp_month: expMonth, exp_year: expYear, cvc },
          bank_account: { account_number: bankAccountNumber, routing_number: bankRoutingNumber },
        },
      });
      console.log("Payment method added successfully:", data);
    } catch (error) {
      console.log("Error adding payment method:", error);
    }
  };

  return (
    <UserRoute username={username}>
      <div className="settings-billing-pg">
        <Navigation heading={"Billing Settings"} back={true} />
        <UserSettings>
          <form className="settings-billing-pg__form" onSubmit={handleAddPaymentMethod}>
            <h2>Credit/Debit Card</h2>
            <InputField label="Card Number" name="cardNumber" type="text" value={cardNumber} onChange={handleInputChange} />
            <InputField label="Expiry Month" name="expMonth" type="text" value={expMonth} onChange={handleInputChange} />
            <InputField label="Expiry Year" name="expYear" type="text" value={expYear} onChange={handleInputChange} />
            <InputField label="CVC" name="cvc" type="text" value={cvc} onChange={handleInputChange} />
            
            <h2>Bank Account (for Debits)</h2>
            <InputField label="Bank Account Number" name="bankAccountNumber" type="text" value={bankAccountNumber} onChange={handleInputChange} />
            <InputField label="Routing Number" name="bankRoutingNumber" type="text" value={bankRoutingNumber} onChange={handleInputChange} />

            <Button type="submit">Add Payment Method</Button>
          </form>
          <Button onClick={handleBillingPortal}>
            Go to Billing Portal
          </Button>
        </UserSettings>
      </div>
    </UserRoute>
  );
}
