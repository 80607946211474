import React, { useState } from 'react';
import './SocialMediaSelector.scss';

const socialPlatforms = [
  { name: 'Instagram', label: 'Instagram Post ID' },
  { name: 'TikTok', label: 'TikTok Post ID' },
  { name: 'YouTube', label: 'YouTube Channel ID' },
  { name: 'X', label: 'X Post ID' },
  { name: 'Facebook', label: 'Facebook Post ID' },
  { name: 'Snapchat', label: 'Snapchat Post ID' },
  // Add more platforms as needed
];

const SocialMediaSelector = () => {
  const [platforms, setPlatforms] = useState([{ platform: socialPlatforms[0].name, id: '' }]);

  const handlePlatformChange = (index, value) => {
    const updatedPlatforms = [...platforms];
    updatedPlatforms[index].platform = value;
    updatedPlatforms[index].id = ''; // Reset ID when platform changes
    setPlatforms(updatedPlatforms);
  };

  const handleInputChange = (index, value) => {
    const updatedPlatforms = [...platforms];
    updatedPlatforms[index].id = value;
    setPlatforms(updatedPlatforms);
  };

  const handleAddPlatform = () => {
    setPlatforms([...platforms, { platform: socialPlatforms[0].name, id: '' }]);
  };

  const handleRemovePlatform = (index) => {
    const updatedPlatforms = platforms.filter((_, i) => i !== index);
    setPlatforms(updatedPlatforms);
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log('Submitted Data:', platforms);
  // };

  const getCurrentLabel = (platform) => {
    const selectedPlatform = socialPlatforms.find((p) => p.name === platform);
    return selectedPlatform ? selectedPlatform.label : 'ID';
  };

  return (
    <div className="social-media-selector">
      {platforms.map((entry, index) => (
        <div key={index} className="selector-container">
          <div className="select-box">
            <select
              value={entry.platform}
              onChange={(e) => handlePlatformChange(index, e.target.value)}
              placeholder="Select Platform"
              id="platform-select"
            >
              {socialPlatforms.map((platform) => (
                <option key={platform.name} value={platform.name}>
                  {platform.name}
                </option>
              ))}
            </select>
          </div>
          <div className="input-box">
            <input
              type="text"
              value={entry.id}
              id="platform-id"
              onChange={(e) => handleInputChange(index, e.target.value)}
              placeholder={getCurrentLabel(entry.platform)}
              required
            />
          </div>
          {index > 0 && ( // Show remove button only for entries other than the first one
            <button
              type="button"
              onClick={() => handleRemovePlatform(index)}
              style={{ cursor: 'pointer', alignSelf: 'center', marginLeft: '0px', width: '50px', padding: '4px 0px' }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-x-circled">
                <circle cx="12" cy="12" r="10"></circle>
                <path d="M15 9l-6 6"></path>
              </svg>
            </button>
          )}
        </div>
      ))}
      <button type="button" onClick={handleAddPlatform} className="add-platform-button">
        Add Another Platform
      </button>
    </div>
  );
};

export default SocialMediaSelector;
 