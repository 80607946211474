import React, { useContext } from 'react'
import './CommentInputField.scss';
import ThemeContext from '../../context/ThemeContext';


export default function CommentInputField({ comment, setComment, handlePostComment, imageBorder }) {
    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme'
    const imageBorderClass = imageBorder ? '--image' : '';

    return (
        <div className={`comment-if comment-if--${themeClass}`}>
            <input className={`comment-if__input comment-if__input--${themeClass} comment-if__input${imageBorderClass}`} type="text" placeholder='Add a comment...' value={comment} onChange={(e) => { setComment(e.target.value) }} />
            <button className={`comment-if__button comment-if__button--${themeClass} `} onClick={() => { handlePostComment() }}>Post</button>
        </div>
    )
}
