import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import './SupportedCountriesPage.scss';
import { stripeSupportedCountries } from '../../utils/countriesUtils';
import UserRoute from '../../Routes/Auth';
import { Context } from '../../context';
import Header from '../../Components/Header/Header';
import ThemeContext from "../../context/ThemeContext";

import MobileMenuModal from '../../Components/MobileMenuModal/MobileMenuModal';

const SupportedCountries = () => {
      const [countries, setCountries] = useState(stripeSupportedCountries);
      const { state: { user }, } = useContext(Context);
      const { themeMode } = useContext(ThemeContext);
      const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';
      const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

      useEffect(() => {
            // Fetch the list of countries from the backend
            const fetchCountries = async () => {
                  try {
                        const response = await axios.get('/countries');
                        setCountries(response.data);
                  } catch (error) {
                        console.error('Error fetching countries:', error);
                  }
            };

            fetchCountries();
      }, []);

      const renderCountries = (header = null) => {
            return (
                  <>
                        {header}
                        <div className="supported-countries">
                              <h1 className="supported-countries__title">Supported Countries</h1>
                              <p className="supported-countries__subtitle">
                                    {/* Chequeai currently supports {countries.length} */}
                              </p>
                              <div className="supported-countries__list">
                                    {countries.map((country, index) => (
                                          <div key={index} className="supported-countries__item">
                                                {country.name}
                                          </div>
                                    ))}
                              </div>
                              <p className={`supported-countries__message `}>
                                    We appreciate your support and are looking forward to adding support for more countries soon.
                              </p>
                        </div>
                  </>

            )
      }


      const header = <Header user={null} themeClass={themeClass} isMobileMenuOpen={isMobileMenuOpen} setIsMobileMenuOpen={setIsMobileMenuOpen} modifier="padding"/>

      return (
            user ? (
                  <UserRoute>
                        {renderCountries()}
                  </UserRoute>
            ) : (
                  <>

                        {renderCountries(header)}
                        <MobileMenuModal onClose={() => setIsMobileMenuOpen(false)} isMobileMenuOpen={isMobileMenuOpen} setIsMobileMenuOpen={setIsMobileMenuOpen} user={user} themeClass={themeClass} />
                  </>
            )
      );




};

export default SupportedCountries;
