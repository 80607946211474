// src/components/EarningsActivity/EarningsActivity.js

import React, { useState, useContext, useMemo } from 'react';
import './EarningsActivity.scss';
import EarningsStatement from '../EarningsStatement/EarningsStatement';
import ThemeContext from '../../context/ThemeContext';
import TransactionFilters from '../TransactionFilters/TransactionFilters.jsx';

// Define filter options
const transactionTypeOptions = [
  { value: 'donation', label: 'Donations' },
  { value: 'campaign', label: 'Campaigns' },
];

const statusOptions = [
  { value: 'pending', label: 'Pending' },
  { value: 'complete', label: 'Completed' },
  { value: 'cancelled', label: 'Cancelled' },
  { value: 'refunded', label: 'Refunded' },
  // Add more statuses as needed
];

const campaignStatusOptions = [
  { value: 'pending', label: 'Pending' },
  { value: 'completed', label: 'Completed' },
  { value: 'active', label: 'Active' },
  { value: 'cancelled', label: 'Cancelled' },
  // { value: 'refunded', label: 'Refunded' },
  // { value: 'chargeback', label: 'Chargeback' },
];

const donationStatusOptions = [
  { value: 'complete', label: 'Completed' },
  { value: 'refunded', label: 'Refunded' },
  { value: 'chargeback', label: 'Chargeback' },
];

const paidStatusOptions = [
  { value: 'paid', label: 'Paid' },
  { value: 'unpaid', label: 'Unpaid' },
  { value: 'refunded', label: 'Refunded' },
  // Add more paid statuses as needed
];

const moneyFilterOptions = [
  { value: 'lt_100', label: 'Less than $100' },
  { value: '100_500', label: '$100 - $500' },
  { value: 'gt_500', label: 'More than $500' },
  // Add more money filters as needed
];

// Mapping for campaign statuses
const campaignStatusMapping = {
  pending: ['pending'],
  completed: ['completed', 'review campaign'],
  active: ['approved', 'brand concepts', 'in progress', 'check in', 'comments', 'post campaign'],
  cancelled: ['cancelled'],
  refunded: ['refunded'],
  chargeback: ['chargeback'],
};

export default function EarningsActivity({ transactions, username, handleAccept, handleDecline }) {
  const isMobile = window.innerWidth <= 768; // Detect mobile based on screen width

  const formatDate = (dateString) => {
    if (!dateString) return 'Date not available';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return 'Invalid Date';

    // Options for time and date
    const timeOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
    const dateOptionsMobile = { month: '2-digit', day: '2-digit', year: '2-digit' }; // MM/DD/YY for mobile
    const dateOptionsDesktop = { day: '2-digit', month: 'short', year: 'numeric' };   // DD Mon YYYY for larger screens (short month)

    // Format time
    const formattedTime = new Intl.DateTimeFormat('en-GB', timeOptions).format(date);

    // Format date
    const formattedDate = isMobile
      ? new Intl.DateTimeFormat('en-GB', dateOptionsMobile).format(date)  // MM/DD/YY for mobile
      : new Intl.DateTimeFormat('en-GB', dateOptionsDesktop).format(date); // DD Mon YYYY for desktop (short month)

    // Combine for mobile or larger screens
    return isMobile
      ? `${formattedTime}, ${formattedDate}`  // Mobile: HH:mm:ss, MM/DD/YY
      : `${formattedDate}, ${formattedTime}`; // Desktop: DD Mon YYYY, HH:mm:ss
  };

  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const transactionsPerPage = 5;

  // State for filters
  const [selectedTransactionType, setSelectedTransactionType] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedPaidStatus, setSelectedPaidStatus] = useState(null);
  const [selectedMoneyFilter, setSelectedMoneyFilter] = useState(null);

  // Handle filter changes
  const handleTransactionTypeChange = (selectedOption) => { // New handler
    console.log(selectedOption);
    setSelectedTransactionType(selectedOption);
    setSelectedStatus(null); // Reset status filter when transaction type changes
    setCurrentPage(1); // Reset to first page on filter change
  };

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption);
    setCurrentPage(1); // Reset to first page on filter change
  };

  const handlePaidStatusChange = (selectedOption) => {
    setSelectedPaidStatus(selectedOption);
    setCurrentPage(1); // Reset to first page on filter change
  };

  const handleMoneyFilterChange = (selectedOption) => {
    setSelectedMoneyFilter(selectedOption);
    setCurrentPage(1); // Reset to first page on filter change
  };

  // Apply filters to transactions
  const filteredTransactions = useMemo(() => {
    return transactions.filter((transaction) => {
      // Validate transaction object
      if (
        !transaction ||
        !transaction._id ||
        (!transaction.campaignId && !transaction.donationId) ||
        !transaction?.sellerId?._id
      ) {
        console.error('Invalid transaction object:', transaction);
        return false;
      }

      // Transaction Type Filter
      const transactionTypeMatch = selectedTransactionType
        ? transaction.transactionType.toLowerCase() === selectedTransactionType.value.toLowerCase()
        : true;

      // Status Filter
      let statusMatch = true;
      if (selectedStatus) {
        if (transaction.transactionType.toLowerCase() === "donation") {
          statusMatch = (transaction.status || '').toLowerCase() === selectedStatus.value.toLowerCase();
        } else if (transaction.transactionType.toLowerCase() === "campaign") {
          // Use the mapping to get relevant statuses
          const selectedStatuses = campaignStatusMapping[selectedStatus.value] || [];
          const transactionStatus = (transaction.campaignId.status || '').toLowerCase();
          statusMatch = selectedStatuses.some(status => status.toLowerCase() === transactionStatus);
        }
      }

      // Paid Status Filter
      const paidStatusMatch = selectedPaidStatus
        ? (transaction.creatorPaymentStatus || '').toLowerCase() === selectedPaidStatus.value.toLowerCase()
        : true;

      // Money Filter
      let moneyMatch = true;
      if (selectedMoneyFilter) {
        const { value } = selectedMoneyFilter;
        if (value === 'lt_100') {
          moneyMatch = transaction.total / 100 < 100;
        } else if (value === '100_500') {
          moneyMatch = transaction.total / 100 >= 100 && transaction.total / 100 <= 500;
        } else if (value === 'gt_500') {
          moneyMatch = transaction.total / 100 > 500;
        }
      }

      return transactionTypeMatch && statusMatch && paidStatusMatch && moneyMatch;
    });
  }, [transactions, selectedTransactionType, selectedStatus, selectedPaidStatus, selectedMoneyFilter]);

  // Calculate indexes for current page
  const indexOfLastTransaction = currentPage * transactionsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
  const currentTransactions = filteredTransactions.slice(indexOfFirstTransaction, indexOfLastTransaction);

  // Function to handle page change
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate total pages
  const totalPages = Math.ceil(filteredTransactions.length / transactionsPerPage);

  return (
    <div className={`earnings-activity earnings-activity--${themeClass}`}>
      <h3 className="earnings-activity__title">Transactions</h3>
      <div>
        {/* Transaction Filters */}
        <TransactionFilters
          transactionTypeOptions={transactionTypeOptions}
          statusOptions={
            selectedTransactionType?.value === "donation"
              ? donationStatusOptions
              : selectedTransactionType?.value === "campaign"
              ? campaignStatusOptions
              : statusOptions
          }
          placeholderText={
            selectedTransactionType?.value === "donation"
              ? "Donation Status"
              : selectedTransactionType?.value === "campaign"
              ? "Campaign Status"
              : "Status"
          }
          paidStatusOptions={paidStatusOptions}
          moneyFilterOptions={moneyFilterOptions}
          selectedTransactionType={selectedTransactionType}
          onTransactionTypeChange={handleTransactionTypeChange}
          selectedStatus={selectedStatus}
          onStatusChange={handleStatusChange}
          selectedPaidStatus={selectedPaidStatus}
          onPaidStatusChange={handlePaidStatusChange}
          selectedMoneyFilter={selectedMoneyFilter}
          onMoneyFilterChange={handleMoneyFilterChange}
          themeMode={themeMode}
        />

        {/* Earnings Statements */}
        {currentTransactions.map((transaction) => {
          const isCampaignTransaction = transaction.campaignId && transaction.campaignId._id;

          const campaignOrDonationId = isCampaignTransaction ? transaction.campaignId._id : transaction.donationId._id;
          const campaignOrDonationName = isCampaignTransaction ? transaction.campaignId.campaignName : 'Fan Donation';
          const campaignOrDonationStatus = isCampaignTransaction
            ? transaction.campaignId.status
            : transaction.status.charAt(0).toUpperCase() + transaction.status.slice(1); // Capitalize first letter

          const sponsorOrDonatorLabel = isCampaignTransaction ? '(brand)' : '(donator)'; // Adjust the label based on transaction type

          return (
            <EarningsStatement
              username={username || ""}
              key={transaction._id}
              campaignId={campaignOrDonationId}
              campaignName={campaignOrDonationName}
              campaignStatus={campaignOrDonationStatus}
              fee={transaction.total}
              paymentStatus={transaction.creatorPaymentStatus}
              sponsorOrDonatorLabel={sponsorOrDonatorLabel} // Pass label for display
              sponsorOrDonatorName={transaction.buyerId?.username || 'Anonymous'} // Adjust based on transaction type
              creator={transaction.sellerId.username}
              transactionDate={formatDate(transaction.createdAt)}
              handleAccept={isCampaignTransaction ? handleAccept : undefined} // Buttons only for campaign
              handleDecline={isCampaignTransaction ? handleDecline : undefined} // Buttons only for campaign
              currency={transaction.currency}
              totalAfterFees={transaction.totalAfterFees}
            />
          );
        })}

        {/* Handle case when no transactions are available */}
        {filteredTransactions.length === 0 && (
          <div className='no-transactions'>
            <p className="no-transactions__message">No transactions match the selected filters.</p>
          </div>
        )}
      </div>

      {/* Pagination controls */}
      {totalPages > 1 && (
        <div className="pagination">
          <button
            className={`btn btn--${themeClass} ${currentPage === 1 ? 'disabled' : ''}`}
            onClick={() => currentPage !== 1 && paginate(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Prev
          </button>
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index + 1}
              className={`btn btn--${themeClass} ${currentPage === index + 1 ? 'selected' : ''}`}
              onClick={() => paginate(index + 1)}
            >
              {index + 1}
            </button>
          ))}
          <button
            className={`btn btn--${themeClass} ${currentPage === totalPages ? 'disabled' : ''}`}
            onClick={() => currentPage !== totalPages && paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
}
