import React, { useState, useContext } from 'react';
import './PhoneNumberInput.scss';
import { AsYouType, isValidNumber } from 'libphonenumber-js';
import ThemeContext from '../../context/ThemeContext';


const PhoneNumberInput = ({ phoneNumber, setPhoneNumber, setIsPhoneNumberValid, isValid, modifer }) => {
  const [touched, setTouched] = useState(false);
  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

  const handleChange = (e) => {
    const value = e.target.value;
    const formattedValue = new AsYouType().input(value);
    setPhoneNumber(formattedValue);

    const isValid = isValidNumber(formattedValue, 'US') || isValidNumber(formattedValue, 'CA') || isValidNumber(formattedValue, 'GB');
    setIsPhoneNumberValid(isValid);
  };

  const handleBlur = () => {
    setTouched(true);
  };

  return (
    <div className="signup-form__phoneNumber-container">
      <input
        type="text"
        id="phoneNumber"
        value={phoneNumber}
        onChange={handleChange}
        onBlur={handleBlur}
        className={`${modifer} form-control--${themeClass} form-control ${touched && !isValid ? 'is-invalid' : ''}`}
        placeholder="+1 (123)-456-7890"
      />
      {touched && phoneNumber !== "" && !isValid && (
        <div className="invalid-feedback">
          <p className={`input-text input-text--${themeClass}`}>Invalid phone number. Enter a valid phone number with the appropriate country code.</p>
        </div>
      )}
    </div>
  );
};

export default PhoneNumberInput;
