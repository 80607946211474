import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { Context } from "../../context/index";
import ThemeContext from '../../context/ThemeContext';
import UserRoute from '../../Routes/Auth';
import Navigation from "../../Components/Navigation/Navigation";
import EarningsCard from '../../Components/EarningsCard/EarningsCard';
import DonationsCard from "../../Components/DonationsCard/DonationsCard";
import CampaignsOverview from "../../Components/CampaignsOverivew/CampaignsOverview";
import CampaignLinkCard from '../../Components/CampaignLinkCard/CampaignLinkCard';
import { apiGet } from "../../utils/apiUtils"; // Adjust the import path as necessary
import "./OrdersPage.scss";

export default function OrdersPage() {
    const [username, setUsername] = useState("");
    const [data, setData] = useState(null);
    const [metrics, setMetrics] = useState(null);
    const [active, setActive] = useState([]);
    const [completed, setCompleted] = useState([]);
    const [requests, setRequests] = useState([]);
    const [stats, setStats] = useState({});
    const navigate = useNavigate();
    const [mode, setMode] = useState('Today');
    const [tf, setTf] = useState("today");
    const { themeMode } = useContext(ThemeContext);
    const { state: { user } } = useContext(Context);
    const [stripeData, setStripeData] = useState({
        totalAmount: 0,
        currency: "USD",
        totalDonations: 0,
    });

    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

    // Fetch Earnings with tf
    const fetchEarnings = useCallback(async (timeFrame) => {
        try {
            setTf(timeFrame);
            navigate(`?tf=${timeFrame}`);

            if (user?.stripeAccountId) {
                const response = await apiGet('/earnings/', { tf: timeFrame });
                setMetrics(response.metrics);
            }
        } catch (error) {
            console.error("Error fetching earnings:", error);
        }
    }, [navigate, user?.stripeAccountId]);

    // Fetch Campaigns with tf
    const fetchCampaign = useCallback(async (timeFrame) => {
        try {
            const response = await apiGet('/orders/', { tf: timeFrame });
            // console.log(response);
            setData(response.campaigns);
            setActive(response.lastThreeCampaigns?.campaigns || []);
            setCompleted(response.lastThreeCampaigns?.oldCampaigns || []);
            setRequests(response.lastThreeCampaigns?.incomingCampaigns || []);
        } catch (error) {
            console.error("Error fetching campaigns:", error);
        }
    }, []);

    // Fetch Donations Stats with tf
    const fetchDonationsStats = useCallback(async (timeFrame) => {
        try {
            const response = await apiGet('/donations/stats', { tf: timeFrame });
            // console.log(response);
            setStats(response.dbData);
            setStripeData(response.stripeData)
        } catch (error) {
            console.error("Error fetching donations stats:", error);
        }
    }, []);

    // Combined useEffect to fetch all data based on tf
    useEffect(() => {
        if (user?.username) {
            setUsername(user.username);
        }

        // Only fetch data when user is available
        if (user) {
            fetchCampaign(tf);
            fetchEarnings(tf);
            fetchDonationsStats(tf);
        }
    }, [user, tf, fetchEarnings, fetchCampaign, fetchDonationsStats]);

    // Handle date change and cycle through time frames
    const handleDateChange = () => {
        switch (tf) {
            case "1Y":
                setTf('today');
                setMode('Today');
                break;
            case "today":
                setTf('1W');
                setMode('Week');
                break;
            case "1W":
                setTf('1M');
                setMode('Month');
                break;
            case "1M":
                setTf('1Y');
                setMode('Year');
                break;
            default:
                setTf('today');
                setMode('Today');
        }
    };

    return (
        <UserRoute>
            <div className={`orders-pg orders-pg--${themeClass}`}>
                <Navigation
                    title={"Earnings"}
                    payments={true}
                    edit={"/settings/promotions"}
                    outgoing={true}
                    setTf={fetchEarnings}
                    handleDateChange={handleDateChange}
                    tf={tf}
                    mode={mode}
                    navRight={true}
                />
                <EarningsCard
                    grossVolume={(metrics?.metrics?.grossVolume ?? 0) / 100}
                    numberOfCustomersToday={metrics?.metrics?.campaignCount ?? 0}
                    numberOfPayments={metrics?.metrics?.successfulChargesCount ?? 0}
                    currency={user?.currency || 'USD'} // Provide a default currency
                />
                <h3 className='campaigns-overview__title'>Donations</h3>
                <DonationsCard 
                    totalDonations={stats?.totalDonations ?? 0}
                    totalAmount={stripeData?.donationsAmount / 100 || 0}
                    newSupporters={stats?.newSupporters ?? 0}                    
                    currency={user?.currency || 'USD'} // Provide a default currency    
                />
                <h3 className='campaigns-overview__title'>Campaigns</h3>
                <CampaignsOverview
                    requests={user?.userType === "creator" ? data?.incomingCampaigns ?? 0 : data?.outgoingCampaigns ?? 0}
                    past={data?.oldCampaigns ?? 0}
                    active={data?.campaigns ?? 0}
                    userType={user?.userType}
                />

                {active.length >= 0 && <h4 className='orders-pg__sub-title'>Active Campaigns</h4>}
                {active.length > 0 ? (
                    active.map((campaign) => (
                        <CampaignLinkCard
                            key={campaign._id}
                            username={username}
                            to={`/campaigns/${campaign._id}`}
                            title={campaign.campaignName}
                            brand={campaign.brand?.username || 'N/A'}
                            category={campaign.category || 'N/A'}
                            productName={campaign.productName || 'Product Name'}
                            tiktok={campaign.tiktok || ''}
                            instagram={campaign.instagram || ''}
                            youtube={campaign.youtube || ''}
                            podcast={campaign.podcast || ''}
                            image={campaign.image || ''}
                            id={campaign._id}
                            status={campaign.status || 'N/A'}
                            location={campaign.location || 'N/A'}
                            creator={campaign.creator?.username || 'N/A'}
                        />
                    ))
                ) : (
                    <div className="orders-pg__no-campaigns">
                        <p>No active campaigns found</p>
                    </div>
                )}

                {requests.length >= 0 && <h4 className='orders-pg__sub-title'>Pending Campaigns </h4>}
                {requests.length > 0 ? (
                    requests.map((campaign) => (
                        <CampaignLinkCard
                            key={campaign._id}
                            username={username}
                            to={`/campaigns/requests/#${campaign._id}`}
                            title={campaign.campaignName}
                            brand={campaign.brand?.username || 'N/A'}
                            category={campaign.category || 'N/A'}
                            productName={campaign.productName || 'Product Name'}
                            tiktok={campaign.tiktok || ''}
                            instagram={campaign.instagram || ''}
                            youtube={campaign.youtube || ''}
                            podcast={campaign.podcast || ''}
                            image={campaign.image || ''}
                            id={campaign._id}
                            status={campaign.status || 'N/A'}
                            location={campaign.location || 'N/A'}
                            creator={campaign.creator?.username || 'N/A'}
                        />
                    ))
                ) : (
                    <div className="orders-pg__no-campaigns">
                        <p>No pending requests</p>
                    </div>
                )}

                {completed.length >= 0 && <h4 className='orders-pg__sub-title'>Campaign History</h4>}
                {completed.length > 0 ? (
                    completed.map((campaign) => (
                        <CampaignLinkCard
                            key={campaign._id}
                            username={username}
                            to={`/campaigns/past/`}
                            title={campaign.campaignName}
                            brand={campaign.brand?.username || 'N/A'}
                            category={campaign.category || 'N/A'}
                            productName={campaign.productName || 'Product Name'}
                            tiktok={campaign.tiktok || ''}
                            instagram={campaign.instagram || ''}
                            youtube={campaign.youtube || ''}
                            podcast={campaign.podcast || ''}
                            image={campaign.image || ''}
                            id={campaign._id}
                            status={campaign.status || 'N/A'}
                            location={campaign.location || 'N/A'}
                            creator={campaign.creator?.username || 'N/A'}
                        />
                    ))
                ) : (
                    <div className="orders-pg__no-campaigns">
                        <p>No past campaigns found</p>
                    </div>
                )}
            </div>
        </UserRoute>
    );
}
