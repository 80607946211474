import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import MenuIcon from '../../Pages/UserPage/icons/MenuIcon';
import './ProductCard.scss';
import Card from '../Card/Card';
import { formatLabel } from '../../utils/helperFunctions';

const ProductCard = ({ product = {}, index, themeMode, themeClass, setShopModule }) => {
  // State to track if the card is in large or small view
  const [isLargeView, setIsLargeView] = useState(false);

  const toggleView = () => {
    setIsLargeView(!isLargeView);
  };

  return (
    <Card key={index} className={`  ${isLargeView ? '' : ' product-card--alt-view'}`}>
      <div className={`product-card__container ${isLargeView ? '' : 'product-card__container--alt-view'}`}>
        <div className={`product-card__image-container ${isLargeView ? '' : 'product-card__image-container--alt-view'}`}>
          <img
            onClick={toggleView}
            src={product.images?.[0]?.url || 'default-image-url'}
            alt="product"
            className={`product-card__image ${isLargeView ? '' : 'product-card__image--alt-view'}`}
          />
        </div>
        <div className={`product-card__details ${isLargeView ? '' : 'product-card__details--alt-view'}`}>
          <div className="product-card__details-header">
            <div className={`product-card__details-header-left  ${isLargeView ? '' : 'product-card__details-header-left--alt-view'}`}>
              <h2 className={`product-card__name  ${isLargeView ? '' : 'product-card__name--alt-view'}`}>{product.name || 'Default Name'}</h2>

              {isLargeView && (<div onClick={() => setShopModule(product)} className={`product-card__link--options  ${isLargeView ? '' : 'product-card__link--options--alt-view'}`}>
                <MenuIcon themeMode={themeMode} />
              </div>)}

            </div>
            {
              isLargeView && (<RouterLink to={product.url || '#'} className={`product-card__link product-card__link--${themeClass}`}>
                <p className="product-card__price">${product.price || 0}</p>
              </RouterLink>)
            }
          </div>
          <div className="product-card__details-body">
            {isLargeView && (<p className="product-card__description">{formatLabel(product.category) || 'Category: N/A)'}</p>)}
            {isLargeView && (<p className="product-card__description">{product.sku || 'SKU: Not Specified'}</p>)}
            {isLargeView && <p className="product-card__description">{product.color || 'Color: Not Specified'}</p>}
            {isLargeView && <p className="product-card__description">{product.description || 'Description: ???'}</p>}
          </div>
          {/* {!isLargeView && (
            <RouterLink to={product.url || '#'} className={`product-card__link product-card__link--${themeClass}  ${isLargeView ? '' : 'product-card__link--alt-view'}`}>
              <p className="product-card__price">${product.price.toFixed(2) || 0}</p>
            </RouterLink>
          )} */}
        </div>
      </div>
    </Card>
  );
};

export default ProductCard;
