import { useNavigate } from "react-router-dom";
import UserRoute from "../../Routes/Auth";
import "./HomePage.scss";
import { Context } from "../../context/index";
import React, { useContext, useEffect, useState, useCallback } from "react";
import Loading from "../../Components/Loading/Loading";
import WidgetGrid from "../../Components/WidgetGrid/WidgetGrid";
import { apiGet, apiPost } from "../../utils/apiUtils"; // Adjust the import path as necessary

function HomePage() {
  const [homepageData, setHomepageData] = useState({
    incomingRequests: 0,
    activeCampaigns: 0,
    unreadNotifications: 0,
    completedCampaigns: 0,
  });
  const [donationsCount, setDonationsCount] = useState(0);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [mode, setMode] = useState("Today");
  const [metrics, setMetrics] = useState();
  const timeFrames = ["today", "1W", "1M", "1Y"];
  const [tf, setTf] = useState("today");
  const {
    state: { user },
  } = useContext(Context);
  const navigate = useNavigate();

  const fetchHomepageData = useCallback(
    async (tf) => {
      try {
        const res = await apiGet(`/homepageData/`, { tf });
        setHomepageData(res);
      } catch (err) {
        console.error("Error fetching homepage data", err);
      }
    },
    []
  );

  const fetchDonations = useCallback(
    async (tf) => {
      try {
        const res = await apiGet(`/donations/totalDonations/`, { tf });
        setDonationsCount(res.donationCount);
      } catch (err) {
        console.error("Error fetching homepage data", err);
      }
    },
    []
  );

  const fetchEarnings = useCallback(
    async (tf) => {
      try {
        setTf(tf);
        navigate(`?tf=${tf}`);

        if (user && user.stripeAccountId !== "") {
          const response = await apiGet(`/earnings/`, { tf });
          setMetrics(response.metrics);
        }
      } catch (error) {
        console.error("Error fetching earnings:", error);
      }
    },
    [navigate, user]
  );

  const fetchUnreadNotifications = useCallback(
    async () => {
      try {
        if (user) {
          const response = await apiGet(`/notifications/unread-length/`);
          setNotificationsCount(response.unreadCount);
        }
      } catch (error) {
        console.error("Error fetching earnings:", error);
      }
    },
    [user ]
  );


  useEffect(() => {
    if (!user) {
      navigate(`/login/`);
    } else {
      fetchHomepageData(tf);
      fetchEarnings(tf);
      fetchDonations(tf);
      fetchUnreadNotifications()
    }
  }, [user, navigate, fetchEarnings, fetchHomepageData, tf, fetchDonations, fetchUnreadNotifications]);

  const onNextHandle = () => {
    const currentIndex = timeFrames.indexOf(tf);
    const nextIndex = (currentIndex + 1) % timeFrames.length;
    const nextTf = timeFrames[nextIndex];
    setTf(nextTf);
    setMode(convertTimeFrameToMode(nextTf));
    fetchHomepageData(nextTf);
    fetchEarnings(nextTf);
  };

  const onBackHandle = () => {
    const currentIndex = timeFrames.indexOf(tf);
    const prevIndex = (currentIndex - 1 + timeFrames.length) % timeFrames.length;
    const prevTf = timeFrames[prevIndex];
    setTf(prevTf);
    setMode(convertTimeFrameToMode(prevTf));
    fetchHomepageData(prevTf);
    fetchEarnings(prevTf);
  };

  const handleMoodToday = async (mood) => {
    try {
      const response = await apiPost("/user/mood", { mood });
      console.log(response);
    } catch (error) {
      console.error("Error posting mood:", error);
    }
  };

  const convertTimeFrameToMode = (tf) => {
    switch (tf) {
      case "today":
        return "Today";
      case "1W":
        return "Week";
      case "1M":
        return "Month";
      case "1Y":
        return "Year";
      default:
        return "Today";
    }
  };

  if (!user) {
    return <Loading />;
  }

  return (
    <UserRoute>
      <div className="home-pg">
        <WidgetGrid
          metrics={metrics}
          data={homepageData}
          donationsCount={donationsCount}
          notificationsCount={notificationsCount}
          username={user?.username || ""}
          mode={mode}
          onBackHandle={onBackHandle}
          onNextHandle={onNextHandle}
          currency={user?.currency || "USD"}
          handleMoodToday={handleMoodToday}
        />
      </div>
    </UserRoute>
  );
}

export default HomePage;
