import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

// Importing custom components and contexts
import UserRoute from '../../Routes/Auth';
import Navigation from '../../Components/Navigation/Navigation';
import Card from '../../Components/Card/Card';
import InputField from '../../Components/InputField/InputField';
import Button from '../../Components/Button/Button';
import DarkLoadingSpinner from '../../Components/DarkLoadingSpinner/DarkLoadingSpinner';
import ConfirmModal from '../../Components/ConfirmModal/ConfirmModal';
import DaysFilter from '../../Components/DaysFilter/DaysFilter';
import { Context } from "../../context/index";
import ThemeContext from '../../context/ThemeContext';

// Importing styles
import './EditLinksPage.scss';

// Define timeframes outside the component to maintain reference
const timeframesList = ['Today', 'Week', 'Month', 'Quarter', 'Year'];

export default function EditLinksPage() {
    // Extracting the link ID from the URL parameters
    const { id } = useParams();

    // Accessing user context
    const {
        state: { user },
    } = useContext(Context);

    // Accessing theme context
    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

    // Navigation hook
    const navigate = useNavigate();

    // Component state
    const [loading, setLoading] = useState(true); // Set initial loading to true
    const [deleteModal, setDeleteModal] = useState(false);
    const [link, setLink] = useState({
        title: "",
        url: "",
        metrics: {}
    });

    // Timeframe state
    const [timeframeIndex, setTimeframeIndex] = useState(0);

    // Since timeframesList is defined outside, no need to memoize it
    const timeframes = timeframesList;

    // Aggregated metrics state
    const [aggregatedMetrics, setAggregatedMetrics] = useState({ totalClicks: 0, totalShares: 0 });
    const [previousAggregatedMetrics, setPreviousAggregatedMetrics] = useState({ totalClicks: 0, totalShares: 0 });

    // Handler for input changes (title and URL)
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLink((prevLink) => ({
            ...prevLink,
            [name === 'linkTitle' ? 'title' : 'url']: value,
        }));
    };

    // Function to update the link
    const updateLink = async () => {
        try {
            const { data } = await axios.put(`${process.env.REACT_APP_API}/user/edit-profile/link/${id}`, link);
            console.log('Link updated:', data);
            navigate(`/${user.username}/share`);
        } catch (error) {
            console.error('Error updating link:', error);
        }
    };

    // Fetch the link data when the component mounts or the ID changes
    useEffect(() => {
        const fetchLink = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_API}/user/edit-profile/link/${id}`);
                console.log('Fetched link data:', data);
                setLink(data);
                setLoading(false); // Set loading to false after data is fetched
            } catch (error) {
                console.error('Error fetching link:', error);
                setLoading(false); // Even if there's an error, stop loading
            }
        };

        fetchLink();
    }, [id]);

    // Function to delete the link
    const handleDelete = async () => {
        try {
            const { data } = await axios.delete(`${process.env.REACT_APP_API}/user/edit-profile/link/${id}`);
            console.log('Link deleted:', data);
            navigate(`/${user.username}/share`);
        } catch (error) {
            console.error('Error deleting link:', error);
        }
    };

    // Handlers to navigate between timeframes
    const handleBackDateChange = () => {
        setTimeframeIndex((prevIndex) => (prevIndex === 0 ? timeframes.length - 1 : prevIndex - 1));
    };

    const handleNextDateChange = () => {
        setTimeframeIndex((prevIndex) => (prevIndex === timeframes.length - 1 ? 0 : prevIndex + 1));
    };

    // Function to get the label for the previous timeframe
    function getPreviousTimeframeLabel(timeframe) {
        switch (timeframe) {
            case 'Today':
                return 'Yesterday';
            case 'Week':
                return 'Last Week';
            case 'Month':
                return 'Last Month';
            case 'Quarter':
                return 'Last Quarter';
            case 'Year':
                return 'Last Year';
            default:
                return '';
        }
    }

    // Effect to aggregate metrics whenever link.metrics or timeframeIndex changes
    useEffect(() => {
        // Define the helper functions inside useEffect to avoid dependency issues
        const getDateRange = (timeframe) => {
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0); // Start of today in local time
            let startDate = new Date(currentDate);

            switch (timeframe) {
                case 'Today':
                    // Start date is today
                    break;
                case 'Week':
                    startDate.setDate(startDate.getDate() - 6); // Last 7 days including today
                    break;
                case 'Month':
                    startDate.setDate(startDate.getDate() - 29); // Last 30 days including today
                    break;
                case 'Quarter':
                    startDate.setDate(startDate.getDate() - 89); // Last 90 days approximately
                    break;
                case 'Year':
                    startDate.setDate(startDate.getDate() - 364); // Last 365 days including today
                    break;
                default:
                    break;
            }

            // End date is today at the end of the day in local time
            const endDate = new Date(currentDate);
            endDate.setHours(23, 59, 59, 999);

            return { startDate, endDate };
        };

        const getPreviousDateRange = (timeframe) => {
            const { startDate, endDate } = getDateRange(timeframe);
            let prevStartDate = new Date(startDate);
            let prevEndDate = new Date(endDate);

            switch (timeframe) {
                case 'Today':
                    prevStartDate.setDate(prevStartDate.getDate() - 1);
                    prevEndDate.setDate(prevEndDate.getDate() - 1);
                    break;
                case 'Week':
                    prevStartDate.setDate(prevStartDate.getDate() - 7);
                    prevEndDate.setDate(prevEndDate.getDate() - 7);
                    break;
                case 'Month':
                    prevStartDate.setMonth(prevStartDate.getMonth() - 1);
                    prevEndDate.setMonth(prevEndDate.getMonth() - 1);
                    break;
                case 'Quarter':
                    prevStartDate.setMonth(prevStartDate.getMonth() - 3);
                    prevEndDate.setMonth(prevEndDate.getMonth() - 3);
                    break;
                case 'Year':
                    prevStartDate.setFullYear(prevStartDate.getFullYear() - 1);
                    prevEndDate.setFullYear(prevEndDate.getFullYear() - 1);
                    break;
                default:
                    break;
            }

            return { startDate: prevStartDate, endDate: prevEndDate };
        };

        const aggregateMetrics = (metrics, startDate, endDate) => {
            let totalClicks = 0;
            let totalShares = 0;

            for (const dateKey in metrics) {
                // Parse the date key in 'MM_DD_YYYY' format
                const [month, day, year] = dateKey.split('_');
                const date = new Date(Number(year), Number(month) - 1, Number(day)); // months are 0-based

                // Set hours to noon to avoid DST issues
                date.setHours(12, 0, 0, 0);

                if (date >= startDate && date <= endDate) {
                    const metric = metrics[dateKey];
                    totalClicks += metric.clicks || 0;
                    totalShares += metric.shares || 0;
                }
            }

            return { totalClicks, totalShares };
        };

        if (link.metrics && Object.keys(link.metrics).length > 0) {
            const timeframe = timeframes[timeframeIndex];

            // Get current and previous date ranges
            const { startDate, endDate } = getDateRange(timeframe);
            const { startDate: prevStartDate, endDate: prevEndDate } = getPreviousDateRange(timeframe);

            // Aggregate metrics for current and previous timeframes
            const aggregatedData = aggregateMetrics(link.metrics, startDate, endDate);
            const previousAggregatedData = aggregateMetrics(link.metrics, prevStartDate, prevEndDate);

            // Update state with aggregated metrics
            setAggregatedMetrics(aggregatedData);
            setPreviousAggregatedMetrics(previousAggregatedData);
        } else {
            // If no metrics are available, reset aggregated metrics
            setAggregatedMetrics({ totalClicks: 0, totalShares: 0 });
            setPreviousAggregatedMetrics({ totalClicks: 0, totalShares: 0 });
        }
    }, [link.metrics, timeframeIndex, timeframes]); // Removed external function dependencies

    return (
        <UserRoute>
            <div className='edit-links-pg'>
                {/* Navigation Component */}
                <Navigation
                    heading={'Edit Link'}
                    back={true}
                    handleDelete={() => { setDeleteModal(true); }}
                    navRight={true}
                />

                {/* Card for Editing Link Details */}
                <Card className="card--input">
                    {loading ? (
                        <DarkLoadingSpinner />
                    ) : (
                        <>
                            <div className="link__header">
                                <h3 className="link-metrics__title">{link.title}</h3>
                                <h3 className={`link__status link__status${link.isActive ? '--active' : '--inactive'}`}>{link.isActive ? "Active" : "Offline"}</h3>


                            </div>

                            <InputField
                                label="Link Title"
                                name="linkTitle" // Consider renaming to "title" for consistency
                                type="text"
                                value={link.title}
                                onChange={handleInputChange}
                            />
                            <InputField
                                label="Link URL"
                                name="linkURL" // Consider renaming to "url" for consistency
                                type="url"
                                value={link.url}
                                onChange={handleInputChange}
                            />
                            <Button modifier="button" onClick={updateLink}>Update</Button>
                        </>
                    )}
                </Card>

                {/* Card for Displaying Analytics */}
                <Card className="card--input">
                    <div className='link-metrics__header'>
                        <h3 className="link-metrics__title">Analytics</h3>
                        <DaysFilter
                            themeClass={themeClass}
                            themeMode={themeMode}
                            handleBackDateChange={handleBackDateChange}
                            handleNextDateChange={handleNextDateChange}
                            currentMode={timeframes[timeframeIndex]}
                        />
                    </div>
                    <div className="link-metrics">
                        {/* Clicks Metric */}
                        <div className="link-metric">
                            <div className='link-metric__label-container'>
                                <span className="link-metric-label">Clicks</span>
                                <span className="link-metric-label">{getPreviousTimeframeLabel(timeframes[timeframeIndex])}</span>
                            </div>
                            <div className='link-metric__value-container'>
                                <span className="link-metric-value">{aggregatedMetrics.totalClicks}</span>
                                <span className="link-metric-value">{previousAggregatedMetrics.totalClicks}</span>
                            </div>
                        </div>

                        {/* Shares Metric */}
                        <div className="link-metric">
                            <div className='link-metric__label-container'>
                                <span className="link-metric-label">Shares</span>
                                <span className="link-metric-label">{getPreviousTimeframeLabel(timeframes[timeframeIndex])}</span>
                            </div>
                            <div className='link-metric__value-container'>
                                <span className="link-metric-value">{aggregatedMetrics.totalShares}</span>
                                <span className="link-metric-value">{previousAggregatedMetrics.totalShares}</span>
                            </div>
                        </div>
                    </div>
                </Card>

                {/* Confirm Deletion Modal */}
                {deleteModal && (
                    <ConfirmModal
                        isOpen={deleteModal}
                        onClose={() => setDeleteModal(false)}
                        setIsOpen={setDeleteModal}
                        message={"Are you sure you want to delete this link?"}
                        onConfirm={handleDelete}
                    />
                )}
            </div>
        </UserRoute>
    );
}
