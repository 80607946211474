import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './step.scss';
import TextInput from '../../TextInput/TextInput';
import Select from "react-select";
import { onSelectLightMode, onSelectDarkMode } from '../../../utils/stylesUtils';
import  { languageOptions } from '../../../utils/constants'

function ProfessionalDetailsStep({ data, onChange, onNext, onBack, themeMode }) {
  const [bio, setBio] = useState(data.bio || '');
  const [categories, setCategories] = useState(data.categories || []);
  const [website, setWebsite] = useState(data.website || '');


  const [languages, setLanguages] = useState(
    data.languages?.length > 0 
      ? data.languages.map((lang) => languageOptions.find((opt) => opt.value === lang)) 
      : [{ value: "english", label: "English" }]
  ); // Default to English if languages array is empty
  const [errors, setErrors] = useState({});

  const themeClass = themeMode === "light" ? "light-theme" : "dark-theme";

  const categoryOptions = [
    { value: "Artist", label: "Artist" },
    { value: "Actor", label: "Actor" },
    { value: "Athlete", label: "Athlete" },
    { value: "Automotive", label: "Automotive" },
    { value: "Beauty", label: "Beauty" },
    { value: "Content Producer", label: "Content Producer" },
    { value: "Content Creator", label: "Content Creator" },
    { value: "Comedian", label: "Comedian" },
    { value: "Content Pages", label: "Content Pages" },
    { value: "Creative", label: "Creative" },
    { value: "Fashion", label: "Fashion" },
    { value: "Fitness", label: "Fitness" },
    { value: "Food", label: "Food" },
    { value: "Gaming", label: "Gaming" },
    { value: "Health", label: "Health" },
    { value: "Lifestyle", label: "Lifestyle" },
    { value: "Live Streaming", label: "Live Streaming" },
    { value: "Music", label: "Music" },
    { value: "Music Production", label: "Music Production" },
    { value: "Model", label: "Model" },
    { value: "Photographer", label: "Photographer" },
    { value: "Podcasting", label: "Podcasting" },
    { value: "Street Wear", label: "Street Wear" },
    { value: "Tech", label: "Tech" },
    { value: "Travel", label: "Travel" },
    { value: "Writer", label: "Writer" },
    { value: "Copy-Writer", label: "Copy-Writer" },
    { value: "Video Editor", label: "Video Editor" },
  ];


  const handleCategoryChange = (selectedOptions) => {
    setCategories(selectedOptions);
  };

  const handleLanguagesChange = (selectedOptions) => {
    setLanguages(selectedOptions);
  };

  const validate = () => {
    const newErrors = {};
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validate()) {
      onChange({ bio, categories, website, languages: languages.map((lang) => lang.value) });
      onNext();
    }
  };

  return (
    <div className={`step ${themeClass}`}>
      <h2 className={`step__title ${themeClass}`}>Professional Details</h2>
      <form>
        {/* Categories Multi-Select */}
        <div className={`form-group ${themeClass}`}>
          <label htmlFor="categories">Category</label>
          <Select
            id="categories"
            options={categoryOptions}
            isMulti
            onChange={handleCategoryChange}
            value={categories}
            styles={themeMode === "light" ? onSelectLightMode : onSelectDarkMode}
            className={`signup-form__categories-select ${themeClass}`}
          />
        </div>

        {/* Languages Multi-Select */}
        <div className={`form-group ${themeClass}`}>
          <label htmlFor="languages">Languages</label>
          <Select
            id="languages"
            options={languageOptions}
            isMulti
            onChange={handleLanguagesChange}
            value={languages}
            styles={themeMode === "light" ? onSelectLightMode : onSelectDarkMode}
            className={`signup-form__languages-select ${themeClass}`}
          />
        </div>

        {/* Bio (Text Area) */}
        <div className="form-group" style={{ marginBottom: '0' }}>
          <TextInput
            id="bio"
            label="Bio"
            type="textarea"
            value={bio}
            onChange={(e) => setBio(e.target.value)}
            placeholder="Enter a brief bio (max 500 characters)"
            maxLength={500}
            themeMode={themeMode}
          />
        </div>

        {/* Website URL */}
        <div className="form-group" style={{ marginBottom: '8px' }}>
          <TextInput
            id="website"
            label="Website"
            type="url"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
            placeholder="Enter your website URL"
            themeMode={themeMode}
          />
        </div>

        {/* Navigation Buttons */}
        <div className="navigation-buttons">
          <button type="button" onClick={onBack} className={`back-button ${themeClass}`}>
            Back
          </button>
          <button type="button" onClick={handleNext} className={`next-button ${themeClass}`}>
            Next
          </button>
        </div>
      </form>
      {errors && <p className="error-message">{errors}</p>}
    </div>
  );
}

ProfessionalDetailsStep.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
};

export default ProfessionalDetailsStep;
