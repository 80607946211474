import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import './VerifyEmailPage.scss';

const VerifyEmail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const token = query.get('token');

  const [message, setMessage] = useState('');

  const handleVerify = useCallback(async () => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API}/verify-email`, { token });
      if (res.data.ok) {
        setMessage('Email Verified');
        setTimeout(() => {
          navigate('/login');
        }, 2000); // Redirect to login page after 2 seconds
      } else {
        setMessage(res.data.message || 'Error verifying email');
      }
    } catch (err) {
      setMessage('Error verifying email');
    }
  }, [token, navigate]);

  useEffect(() => {
    if (token) {
      handleVerify();
    }
  }, [token, handleVerify]);

  return (
    <div className='verify-email-form__container'>
      <Link to={"/login"} className='verify-email-form__header'>Chequeai</Link>
      <div className='verify-email-form'>
        {message && <div className={`verify-email-form__message ${message === 'Email Verified' ? 'verify-email-form__message--success' : 'verify-email-form__message--error'}`}>{message}</div>}
      </div>
    </div>
  );
};

export default VerifyEmail;
