
import React, { useEffect, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import './SubscriptionFailurePage.scss';
import UserRoute from '../../Routes/Auth';
import axios from 'axios';
import ThemeContext from '../../context/ThemeContext';


function SubscriptionFailurePage() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

    useEffect(() => {
        const failureRequest = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_API}/user/subscriptions/${id}`);
                console.log(data);
                if (!data.success) {
                    navigate('/subscriptions');
                }
            } catch (err) {
                console.error(err);
            }
        };

        failureRequest();
    }, [id, navigate]);

    return (
        <UserRoute>
            <div className='subscription-failure-pg'>
                <h1 className='subscription-failure-pg__title'>Payment Cancelled</h1>
                <p className={`subscription-failure-pg__text subscription-failure-pg__text--${themeClass}`}>Something went wrong, please try again later.</p>
                <p className='subscription-failure-pg__link'>
                    <Link to="/settings/subscription" className={`subscription-failure-pg__link-text subscription-failure-pg__link-text--${themeClass}`}>
                        Plans
                    </Link>
                </p>
            </div>
        </UserRoute>
    );
}

export default SubscriptionFailurePage;
