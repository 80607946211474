import React, { useState, useContext } from 'react';
import './CustomSelect.scss';

import ThemeContext from '../../context/ThemeContext';

const CustomSelect = ({ options, disabledOptions, onOptionSelect, value  }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

  const handleOptionClick = (option) => {
    if (!disabledOptions.includes(option)) {
      setSelectedOption(option);
      setIsOpen(false);
      onOptionSelect(option);
    }
  };

  return (
    <div className={`custom-select custom-select--${themeClass}`} onClick={() => setIsOpen(!isOpen)}>
      <div className={`select-selected select-selected--${themeClass} ${isOpen ? 'select-arrow-active' : ''}`}>
        {selectedOption ? selectedOption : value}
      </div>
      {isOpen && (
        <div className={`select-items select-items--${themeClass}`}>
          {options.map((option, index) => (
            <div
              key={index}
              onClick={() => handleOptionClick(option)}
              className={disabledOptions.includes(option) ? 'disabled-option' : ''}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
