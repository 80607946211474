import React from "react";

export default function PortfolioIcon(themeMode) {
  const themeClass = themeMode === "light" ? "light-theme" : "dark-theme";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      stroke={themeClass === "light-theme" ? "black" : "darkgray"}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
      className="icon icon-tabler icons-tabler-outline icon-tabler-crop-portrait"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M6 4m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z" />
    </svg>
  );
}
