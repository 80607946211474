import React, { useContext } from 'react';
import UploadImageComponent from '../UploadImageComponent/UploadImageComponent';
import './CampaignPortfolioContainer.scss';
import ThemeContext from '../../context/ThemeContext';
import CampaignPng from '../../assets/defaults/Campaign.png';
import CampaignLightPng from '../../assets/defaults/CampaignLight.png';


export default function CampaignPortfolioContainer({ username, status, brand, creator, tag, uploadCreatorPreviewImage, setUploadCreatorPreviewImage, uploadBrandPreviewImage, setUploadBrandPreviewImage, portfolioItems, handleAddItem, uploadImage, setUploadImage, type = 'image/png, image/jpeg', videoDat, handleItemClick, campaignPostsCount }) {

  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';
  const waitingContent = themeMode === 'light' ? CampaignLightPng : CampaignPng;

  return (
    <div className={`campaign-portfolio-container campaign-portfolio-container--${themeClass}`}>
      {portfolioItems && portfolioItems.map((item, index) => (
        <div key={index} className={`campaign-portfolio-container__portfolio  campaign-portfolio-container__portfolio--modifier`}>
          {item.key.endsWith('.mp4') ? (
            <div className='campaign-portfolio-container__portfolio-item' onClick={() => handleItemClick(item, brand, creator)}>
              <img className='campaign-portfolio-container__portfolio-image' src={item.thumbnail} alt={`Video ${index}`} />
            </div>
          ) : (
            <img className='campaign-portfolio-container__portfolio-image' src={item.url} alt={`Portfolio ${index}`} onClick={() => handleItemClick(item, brand, creator)} />
          )}
        </div>
      ))}
      {(campaignPostsCount < 12) && (
        <>
          {((username === brand?.username) || (username === creator?.username)) && (status !== "Completed" && status !== 'Review Campaign') ? (
            <UploadImageComponent tag={tag} imagePreview={uploadCreatorPreviewImage ? uploadCreatorPreviewImage : uploadBrandPreviewImage} setImagePreview={setUploadCreatorPreviewImage ? setUploadCreatorPreviewImage : setUploadBrandPreviewImage} handleAdd={handleAddItem} image={uploadImage} setImage={setUploadImage} type={type} />
          ) : <img style={{ width: '100%', maxHeight: '130px', maxWidth: '130px', borderRadius: "16px", }} src={waitingContent} className='' alt='campagin placeholder' />}
        </>

      )}
    </div>
  );
}
