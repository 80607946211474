import React, { useContext }  from 'react'
import ThemeContext from '../../context/ThemeContext';
import './SocialField.scss';
export default function SocialFieldCard({ name, setPosts, posts }) {
    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';


    const handlePlusClick = () => {
        if (posts < 4) {
            setPosts(posts + 1);
        }
    };

    const handleMinusClick = () => {
        if (posts > 0) {
            setPosts(posts - 1);
        }
    };

    return (
        <div className="social-field-card">
            <div className="social-field-card__header">
                <label className='social-field-card__label'>{name} Spots</label>
            </div>
            <div className="social-field-card__quantity">
                <button className={`social-field-card__quantity-button social-field-card__quantity-button--${themeClass}`} onClick={handleMinusClick}>
                    <p className='social-field-card__qty-text'>-</p>
                </button>
                <span>{posts}</span>
                <button className={`social-field-card__quantity-button social-field-card__quantity-button--${themeClass}`} onClick={handlePlusClick}>
                    <p className='social-field-card__qty-text'>+</p>
                </button>
            </div>
        </div>
    );
}
