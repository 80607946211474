// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dark-loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #000;
  color: #fff;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
p {
  margin-top: 20px;
  font-size: 1.2rem;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/Components/DarkLoadingSpinner/DarkLoadingSpinner.scss"],"names":[],"mappings":"AAKA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;EACA,sBAVgB;EAWhB,WATW;AAKb;;AAOA;EACE,0CAAA;EACA,0BAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,kCAAA;AAJF;;AAQA;EACE;IACE,uBAAA;EALF;EAOA;IACE,yBAAA;EALF;AACF;AAQA;EACE,gBAAA;EACA,iBAAA;EACA,WAlCW;AA4Bb","sourcesContent":["// Variables for dark mode colors\n$dark-background: #000;\n$spinner-color: #fff;\n$text-color: #fff;\n\n.dark-loading-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  background-color: $dark-background;\n  color: $text-color;\n}\n\n.spinner {\n  border: 4px solid rgba(255, 255, 255, 0.3); // Light grey\n  border-top: 4px solid $spinner-color; // Spinner color\n  border-radius: 50%;\n  width: 40px;\n  height: 40px;\n  animation: spin 1s linear infinite;\n}\n\n// Spinner animation\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\np {\n  margin-top: 20px;\n  font-size: 1.2rem;\n  color: $text-color;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
