import React, { useState, useContext, useEffect, useCallback } from 'react';
import axios from 'axios';
import './LoginPage.scss';
import { Link, useNavigate } from 'react-router-dom';
import { Context } from "../../context/index";
import Cookies from 'js-cookie';
import ThemeContext from '../../context/ThemeContext';
import FooterComponent from '../../Components/FooterComponent/FooterComponent';
// import Button from '../../Components/Button/Button';
// import { startAuthentication } from '@simplewebauthn/browser';


export default function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State for showing password
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { state, dispatch } = useContext(Context);
  const { user, csrfToken } = state;
  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === 'light' ? 'light-theme' : '';
  // console.log(csrfToken)


  const fetchCsrfToken = useCallback(async () => {
    try {
      const response = await axios.get("/csrf-token");
      if (response?.data?.csrf) {
        dispatch({ type: "SET_CSRF_TOKEN", payload: response.data.csrf });
        // Set the CSRF token in Axios default headers
        axios.defaults.headers.common["x-csrf-token"] = response.data.csrf;
      }
    } catch (error) {
      console.error("Error fetching CSRF token:", error);
    }
  }, [dispatch]); // useCallback with `dispatch` as a dependency
  
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage('');
    try {
      const response = await axios.post(`/auth/login`, {
        username,
        password,},
        {
          headers: {
              "x-xsrf-token": csrfToken,      

          },
      });

      if (response.data.token && response.data.user) {
        Cookies.set('token', response.data.token, { 
          expires: 7, 
          secure: process.env.NODE_ENV === 'production', 
          sameSite: 'None' 
        });
        axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.token}`;
        dispatch({
          type: 'LOGIN',
          payload: response.data.user,
        });

        window.localStorage.setItem('user', JSON.stringify(response.data.user));
        window.localStorage.setItem('token', response.data.token);

        navigate(`/${response.data.user.username}`);
      } else {
        setErrorMessage('Unexpected server response.');
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('An error occurred. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  // const handlePasskeyLogin = async () => {
  //   setErrorMessage('');
  //   try {
  //     const optionsResponse = await axios.post(`/webauthn/generate-authentication-options`, { email: username });
  //     console.log(optionsResponse.data.options);
  //     const options = optionsResponse.data.options;
  //     const assertion = await startAuthentication(options);
  //     const verificationResponse = await axios.post(`/webauthn/verify-authentication`, {
  //       email: username,
  //       assertion,
  //     });

  //     if (verificationResponse.data.verified) {
  //       const { user, token } = verificationResponse.data;
  //       Cookies.set('_token', token, { 
  //         expires: 7, 
  //         secure: process.env.NODE_ENV === 'production', 
  //         sameSite: 'None' 
  //       });
  //       dispatch({ type: 'LOGIN', payload: user });
  //       window.localStorage.setItem('user', JSON.stringify(user));
  //       navigate(`/${username}`);
  //     } else {
  //       setErrorMessage('Passkey login failed.');
  //     }
  //   } catch (error) {
  //     if (error.response && error.response.data && error.response.data.message) {
  //       setErrorMessage(error.response.data.message);
  //     } else {
  //       setErrorMessage('Error during passkey login. Please try again.');
  //     }
  //   }
  // };

  useEffect(() => {
    if (user !== null) navigate('/home');
    fetchCsrfToken();
  }, [user, navigate, fetchCsrfToken]);

  return (
    <>
      <div className={`login-form__container login-form__container--${themeClass}`}>
        <Link to={'/'}>
          <h1 className="login-form__header">Chequeai</h1>
        </Link>

        <form className="login-form" onSubmit={handleSubmit}>
          <div className="login-form__username-container">
            <input
              className={`login-form__username-input login-form__username-input--${themeClass}`}
              name="username"
              placeholder="Username"
              value={username}
              onChange={(event) => setUsername(event.target.value)}
              disabled={loading}
              required
            />
          </div>

          <div className={`login-form__password-container login-form__password-container--${themeClass}`}>
            <input
              className={`login-form__password-input login-form__password-input--${themeClass}`}
              name="password"
              placeholder="Password"
              type={showPassword ? 'text' : 'password'} // Toggle between text and password
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              disabled={loading}
              required
            />
            <span 
              className={`login-form__show-password-toggle ${showPassword ? 'active' : ''}`}
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? 'Hide' : 'Show'}
            </span>
          </div>

          {errorMessage && <p className="login-form__error">{errorMessage}</p>}

          <div className="login-form__btn-container">
            <button
              className={`login-form__btn-submit login-form__btn-submit--${themeClass}`}
              type="submit"
              disabled={loading || !csrfToken}
            >
              {loading ? 'Logging in...' : 'Login'}
            </button>

            {/* <Button 
              style={{ marginTop: '0.75rem', fontWeight: 'bold' }} 
              onClick={handlePasskeyLogin} 
              disabled={loading || !csrfToken}
            >
              <p style={{ marginLeft: '0.75rem', textAlign: 'center', fontSize: '16px' }}>Sign in with Passkeys</p>
            </Button> */}

            <Link to="/forgot-password" className={`login-form__btn-forgot login-form__btn-forgot--${themeClass}`}>
              Reset Password?
            </Link>

            <Link to="/signup" className={`login-form__btn-signup login-form__btn-signup--${themeClass}`}>
              Sign Up
            </Link>
          </div>
        </form>
      </div>

      <FooterComponent />
    </>
  );
}
