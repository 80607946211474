import React from 'react';
import { Link } from 'react-router-dom';
import './UserNotFound.scss'; // Optional for styling

const UserNotFound = () => {
  return (
    <div className="user-not-found">
      <h1>User not found...</h1>
      <p>The user you are looking for does not exist or may have been removed.</p>
      <Link to="/" className="user-not-found__link">
        <button className="user-not-found__button">Home</button>
      </Link>
    </div>
  );
};

export default UserNotFound;
