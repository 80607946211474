import React from 'react';
import './CampaignRequest.scss';
import Button from '../Button/Button';
import Card from '../Card/Card';
import Navigation from '../Navigation/Navigation';
import PromotionPostCard from '../PromotionPostCard/PromotionPostCard';
import CampaignPoster from '../../assets/defaults/campaignPoster.png';
import AcceptIcon from './AcceptIcon';
import RefundIcon from './RefundIcon';
// import { useNavigate } from 'react-router-dom';


export default function CampaignRequest({ username = "", campaign, handleAccept, handleDecline, actions, edit = false, listView, isProcessing, themeClass }) {
  const {
    campaignName,
    createdAt,
    category,
    location,
    price,
    description,
    tiktok,
    instagram,
    youtube,
    podcast,
  } = campaign;
  const id = campaign._id;
  // const navigate = useNavigate();

  const brandName = campaign.brand.username;
  const creator = campaign.creator.username;

  // Format the createdAt date string
  // Format the createdAt date string to 24-hour format without AM/PM
  const formattedCreatedAt = new Date(createdAt).toLocaleString(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hourCycle: 'h23', // 24-hour format
  });


  const handleCardClick = () => {
    // navigate(`/campaigns/${id}`);
    // window.location.reload();
  };

  return (
    <div className='campaign-request__link' onClick={handleCardClick}>
      <Card className={`card--campaign-request`}>
        <div className={!listView ? 'campaign-request--div' : ''}>
          <div className='campaign-request--listView'>
            {!listView && (
              <img className={`campaign-request__img campaign-request__img--listView`} src={campaign?.image || CampaignPoster} alt='campaign request' />
            )}

          </div>
          <div className={`campaign-request ${!listView ? ' campaign-request__listView' : ''}`}>
            <div className={`campaign-request__header ${!listView ? 'campaign-request__header--listView' : ''}`}>
              {edit ? (
                <Navigation heading={campaignName} edit={`/campaigns/outgoing/edit/${id}`} navRight={false} modifer={`w-100`} />
              ) : (
                <Navigation heading={campaignName} navRight={false}  modifer={`w-100`}/>
              )}
              {listView && (
                <div className='campaign-request__header--right'>
                  <span>Campaign ID: {id?.slice(-10) || 0}</span>
                  <p className='campaign-request__date'>{formattedCreatedAt}</p>
                </div>
              )}
            </div>
            <div className='campaign-request__body'>
              {listView && (
                <div className='campaign-request__img-container'>
                  <img className='campaign-request__img' src={campaign?.image || CampaignPoster} alt='campaign request' />
                </div>
              )}
              <div className='campaign-request__info'>
                <div className='campaign-request__info-header'>
                  {listView ?
                    (
                      <h2 className='campaign-request__sub-title'>{`${brandName} x ${creator}`}</h2>

                    ) : (
                      <h2 className='campaign-request__sub-title'>{username === creator ? `${brandName}` : `${creator}`}</h2>

                    )
                  }
                  <span className='campaign-request__fee'> ${price.toFixed(2)}</span>
                </div>
                {listView && (
                  <div className='campaign-request__details'>
                    <div className='campaign-request__details__users'>
                      <p className='campaign-request__info--bold'>
                        <span className='campaign-request__info-sh'>Brand:</span> @{brandName}
                      </p>
                      <p className='campaign-request__info--bold'>
                        <span className='campaign-request__info-sh'>Creator:</span> @{creator}
                      </p>
                    </div>
                    <div className='campaign-request__details__desc'>
                      <p className='campaign-request__info--bold'>
                        <span className='campaign-request__info-sh'>Category:</span>{category}
                      </p>
                      <p className='campaign-request__info--bold'>
                        <span className='campaign-request__info-sh'>Location:</span>{location}
                      </p>
                    </div>
                  </div>
                )}
                {listView && <span className='campaign-request__info-sh'>Description</span>}
                <p className={`campaign-request__info--bold campaign-request__info--bold--mb` + (!listView ? ' campaign-request__info--bold--listView' : '')}>
                  {description}
                </p>
                {(tiktok.enabled || instagram.enabled || youtube.enabled || podcast.enabled) && (
                  <div className='campaign-request__channels'>
                    {tiktok.enabled && <PromotionPostCard name='TikTok' posts={tiktok?.quantity || 0} enabled={tiktok?.enabled} modifier='post-card--width' />}
                    {instagram.enabled && <PromotionPostCard name='Instagram' posts={instagram?.quantity || 0} enabled={instagram?.enabled} modifier='post-card--width' />}
                    {youtube.enabled && <PromotionPostCard name='Youtube' posts={youtube?.quantity || 0} enabled={youtube?.enabled} tag='Spot' modifier='post-card--width' />}
                    {podcast.enabled && <PromotionPostCard name='Podcast' posts={podcast?.quantity || 0} enabled={podcast?.enabled} tag='Spot' modifier='post-card--width' />}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className=''>
          {actions ? (
            <div className='campaign-request__footer'>
              {campaign.status === 'Pending' && !isProcessing ? (
                <>
                  <Button onClick={() => handleDecline(campaign._id, brandName)}>
                    <div className='campaign-request__refund'>
                      <RefundIcon />
                      <h4>Refund</h4>
                    </div>
                  </Button>
                  <Button modifier='button--ml' onClick={() => handleAccept(campaign._id, brandName)}>
                    <div className='campaign-request__accept'>
                      <AcceptIcon />
                      <h4>Accept</h4>
                    </div>
                  </Button>
                </>
              ) : (
                <div className={`campaign-request__processing campaign-request__processing--${themeClass}`}>
                  {"Processing"}
                </div>
              )}
            </div>
          ) : campaign.status === 'Pending' ? (
            <div className='campaign-request__footer'>
              <p className='campaign-request__footer__info'>Waiting for Creator to Accept Campaign Request</p>
            </div>
          ) : (
            <div className='campaign-request__footer campaign-request__footer--gp'>
            {(campaign.status === 'Accepted' || campaign.status === 'Completed' || campaign.status === "Review Campaign") ? (
                <>
                  <svg className='campaign-request__icon' xmlns='http://www.w3.org/2000/svg' width={23} height={23} viewBox='0 0 24 24' fill='none' stroke='green' strokeWidth={1.5} strokeLinecap='round' strokeLinejoin='round'>
                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                    <circle cx={12} cy={12} r={9} />
                    <path d='M9 12l2 2l4 -4' />
                  </svg>
                </>
              ) : (
                <>
                  <svg className='campaign-request__icon' xmlns='http://www.w3.org/2000/svg' width='23' height='23' viewBox='0 0 24 24' fill='none' stroke='#E01C34' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'>
                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                    <path d='M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0' />
                    <path d='M10 10l4 4m0 -4l-4 4' />
                  </svg>
                </>
              )}
              <p className='campaign-request__info--status'>{campaign.status}</p>
    
            </div>
          )}
        </div>
      </Card>
    </div>
  );
}
