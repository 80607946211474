import React, { useState } from 'react';
import './Description.scss';

export default function Description({ description }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleSeeMore = () => {
    setIsExpanded(true);
  };

  const handleSeeLess = () => {
    setIsExpanded(false);
  };

  const isMobile = window.innerWidth <= 768; // Detect mobile based on screen width
  let shouldShowSeeMore = false;
  if (isMobile) {
     shouldShowSeeMore = description.length > 170;
  } else {
    shouldShowSeeMore = description.length > 400;
  }


  return (
    <div className='description'>
      <p
        className={`description__text ${isExpanded ? 'description__text--expanded' : ''}`}
        style={{ maxHeight: isExpanded ? 'none' : '70px' }}
      >
        {description}
      </p>
      {shouldShowSeeMore && !isExpanded && (
        <button className='description__see-more' onClick={handleSeeMore}>
          See More
        </button>
      )}
      {isExpanded && (
        <button className='description__see-less' onClick={handleSeeLess}>
          See Less
        </button>
      )}
    </div>
  );
}
