import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import Card from '../Card/Card'
import './UserSettings.scss'
import ThemeContext from '../../context/ThemeContext';
import { Context } from "../../context/index"

export default function UserSettings({ children }) {
      const { themeMode } = useContext(ThemeContext);
      const { state: { user }, } = useContext(Context);
      const themeClass = themeMode === "light" ? "light-theme" : "dark-theme";

      return (
            <Card className={`user-settings user-settings--${themeClass}`}>
                  <div className={`user-settings__nav user-settings__nav--${themeClass}`}>
                        <NavLink className={`user-settings__nav-item user-settings__nav-item--${themeClass}`} to={`/settings/general`}>General</NavLink>
                        <NavLink className={`user-settings__nav-item user-settings__nav-item--${themeClass}`} to={`/settings/general/advanced`}>Advanced</NavLink>
                        <NavLink className={`user-settings__nav-item user-settings__nav-item--${themeClass}`} to={`/settings/email`}>Email</NavLink>
                        <NavLink className={`user-settings__nav-item user-settings__nav-item--${themeClass}`} to={`/settings/password`}>Security</NavLink>
                        <NavLink className={`user-settings__nav-item user-settings__nav-item--${themeClass}`} to={`/settings/donations`}>Donations</NavLink>
                        <NavLink className={`user-settings__nav-item user-settings__nav-item--${themeClass}`} to={`/settings/teams`}>Teams</NavLink>
                        <NavLink className={`user-settings__nav-item user-settings__nav-item--${themeClass}`} to={`/settings/payments`}>Payments</NavLink>
                        <NavLink className={`user-settings__nav-item user-settings__nav-item--${themeClass}`} to={`/settings/socials`}>Socials</NavLink>
                        <NavLink className={`user-settings__nav-item user-settings__nav-item--${themeClass}`} to={`/settings/subscription`}>Subscription</NavLink>
                        <NavLink className={`user-settings__nav-item user-settings__nav-item--${themeClass}`} 
                        to={ user?.stripeSeller?.type === 'express' ? process.env.REACT_APP_STRIPE_BILLING : "https://dashboard.stripe.com/login" }>Billing</NavLink>
                  </div>
                  <div className="user-settings__content">
                        {children}
                  </div>
            </Card>
      )
}
