import React, { useState, useEffect, useContext } from 'react';
import ChatRoom from '../../Components/ChatRoom/ChatRoom';
import UserRoute from '../../Routes/Auth';
import { useParams } from 'react-router-dom';
import axios from "axios";
import { Context } from "../../context/index";
import "./DirectMessage.scss";
import Navigation from '../../Components/Navigation/Navigation';
import ViewPostModel from '../../Components/ViewPostModal/ViewPostModal'



export default function DirectMessage({ currentRoom, setCurrentRoom, isNewMessage, serverUrl }) {
  const { id } = useParams();
  const [messages, setMessages] = useState([]);
  const [members, setMembers] = useState([]);
  const [username, setUsername] = useState('');
  const [isViewPostModalOpen, setIsViewPostModalOpen] = useState(false);

  const { state: { user } } = useContext(Context);

  useEffect(() => {
    setUsername(user?.username);
    setCurrentRoom(id);
    const fetchMessages = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/user/messages/${id}`);
        const data = response.data;
        // console.log(data.messages)
        setMessages(data.messages);
        setMembers(data.members);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };
    fetchMessages();
  }, [id, setCurrentRoom, user]);

  const otherMembers = members.filter(member => member.username !== username);
  const heading = otherMembers.map(member => member.username).join(', ');
  const avatar = otherMembers.length > 0 ? otherMembers[0].picture : null;


  return (
    <UserRoute>
      <div className='direct-message-pg'>
        <Navigation 
          link={heading} 
          back={true} 
          avatar={avatar} 
        />
        <ChatRoom 
          messages={messages} 
          setMessages={setMessages} 
          serverUrl={serverUrl} 
          currentRoom={currentRoom} 
          setCurrentRoom={setCurrentRoom} 
          username={username} 
          isNewMessage={isNewMessage} 
          members={members} 
          setIsViewPostModalOpen={(attachment) => setIsViewPostModalOpen(attachment)} 
        />
        {isViewPostModalOpen && <ViewPostModel isOpen={isViewPostModalOpen} onClose={() => setIsViewPostModalOpen(false)}   />}
      </div>
    </UserRoute>
  );
}
