// MobileMenuModal.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import './MobileMenuModal.scss'; // Ensure you create corresponding SCSS/CSS
import PropTypes from 'prop-types';
import ChequeaiLogo from '../../assets/icons/chequai-logo.png';
import CloseIcon from './CloseIcon';


const MobileMenuModal = ({ onClose, isMobileMenuOpen, setIsMobileMenuOpen, user, themeClass }) => {
      if (!isMobileMenuOpen) return null;
      const isMoblieView = window.innerWidth <= 768;


      return (
            <div className={`mobile-menu-modal mobile-menu-modal--${themeClass}`}>
                  <div className="mobile-menu-modal__overlay" onClick={onClose}></div>
                  <div className={`mobile-menu-modal__content mobile-menu-modal__content--${themeClass}`}>
                        <div className="header__header-left">

                              <div className='header__header-left-container' onClick={onClose}>
                                    <img src={ChequeaiLogo} alt="Search for Influencers" className="header__header__logo-img" />
                                    <Link to="/" className="header__logo">
                                          <h1 className="header__header__title">Chequeai</h1>
                                    </Link>
                              </div>

                              <div className="header__hamburger header__mobile-menu__close" onClick={() => setIsMobileMenuOpen(false)}>
                                    {isMoblieView && isMobileMenuOpen && <CloseIcon themeClass={themeClass} />}
                              </div>
                        </div>
                        <nav className="mobile-menu-modal__nav">
                              <Link
                                    to="/blog"
                                    className={`mobile-menu-modal__nav-link mobile-menu-modal__nav-link--${themeClass}`}
                                    onClick={onClose}
                              >
                                    Blog
                              </Link>
                              <a
                                    href="/#features"
                                    className={`mobile-menu-modal__nav-link mobile-menu-modal__nav-link--${themeClass}`}
                                    onClick={onClose}
                              >
                                    Features
                              </a>
                              <a
                                    href="/#subscriptions"
                                    className={`mobile-menu-modal__nav-link mobile-menu-modal__nav-link--${themeClass}`}
                                    onClick={onClose}
                              >
                                    Subscriptions
                              </a>
                              <Link
                                    to="/signup"
                                    className={`mobile-menu-modal__nav-link mobile-menu-modal__nav-link--${themeClass}`}
                                    onClick={onClose}
                              >
                                    Sign Up
                              </Link>
                              <Link to="/login" onClick={onClose}>
                                    <button
                                          className={`mobile-menu-modal__btn mobile-menu-modal__btn--${user ? 'home' : 'login'} mobile-menu-modal__btn--${themeClass}`}
                                    >
                                          {user ? 'Home' : 'Login'}
                                    </button>
                              </Link>
                        </nav>
                  </div>
            </div>
      );
};

MobileMenuModal.propTypes = {
      isMobileMenuOpen: PropTypes.bool.isRequired,
      onClose: PropTypes.func.isRequired,
      user: PropTypes.object,
      themeClass: PropTypes.string.isRequired,
};

export default MobileMenuModal;
