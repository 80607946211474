// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.redirect-page {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  font-size: 1.5rem;
  font-weight: bold;
  color: #555;
  text-align: center;
}
.redirect-page__message {
  animation: blink 1s infinite;
}
@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/RedirectPage/RedirectPage.scss"],"names":[],"mappings":"AAGA;EACM,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;EACA,iBAAA;EACA,iBAAA;EACA,WAAA;EACA,kBAAA;AAFN;AAIM;EACE,4BAAA;AAFR;AAMM;EACE;IACE,UAAA;EAJR;EAMM;IACE,YAAA;EAJR;AACF","sourcesContent":["// src/components/RedirectPage.scss\n\n\n.redirect-page {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      min-height: 100vh;\n      font-size: 1.5rem;\n      font-weight: bold;\n      color: #555;\n      text-align: center;\n    \n      &__message {\n        animation: blink 1s infinite;\n      }\n    \n      // Blink animation for the \"Redirecting...\" message\n      @keyframes blink {\n        0%, 100% {\n          opacity: 1;\n        }\n        50% {\n          opacity: 0.5;\n        }\n      }\n    }\n    "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
