import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Card from "../../Components/Card/Card";
import Button from "../../Components/Button/Button";
import { Context } from "../../context/index";
import UserRoute from "../../Routes/Auth";
import Toggle from "../../Components/Toggle/Toggle";
import "./DonationsSettingsPage.scss";
import Navigation from "../../Components/Navigation/Navigation";
import ThemeContext from "../../context/ThemeContext";
import Select from "react-select"; // Import react-select
import { Link } from "react-router-dom";
import { currencyOptions, currencySymbols } from "../../utils/constants";
import UserSettings from "../../Components/UserSettings/UserSettings";

const lightMode = {
  control: (base) => ({
    ...base,
    backgroundColor: "#E1E1E1",
    borderRadius: "8px",
    border: "none",
    boxShadow: "none",
    "&:hover": {
      border: "none",
      boxShadow: "none",
    },
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: "#E1E1E1",
    borderRadius: "4px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
    "&:hover": {
      backgroundColor: "neutral70",
    },
  }),
  option: (base, state) => ({
    ...base,
    color: "black",
    backgroundColor: state.isFocused ? "neutral70" : "#E1E1E1",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
  }),
  singleValue: (base) => ({
    ...base,
    color: "black",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "black", // Set the dropdown arrow color to darkgrey
    svg: {
      fill: "black", // Make sure the SVG icon also changes to darkgrey
    },
    "&:hover": {
      color: "black", // Ensure the hover state is also darkgrey
      svg: {
        fill: "black", // Ensure the SVG remains darkgrey on hover
      },
    },
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none", // Remove the line between the input and the dropdown icon (optional)
  }),
};

const styles1 = {
  control: (base) => ({
    ...base,
    backgroundColor: "#141414",
    borderRadius: "8px",
    border: "none",
    boxShadow: "none",
    "&:hover": {
      border: "none",
      boxShadow: "none",
    },
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: "#141414",
    borderRadius: "4px",
  }),
  option: (base, state) => ({
    ...base,
    color: "white",
    backgroundColor: state.isFocused ? "#555555" : "#141414",
    ...(state.isSelected && {
      backgroundColor: "#141414",
    }),
    cursor: "pointer",
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: "dangerLight",
    borderRadius: "4px",
    display: "inline-flex",
    alignItems: "center",
    padding: "2px 6px",
    marginRight: "4px",
    marginTop: "4px",
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: "white",
    fontSize: "14px",
    fontWeight: "600",
  }),
  multiValueRemove: (base) => ({
    ...base,
    color: "white",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "none",
      color: "dangerLight",
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "black",
    svg: {
      fill: "black",
    },
    "&:hover": {
      color: "black",
      svg: {
        fill: "black",
      },
    },
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
};

const DonationsSettingsPage = () => {
  const [donationEnabled, setDonationEnabled] = useState(false);
  const [customAmountEnabled, setCustomAmountEnabled] = useState(false);
  const [suggestedAmounts, setSuggestedAmounts] = useState([5, 10, 20, 50]);
  const [currency, setCurrency] = useState("USD"); // Default currency
  const { user } = useContext(Context).state;
  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === "light" ? "light-theme" : "dark-theme";

  useEffect(() => {
    const fetchDonationSettings = async () => {
      try {
        const { data } = await axios.get("/settings/donations");
        // console.log("Donation settings:", data);
        setDonationEnabled(data.donationEnabled);
        setCustomAmountEnabled(data.customAmountEnabled || false);
        setSuggestedAmounts(data.suggestedAmounts || [5, 10, 20, 50]);
        setCurrency(data.defaultCurrency || "USD");
      } catch (error) {
        console.error("Error fetching donation settings:", error);
      }
    };
    fetchDonationSettings();
  }, [user]);

  const handleToggleDonations = async () => {
    try {
      await axios.post("/settings/donations/toggle", {
        donationEnabled: !donationEnabled,
      });
      setDonationEnabled((prevState) => !prevState);
    } catch (error) {
      console.error("Error toggling donations:", error);
    }
  };

  const handleToggleCustomAmount = async () => {
    try {
      await axios.post("/settings/donations/toggle-custom-amount", {
        customAmountEnabled: !customAmountEnabled,
      });
      setCustomAmountEnabled((prevState) => !prevState);
    } catch (error) {
      console.error("Error toggling custom donation amount:", error);
    }
  };

  const handleUpdateSettings = async () => {
    try {
      await axios.post("/settings/donations/update-amounts", {
        suggestedAmounts,
        currency,
      });
    } catch (error) {
      console.error("Error updating settings:", error);
    }
  };

  const handleAmountChange = (index, value) => {
    const newAmounts = [...suggestedAmounts];
    newAmounts[index] = Number(value);
    setSuggestedAmounts(newAmounts);
  };

  const handleCurrencyChange = (selectedOption) => {
    setCurrency(selectedOption.value);
  };

  if (user?.stripeAccountId === "" || Object.keys(user?.stripeSeller).length === 0 || user?.stripeSeller?.charges_enabled === false) {
    return (
      <UserRoute>
        <div className={`donations-settings donations-settings--${themeClass}`}>
          <Navigation heading={"Donations"} back={true} />
          <Card className={`card card--donations card--donations--${themeClass}`}>
            <h2 className={`donations-settings__title ${themeClass}`}>
              <span className="donations-settings__branding">Connect Stripe</span>
            </h2>
            <p className="donations-settings__message">
              {user?.stripeSeller && Object.keys(user?.stripeSeller).length === 0 && user?.stripeAccountId !== ""
                ? "Your Stripe account is connected to this account. Please complete the Stripe connection." : "Stripe is not connected to this account. Please connect your Stripe account to enable donations."
              }
            </p>
            <div className="donations-settings__connect-button__div">
              <Link to="/settings/payments/"
                className={`donations-settings__connect-button donations-settings__connect-button--${themeClass}`}
              >
                Connect Stripe
              </Link>
            </div>
          </Card>
        </div>
      </UserRoute>
    );
  }

  return (
    <UserRoute>
      <div className={`donations-settings donations-settings--${themeClass}`}>
        <Navigation heading={"Settings Donations"} back={true} />
        <UserSettings>
          {/* <h2 className={`donations-settings__title ${themeClass}`}>
            <span className="donations-settings__branding">Settings</span>
          </h2> */}
          <div>
          <div className={`donations-settings__toggle donations-settings__toggle--${themeClass}`}>
            <Toggle
              label="Enable Donations"
              onChange={handleToggleDonations}
              value={donationEnabled}
              modifier={`donations`}
            />
          </div>
       
          {donationEnabled && (

            <>
            <div className={`donations-settings__toggle donations-settings__toggle--${themeClass}`}>
            <Toggle
              label="Custom Donations"
              onChange={handleToggleCustomAmount}
              value={customAmountEnabled}
              modifier={`donations`}
            />
          </div>
              <div className={`donations-settings__currency ${themeClass}`}>
                <h3
                  className={`donations-settings__subtitle donations-settings__subtitle--${themeClass}`}
                >
                  Currency
                </h3>
                <Select
                  value={currencyOptions.find((option) => option.value === currency)}
                  onChange={handleCurrencyChange}
                  options={currencyOptions}
                  styles={themeMode === "light" ? lightMode : styles1}
                  isSearchable={false}
                />
              </div>
              <div className={`donations-settings__amounts ${themeClass}`}>
                <h3
                  className={`donations-settings__subtitle donations-settings__subtitle--${themeClass}`}
                >
                  Presets ({currencySymbols[currency] || currency})
                </h3>
                <div
                  className={`donations-settings__amount-wrapper ${themeClass}`}
                >
                  {suggestedAmounts.map((amount, index) => (
                    <div
                      key={index}
                      className="donations-settings__amount-input-wrapper"
                    >
                      <span
                        className={`donations-settings__currency-symbol donations-settings__currency-symbol--${themeClass}`}
                      >
                        {currencySymbols[currency] || currency}
                      </span>
                      <input
                        type="number"
                        min="0"
                        className={`donations-settings__amount-input donations-settings__amount-input--${themeClass}`}
                        value={amount}
                        onChange={(e) =>
                          handleAmountChange(index, e.target.value)
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>

            </>
          )}
          </div>
         
          <Button
            onClick={handleUpdateSettings}
            modifier={`donations-settings__amount-button`}
          >
            Update
          </Button>
        </UserSettings>
      </div>
    </UserRoute>
  );
};

export default DonationsSettingsPage;
