import { Context } from "../../context/index"
import React, { useContext, useEffect, useState } from 'react';
import UserRoute from '../../Routes/Auth';
import InputField from '../../Components/InputField/InputField'
import "./ChangeEmailPage.scss"
import Button from '../../Components/Button/Button';
import Navigation from "../../Components/Navigation/Navigation";
import axios from "axios";
import UserSettings from "../../Components/UserSettings/UserSettings";

export default function ChangeEmailPage() {
  const [formData, setFormData] = useState({
    priorEmail: "",
    newEmail: "",
    confirmEmail: "",
    password: "",
  });
  const [username, setUsername] = useState("");
  const { state: {user}, } = useContext(Context);

  useEffect(() => {
    if(user !== null) {
      setUsername(user.username)
    }

  }, [user]);

  const { priorEmail, newEmail, confirmEmail, password } = formData;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newEmail !== confirmEmail) {
      alert("Emails do not match.");
      return;
    }
    // Handle form submission here
    try {
      const {data } = await axios.put(`${process.env.REACT_APP_API}/user/edit-profile/update-email`, { oldEmail: priorEmail, email: newEmail, password });
      console.log(data);
      console.log("Email updated successfully.");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <UserRoute username={username}>
      <div className="ch-email-pg">
        <Navigation heading={"Change Email"} back={true} />
        <UserSettings>
        <form className="ch-email-pg__form"
>
          <InputField
            label="Old Email"
            name="priorEmail"
            type="email"
            value={priorEmail}
            onChange={handleInputChange}
          />
          <InputField
            label="New Email"
            name="newEmail"
            type="email"
            value={newEmail}
            onChange={handleInputChange}
          />
          <InputField
            label="Confirm New Email"
            name="confirmEmail"
            type="email"
            value={confirmEmail}
            onChange={handleInputChange}
          />
          <InputField
            label="Confirm Password"
            name="password"
            type="password"
            value={password}
            onChange={handleInputChange}
          />
      
        </form>
        <Button onClick={handleSubmit}>
            Change
          </Button>
        </UserSettings>
    </div>
    </UserRoute>

  );
}
