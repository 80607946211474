import React, { useState, useContext } from 'react';
import UserRoute from '../../Routes/Auth';
import Navigation from '../../Components/Navigation/Navigation';
import './AddLinkPage.scss';
import Card from '../../Components/Card/Card';
import InputField from '../../Components/InputField/InputField';
import Button from '../../Components/Button/Button';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Context } from "../../context/index";

export default function AddLinkPage() {
    const navigate = useNavigate();
    const [link, setLink] = useState({
        title: "",
        url: "",
    });
    const {
        state: { user },
    } = useContext(Context);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "linkTitle") {
            setLink((prevLink) => ({
                ...prevLink,
                title: value,
            }));
        } else if (name === "linkURL") {
            setLink((prevLink) => ({
                ...prevLink,
                url: value,
            }));
        }
    };

    const addLink = async () => {
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_API}/user/edit-profile/link`, link);
            console.log(data);
            navigate(`/${user.username}/share`);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <UserRoute>
            <div className="add-link-pg">
                <Navigation title={'Add Link'} back={true} />
                <Card className="card--input">
                    <InputField
                        label="Link Title"
                        name="linkTitle"
                        type="text"
                        value={link.title}
                        onChange={handleInputChange}
                    />
                    <InputField
                        label="Link URL"
                        name="linkURL"
                        type="url"
                        value={link.url}
                        onChange={handleInputChange}
                    />
                    <Button modifier="button" onClick={addLink}>Add Link</Button>
                </Card>
                
            </div>
        </UserRoute>
    );
}
