// src/pages/TikTokErrorPage.js
import React, { useEffect } from 'react';
import UserRoute from '../../Routes/Auth';
import { apiGet } from '../../utils/apiUtils';
import './TikTokErrorPage.scss'

const TikTokErrorPage = () => {
  
  useEffect(() => {
    const logErrorToBackend = async () => {
      try {
        const response = await apiGet('/tiktok/error-log', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ message: 'TikTok linking error occurred' }),
        });

        if (!response.ok) {
          console.error("Failed to log error to backend");
        }
      } catch (error) {
        console.error("Error logging to backend:", error);
      }
    };

    logErrorToBackend();
  }, []);

  return (
    <UserRoute>
      <div className="tiktok-error">
        <h1 className="tiktok-error__title">Error 404</h1>
        <p className="tiktok-error__message">
          There was an error linking your TikTok account. Please try again.
        </p>
      </div>
    </UserRoute>
  );
};

export default TikTokErrorPage;
