import React, { useState, useEffect, useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import "./ConnectStripePage.scss";
import Card from "../../Components/Card/Card";
import { Context } from "../../context/index";
import axios from "axios";
// import Cookies from "js-cookie";
import UserRoute from "../../Routes/Auth";
import Button from "../../Components/Button/Button";
import ThemeContext from "../../context/ThemeContext";
import PayoutCard from "../../Components/PayoutCard/PayoutCard";
import PayoutComponent from "../../Components/PayoutComponent/PayoutComponent";
import Tabs from "../../Components/Tabs/Tabs";
import PaymentCardComponent from "../../Components/PaymentCardComponent/PaymentCardComponent";
import { apiGet } from "../../utils/apiUtils";

const ConnectStripePage = ({ onStripeConnected }) => {
  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === "light" ? "light-theme" : "";

  const [creatorBalanceAvailable, setCreatorBalanceAvailable] = useState(false);
  const [creatorBalancePending, setCreatorBalancePending] = useState(false);
  const [payoutSchedule, setPayoutSchedule] = useState("");
  const [payouts, setPayouts] = useState([]);
  const [payments, setPayments] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [stripeAccount, setStripeAccount] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const {
    state: { user },
  } = useContext(Context);

  // Handle URL hash changes
  const handleHashChange = useCallback(() => {
    const hash = window.location.hash;
    if (hash === "#payments") {
      setActiveTab(0);
    } else if (hash === "#payouts") {
      setActiveTab(1);
    }
  }, []);

  // Update the URL hash when the tab is changed
  const handleTabChange = useCallback((tabIndex) => {
    setActiveTab(tabIndex);
    if (tabIndex === 0) {
      window.location.hash = "payments";
    } else if (tabIndex === 1) {
      window.location.hash = "payouts";
    }
  }, []);

  // Set the active tab based on the URL hash when the component mounts
  useEffect(() => {
    handleHashChange();
    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [handleHashChange]);

  const fetchStripeAccountSettings = useCallback(async () => {
    try {
      const response = await apiGet("/settings/payments");
      setStripeAccount(response.stripeAccount);
    } catch (error) {
      console.log(error);
    }
  }, []);



  useEffect(() => {
    const fetchStripeAccount = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/creator/revenue/`
        );
        // console.log(response.data.paymentHistory);
        setCreatorBalanceAvailable(response.data.balance?.available || false);
        setCreatorBalancePending(response.data.balance?.pending || false);
        setPayoutSchedule(response.data.payoutSchedule || "");
        setPayouts(response.data.payouts || []);
        setPayments(response.data.paymentHistory || []);
      } catch (err) {
        console.log("FETCH STRIPE ACCOUNT ERR", err);
      }
    };
    fetchStripeAccountSettings();
    fetchStripeAccount();
  }, [user, fetchStripeAccountSettings]);

  /*   useEffect(() => {
      const fetchPayoutHistory = async () => {
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_API}/creator/payout-history/`
          );
          setPayouts(data?.payouts || []);
        } catch (error) {
          console.log("FETCH PAYOUT HISTORY ERR", error);
        }
      };
  
      if (user?.stripeAccountId !== "" || user?.stripeAccountId !== null) {
        fetchPayoutHistory();
      }
  
  
    }, [user?.stripeAccountId]); */

  const handleConnectStripe = async () => {
    try {
      const response = await axios.post(`/stripe/authorize`, { user_id: user._id, });
      const url = response.data;
      window.location.href = url;
    } catch (err) {
      console.log("HANDLE CONNECT STRIPE ERR", err);
    }
  };

  const stripeExpressDashboard = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/creator/payout-history/`
      );
      window.open(data || "", "_blank");
    } catch (error) {
      console.log("FETCH PAYOUT HISTORY ERR", error);
    }
  };

  const paginate = (items) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return items.slice(startIndex, startIndex + itemsPerPage);
  };

  const nextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const prevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  return (
    <UserRoute>
      <div className="stripe-pg">
        <Card className="card--padding card--stripe">
          {user?.stripeAccountId ? (
            <h2 className="stripe-pg__title">
              Wallet{" "}
              <span className="stripe-branding">connected with Stripe</span>
            </h2>
          ) : (
            <h2>
              Payments <span className="stripe-branding">with Stripe</span>
            </h2>
          )}
          {user?.stripeSeller && user?.stripeAccountId && user?.stripeSeller.charges_enabled === true && Object.keys(user.stripeSeller).length !== 0  && user.stripeSeller.id === stripeAccount.id ? (
            <div className="stripe-pg__info">
              <div className="stripe-pg__row">
                <button
                  className={`stripe-pg__campaigns-link stripe-pg__campaigns-link--${themeClass}`}
                  onClick={stripeExpressDashboard}
                  target="_blank"
                >
                  <p className="stripe-pg__campaigns-text">Stripe Dashboard</p>
                </button>
                <Link
                  to={`/orders`}
                  className={`stripe-pg__campaigns-link stripe-pg__campaigns-link--${themeClass}`}
                >
                  <p className="stripe-pg__campaigns-text">My Campaigns</p>
                </Link>
              </div>
              <PayoutCard
                pending={creatorBalancePending}
                available={creatorBalanceAvailable}
                payoutSchedule={payoutSchedule}
                stripeExpressDashboard={stripeExpressDashboard}
              />

              <Tabs
                activeTab={activeTab}
                setActiveTab={handleTabChange} // Use the updated tab change handler
                label1={"Payments"}
                label2={"Payouts"}
              />
              {activeTab === 0 && payments.length > 0 && (
                <div className="payouts-list">
                  {Array.isArray(payments) && payments.length > 0 ? (
                    paginate(payments).map((payment, index) => (
                      <PaymentCardComponent
                        key={index}
                        payment={payment}
                        themeClass={themeClass}
                      />
                    ))
                  ) : (
                    <Card
                      className={`card--link campaign-complete campaign-complete--${themeClass}`}
                    >
                      <p>
                        <span>Waiting for Payments</span>
                      </p>
                    </Card>
                  )}
                  <div className="pagination-controls">
                    <Button onClick={prevPage} disabled={currentPage === 1}>
                      <span>Back</span>
                    </Button>
                    <Button
                      onClick={nextPage}
                      disabled={
                        (currentPage - 1) * itemsPerPage >= payments.length
                      }
                    >
                      <span>Next</span>
                    </Button>
                  </div>
                </div>
              )}
              {activeTab === 1 && payouts.length > 0 && (
                <div className="payouts-list">
                  {Array.isArray(payouts) && payouts.length > 0 ? (
                    paginate(payouts).map((payout, index) => (
                      <PayoutComponent
                        key={index}
                        payout={payout}
                        themeClass={themeClass}
                      />
                    ))
                  ) : (
                    <Card
                      className={`card--link campaign-complete campaign-complete--${themeClass}`}
                    >
                      <p>
                        <span>Waiting for Payouts</span>
                      </p>
                    </Card>
                  )}
                  <div className="pagination-controls">
                    <Button onClick={prevPage} disabled={currentPage === 1}>
                      <span>Back</span>
                    </Button>
                    <Button
                      onClick={nextPage}
                      disabled={
                        (currentPage - 1) * itemsPerPage >= payouts.length
                      }
                    >
                      <span>Next</span>
                    </Button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="stripe-pg__info">
              <p className="stripe-pg__info-text">
                To start selling your promotional services and montieize your audience, you need to setup a Stripe
                Connect account. After setup, you'll be able to receive payments
                directly from brands collrabated with, and your audience.
              </p>
              <Button onClick={handleConnectStripe}>
                Connect
              </Button>
            </div>
          )}
        </Card>
      </div>
    </UserRoute>
  );
};

export default ConnectStripePage;
