import React, { useEffect, useState, useContext } from 'react';
import UserRoute from '../../Routes/Auth';
import Navigation from '../../Components/Navigation/Navigation';
import './EditCampaignPage.scss';
import Card from '../../Components/Card/Card';
import InputField from '../../Components/InputField/InputField';
import Button from '../../Components/Button/Button';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import TextArea from '../../Components/TextArea/TextArea';
import ThemeContext from '../../context/ThemeContext';
import DarkLoadingSpinner from '../../Components/DarkLoadingSpinner/DarkLoadingSpinner';


export default function EditCampaignPage() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';


    const [campaign, setCampaign] = useState({
        campaignName: '',
        productName: '',
        sku: '',
        productType: '',
        productStatus: '',
        trackingURL: '',
        productURL: '',
        affiliateLink: '',
        affiliateURL: '',
        price: '',
        description: '',
        instagramPosts: '',
        tikTokPosts: '',
        youtubePosts: '',
        podcastPosts: '',
        endDate: '',
        location: '',
        campaignImage: '',
        promoCode: '',
    });


    const [loading, setLoading] = useState(true);

    const updateCampaign = async () => {
        try {
            // console.log(campaign)
            await axios.put(`${process.env.REACT_APP_API}/campaigns/${id}`, campaign);
            //  console.log(data);
            navigate(`/campaigns/${id}`);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const fetchCampaign = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_API}/campaigns/${id}`);
                // console.log(data);

                // Ensure every field has a default value to prevent uncontrolled-to-controlled transition
                const formattedData = {
                    campaignName: data.campaignName || '',
                    productName: data.productName || '',
                    sku: data.sku || '',
                    productType: data.productType || '',
                    productStatus: data.productStatus || '',
                    trackingURL: data.trackingURL || '',
                    productURL: data.productURL || '',
                    affiliateLink: data.affiliateLink || '',
                    affiliateURL: data.affiliateURL || '',
                    price: data.price || '',
                    description: data.description || '',
                    instagramPosts: data.instagramPosts || '',
                    tikTokPosts: data.tikTokPosts || '',
                    youtubePosts: data.youtubePosts || '',
                    podcastPosts: data.podcastPosts || '',
                    endDate: data.endDate ? new Date(data.endDate).toISOString().split('T')[0] : '',
                    location: data.location || '',
                    campaignImage: data.campaignImage || '',
                    promoCode: data.promoCode || '',
                };

                setCampaign(formattedData);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };

        fetchCampaign();
    }, [id]);



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCampaign((prevCampaign) => ({
            ...prevCampaign,
            [name]: value,
        }));
    };

    const heading = (campaign?.status !== 'Completed' && campaign?.status !== 'Post Campaign') ? 'Edit Campaign Info' : `Campaign is ${campaign?.status}`

    return (
        <UserRoute>
            <div className="edit-campaign-pg">
                <Navigation heading={heading} back={true} />
                {(campaign?.status !== 'Completed' && campaign?.status !== 'Post Campaign') ? (
                    <Card className="card--input">
                        {loading ? (
                            <DarkLoadingSpinner />
                        ) : (
                            <>
                                <InputField
                                    label="Campaign Name"
                                    name="campaignName"
                                    type="text"
                                    value={campaign.campaignName}
                                    onChange={handleInputChange}
                                />
                                <InputField label="Campaign End Date" name="endDate" type="date" value={campaign?.endDate} onChange={handleInputChange} />

                                <InputField label="Location" name="location" type="text" value={campaign.location} onChange={handleInputChange} placeholder={"123 Main St, New York, NY, 10001, United States"} />
                                <TextArea
                                    label="Campaign Description"
                                    name="description"
                                    type="text"
                                    value={campaign.description}
                                    onChange={handleInputChange}
                                />
                                <InputField label="SKU" name="sku" type="text" value={campaign.sku} onChange={handleInputChange} />
                                <InputField
                                    label="Product Type"
                                    name="productType"
                                    value={campaign.productType}
                                    onChange={handleInputChange}
                                    isSelect={true} // Enable the Select component
                                    options={[
                                        { value: 'Digital Product', label: 'Digital Product' },
                                        { value: 'Physical Product', label: 'Physical Product' },
                                        { value: 'Service', label: 'Service' },
                                    ]}
                                    placeholder="Select Product Type"
                                />

                                <InputField
                                    label="Product Name"
                                    name="productName"
                                    type="text"
                                    value={campaign.productName}
                                    onChange={handleInputChange}
                                />
                                <InputField
                                    label="Promo Code"
                                    name="promoCode"
                                    type="text"
                                    value={campaign.promoCode}
                                    onChange={handleInputChange}
                                />
                                {/* <
                            <InputField
                                label="Product URL"
                                name="productURL"
                                type="text"
                                value={campaign.productURL}
                                onChange={handleInputChange}
                            />
                            {/* <InputField
                                label="Product Status"
                                name="productStatus"
                                type="text"
                                value={campaign.productStatus}
                                onChange={handleInputChange}
                            /> */}


                                <InputField
                                    label="Affiliate Link"
                                    name="affiliateLink"
                                    type="text"
                                    value={campaign.affiliateLink}
                                    onChange={handleInputChange}
                                />
                                {/* <InputField
                                    label="Affiliate URL"
                                    name="affiliateURL"
                                    type="text"
                                    value={campaign.affiliateURL}
                                    onChange={handleInputChange}
                                /> */}
                                <InputField
                                    label="Shipping Tracking"
                                    name="trackingURL"
                                    type="text"
                                    value={campaign.trackingURL}
                                    onChange={handleInputChange}
                                />
                                <Button modifier="button" onClick={updateCampaign}>
                                    Update
                                </Button>
                            </>
                        )}
                    </Card>) : <Card className={`card--link campaign-complete  campaign-complete--${themeClass}`}>
                    <p><span>Campaign</span> has been</p>
                    <p>{campaign?.status || "Campaign Status"}</p>

                </Card>}
            </div>
        </UserRoute>
    );
}
