import React, { useContext, useState, useEffect, useCallback } from "react";
import UserRoute from "../../Routes/Auth";
import "./SubscriptionsPage.scss";
import Card from "../../Components/Card/Card";
import SubscriptionPlanCard from "../../Components/SubscriptionPlanCard/SubscriptionPlanCard";
import Navigation from "../../Components/Navigation/Navigation";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { Context } from "../../context/index";
import Tabs from "../../Components/Tabs/Tabs";
import ThemeContext from '../../context/ThemeContext';
import { formatMoney } from "../../utils/moneyUtils";

export default function SubscriptionsPage() {
  const {
    state: { user },
  } = useContext(Context);
  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

  const [activeTab, setActiveTab] = useState(0);
  const [currency, setCurrency] = useState("USD"); // State for currency
  const [plans, setPlans] = useState([]); // State to hold fetched plans
  const [billingPeriod, setBillingPeriod] = useState("monthly"); // State for billing period (monthly/annually)

  // Available currency options
  const currencies = [
    { value: "USD", label: "USD" },
    { value: "EUR", label: "EUR" },
    { value: "GBP", label: "GBP" },
    { value: "CAD", label: "CAD" },
  ];

  // Fetch subscription plans from the API
  const fetchPlans = async () => {
    try {
      const response = await axios.get("/subscriptions/");
      setPlans(response.data.data.reverse());
    } catch (err) {
      console.error("Error fetching plans. Please try again.");
    }
  };

  // Fetch plans when the component mounts
  useEffect(() => {
    fetchPlans();
  }, []);

  // Memoize the handleHashChange function with useCallback
  const handleHashChange = useCallback(() => {
    const hash = window.location.hash;
    if (hash === "#basic") {
      setActiveTab(0);
    } else if (hash === "#pro") {
      setActiveTab(1);
    } else if (user?.subscription) {
      if (user.subscription === "basic") {
        setActiveTab(0);
        window.location.hash = "basic";
      } else if (user.subscription === "standard" || user.subscription === "premium") {
        setActiveTab(1);
        window.location.hash = "pro";
      }
    }
  }, [user?.subscription]);

  const handleTabChange = useCallback((tabIndex) => {
    setActiveTab(tabIndex);
    if (tabIndex === 0) {
      window.location.hash = "basic";
    } else if (tabIndex === 1) {
      window.location.hash = "pro";
    }
  }, []);

  useEffect(() => {
    handleHashChange();
    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [handleHashChange]);



  const handleCheckout = async ({ id }) => {
    try {
      const plan = plans.find(plan => plan.priceId === id);

      if (id === "free" || (user?.subscriptionDetails && user?.subscriptionDetails.planId === id)) {
        window.open(process.env.REACT_APP_STRIPE_BILLING, "_blank");
      }
      else if (plan && user?.subscriptionDetails?.planId !== id) {
        const { data } = await axios.get(`${process.env.REACT_APP_API}/user/edit-profile/subscribe/${id}`);
        const stripe = await loadStripe(process.env.REACT_APP_PUBLIC_STRIPE_KEY);
        stripe.redirectToCheckout({ sessionId: data.id });
      } 
    } catch (error) {
      console.log(error);
    }
  };

  // Filter the plans based on the selected tab and billing period
  const freePlan = plans.find((plan) => plan.name === "Free");
  const basicPlans = plans.filter((plan) => plan.name === "Basic" && plan.duration === billingPeriod);
  const proPlans = plans.filter((plan) =>
    (plan.name === "Standard" || plan.name === "Premium") && plan.duration === billingPeriod
  );

  const header = () => {
    return (
      <div className="subscriptions-pg__header">
        <div className="currency-selector">
          <select
            id="currency"
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
            className={`currency-selector__selector currency-selector__selector--${themeClass}`}
          >
            {currencies.map((cur) => (
              <option key={cur.value} value={cur.value}>
                {cur.label}
              </option>
            ))}
          </select>
        </div>
        <div className="subscriptions-pg__duration-container">
          <div
            className={billingPeriod === "monthly" ? `subscriptions-pg__duration-selector subscriptions-pg__duration-selector--active subscriptions-pg__duration-selector--${themeClass}`
              : `subscriptions-pg__duration-selector subscriptions-pg__duration-selector--${themeClass}`}
            onClick={() => setBillingPeriod("monthly")}
          >
            Monthly
          </div>
          <div
            className={billingPeriod === "annually" ? `subscriptions-pg__duration-selector subscriptions-pg__duration-selector--active subscriptions-pg__duration-selector--${themeClass}` :
              `subscriptions-pg__duration-selector subscriptions-pg__duration-selector--${themeClass}`}
            onClick={() => setBillingPeriod("annually")}
          >
            Annual
          </div>
        </div>

      </div>

    );
  };

  return (
    <UserRoute>
      <div className="subscriptions-pg">
        <Navigation heading={"Subscription"} backLink="/settings/"  />

        <Tabs
          activeTab={activeTab}
          setActiveTab={handleTabChange}
          label1={"Basic"}
          label2={"Pro"}
        />

        {activeTab === 0 && (
          <Card className="card--subscriptions">
            {header()}
            <div className="subscriptions-pg__plan-cards">
              {freePlan && (
                <SubscriptionPlanCard
                  priceId={freePlan.priceId}
                  name={freePlan.name}
                  price={formatMoney(freePlan?.prices?.[currency] || 0, currency)}
                  duration={freePlan.duration}
                  buttonLabel={user?.subscription === "free" ? "Subscribed" : "Subscribe"}
                  handleCheckout={handleCheckout}
                  features={freePlan.features}
                  enabled={user?.subscription === "free"}
                />
              )}
              {basicPlans.map((plan) => (
                <SubscriptionPlanCard
                  key={plan.priceId}
                  priceId={plan.priceId}
                  name={plan.name}
                  price={billingPeriod === "monthly" ? formatMoney(plan?.prices?.monthly?.[currency] || 0, currency) : formatMoney(plan?.prices?.annually?.[currency] || 0, currency)}
                  duration={plan.duration}
                  buttonLabel={user?.subscription === plan.name.toLowerCase() ? "Subscribed" : "Subscribe"}
                  handleCheckout={handleCheckout}
                  features={plan.features}
                  enabled={user?.subscription === plan.name.toLowerCase() && user?.subscriptionDetails?.planId === plan.priceId}
                />
              ))}
            </div>
          </Card>
        )}

        {activeTab === 1 && (
          <Card className="card--subscriptions">
            {header()}
            <div className="subscriptions-pg__plan-cards">
              {proPlans.map((plan) => (
                <SubscriptionPlanCard
                  key={plan.priceId}
                  priceId={plan.priceId}
                  name={plan.name}
                  price={billingPeriod === "monthly" ? formatMoney(plan?.prices?.monthly?.[currency] || 0, currency) : formatMoney(plan?.prices?.annually?.[currency] || 0, currency)}
                  duration={plan.duration}
                  buttonLabel={user?.subscription === plan.name.toLowerCase() ? "Subscribed" : "Subscribe"}
                  handleCheckout={handleCheckout}
                  features={plan.features}
                  enabled={user?.subscription === plan.name.toLowerCase()}
                />
              ))}
            </div>
          </Card>
        )}
      </div>
    </UserRoute>
  );
}
