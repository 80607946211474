// src/components/RedirectPage.js
import React, { useEffect, useCallback, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiGet } from "../../utils/apiUtils";
import Cookies from 'js-cookie'; // Ensure this library is installed
import './RedirectPage.scss';
import { Context } from '../../context';
import UserRoute from '../../Routes/Auth';
import axios from 'axios';
import { isIOS, isAndroid } from 'react-device-detect';

const RedirectPage = () => {
  const navigate = useNavigate();
  const { state: { user } } = useContext(Context);
  const [error, setError] = useState("");
  const { code, type } = useParams();

  const fetchRedirect = useCallback(
    async (code, type, advertisingData) => {
      try {
        // Validate the type
        if (!['product', 'link'].includes(type)) {
          throw new Error("Invalid type parameter.");
        }

        // Include advertising data in the API request
        const response = await apiGet(`/redirect/${code}/${type}`, {
          params: advertisingData
        });

        if (response && response.targetUrl) {
          // Set a cookie to track the referral ID for conversions
          // Adjust the cookie name and attributes as needed
          Cookies.set('referralId', code, { expires: 30, path: '/' });

          // Optionally, set additional cookies based on type
          if (type === 'product') {
            Cookies.set('referralType', 'product', { expires: 30, path: '/' });
          } else if (type === 'link') {
            Cookies.set('referralType', 'link', { expires: 30, path: '/' });
          }

          // Redirect to the actual target URL
          window.location.href = response.targetUrl;
        } else {
          navigate(`/redirect/${code}/error`);
          setError("Something went wrong");
        }
      } catch (error) {
        console.error("Redirection error:", error);
        navigate(`/redirect/${code}/error`);
        setError("Something went wrong");
      }
    },
    [navigate]
  );

  const collectAdvertisingData = async () => {
    // Collect device information
    const deviceInfo = {
      deviceType: isIOS ? 'iOS' : isAndroid ? 'Android' : 'Web',
      userAgent: navigator.userAgent,
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
    };

    // Collect geolocation information
    let geoInfo = {
      // Default to null; will attempt to fetch
      latitude: null,
      longitude: null,
      city: null,
      region: null,
      country: null,
    };

    // Attempt to get precise location (requires user consent)
    if (navigator.geolocation) {
      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject, {
            enableHighAccuracy: false,
            timeout: 5000,
            maximumAge: 0
          });
        });

        geoInfo = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          // You can use a reverse geocoding service to get city, region, country
        };
      } catch (error) {
        console.warn("Geolocation permission denied or unavailable.");
        // Optionally, fallback to IP-based geolocation
        try {
          const ipResponse = await axios.get('https://ipapi.co/json/');
          geoInfo = {
            latitude: ipResponse.data.latitude,
            longitude: ipResponse.data.longitude,
            city: ipResponse.data.city,
            region: ipResponse.data.region,
            country: ipResponse.data.country_name,
          };
        } catch (ipError) {
          console.error("IP-based geolocation failed:", ipError);
        }
      }
    }

    return { ...deviceInfo, ...geoInfo };
  };

  useEffect(() => {
    const handleRedirect = async () => {
      if (code && type) {
        // Collect advertising data
        const advertisingData = await collectAdvertisingData();

        // Optionally, you can store this data locally or send it to your analytics service
        // For example, sending to your backend:
        // await apiPost('/collect-ad-data', advertisingData);

        // Proceed with the redirect
        fetchRedirect(code, type, advertisingData);
      } else {
        navigate(`/redirect/${code}/error`);
      }
    };

    handleRedirect();
  }, [code, type, navigate, fetchRedirect]);

  // Conditionally render based on user authentication

  if (error !== "") {
    return (
      // If redirection should be public, remove <UserRoute>
      <div className="redirect-page">
        <div className="redirect-page__text">{error}..</div>
      </div>
    );
  }

  if (!user) {
    return (
      // If redirection should be public, remove <UserRoute>
      <div className="redirect-page">
        <div className="redirect-page__message">Redirecting...</div>
      </div>
    );
  }

  return (
    // If redirection should be authenticated, retain <UserRoute>
    <UserRoute>
      <div className="redirect-page">
        <div className="redirect-page__message">Redirecting...</div>
      </div>
    </UserRoute>
  );
};

export default RedirectPage;
