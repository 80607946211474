// src/components/CampaignStatus/CampaignStatus.jsx

import React, { useContext } from 'react';
import { Tooltip } from 'react-tooltip'; // Import Tooltip from react-tooltip
import './CampaignStatus.scss';
import ThemeContext from '../../context/ThemeContext';
import TransferFundsIcon from './TransferFundsIcon';

export default function CampaignStatus({
    status,
    isBrand = false,
    isCreator = false,
    handleRevisionReq,
    handleCampaignCompletion,
    handlePostCampaignStatus,
    handleReviewCampaignStatus,
    handleTransfer,
    creatorPaymentStatus,
    isProcessingTransfer,
    stripeTransferId
}) {
    let icon = null;
    let progress = 0;
    let text = '';
    let message = '';
    let altMessage = '';
    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';
    const color = themeMode === 'light' ? 'black' : 'white';

    // Icon Components
    const PendingIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={color} className="bi bi-clock" viewBox="0 0 16 16">
            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
        </svg>
    );

    const ApprovedIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill={color} className="bi bi-check-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
        </svg>
    );

    const CheckInIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill={color} className="bi bi-chat-heart" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M2.965 12.695a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2Zm-.8 3.108.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125ZM8 5.993c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132Z" />
        </svg>
    );

    const CancelledIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={color} className="bi bi-x-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
        </svg>
    );

    const InProgressIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill={color} className="bi bi-camera" viewBox="0 0 16 16">
            <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
            <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
        </svg>
    );

    const CompletedIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill={color} className="bi bi-check2-circle" viewBox="0 0 16 16">
            <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
            <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
        </svg>
    );

    // Determine Icon, Progress, Text, and Messages based on Status
    switch (status) {
        case 'Pending':
            icon = <PendingIcon />;
            progress = 0;
            text = 'Pending';
            message = 'Your campaign is pending approval from the Creator.';
            altMessage = "Please check the Campaign Requests page to review and approve the campaign!";
            break;
        case 'Approved':
            icon = <ApprovedIcon />;
            progress = 15;
            text = 'Approved';
            message = "Just waiting on you to update the campaign concepts and add product details. Almost there!";
            altMessage = "Waiting on the brand to update campaign concepts and add product details. Almost ready!";
            break;
        case 'Brand Concepts':
            icon = <ApprovedIcon />;
            progress = 25;
            text = 'Brand Concepts';
            message = "Great news! Your brand concepts are ready. Check in with the creator to discuss next steps for the campaign.";
            altMessage = 'Brand concepts are ready! Feel free to connect with the brand to discuss the next steps for the campaign.';
            break;
        case 'In Progress':
            icon = <InProgressIcon />;
            progress = 40;
            text = 'In Progress';
            message = 'Creator is working on their magic';
            altMessage = "Best of luck crafting your campaign posts! Can’t wait to see the magic you create.";
            break;
        case 'Check In':
            icon = <CheckInIcon />;
            progress = 60;
            text = 'Check In';
            message = "Ad content has been posted in campaign. Review when ready, and creator will post on socials soon.";
            altMessage = "Thanks for all your hard work! Your campaign assets have been submitted for review & feedback.";
            break;
        case 'Comments':
            icon = <CheckInIcon />;
            progress = 70;
            text = 'Comments & Revisions';
            altMessage = "The brand has a few notes for the campaign content before posting.";
            message = "You’ve added feedback on the campaign. The creator will review before posting.";
            break;
        case 'Post Campaign':
            icon = <CheckInIcon />;
            text = 'Post Campaign to Socials';
            progress = 80;
            message = "Campaign ad content assets are approved. The creator will post and add post IDs for analytics.";
            altMessage = "Awesome! The brand approved your ad content assets. Post to socials now, and add the social post IDs in Analytics.";
            break;
        case 'Completed':
            icon = <CompletedIcon />;
            progress = 90;
            text = 'Campaign is Complete';
            message = 'Check back in for Post Campaign results in Campaign Analytics.';
            break;
        case 'Review Campaign':
            icon = <CompletedIcon />;
            progress = 100;
            text = 'Review Campaign';
            message = 'Review campaign in analytics tab. Campaign has been completed.';
            break;
        case 'Cancelled':
            icon = <CancelledIcon />;
            progress = 0;
            text = "Wasn't Approved";
            message = 'Campaign was not approved by the creator';
            break;
        default:
            icon = null;
            progress = 0;
            message = '';
            break;
    }

    return (
        <div className={`campaign-status campaign-status--${themeClass}`}>
            <div className='campaign-status__cta'>
                {(isBrand && (status === 'Check In' || status === 'Comments')) && (
                    <div className="campaign-status__completed">
                        {(isBrand && status === 'Check In') && (
                            <button
                                className={`campaign-status__approve campaign-status__approve--${themeClass}`}
                                onClick={handleRevisionReq}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="red" strokeWidth={1} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-bubble-text">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M7 10h10" />
                                    <path d="M9 14h5" />
                                    <path d="M12.4 3a5.34 5.34 0 0 1 4.906 3.239a5.333 5.333 0 0 1 -1.195 10.6a4.26 4.26 0 0 1 -5.28 1.863l-3.831 2.298v-3.134a2.668 2.668 0 0 1 -1.795 -3.773a4.8 4.8 0 0 1 2.908 -8.933a5.33 5.33 0 0 1 4.287 -2.16" />
                                </svg>
                                Comments
                            </button>
                        )}
                        <button
                            className={`campaign-status__approve campaign-status__approve--${themeClass}`}
                            onClick={handlePostCampaignStatus}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="green" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <circle cx={12} cy={12} r={9} />
                                <path d="M9 12l2 2l4 -4" />
                            </svg>
                            Go Live
                        </button>
                    </div>
                )}
                {(!isCreator && (status === 'Post Campaign')) && (
                    <div className="campaign-status__completed">
                        <button
                            className={`campaign-status__approve campaign-status__approve--${themeClass}`}
                            onClick={handleCampaignCompletion}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="green" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <circle cx={12} cy={12} r={9} />
                                <path d="M9 12l2 2l4 -4" />
                            </svg>
                            Campaign Complete
                        </button>
                    </div>
                )}
                {!isCreator && creatorPaymentStatus !== "paid" && (
                    <div className="campaign-status__completed">
                        {!isProcessingTransfer ? (
                            <button
                                onClick={handleTransfer}
                                className={`campaign-status__approve campaign-status__approve--m campaign-status__approve--${themeClass}`}
                            >
                                <TransferFundsIcon themeMode={themeMode} />
                                Transfer Funds
                            </button>
                        ) : (
                            <div className={`campaign-status__approve campaign-status__approve--${themeClass} campaign-status__approve--animate`}>
                                <TransferFundsIcon themeMode={themeMode} />
                                Transferring Funds
                            </div>
                        )}
                    </div>
                )}
                {(!isCreator && (status === 'Completed')) && (
                    <div className="campaign-status__completed">
                        <button
                            className={`campaign-status__approve campaign-status__approve--${themeClass}`}
                            onClick={handleReviewCampaignStatus}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="green" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <circle cx={12} cy={12} r={9} />
                                <path d="M9 12l2 2l4 -4" />
                            </svg>
                            Campaign Reviewed
                        </button>
                    </div>
                )}
                {isCreator && stripeTransferId !== "" && (
                    <div className="campaign-status__completed">
                        {/* Tooltip Trigger */}
                        <div
                            className={`campaign-status__approve campaign-status__approve--${themeClass}`}
                            data-tooltip-id="transferTooltip"
                            data-tooltip-content={`Stripe Transfer: ${stripeTransferId}`}
                            style={{ cursor: 'pointer' }}
                        >
                            <TransferFundsIcon themeMode={themeMode} />
                            Funds Transferred
                        </div>
                        {/* Tooltip Component */}
                        <Tooltip
                            id="transferTooltip"
                            place="top"
                            effect="solid"
                            className={`tooltip--${themeClass}`} // Optional: For custom styling based on theme
                        />
                    </div>
                )}
            </div>
            <div className="campaign-status__info">
                <div className="campaign-status__icon">
                    {icon}
                </div>
                <div className="campaign-status__details">
                    <div className='campaign-status__div'>
                        <p className="campaign-status__name">{text}</p>
                    </div>
                    <p className="campaign-status__message">{isCreator ? (altMessage || message) : message}</p>
                </div>
            </div>
            <div className="campaign-status__progress">
                <div className="campaign-status__progress-bar" style={{ width: `${progress}%` }}></div>
            </div>
        </div>
    );
    }
