// Provider.js

import React, { useReducer, createContext, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Context = createContext();

const initialState = {
  user: JSON.parse(localStorage.getItem("user")) || null,
  csrfToken: null,
};

const rootReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return { ...state, user: action.payload };
    case "LOGOUT":
      return { ...state, user: null };
    case "SET_CSRF_TOKEN":
      return { ...state, csrfToken: action.payload };
    case "UPDATE_USER":
      return { ...state, user: action.payload };
    default:
      return state;
  }
};

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(rootReducer, initialState);
  const navigate = useNavigate();

  // Configure Axios
  axios.defaults.withCredentials = true;
  axios.defaults.baseURL = process.env.REACT_APP_API || "https://api.chequeai.com/api";
  axios.defaults.timeout = 10000; // 10 seconds

  // Fetch CSRF Token on Mount
  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await axios.get("/csrf-token");
        if (response?.data?.csrf) {
          dispatch({ type: "SET_CSRF_TOKEN", payload: response.data.csrf });
          // Set the CSRF token in Axios default headers
          axios.defaults.headers.common["x-csrf-token"] = response.data.csrf;
        }
      } catch (error) {
        console.error("Error fetching CSRF token:", error);
      }
    };

    // Fetch the CSRF token only once
    if (!state.csrfToken) {
      fetchCsrfToken();
    }
  }, [state.csrfToken]); // Empty dependency array ensures this runs only once

  // Axios Request Interceptor to add CSRF Token
  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        if (state.csrfToken) {
          config.headers["x-csrf-token"] = state.csrfToken;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
    };
  }, [state.csrfToken]);

  // Axios Response Interceptor for 401 Errors
  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        if (
          error.response &&
          error.response.status === 401 &&
          !originalRequest._retry
        ) {
          originalRequest._retry = true;
          try {
            await axios.get("/logout");
            dispatch({ type: "LOGOUT" });
            localStorage.removeItem("user");
            localStorage.removeItem("twoFactorVerified");
            localStorage.removeItem("fcmToken");
            localStorage.removeItem("token");
            navigate("/login");
          } catch (logoutError) {
            console.error("Error during logout after 401:", logoutError);
          }
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [navigate, dispatch]);

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};

export { Context, Provider };
