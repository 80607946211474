import React, { useContext } from 'react'

import "./Button.scss";
import ThemeContext from '../../context/ThemeContext';

function Button({ labelModifier = "", price, modifier, children, cn, ...props  }) {
  const { themeMode } = useContext(ThemeContext);

  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';




  return (
    <button className={`button button--${themeClass} ${modifier} ${modifier}--${themeClass} ${cn}`} {...props}>
      <div  className={`button__label-container ${modifier}__label-container`}>
        <span className={`button__label ${labelModifier} button__label--${themeClass} ${labelModifier}--${themeClass}`}>{children}</span>
      </div>
      {price && <span className={`button__icon button__icon--${themeClass}`}>{price}</span>}
    </button>
  );
}

export default Button;
