// src/components/UserRoute.js

import React, { useEffect, useState, useContext, useCallback } from "react";
import { useNavigate, Link, NavLink } from "react-router-dom";
import { Context } from "../context/index";
import ThemeContext from "../context/ThemeContext";
import "./Auth.scss";
import "./Header.scss";
import ChequaiLogo from "../assets/icons/chequai-logo.png";
import { apiGet } from "../utils/apiUtils"; // Ensure apiGet is a stable function
import FooterComponent from "../Components/FooterComponent/FooterComponent";
import GetStartedModal from "../Components/GetStartedModal/GetStartedModal";
import OnboardingModal from "../Components/OnboardingModal/OnboardingModal";
import { OnBoardingIcon } from "./Icons/OnBoardingIcon";
import { LogoutIcon } from "./Icons/LogoutIcon";
import { HomeIcon } from "./Icons/HomeIcon";
import { PersonIcon } from "./Icons/PersonIcon";
import { NotificationIcon } from "./Icons/NotificationIcon";
import { MessageIcon } from "./Icons/MessageIcon";
import { SearchIcon } from "./Icons/SearchIcon";
import { OrdersIcon } from "./Icons/OrdersIcon";
import { PostIcon } from "./Icons/PostIcon";
import { GetStartedIcon } from "./Icons/GetStartedIcon";
import { PointIcon } from "./Icons/PointIcon";
import { SettingsIcon } from "./Icons/SettingsIcon";
import TwoFactorAuthLogin from "../Components/TwoFactorAuthLogin/TwoFactorAuthLogin";
// import chequeaiLogo from "../assets/icons/chequai-logo.png";

// Convert the VAPID key to a Uint8Array for use in the subscription
export function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export function askForNotificationPermission() {
  if ("Notification" in window) {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
      } else {
        console.log('Notification permission denied.');
      }
    });
  } else {
    console.log('This browser does not support notifications.');
  }
};

const UserRoute = ({ children }) => {
  const [ok, setOk] = useState(false);
  const { state, dispatch } = useContext(Context); // Assuming you have a dispatch method
  const [user, setUser] = useState(state.user);
  const { themeMode } = useContext(ThemeContext);
  const [twoFactorRequired, setTwoFactorRequired] = useState(false);
  const themeClass = themeMode === "light" ? "light-theme" : "";
  const [isGetStartedModalOpen, setGetStartedModalOpen] = useState(false);
  const [isOnboardingModalOpen, setOnboardingModalOpen] = useState(false);
  const [newMessage, setNewMessage] = useState(false);
  const [newNotification, setNewNotification] = useState(false);
  const navigate = useNavigate();
  // const [isNotificationPageVisible, setIsNotificationPageVisible] =useState(false);

  /**
   * Function to check 2FA status from the server
   * This endpoint should return whether 2FA is required and any additional data like unread messages or notifications
   */
  const checkTwoFactorStatus = useCallback(async () => {
    try {
      const response = await apiGet("/2fa/status"); // Ensure this endpoint exists on your server
      if (response.twoFactorRequired) {
      // console.log(response.twoFactorRequired)
        setTwoFactorRequired(true);
      } else {
        setTwoFactorRequired(false);
      }
    } catch (error) {
      console.error("Error checking 2FA status:", error);
      // Optionally handle unauthorized access or redirect to login
      navigate("/login");
    }
  }, [navigate]);

  /**
   * useEffect to fetch user data and check 2FA status when the component mounts or when the user changes
   */
  const fetchUser = useCallback(async () => {
    try {
      const response = await apiGet("/current-user");
      if (response) {
        // console.log(response);
        // Dispatch the UPDATE_USER action with the user data
        dispatch({
          type: 'UPDATE_USER',
          payload: response.user,
        });
        setUser(response.user);
        setOk(true);
        setNewMessage(response.unreadMessagesInRooms);
        setNewNotification(response.newNotifications);
      }
    } catch (error) {
      console.error("UserRoute error", error);
      // Optionally handle unauthorized access or redirect to login
      navigate("/login");
    }
  }, [dispatch, navigate, setUser]);

  /**
   * useEffect to fetch user data and check 2FA status when the component mounts or when the user changes
   */
  useEffect(() => {
      fetchUser();
      checkTwoFactorStatus(); // Call checkTwoFactorStatus after fetching user
  }, [ fetchUser, checkTwoFactorStatus]);

  /**
   * Handle user logout
   * This function calls the logout API endpoint, clears user data from context, and navigates to the login page
   */
  const handleLogout = useCallback(async () => {
    try {
      await apiGet("/logout");
      dispatch({ type: "LOGOUT" }); // Adjust based on your context implementation
      navigate("/login");
    } catch (error) {
      console.error("Logout error:", error);
    }
  }, [dispatch, navigate]);

  /**
   * Render the TwoFactorAuthLogin component if 2FA is required
   * Once verified, update the state to proceed with protected routes
   */
  if (twoFactorRequired) {
    return (
      <TwoFactorAuthLogin
        themeMode={themeMode}
        setTwoFactorVerified={() => {
          setTwoFactorRequired(false);
          setOk(true);
          checkTwoFactorStatus(); // Optionally re-fetch 2FA status or user data
        }}
      />
    );
  }

  /**
   * Show a loading state while verifying 2FA status
   */
  if (user && !ok) {
    return (
      <div className={`auth auth--${themeClass}`}>
        <div className={`auth__link auth__link--${themeClass}`}>
          <NavLink
            className={`nav__link nav__link--m nav__link--${themeClass}`}
            to="/notifications"
          >
            <NotificationIcon themeMode={themeMode} />
          </NavLink>
          <Link
            to="/home"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              className="nav__icon nav__icon--profile"
              src={ChequaiLogo}
              alt="Chequeai"
            />
            <h1 className={`auth__title auth__title--${themeClass}`}>
              Chequeai
            </h1>
          </Link>
          <Link
            className={`nav__link nav__link--m nav__link--profile nav__link--${themeClass}`}
            to="/messages"
          >
            <MessageIcon themeMode={themeMode} />
          </Link>
        </div>
        <div className={`auth__app auth__app--${themeClass}`}>
          <div className={`auth__app-body auth__app-body--${themeClass}`}>
            <div className="auth__app-body-content">
              <h2 className="auth__app-body-title">📱</h2>
            </div>
            <FooterComponent country={user?.country} />
          </div>
          <nav className={`nav nav--${themeClass}`}>
            <div className="nav__m">
              <Link to="/home" className={`nav__link-title`}>
                <img
                  className="nav__icon nav__icon--profile"
                  src={ChequaiLogo}
                  alt="Chequeai"
                />
                <h1 className={`auth__title auth__title--${themeClass}`}>
                  Chequeai
                </h1>
              </Link>
              <NavLink
                className={`nav__link nav__link--home nav__link--${themeClass}`}
                to="/home"
              >
                <HomeIcon themeMode={themeMode} />
                <h3 className={`nav__link__text nav__text--${themeClass}`}>
                  Home
                </h3>
              </NavLink>

              <NavLink
                className={`nav__link nav__link--${themeClass}`}
                to="/featured"
              >
                <SearchIcon themeMode={themeMode} />
                <h3 className={`nav__link__text nav__text--${themeClass}`}>
                  Search
                </h3>
              </NavLink>
              <NavLink
                className={`nav__link nav__link--${themeClass}`}
                to="/post"
              >
                <PostIcon themeMode={themeMode} />
                <h3 className={`nav__link__text nav__text--${themeClass}`}>
                  Portfolio
                </h3>
              </NavLink>
              <NavLink
                className={`nav__link nav__link--${themeClass}`}
                to="/orders"
              >
                <OrdersIcon themeMode={themeMode} />
                <h3 className={`nav__link__text nav__text--${themeClass}`}>
                  Orders
                </h3>
              </NavLink>
              <NavLink
                className={`nav__link nav__link--profile nav__link--${themeClass} nav__link--mh`}
                to="/messages"
              >
                <MessageIcon themeMode={themeMode} />
                <h3 className={`nav__link__text nav__text--${themeClass}`}>
                  Messages
                </h3>
                {newMessage && <PointIcon themeMode={themeMode} />}
              </NavLink>
              {user?.username && (
                <NavLink
                  className={`nav__link nav__link--profile nav__link--${themeClass}`}
                  to={`/${user?.username}`}
                >
                  <PersonIcon themeMode={themeMode} />
                  <h3 className={`nav__link__text nav__text--${themeClass}`}>
                    Profile
                  </h3>
                </NavLink>
              )}
              <NavLink
                className={`nav__link nav__link--${themeClass} nav__link--mh`}
                to="/notifications"
              >
                <NotificationIcon themeMode={themeMode} />
                <h3 className={`nav__link__text nav__text--${themeClass}`}>
                  Notifications
                </h3>
                {newNotification && <PointIcon themeMode={themeMode} />}
              </NavLink>
            </div>
            <div className="nav__tablet">
              {user &&
                (!user?.stripeAccountId ||
                  !user?.promotions ||
                  !user?.campaigns) && (
                  <div
                    className={`nav__link nav__link--${themeClass} nav__link--desktop`}
                    onClick={() => setGetStartedModalOpen(true)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") setGetStartedModalOpen(true);
                    }}
                    role="button"
                    tabIndex={0}
                    style={{ cursor: "pointer" }}
                  >
                    <GetStartedIcon themeMode={themeMode} />
                    <h3 className={`nav__link__text nav__text--${themeClass}`}>
                      Get Started
                    </h3>
                  </div>
                )}
                {user && (!user?.firstName || !user?.socialMedia || !user?.location || !user?.country) && (
                <div
                  className={`nav__link nav__link--${themeClass} nav__link--desktop`}
                  onClick={() => setOnboardingModalOpen(true)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") setOnboardingModalOpen(true);
                  }}
                  role="button"
                  tabIndex={0}
                  style={{ cursor: "pointer" }}
                >
                  <OnBoardingIcon themeMode={themeMode} />
                  <h4 className={`nav__link__text nav__text--${themeClass}`}>
                    Account Setup
                  </h4>
                </div>
              )}
              <NavLink
                className={`nav__link nav__link--${themeClass} nav__link--desktop`}
                to="/settings"
              >
                <SettingsIcon themeMode={themeMode} />
                <h3 className={`nav__link__text nav__text--${themeClass}`}>
                  Settings
                </h3>
              </NavLink>
              <NavLink
                className={`nav__link nav__link--${themeClass} nav__link--desktop`}
                to="/logout"
                onClick={handleLogout}
              >
                <LogoutIcon themeMode={themeMode} />
                <h3 className={`nav__link__text nav__text--${themeClass}`}>
                  Logout
                </h3>
              </NavLink>
            </div>
          </nav>
          {isGetStartedModalOpen && (
            <GetStartedModal
              isOpen={isGetStartedModalOpen}
              onClose={() => setGetStartedModalOpen(false)}
              themeMode={themeMode}
              profileUrl={user?.username || ""}
            />
          )}
          {isOnboardingModalOpen && (
            <OnboardingModal
              isOpen={isOnboardingModalOpen}
              onClose={() => setOnboardingModalOpen(false)}
              username={user?.username || ""}
              themeMode={themeMode}
            />
          )}
        </div>
      </div>
    )

      ; // You can replace this with a spinner or skeleton UI
  }

  /**
   * If the user is not authenticated, redirect to the login page
   */
  if (!user) {
    navigate("/login");
    return null;
  }

  return (
    <div className={`auth auth--${themeClass}`}>
      <div className={`auth__link auth__link--${themeClass}`}>
        <NavLink
          className={`nav__link nav__link--m nav__link--${themeClass}`}
          to="/notifications"
        >
          <NotificationIcon themeMode={themeMode} />
        </NavLink>
        <Link
          to="/home"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            className="nav__icon nav__icon--profile"
            src={ChequaiLogo}
            alt="Chequeai"
          />
          <h1 className={`auth__title auth__title--${themeClass}`}>
            Chequeai
          </h1>
        </Link>
        <Link
          className={`nav__link nav__link--m nav__link--profile nav__link--${themeClass}`}
          to="/messages"
        >
          <MessageIcon themeMode={themeMode} />
        </Link>
      </div>
      <div className={`auth__app auth__app--${themeClass}`}>
        <div className={`auth__app-body auth__app-body--${themeClass}`}>
          {children}
          <FooterComponent country={user?.country} />
        </div>
        <nav className={`nav nav--${themeClass}`}>
          <div className="nav__m">
            <Link to="/home" className={`nav__link-title`}>
              <img
                className="nav__icon nav__icon--profile"
                src={ChequaiLogo}
                alt="Chequeai"
              />
              <h1 className={`auth__title auth__title--${themeClass}`}>
                Chequeai
              </h1>
            </Link>
            <NavLink
              className={`nav__link nav__link--home nav__link--${themeClass}`}
              to="/home"
            >
              <HomeIcon themeMode={themeMode} />
              <h3 className={`nav__link__text nav__text--${themeClass}`}>
                Home
              </h3>
            </NavLink>

            <NavLink
              className={`nav__link nav__link--${themeClass}`}
              to="/featured"
            >
              <SearchIcon themeMode={themeMode} />
              <h3 className={`nav__link__text nav__text--${themeClass}`}>
                Search
              </h3>
            </NavLink>
            <NavLink
              className={`nav__link nav__link--${themeClass}`}
              to="/post"
            >
              <PostIcon themeMode={themeMode} />
              <h3 className={`nav__link__text nav__text--${themeClass}`}>
                Portfolio
              </h3>
            </NavLink>
            <NavLink
              className={`nav__link nav__link--${themeClass}`}
              to="/orders"
            >
              <OrdersIcon themeMode={themeMode} />
              <h3 className={`nav__link__text nav__text--${themeClass}`}>
                Orders
              </h3>
            </NavLink>
            <NavLink
              className={`nav__link nav__link--profile nav__link--${themeClass} nav__link--mh`}
              to="/messages"
            >
              <MessageIcon themeMode={themeMode} />
              <h3 className={`nav__link__text nav__text--${themeClass}`}>
                Messages
              </h3>
              {newMessage && <PointIcon themeMode={themeMode} />}
            </NavLink>
            {user?.username && (
              <NavLink
                className={`nav__link nav__link--profile nav__link--${themeClass}`}
                to={`/${user?.username}`}
              >
                <PersonIcon themeMode={themeMode} />
                <h3 className={`nav__link__text nav__text--${themeClass}`}>
                  Profile
                </h3>
              </NavLink>
            )}
            <NavLink
              className={`nav__link nav__link--${themeClass} nav__link--mh`}
              to="/notifications"
            >
              <NotificationIcon themeMode={themeMode} />
              <h3 className={`nav__link__text nav__text--${themeClass}`}>
                Notifications
              </h3>
              {newNotification && <PointIcon themeMode={themeMode} />}
            </NavLink>
          </div>
          <div className="nav__tablet">
            {user &&
              (!user?.stripeAccountId || user.stripeSeller?.capabilities.transfers === "inactive" ||
                !user?.promotions ||
                !user?.campaigns) && (
                <div
                  className={`nav__link nav__link--${themeClass} nav__link--desktop`}
                  onClick={() => setGetStartedModalOpen(true)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") setGetStartedModalOpen(true);
                  }}
                  role="button"
                  tabIndex={0}
                  style={{ cursor: "pointer" }}
                >
                  <GetStartedIcon themeMode={themeMode} />
                  <h3 className={`nav__link__text nav__text--${themeClass}`}>
                    Get Started
                  </h3>
                </div>
              )}
            {user && (!user?.firstName || !user?.socialMedia || !user?.location || !user?.country) && (
              <div
                className={`nav__link nav__link--${themeClass} nav__link--desktop`}
                onClick={() => setOnboardingModalOpen(true)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") setOnboardingModalOpen(true);
                }}
                role="button"
                tabIndex={0}
                style={{ cursor: "pointer" }}
              >
                <OnBoardingIcon themeMode={themeMode} />
                <h4 className={`nav__link__text nav__text--${themeClass}`}>
                  Account Setup
                </h4>
              </div>
            )}
            <NavLink
              className={`nav__link nav__link--${themeClass} nav__link--desktop`}
              to="/settings"
            >
              <SettingsIcon themeMode={themeMode} />
              <h3 className={`nav__link__text nav__text--${themeClass}`}>
                Settings
              </h3>
            </NavLink>
            <NavLink
              className={`nav__link nav__link--${themeClass} nav__link--desktop`}
              to="/logout"
              onClick={handleLogout}
            >
              <LogoutIcon themeMode={themeMode} />
              <h3 className={`nav__link__text nav__text--${themeClass}`}>
                Logout
              </h3>
            </NavLink>
          </div>
        </nav>
        {isGetStartedModalOpen && (
          <GetStartedModal
            isOpen={isGetStartedModalOpen}
            onClose={() => setGetStartedModalOpen(false)}
            themeMode={themeMode}
            profileUrl={user?.username || ""}
          />
        )}
        {isOnboardingModalOpen && (
          <OnboardingModal
            isOpen={isOnboardingModalOpen}
            onClose={() => setOnboardingModalOpen(false)}
            username={user?.username || ""}
            user={user}
            themeMode={themeMode}
          />
        )}
      </div>
    </div>
  );
};

export default UserRoute;
