import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './step.scss';
import { stripeSupportedCountries } from '../../../utils/countriesUtils';

function PersonalInfoStep({ data, onChange, onNext, themeMode }) {
  const [firstName, setFirstName] = useState(data.firstName || '');
  const [lastName, setLastName] = useState(data.lastName || '');
  const [dateOfBirth, setDateOfBirth] = useState(
    data.dateOfBirth ? new Date(data.dateOfBirth).toISOString().slice(0, 10) : ''
  );
  const [country, setCountry] = useState(data.country || '');
  const [userType, setUserType] = useState(data.userType || ''); // State for Account/User Type
  const [companyName, setCompanyName] = useState(data.companyName || ''); // State for Company Name
  const [errors, setErrors] = useState({});
  const usernameRef = useRef(null);
  const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

  useEffect(() => {
    if (usernameRef.current) {
      usernameRef.current.focus();
    }
  }, []);

  const validate = () => {
    const newErrors = {};
    if (!firstName.trim()) newErrors.firstName = 'First name is required';
    if (!lastName.trim()) newErrors.lastName = 'Last name is required';
    if (!country.trim()) newErrors.country = 'Country is required';
    if (!userType.trim()) newErrors.userType = 'Account type is required';
    if (userType === 'brand') {
      if (!companyName.trim()) newErrors.companyName = 'Company name is required';
      if (!dateOfBirth.trim()) newErrors.dateOfBirth = 'Established year is required';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNextClick = (e) => {
    e.preventDefault();
    if (validate()) {
      onChange({ firstName, lastName, dateOfBirth, country, userType, companyName });
      onNext();
    }
  };

  return (
    <div className={`step ${themeMode}`}>
      <h2 className={`step__title step__title--${themeClass}`}>Personal Information</h2>
      <form>
        {/* Account/User Type Dropdown */}
        <div className={`form-group form-group--${themeClass}`}>
          <label htmlFor="userType">Account *</label>
          <select
            id="userType"
            value={userType}
            onChange={(e) => setUserType(e.target.value)}
            required
            aria-describedby="userType-error"
          >
            <option value="" disabled>Select</option>
            <option value="creator">Creator</option>
            <option value="brand">Brand</option>
          </select>
          {errors.userType && (
            <span className="error-message" id="userType-error" role="alert">
              {errors.userType}
            </span>
          )}
        </div>

        {/* Conditional Company Name Input */}
        {userType === 'brand' && (
          <div className={`form-group form-group--${themeClass}`}>
            <label htmlFor="companyName">Company Name *</label>
            <input
              type="text"
              id="companyName"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              required
              placeholder="Enter your company name"
              aria-describedby="companyName-error"
            />
            {errors.companyName && (
              <span className="error-message" id="companyName-error" role="alert">
                {errors.companyName}
              </span>
            )}
          </div>
        )}

        <div className={`form-group form-group--${themeClass}`}>
          <label htmlFor="firstName">First Name *</label>
          <input
            type="text"
            id="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
            placeholder="Enter your first name"
            aria-describedby="firstName-error"
          />
          {errors.firstName && (
            <span className="error-message" id="firstName-error" role="alert">
              {errors.firstName}
            </span>
          )}
        </div>

        <div className={`form-group form-group--${themeClass}`}>
          <label htmlFor="lastName">Last Name *</label>
          <input
            type="text"
            id="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
            placeholder="Enter your last name"
            aria-describedby="lastName-error"
          />
          {errors.lastName && (
            <span className="error-message" id="lastName-error" role="alert">
              {errors.lastName}
            </span>
          )}
        </div>

        {/* Conditional Date of Birth / Established Year */}
        <div className={`form-group form-group--${themeClass}`}>
          <label htmlFor="dateOfBirth">
            {userType === 'brand' ? 'Year Established' : 'Date of Birth'}
          </label>
          <input
            type={userType === 'brand' ? 'number' : 'date'}
            id="dateOfBirth"
            value={dateOfBirth}
            onChange={(e) => setDateOfBirth(e.target.value)}
            placeholder={userType === 'brand' ? 'Enter year of establishment' : 'Select your birth date'}
            aria-describedby="dateOfBirth-error"
          />
          {errors.dateOfBirth && (
            <span className="error-message" id="dateOfBirth-error" role="alert">
              {errors.dateOfBirth}
            </span>
          )}
        </div>

        <div className={`form-group form-group--${themeClass}`}>
          <label htmlFor="country">Country *</label>
          <select
            id="country"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            required
            aria-describedby="country-error"
          >
            <option value="" disabled>Select your country</option>
            {stripeSupportedCountries.map((country) => (
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            ))}
          </select>
          {errors.country && (
            <span className="error-message" id="country-error" role="alert">
              {errors.country}
            </span>
          )}
        </div>

        <div className="navigation-buttons">
          <button type="button" onClick={handleNextClick} className={`next-button next-button--${themeClass}`}>
            Next
          </button>
        </div>
      </form>
    </div>
  );
}

PersonalInfoStep.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
};

export default PersonalInfoStep;
