import React, { createContext, useState, useEffect } from 'react';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [themeMode, setThemeMode] = useState('');
  const [widgetColor, setWidgetColor] = useState(''); // State for widget color

  const toggleTheme = () => {
    setThemeMode(prevMode => {
      const newMode = prevMode === 'light' ? 'dark' : 'light';
      localStorage.setItem('themeMode', newMode); // Save the theme in localStorage
      return newMode;
    });
  };

  const changeWidgetColor = (color) => {
    setWidgetColor(color); 
    localStorage.setItem('widgetColor', color); // Save the widget color in localStorage
  };

  useEffect(() => {
    // Retrieve the saved theme and widget color from localStorage
    const savedTheme = localStorage.getItem('themeMode') || 'dark'; // Default to dark
    setThemeMode(savedTheme);

    const savedWidgetColor = localStorage.getItem('widgetColor') || '#FFFFFF'; // Default to white
    setWidgetColor(savedWidgetColor);
  }, []);

  return (
    <ThemeContext.Provider value={{ themeMode, toggleTheme, widgetColor, changeWidgetColor }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;
