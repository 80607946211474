// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.star-icon {
  fill: #ddd;
  cursor: pointer;
}
.star-icon.filled {
  fill: gold;
}
.star-icon:hover, .star-icon:active {
  fill: yellow;
}`, "",{"version":3,"sources":["webpack://./src/Pages/CampaignReviewPage/StarIcon.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EAQA,eAAA;AANJ;AADI;EACI,UAAA;AAGR;AADI;EAEI,YAAA;AAER","sourcesContent":[".star-icon {\n    fill: #ddd; // Default color for empty stars\n    &.filled {\n        fill: gold; // Filled star color\n    }\n    &:hover,\n    &:active {\n        fill: yellow; // Hover and active color\n    }\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
