import React, { useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import ThemeContext from '../../context/ThemeContext';
import './CampaignSubHeader.scss';



export default function CampaignSubHeader({ status, mode, setMode }) {

    const { id } = useParams();
    const url = useLocation();
    const { themeMode } = useContext(ThemeContext);
    const themeClass = themeMode === 'light' ? 'light-theme' : 'dark-theme';

    const isActive = (pathname) => {
        return url.pathname === pathname ? `campaign-card__link--active campaign-card__link--${themeClass}--active` : '';
    };



    return (
        <>
            <button to={`/campaigns/${id}/`} className={`campaign-sub-header__link campaign-sub-header__link--${themeClass} ${isActive(`/campaigns/${id}/`)}`} style={{maxWidth: '100px'}} onClick={() => setMode('Pre Production')}>
                Pre Proudction
            </button>
            <button to={`/campaigns/${id}/campaign`} className={`campaign-sub-header__link campaign-sub-header__link--${themeClass} ${isActive(`/campaigns/${id}/campaign`)}`} style={{maxWidth: '100px'}} onClick={() => setMode('Production')}>
                Production
            </button>
            <button to={`/campaigns/${id}/products`} className={`campaign-sub-header__link campaign-sub-header__link--${themeClass} ${isActive(`/campaigns/${id}/products`)}`}  style={{maxWidth: '110px'}} onClick={() => setMode('Post Production')}>     
                Post Production
            </button>
 
        </>
    )
}
