// src/pages/TikTokSuccessPage.js
import React from 'react';
// import { useLocation } from 'react-router-dom';
import UserRoute from '../../Routes/Auth';
import "./TikTokSuccessPage.scss";

const TikTokSuccessPage = () => {
  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  // const token = searchParams.get('token');

  // useEffect(() => {
  //   const sendTokenToBackend = async () => {
  //     if (token) {
  //       try {
  //         const response = await fetch('/tiktok/store-token', {
  //           method: 'POST',
  //           headers: {
  //             'Content-Type': 'application/json',
  //           },
  //           body: JSON.stringify({ token }),
  //         });

  //         if (!response.ok) {
  //           console.error("Failed to store token in the backend");
  //         }
  //       } catch (error) {
  //         console.error("Error storing token in backend:", error);
  //       }
  //     }
  //   };

  //   sendTokenToBackend();
  // }, [token]);

  return (
    <UserRoute>
      <div className="tiktok-success">
        <h1 className="tiktok-success__title">Account Linked</h1>
        <p className="tiktok-success__message">
          Your TikTok account has been successfully linked.
        </p>
        {/* {token && (
          <p className="tiktok-success__token">
            Your token: <span className="tiktok-success__token-value">{token}</span>
          </p>
        )} */}
      </div>
    </UserRoute>
  );
};

export default TikTokSuccessPage;
