import React, { useState, useEffect, useContext } from "react";
import UserRoute from "../../Routes/Auth";
import Navigation from "../../Components/Navigation/Navigation";
import "./AddCampaignTeamMember.scss";
import Card from "../../Components/Card/Card";
import InputField from "../../Components/InputField/InputField";
import Button from "../../Components/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import TextArea from "../../Components/TextArea/TextArea";
import Resizer from "react-image-file-resizer";
import teamDefaultDark from "../../assets/defaults/team.png";
import ThemeContext from "../../context/ThemeContext";
import teamDefaultLight from "../../assets/defaults/teamLight.png";
import Select from "react-select";

export default function AddCampaignTeamMember() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [campaignStatus, setCampaignStatus] = useState(null);
  const { themeMode } = useContext(ThemeContext);
  const themeClass = themeMode === "light" ? "light-theme" : "dark-theme";

  const [teamMember, setTeamMember] = useState({
    name: "",
    jobTitle: "",
    email: "",
    instagram: "",
    number: "",
    link: "",
    chequeai: "",
    description: "",
    category: "Pre Production",
    startDate: new Date().toISOString().slice(0, 10),
    endDate: new Date(Date.now() + 3 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10),
  });

  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTeamMember((prevTeamMember) => ({
      ...prevTeamMember,
      [name]: value,
    }));
  };

  const roleOptions = [
    { value: "Pre Production", label: "Pre Production" },
    { value: "Production", label: "Production" },
    { value: "Post Production", label: "Post Production" },
  ];

  const handleRoleChange = (selectedOption) => {
    setTeamMember((prevTeamMember) => ({
      ...prevTeamMember,
      category: selectedOption.value,
    }));
  };

  const styles = {
    control: (base) => ({
      ...base,
      backgroundColor: "#0a0a0a",
      borderRadius: "4px",
      border: "none",
      boxShadow: "none",
      width: "300px",
      height: "30px",
      "&:hover": {
        border: "none",
        boxShadow: "none",
      },
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: "black",
      borderRadius: "4px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
      "&:hover": {
        backgroundColor: "neutral70",
      },
    }),
    option: (base, state) => ({
      ...base,
      color: "darkgray",
      backgroundColor: state.isFocused ? "neutral70" : "black",
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.1)",
      },
    }),
    singleValue: (base) => ({
      ...base,
      color: "darkgray",
      fontSize: "12px",
    }),
    placeholder: (base) => ({
      ...base,
      color: "darkgray",
      fontSize: "12px",
    }),
    input: (base) => ({
      ...base,
      color: "darkgray",
      fontSize: "12px",
    }),
  };

  const lightMode = {
    control: (base) => ({
      ...base,
      backgroundColor: "#E1E1E1",
      borderRadius: "4px",
      border: "none",
      boxShadow: "none",
      "&:hover": {
        border: "none",
        boxShadow: "none",
      },
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: "#E1E1E1",
      borderRadius: "4px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
      "&:hover": {
        backgroundColor: "neutral70",
      },
    }),
    option: (base, state) => ({
      ...base,
      color: "black",
      backgroundColor: state.isFocused ? "rgba(150, 150, 150, 1)" : "#E1E1E1",
      "&:hover": {
        backgroundColor: "rgba(200, 200, 200, 1)",
      },
    }),
    singleValue: (base) => ({
      ...base,
      color: "black",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "black",
    }),
  };

  const teamImage = themeMode === "light" ? teamDefaultLight : teamDefaultDark;

  const handleAddTeamMember = async (e) => {
    e.preventDefault();

    if (image) {
      Resizer.imageFileResizer(
        image,
        500,
        500,
        "PNG",
        100,
        0,
        async (uri) => {
          try {
            let { data } = await axios.post(
              `${process.env.REACT_APP_API}/campaigns/${id}/teams/add`,
              {
                image: uri,
                teamMember,
              }
            );
            setImage(data);
            navigate(-1);
          } catch (err) {
            console.log("Failed to upload image. Try later.", err);
          }
        }
      );
    } else {
      try {
        await axios.post(
          `${process.env.REACT_APP_API}/campaigns/${id}/teams/add`,
          {
            teamMember,
          }
        );
        navigate(-1);
      } catch (err) {
        setError("Failed to add team member. Try later.");
        console.log("Failed to add team member. Try later.", err);
      }
    }
  };

  const validateImage = (e) => {
    const file = e.target.files[0];
    if (file.size > 2048576) {
      console.error("Max file size 2MB");
    } else {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  useEffect(() => {
    const fetchCampaignStatus = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/campaigns/${id}/campaign-status`
        );
        setCampaignStatus(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    if (id && !campaignStatus) {
      fetchCampaignStatus();
    }
  }, [id, campaignStatus]);

  return (
    <UserRoute>
      <div className="add-cp-teams-pg">
        <Navigation title={"Add Team Member"} back={true} />
        {campaignStatus !== "Completed" &&
        campaignStatus !== "Post Campaign" ? (
          <Card className="card--input">
            <div className="add-cp-teams-pg__profile-img">
              <label htmlFor="file">
                <img
                  className="add-camp-pg__img"
                  src={imagePreview ? imagePreview : teamImage}
                  alt="profile"
                />
              </label>
              <input
                type="file"
                id="file"
                hidden
                accept="image/png, image/jpeg, image/webp"
                onChange={validateImage}
              />
            </div>
            <div className="add-cp-teams-pg__div">
              <InputField
                label="Start Date"
                name="startDate"
                type="date"
                value={teamMember.startDate}
                onChange={handleInputChange}
              />
              <InputField
                label="End Date"
                name="endDate"
                type="date"
                value={teamMember.endDate}
                onChange={handleInputChange}
              />
            </div>
            <div className="add-cp-teams-pg__div">
              <label className="add-cp-teams-pg__label">
                Category
              </label>
              <Select
                id="role-select"
                name="role"
                value={roleOptions.find(option => option.value === teamMember.category)}
                onChange={handleRoleChange}
                options={roleOptions}
                placeholder="Select a Production Category"
                styles={themeMode === "light" ? lightMode : styles}
                className={`team-management__select team-management__select--${themeClass}`}
              />
              <InputField
                label="Job Title"
                name="jobTitle"
                type="text"
                value={teamMember.jobTitle}
                onChange={handleInputChange}
              />
            </div>
            <div className="add-cp-teams-pg__div">
              <InputField
                label="Name"
                name="name"
                type="text"
                value={teamMember.name}
                onChange={handleInputChange}
              />
              <InputField
                label="Number"
                name="number"
                type="text"
                value={teamMember.number}
                onChange={handleInputChange}
              />
            </div>

            <div className="add-cp-teams-pg__div">
              <InputField
                label="Email"
                name="email"
                type="text"
                value={teamMember.email}
                onChange={handleInputChange}
              />

              <InputField
                label="Instagram"
                name="instagram"
                type="text"
                value={teamMember.instagram}
                onChange={handleInputChange}
              />
            </div>

            <div className="add-cp-teams-pg__div">
              <InputField
                label="Link"
                name="link"
                type="url"
                value={teamMember.link}
                onChange={handleInputChange}
              />
              <InputField
                label="Chequeai"
                name="chequeai"
                type="text"
                value={teamMember.chequeai}
                onChange={handleInputChange}
              />
            </div>
            <TextArea
              label="Description"
              name="description"
              type="text"
              value={teamMember.description}
              onChange={handleInputChange}
            />

            <Button modifier="button" onClick={handleAddTeamMember}>
              Add Team Member
            </Button>
            {error && <div className="error-message">{error}</div>}

          </Card>
        ) : (
          <Card
            className={`card--link campaign-complete campaign-complete--${themeClass}`}
          >
            <p>
              <span>Campaign</span> has been
            </p>
            <p>{campaignStatus}</p>
          </Card>
        )}
      </div>
    </UserRoute>
  );
}
